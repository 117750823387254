import React, { useEffect, useState } from "react";
import Layout from "../../components/Container/Layout";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomers, fetchResponsible } from "../../redux/actions/customer_ac";
import Avatar from "../../components/anBrains/Avatar";
import { fetchRoles } from "../../redux/actions/position_ac";
import ContentLoader from "react-content-loader";
import Input from "../../components/anBrains/Input";
import Button from "../../components/anBrains/Button";
import { crud, fileFromType } from "../../config/constants";
import { baseUrl } from "../../config";
import firm from "../../images/firm.png";
import { TrushIcon, PlusIcon, EditIcon } from "../../components/icons";
import { responsibleType, customerMenus } from "../../redux/action_types";
import EmployeeDetailInsideMenu from "../../components/employee/EmployeeDetailInsideMenu";
import CustomerEvent from "../../components/customer/CustomerEvent";
import File from "../../components/project/File";

export default function CustomerDetail(props) {
  const {
    match: { params },
  } = props;
  const dispatch = useDispatch();
  const trans = useSelector((state) => state.trans);
  const theme = useSelector((state) => state.theme);
  const current = useSelector((state) => state.current);
  const positions = useSelector((state) => state.position);
  const [request, setRequest] = useState({ isCustomerDetail: true, id: params.id, type: -1, rowCount: 14, pageCount: 0, search: "", sort: "id", desc: true });
  const { menus } = useSelector((state) => state.customer);

  useEffect(() => {
    dispatch(fetchCustomers(request, crud.list, {}, trans));
  }, []);

  useEffect(() => {
    setField({
      ...current.item,
      photo: baseUrl + current.item.photo,
      fax: "",
      superVisorId: current.item.supervisorId,
    });
    if (!positions.length > 0) dispatch(fetchRoles());
  }, [current.item]);

  var fields = {
    type: 0,
    name: "",
    superVisorId: 0,
    email: "",
    phone: "",
    address: "",
    fax: "",
    photo: "",
    taxOffice: "",
    taxNumber: "",
  };
  const [field, setField] = useState(fields);

  const errors = {
    nameError: "",
    typeError: "",
    emailError: "",
    phoneError: "",
    superVisorIdError: "",
    addressError: "",
    photoError: "",
    taxOfficeError: "",
    taxNumberError: "",
  };
  const [err, setErr] = useState(errors);

  let validate = () => {
    // if (field.code.length < 3 || field.code.length > 20) {
    //     setErr({ ...errors, codeError: trans.toShort })
    //     return false
    // }

    if (field.name === null || field.name.length < 3 || field.name.length > 50) {
      setErr({ ...errors, nameError: trans.toShort });
      return false;
    }

    // if (field.phone === null || field.phone.length < 10 || field.phone.length > 20) {
    //     setErr({ ...errors, phoneError: trans.pleaseEnterValidPhoneAddress })
    //     return false
    // }

    // if (field.fax.length < 10 || field.fax.length > 20) {
    //     setErr({ ...errors, faxError: trans.pleaseEnterValidPhoneAddress })
    //     return false
    // }

    // if (field.email === null || !field.email.includes('@') || field.email.length > 40) {
    //     setErr({ ...errors, mailError: trans.pleaseEnterValidEmailAddress })
    //     return false
    // }

    // if (field.address === null || field.address.length < 5 || field.address.length > 50) {
    //     setErr({ ...errors, addressError: trans.toShort })
    //     return false
    // }

    // if (field.taxOffice === null || field.taxOffice.length < 5 || field.taxOffice.length > 50) {
    //     setErr({ ...errors, taxOfficeError: trans.toShort })
    //     return false
    // }
    // if (field.taxNumber === null || field.taxNumber.length < 5 || field.taxNumber.length > 50) {
    //     setErr({ ...errors, taxNumberError: trans.toShort })
    //     return false
    // }

    if (field.superVisorId <= 0) {
      setErr({ ...errors, superVisorIdError: trans.pleaseEnterValid });
      return false;
    }
    if (field.type < 0) {
      setErr({ ...errors, typeError: trans.pleaseEnterValid });
      return false;
    }
    return true;
  };

  const register = (e) => {
    e.preventDefault();
    if (validate()) {
      setErr(errors);
      dispatch(
        fetchCustomers(
          {},
          crud.update,
          {
            ...field,
            photo:
              current.item.photo && (current.item.photo.includes("http:") || current.item.photo.includes("https:"))
                ? current.item.photo.replace(baseUrl, "")
                : current.item.photo,
          },
          trans
        )
      );
    }
  };

  const changeUrl = (url) => {
    setField({ ...field, photo: url });
  };
  const companyChange = (e) => {
    setField({ ...field, type: e.target.value });
  };
  const superVisorChange = (e) => {
    setField({ ...field, superVisorId: e.target.value });
  };
  const loader = useSelector((state) => state.loader);

  const deleteConfirm = (item) => {
    dispatch(fetchResponsible({}, crud.delete, item, trans));
    let responsiblePersons = current.item.responsiblePersons.filter((e) => {
      return e.id !== item.id;
    });
    dispatch({ type: responsibleType, payload: responsiblePersons });
  };

  var ress = {
    name: "",
    email: "",
    phone: "",
  };
  const [responsiblePerson, setResponsiblePerson] = useState(ress);

  const resErrors = {
    nameError: "",
    emailError: "",
    phoneError: "",
  };
  const [resErr, setResErr] = useState(resErrors);

  const editItem = (e) => {
    setResponsiblePerson(e);
    //setIsAddOpen(true);
    setIsEditResponsible(true);
  };

  const [isEditResponsible, setIsEditResponsible] = useState(false);

  let resValidate = () => {
    if (responsiblePerson.name === null || responsiblePerson.name < 3 || responsiblePerson.name > 50) {
      setResErr({ ...resErrors, nameError: trans.toShort });
      return false;
    }
    if (responsiblePerson.phone === null || responsiblePerson.phone.length < 10 || responsiblePerson.phone.length > 20) {
      setResErr({ ...resErrors, phoneError: trans.pleaseEnterValidPhoneAddress });
      return false;
    }
    if (responsiblePerson.email === null || !responsiblePerson.email.includes("@") || responsiblePerson.email.length > 40) {
      setResErr({ ...resErrors, emailError: trans.pleaseEnterValidEmailAddress });
      return false;
    }
    return true;
  };

  const addResponsiblePerson = (e) => {
    e.preventDefault();
    if (resValidate()) {
      setResErr(resErrors);
      dispatch(fetchResponsible({}, crud.create, { ...responsiblePerson, customerId: params.id }, trans));
      setResponsiblePerson("");
    }
  };

  const updateResponsiblePerson = (e) => {
    e.preventDefault();
    if (resValidate()) {
      setResErr(resErrors);

      dispatch(fetchResponsible({}, crud.update, { ...responsiblePerson, customerId: params.id }, trans));
      let responsiblePersons2 = current.item.responsiblePersons.map((e) => {
        if (e.id === responsiblePerson.id) e = responsiblePerson;
        return e;
      });
      dispatch({ type: responsibleType, payload: responsiblePersons2 });
      setResponsiblePerson("");
      setIsEditResponsible(false);
    }
  };

  const changeTab = (content) => {
    menus.map((x) => (x.active = false));
    menus.find((x) => x.content === content).active = true;
    dispatch({ type: customerMenus, payload: menus });
  };

  return (
    <Layout>
      {field && (
        <div className="custemer_detail_container">
          <div className="customer_detail_profile">
            {current && current.item && <Avatar isCompany={true} />}
            <div className="customer_detail_profile_information">
              <span>{field.name}</span>
              <span>
                {current && current.item && current.item.responsiblePersons && current.item.responsiblePersons.length > 0
                  ? current.item.responsiblePersons[0].name
                  : null}
              </span>
              <span>
                {current && current.item && current.item.responsiblePersons && current.item.responsiblePersons.length > 0
                  ? current.item.responsiblePersons[0].phone
                  : field.phone}
              </span>
            </div>
          </div>
          <div className="employee_detail_right" style={{ width: "100%" }}>
            <ul className="inside_menu">
              {menus && menus.length > 0
                ? menus.map((menu, i) => {
                    return (
                      <EmployeeDetailInsideMenu
                        id={menu.id}
                        key={i}
                        changeTab={changeTab}
                        active={menu.active}
                        content={menu.content}
                        name={menu.name[trans.lang]}
                      />
                    );
                  })
                : null}
            </ul>
            <div className="customer_detail_content" style={{ padding: "7px" }}>
              {menus.find((x) => x.content === "detail").active === true ? (
                <div className="detail">
                  <div className="customer_detail_information_container">
                    <div className="customer_detail_information">
                      <Input
                        placeholder={trans.name}
                        name={trans.name}
                        header={trans.name}
                        value={field.name}
                        onChange={(e) => setField({ ...field, name: e.target.value })}
                        error={err.nameError}
                      />
                      <Input
                        placeholder={"5xxxxxxx"}
                        type={"number"}
                        name={trans.phone}
                        header={trans.phone}
                        value={field.phone}
                        classes={"customer_input"}
                        onChange={(e) => setField({ ...field, phone: e.target.value })}
                        error={err.phoneError}
                      />
                      <Input
                        placeholder={trans.email}
                        name={trans.email}
                        header={trans.email}
                        value={field.email}
                        classes={"customer_input"}
                        onChange={(e) => setField({ ...field, email: e.target.value })}
                        error={err.mailError}
                      />
                      {/* <Input
                                placeholder={"xxxxxxxx"}
                                type={"number"}
                                name={trans.fax}
                                header={trans.fax}
                                value={field.fax}
                                onChange={e => setField({ ...field, fax: e.target.value })}
                                error={err.faxError} /> */}
                      <Input
                        placeholder={trans.address}
                        name={trans.address}
                        header={trans.address}
                        value={field.address}
                        classes={"customer_input"}
                        onChange={(e) => setField({ ...field, address: e.target.value })}
                        error={err.addressError}
                      />
                    </div>
                    <div className="customer_detail_information">
                      <Input
                        placeholder={trans.taxoffice}
                        name={trans.taxoffice}
                        header={trans.taxoffice}
                        value={field.taxOffice}
                        onChange={(e) => setField({ ...field, taxOffice: e.target.value })}
                        error={err.taxOfficeError}
                      />

                      <Input
                        placeholder={trans.taxnumber}
                        type={"number"}
                        name={trans.taxnumber}
                        header={trans.taxnumber}
                        value={field.taxNumber}
                        min={0}
                        max={1000}
                        // maxLength={15}
                        minLength={5}
                        classes={"customer_input"}
                        onChange={(e) => setField({ ...field, taxNumber: e.target.value })}
                        error={err.taxNumberError}
                      />
                      <div className="input-container">
                        <span className="input_header customer_input">
                          Supervisor
                          {err.superVisorIdError && <p className="input_danger_message">{err.superVisorIdError}</p>}
                        </span>
                        <select className="input select" onChange={superVisorChange} value={field.superVisorId}>
                          <option value={0}>{trans.select}</option>
                          {current.users && current.users.length
                            ? current.users.map((u, i) => {
                                return (
                                  <option key={"superVisor" + i} value={u.id}>
                                    {u.name}
                                  </option>
                                );
                              })
                            : null}
                        </select>
                      </div>
                      <div className="input-container">
                        <span className="input_header customer_input">
                          {trans.partnerType}
                          {err.typeError && <p className="input_danger_message">{err.typeError}</p>}
                        </span>
                        <select className="input select" onChange={(e) => companyChange(e)} value={field.type}>
                          <option value={0}>{trans.customer}</option>
                          <option value={1}>{trans.supplier}</option>
                        </select>
                      </div>

                      <Button classes={"btn-primary customer_button"} onClick={register}>
                        {trans.save}
                      </Button>
                    </div>
                  </div>
                  <div className="responsiple_container" style={{ borderTop: "none" }}>
                    <div className="responsiple_container_header">{trans.responsiblePersons}</div>
                    <div className="table">
                      <table>
                        <thead>
                          <tr>
                            <th>{trans.name}</th>
                            <th>{trans.phone}</th>
                            <th>{trans.email}</th>
                            <th>{trans.settings}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Input
                                placeholder={trans.name}
                                name={trans.name}
                                value={responsiblePerson.name}
                                error={resErr.nameError}
                                onChange={(e) => setResponsiblePerson({ ...responsiblePerson, name: e.target.value })}
                              />
                              <span className="error-message">{resErr.nameError}</span>
                            </td>
                            <td>
                              <Input
                                placeholder={"5xxxxxxx"}
                                type={"number"}
                                name={trans.phone}
                                value={responsiblePerson.phone}
                                error={resErr.phoneError}
                                onChange={(e) => setResponsiblePerson({ ...responsiblePerson, phone: e.target.value })}
                              />
                              <span className="error-message">{resErr.phoneError}</span>
                            </td>
                            <td>
                              <Input
                                placeholder={trans.email}
                                name={trans.email}
                                value={responsiblePerson.email}
                                error={resErr.emailError}
                                onChange={(e) => setResponsiblePerson({ ...responsiblePerson, email: e.target.value })}
                              />
                              <span className="error-message">{resErr.emailError}</span>
                            </td>
                            <td>
                              {!isEditResponsible ? (
                                <Button icon={<PlusIcon color="rgba(96, 168, 221, 1)" />} classes="btn-basic" onClick={addResponsiblePerson}>
                                  {trans.add}
                                </Button>
                              ) : (
                                <Button classes="btn-basic" onClick={updateResponsiblePerson}>
                                  {trans.update}
                                </Button>
                              )}
                            </td>
                          </tr>
                          {current &&
                            current.item &&
                            current.item.responsiblePersons &&
                            current.item.responsiblePersons.length > 0 &&
                            current.item.responsiblePersons.map((e, i) => {
                              return (
                                <tr className="row" key={i}>
                                  <td className="pl-10">{e.name}</td>
                                  <td className="pl-10">{e.phone}</td>
                                  <td className="pl-10">{e.email}</td>
                                  <td className="pl-10">
                                    <button onClick={() => editItem(e)}>
                                      <EditIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />
                                    </button>
                                    <button onClick={() => deleteConfirm(e)}>
                                      <TrushIcon color={theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)"} />
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : null}
              {menus.find((x) => x.content === "event").active === true ? (
                <div>
                  <CustomerEvent customerId={params.id} />
                </div>
              ) : null}
              {menus.find((x) => x.content === "file").active === true ? (
                <div>
                  <File projectId={0} workOrderId={0} customerId={params.id} fileFromType={fileFromType.customer} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
