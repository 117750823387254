import React, { useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { crud } from '../../config/constants';
import Switch from '../anBrains/Switch'
import { departmentType } from '../../redux/action_types'
import Input from '../anBrains/Input'
import { fetchDepartment } from '../../redux/actions/department_ac'
export default function FilterByDepartments(props) {
    const trans = useSelector(state => state.trans)
    const { list } = useSelector(state => state.department)
    const dispatch = useDispatch();
    useEffect(() => {
        if (list.length === 0) {
            dispatch(fetchDepartment({}, crud.list, {}, trans))
        }
    }, [])

    const changeDepartment = (e, n) => {
        for (let index = 0; index < list.length; index++) {
            if (list[index].name === n.name)
                list[index].selected = (list[index].selected === true ? false : true);
        }
        dispatch({ type: departmentType, payload: list })
        props.filterByDepartments(list.filter(x => x.selected === true))
    }
    const [search, setSearch] = useState("")

    return (
        <div className="filter_by_users_menu">
            <Input placeholder={trans.search}
                name={trans.department}
                // header={trans.projectName}
                value={search}
                onChange={e => setSearch(e.target.value)} />
            <ul>
                {list && list.filter(x => x.name.toLocaleLowerCase('tr').includes(search)).map((n, i) => {
                    return (
                        <li key={i} className={n.color ? "licolor" : ""}>
                            <Switch
                                id={"langSwitch" + i}
                                value={n.selected}
                                onChange={e => changeDepartment(e, n)} />
                            {n.name}
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
