import { employeeDocumentType, addEmployeeDocumentType,requiredDocumentsType, employeeDocumentGroupType } from '../action_types'
const reducer = (state = { groups: [], list: [], requiredDocuments: [] }, action) => {
    switch (action.type) {
        case employeeDocumentType:
            return { ...state, list: action.payload };
        case employeeDocumentGroupType:
            return { ...state, groups: action.payload };
        case requiredDocumentsType:
            return { ...state, requiredDocuments: action.payload };
        case addEmployeeDocumentType:
            return { ...state, list: [...state.list, action.payload] };
        default:
            return state;
    }
};
export default reducer;