import React, { useRef, useEffect } from "react";
// eslint-disable-next-line no-unused-vars
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { breadcrumbType, pageTitleType, addBreadcrumbType, userFileTypeType, getUserType } from "../../redux/action_types";
import { left_menu_items } from "../../config/constants";
import {
  HomeIcon,
  ProjectMenuIcon,
  CustomerManagementMenuIcon,
  CompanyCultureMenuIcon,
  SuggestionBoxMenuIcon,
  TaskMenuIcon,
  DairyMenuIcon,
  ChatMenuIcon,
  EmployeeMenuIcon,
  EmployeeManagementMenuIcon,
  ReportMenuIcon,
  DefinitionMenuIcon,
  SettingsMenuIcon,
  EmployeeTaskMenuIcon,
  SupportMenuIcon,
  ChevronBottomIcon,
} from "../icons";
import { support } from "../../icons/proskop";

export default function LeftMenuItem(props) {
  const { path, name, active, subs, subOpen, pathActive } = props;
  const trans = useSelector((state) => state.trans);

  const size = useSelector((state) => state.size);
  const theme = useSelector((state) => state.theme);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { breadcrumbs } = useSelector((state) => state.current);

  const getBreadcrumb = (i, name, path, isSub, pathActive) => {
    props.setRightMenuOpen(true);
    if (breadcrumbs.some((b) => b.name === name)) {
    } else if (!isSub) {
      dispatch({ type: breadcrumbType, payload: [{ name, path, pathActive }] });
      dispatch({ type: pageTitleType, payload: name });
    } else if (isSub && breadcrumbs.length === 1) {
      dispatch({ type: addBreadcrumbType, payload: { name, path, pathActive } });
      dispatch({ type: pageTitleType, payload: name });
    } else if (isSub && breadcrumbs.length === 2) {
      dispatch({ type: breadcrumbType, payload: [breadcrumbs[0], { name, path, pathActive }] });
      dispatch({ type: pageTitleType, payload: name });
    }

    if (!isSub) setMenuActive(path);
  };
  const setMenuActive = (path) => {
    left_menu_items.map((x) => (x.active = false));
    left_menu_items.filter((a) => a.path !== path).map((x) => (x.subOpen = false));
    left_menu_items.find((x) => x.path === path).active = true;
    left_menu_items.find((x) => x.path === path).subOpen = !left_menu_items.find((x) => x.path === path).subOpen;
  };

  const getIcon = (icon) => {
    switch (icon) {
      case "/pano":
        return <HomeIcon color={active ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#555"} />;
      case "/workorders":
        return <TaskMenuIcon color={active ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#555"} />;
      case "/diary":
        return (
          <DairyMenuIcon color={active ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#555"} />
        );
      case "/customer-management":
        return (
          <CustomerManagementMenuIcon
            color={active ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#555"}
          />
        );
      case "/meeting-management":
        return <ChatMenuIcon color={active ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#555"} />;
      case "/projects":
        return (
          <ProjectMenuIcon color={active ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#555"} />
        );
      case "/company-culture":
        return (
          <CompanyCultureMenuIcon
            color={active ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#555"}
          />
        );
      case "/suggestion-box":
        return (
          <SuggestionBoxMenuIcon
            color={active ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#555"}
          />
        );
      case "/personal-performance":
        return (
          <EmployeeMenuIcon color={active ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#555"} />
        );
      case "/personal-management":
        return (
          <EmployeeManagementMenuIcon
            color={active ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#555"}
          />
        );
      case "/report":
        return (
          <ReportMenuIcon color={active ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#555"} />
        );
      case "/report2":
        return (
          <ReportMenuIcon color={active ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#555"} />
        );
      case "/definitions":
        return (
          <DefinitionMenuIcon color={active ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#555"} />
        );
      case "/settings":
        return (
          <SettingsMenuIcon color={active ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#555"} />
        );
      case "/personal-task":
        return (
          <EmployeeTaskMenuIcon
            color={active ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#555"}
          />
        );
      case "/support":
        return (
          <SupportMenuIcon color={active ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#555"} />
        );
      default:
        break;
    }
  };
  const setActiveSubMenu = (i, path) => {
    left_menu_items.find((x) => x.path === path).subs.map((s) => (s.active = false));
    left_menu_items.find((x) => x.path === path).subs[i].active = true;
    left_menu_items.find((x) => x.path === path).subOpen = left_menu_items.find((x) => x.path === path).subOpen === true ? false : true;
    // dispatch({ type: getUserType, payload: user })
  };

  const handleClickOutside = () => {
    props.setRightMenuOpen(!props.rightMenuOpen);
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, handleClickOutside);
  // console.log(subs);
  return subs && subs.length > 0 ? (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="#">
      <li className={active ? "left_menu_active" : ""} onClick={() => getBreadcrumb(0, name, path, false, pathActive)} title={name}>
        <div className="left_menu_base">
          {getIcon(path)}
          <span>{size.isOpenText && name}</span>
          <span className={subOpen === true && size.isOpenLeftMenuItem === true ? "chevron_open" : ""}>
            <ChevronBottomIcon color={theme === "dark" ? "#e5e5e5" : "#000"} />
          </span>
        </div>
        {size.isOpenLeftMenuItem && subOpen && size.isOpenText ? (
          <ul className="left_sub_menu">
            {subs.map((s, i) => {
              if (
                user &&
                user.menus &&
                user.menus.find((x) => x.path === path).subs &&
                user.menus.find((x) => x.path === path).subs.some((a) => a.path === s.path)
              ) {
                return (
                  <Link key={"left_sub_menu" + i} to={s.path} onClick={() => getBreadcrumb(i, s[trans.lang], s.path, true, s.pathActive)}>
                    <li
                      onClick={() => {
                        setActiveSubMenu(i, path);
                      }}
                      className={s.active === false ? "left_sub_menu_item" : "left_sub_menu_item_active left_sub_menu_item"}
                    >
                      <div className={s.active ? "circle circleactive" : "circle"}></div>
                      <div>{s[trans.lang]}</div>
                    </li>
                  </Link>
                );
              }

              if (user && path === "/support" && subs.some((v) => v.path == "/support/whatsapp")) {
                var wppath;
                if (s.path === "/support/whatsapp") wppath = "https://api.whatsapp.com/send?phone=+905534858606&text=" + user.email + trans.whatsappMesaage;
                else if (s.path === "/support/youtube") {
                  wppath = "https://www.youtube.com/channel/UCEFOetcmGtotUcjTJpXZi_A";
                } else if (s.path === "/support/guide") {
                  wppath = "http://api.timezeta.com/Guide.pdf";
                }
                return (
                  <a key={"left_sub_menu" + i} href={wppath} target="_blank" onClick={() => getBreadcrumb(i, s[trans.lang], s.path, true, s.pathActive)}>
                    <li
                      onClick={() => {
                        setActiveSubMenu(i, path);
                      }}
                      className={s.active === false ? "left_sub_menu_item" : "left_sub_menu_item_active left_sub_menu_item"}
                    >
                      <div className={s.active ? "circle circleactive" : "circle"}></div>
                      <div>{s[trans.lang]}</div>
                    </li>
                  </a>
                );
              }
            })}
          </ul>
        ) : null}
        {props.rightMenuOpen && !size.isOpenLeftMenuItem && subOpen ? (
          <ul className="left_sub_menu left_sub_menu_when_close" ref={wrapperRef}>
            {subs.map((s, i) => {
              console.log(user);
              if (
                user &&
                user.menus &&
                user.menus.some((x) => x.path === path) &&
                user.menus.find((x) => x.path === path).subs.some((a) => a.path === s.path)
              ) {
                return (
                  <Link
                    key={"left_sub_menu" + i}
                    to={s.path}
                    onClick={() => {
                      getBreadcrumb(i, s[trans.lang], s.path, true);
                      setActiveSubMenu(i, path);
                    }}
                  >
                    <li className={s.active === false ? "left_sub_menu_item" : "left_sub_menu_item_active left_sub_menu_item"} title={s[trans.lang]}>
                      <div className={s.active ? "circle circleactive" : "circle"}></div>
                      <div>{s[trans.lang]}</div>
                    </li>
                  </Link>
                );
              }
            })}
          </ul>
        ) : null}
      </li>
    </a>
  ) : (
    <Link
      to={
        path === "/support"
          ? { pathname: "https://api.whatsapp.com/send?phone=+905413497556&text=" + user.email + "%20yardımcı%20olabilir%20misiniz%20?" }
          : path
      }
      target={path === "/support" ? "_blank" : null}
      onClick={() => getBreadcrumb(0, name, path, false)}
    >
      <li className={active ? "left_menu_active" : ""} title={name}>
        <div className="left_menu_base">
          {getIcon(path)}
          <span>{size.isOpenText && name}</span>
        </div>
      </li>
    </Link>
  );
}
function useOutsideAlerter(ref, onClick) {
  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      onClick();
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
}
