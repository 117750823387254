import { tr, en } from "../../config/translation"
const reducer = (state = tr, action) => {
    switch (action.type) {
        case "tr":
            return tr;
        case 'en':
            return en;
        default:
            return state;
    }
};
export default reducer;