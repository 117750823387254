import React, { useState } from 'react'
import Input from '../../components/anBrains/Input'
import { useSelector, useDispatch } from 'react-redux'
import { fetchRequestDemo } from '../../redux/actions/request_form_ac'
import { SuccessIcon } from '../../components/icons'
import video from '../../images/login.mp4';
import Switch from '../../components/anBrains/Switch';
import Modal from '../../components/anBrains/Modal'
import TermOfUseDetail from '../../components/requestForm/TermOfUseDetail'
import { Select as SpecialSelect } from 'antd';
import 'antd/dist/antd.css';
const { Option, OptGroup } = SpecialSelect;

export default function RequestForm(props) {
    const trans = useSelector(state => state.trans)
    const result = useSelector(state => state.result)
    const loader = useSelector(state => state.loader)
    const current = useSelector(state => state.current)
    const [renderCount, setRenderCount] = useState(0)
    const dispatch = useDispatch()

    var fields = {
        name: "",
        surName: "",
        email: "",
        companyName: "",
        phone: "",
        password: "",
        newPassword: "",
        termofuse: true,
        advertisement: true,
        companytype: ""
    }
    const [field, setField] = useState(fields)

    const errors = {
        nameError: "",
        surNameError: "",
        emailError: "",
        companyNameError: "",
        phoneError: "",
        passwordError: "",
        newPasswordError: "",
        passwordMatchError: "",
        termofuseError: "",
        companytypeError: ""
    }

    const options = [{ label: trans.engineering, value: 1 },
    { label: trans.productionsector, value: 2 },
    { label: trans.servicesector, value: 3 },
    { label:  trans.civilsocietyorganization, value: 4 },
    { label:  trans.universty, value: 5 },
    { label: trans.publicinstution, value: 6 }]

    const [companyTypeControl, setcompanyTypeControl] = useState(false)

    const [termofuse, settermofuse] = useState(false)

    const openTermofUserDetail = () => {
        settermofuse(!termofuse)
    }

    const setSelectedGroup = (c) => {
        setField({ ...field, companytype: c })
        setcompanyTypeControl(false)
    }

    const [err, setErr] = useState(errors)
    const [message, setMessage] = useState(null)
    let validate = () => {
        if (field.name.length < 2 || field.name.length > 30) {
            setErr({ ...errors, nameError: trans.pleaseEnterValid })
            setMessage(trans.pleaseEnterValid)
            return false
        }
        if (field.surName.length < 2 || field.surName.length > 30) {
            setErr({ ...errors, surNameError: trans.pleaseEnterValid })
            setMessage(trans.pleaseEnterValid)
            return false
        }
        if (field.companyName.length < 3 || field.companyName.length > 30) {
            setErr({ ...errors, companyNameError: trans.pleaseEnterValid })
            setMessage(trans.pleaseEnterValid)
            return false
        }
        if (!field.email.includes('@') || field.email.length > 50) {
            setErr({ ...errors, emailError: trans.pleaseEnterValidEmailAddress })
            setMessage(trans.pleaseEnterValidEmailAddress)
            return false
        }
        if (!field.phone || field.phone.length > 50) {
            setErr({ ...errors, phoneError: trans.pleaseEnterValid })
            setMessage(trans.pleaseEnterValid)
            return false
        }

        if (field.password.length < 6) {
            setErr({ ...errors, passwordError: trans.yourpasswordistooshort })
            setMessage(trans.yourpasswordistooshort)
            return false
        }
        if (field.newPassword.length < 6) {
            setErr({ ...errors, newPasswordError: trans.yourpasswordistooshort })
            setMessage(trans.yourpasswordistooshort)
            return false
        }

        if (field.password !== field.newPassword) {
            setErr({ ...errors, passwordMatchError: trans.yourpassworddoesnotmatch })
            setMessage(trans.yourpassworddoesnotmatch)
            return false
        }

        if (field.termofuse == false) {
            setErr({ ...errors, termofuseError: trans.termofuseaccept })
            setMessage(trans.termofuseaccept)
            return false
        }

        if (field.companytype == "") {
            setErr({ ...errors, companytypeError: trans.companytypeerror })
            setMessage(trans.companytypeerror)
            setcompanyTypeControl(true)
            return false
        }

        return true
    }

    const sendRequest = e => {
        setRenderCount(renderCount + 1)
        e.preventDefault();
        if (validate()) {
            setErr(errors)
            setMessage("")
            dispatch(fetchRequestDemo(field))
        }
    }

    return (
        <div className="login_container">
            <div className="login_image">

                <video src={video} autoPlay muted loop> </video>
                {/* <div className="overlay"></div> */}
                <div className="request_form">
                    {!result.success ?
                        <div className="request_form_inside"><h2>{trans.requestademo}</h2>
                            <p>
                                {trans.trialperode}
                                <br />{trans.yourdatawillnotbelost}
                                <br /> {trans.choosingus}
                            </p>
                            <Input
                                placeholder={trans.name}
                                name={trans.name}
                                value={field.name}
                                onChange={e => setField({ ...field, name: e.target.value })}
                                error={err.nameError} />
                            <Input
                                placeholder={trans.surname}
                                name={trans.surname}
                                value={field.surName}
                                onChange={e => setField({ ...field, surName: e.target.value })}
                                error={err.surNameError} />
                            <Input
                                placeholder={trans.companyname}
                                name={trans.companyname}
                                value={field.companyName}
                                onChange={e => setField({ ...field, companyName: e.target.value })}
                                error={err.companyNameError} />
                            <Input
                                placeholder={trans.email}
                                name={trans.email}
                                value={field.email}
                                onChange={e => setField({ ...field, email: e.target.value })}
                                error={err.emailError} />
                            <Input
                                placeholder={trans.phone}
                                type={"number"}
                                name={trans.phone}
                                value={field.phone}
                                onChange={e => setField({ ...field, phone: e.target.value })}
                                error={err.phoneError} />
                            <Input
                                placeholder={trans.password}
                                type={"password"}
                                name={trans.password}
                                value={field.password}
                                onChange={e => setField({ ...field, password: e.target.value })}
                                error={err.passwordError} />
                            <Input
                                placeholder={trans.confirmPassword}
                                type={"password"}
                                name={trans.confirmPassword}
                                value={field.newPassword}
                                onChange={e => setField({ ...field, newPassword: e.target.value })}
                                error={err.newPasswordError} />
                            <div className="termofuse">

                                <div className="input-container">
                                    <div className="al-center">
                                        <SpecialSelect
                                            listItemHeight={100}
                                            size={"large"}
                                            allowClear
                                            maxTagCount={3}
                        maxTagTextLength={4}
                                            optionFilterProp="children"
                                            onChange={c => setSelectedGroup(c)}
                                            className={companyTypeControl ? "selectItemError" : "myselectItem"}
                                            placeholder={trans.companytype}
                                        >
                                            {options.map((l, i) => {
                                                return (<Option key={l.value} value={l.value}>{l.label}</Option>)
                                            })}

                                        </SpecialSelect>
                                    </div>
                                </div>

                            </div>
                            <div className="termofuse">
                                <Switch
                                    id={"termsofuseSwitch"}
                                    value={field.termofuse}
                                    classes={"termsofuseSwitch"}
                                    onChange={e => setField({ ...field, termofuse: e.target.checked })} />
                                <span className="myCursorItem" onClick={openTermofUserDetail} >
                                    <span className="myspancolor">{trans.approvalletter} </span>
                                      {trans.termofuseinform}
                                </span>
                                {termofuse ?
                                    <Modal withClose setIsOpen={settermofuse} isOpen={termofuse} classes={"term_of_use_detail"} btnclass={"btn-close2"}>
                                        <TermOfUseDetail />
                                    </Modal>
                                    : null}
                            </div>
                            <div className="termofuse">
                                <Switch
                                    id={"advertisementSwitch"}
                                    value={field.advertisement}
                                    onChange={e => setField({ ...field, advertisement: e.target.checked })} />
                                <span>Bilgilendirme Maili almak için onay veriyorum.</span>
                            </div>
                            {loader.loading === false ?
                                <button onClick={sendRequest}>
                                    {trans.sendrequest}
                                </button>
                                :
                                <button disabled>
                                    {trans.pleasewait}
                                </button>}

                            <div className="login_message">{message}</div>
                            {current.requestMessage !== "" ?
                                <div className="login_message">{trans[current.requestMessage]}</div>
                                :
                                null
                            }
                        </div>
                        :
                        <div className="request_form_success">
                            <SuccessIcon />
                            <h4>
                                Giriş bilgileriniz başarıyla gönderilmiştir. Lütfen mail adresinizi kontrol ediniz.
                            </h4>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
