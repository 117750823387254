import axios from 'axios'
import { axiosConfig, baseUrl } from '../../config'
import { notificationType, pageCountType, notificationCountType, notificationAllType } from '../action_types'
import { success, calculatePageNumber, loading } from './loader_ac'
import { crud } from '../../config/constants'

export const fetchNotification = (request = { rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/notifications`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    return dispacth => {
        try {
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            }).then(response => {
                if (response.data.success) {
                    switch (requestType) {
                        case crud.list:
                            if (request.isAll) {
                                dispacth({
                                    type: notificationAllType,
                                    payload: response.data.data,
                                })
                            } else {
                                dispacth({
                                    type: notificationType,
                                    payload: response.data.data,
                                })
                            }
                            break;

                        case crud.count:
                            
                            var count = response.data.data;
                            if (count > 0)
                                dispacth({
                                    type: notificationCountType,
                                    payload: response.data.data,
                                })
                            else {
                                dispacth({
                                    type: notificationType,
                                    payload: response.data.data,
                                })
                                dispacth({
                                    type: notificationCountType,
                                    payload: "",
                                })
                            }
                        default:
                            break;
                    }
                }
            }).catch(reason => {
                //console.log(, " of notification_ac");

            })
        } catch (error) {
        }
    }
}