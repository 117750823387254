import axios from 'axios'
import { axiosConfig, baseUrl } from '../../config'
import { requestFormType, responseType } from '../action_types'
import { success, loading } from './loader_ac'

export const fetchRequestDemo = (data) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/requestDemo`
    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data
            })
                .then(response => {
                    
                    if (response.data.success) {
                        dispacth(success(true))
                        dispacth({ type: responseType, payload: response.data })
                    }
                    else {
                        dispacth(success(false))
                        dispacth({ type: responseType, payload: response.data })
                    }
                    dispacth(loading())
                })
                .catch(reason => {
                    dispacth(loading())
                    dispacth({ type: responseType, payload: { success: false, message: "SomethingWrong" } })
                })
        } catch (error) {
            dispacth(loading())
            dispacth({ type: responseType, payload: { success: false, message: "SomethingWrong" } })
        }
    }
}