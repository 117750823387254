import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

export default function EmployeeDetailInsideMenu(props) {
    const { active, content, name, changeTab, id } = props
    const trans = useSelector(state => state.trans)
    return (
        <li className={active ? "active" : ""}
            onClick={() => changeTab(content)}>
            {name}
        </li>
    )
}
