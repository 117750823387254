import axios from "axios";
import { axiosConfig, baseUrl, openNotification } from "../../config";
import { employeeListType, pageCountType, tcType, itemType, addEmployeeType, isLoginType } from "../action_types";
import { loading, calculatePageNumber, success, spinning } from "./loader_ac";
import { crud } from "../../config/constants";

export const fetchEmployees = (request = { rowCount: 14, pageCount: 0, loader: false }, requestType = 5, data = {}, trans = undefined) => {
  let headers = axiosConfig.headers;
  let url = `${baseUrl}/api/users`;
  if (localStorage.getItem("token")) {
    headers = { ...headers, authorization: `Bearer ${localStorage.getItem("token")}` };
  }
  // else {
  //     localStorage.setItem("token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI5IiwiQ29tcGFueUlkIjoiMSIsInVuaXF1ZV9uYW1lIjoic2V5ZkBzZXlmLmNvbSIsImp0aSI6ImFlZDIxYWQxLTczNGMtNGY2NS1hYTA3LTExMGI1MWZmOGJkNSIsImlhdCI6IjE0LUF1Zy0yMCA2OjI2OjQ2IEFNIiwibmJmIjoxNTk3Mzg2NDA2LCJleHAiOjE2MTAzNDY0MDYsImlzcyI6ImNvZGViZWZvcmUiLCJhdWQiOiJjb2RlYmVmb3JlIn0.OMO6HsRIOqydqr1uSRH5-7R6F6IJ5oZH8x3tLZuydeg")
  // }
  if (data.password === "&&&&&&") {
    data.password = null;
    data.newPassword = null;
  }

  switch (requestType) {
    case crud.update:
      if (data.photo && !data.photo.includes("/personal")) data.photo = data.photo.replace(/^data:image\/[a-z]+;base64,/, "");
      break;

    default:
      break;
  }

  return (dispacth) => {
    try {
      if (request.loader) dispacth(loading());

      if (request.spinner) dispacth(spinning());

      axios({
        method: "POST",
        url,
        headers,
        data: { request, requestType, data },
      })
        .then((response) => {
          if (request.loader) dispacth(loading());
          if (request.spinner) dispacth(spinning());
          if (response.data.success) {
            switch (requestType) {
              case crud.create:
                break;
              case crud.read:
                break;
              case crud.delete:
                openNotification("success", trans.successTitle, trans.successBody, 3);
                break;
              case crud.list:
                dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) });
                dispacth({
                  type: employeeListType,
                  payload: response.data.data,
                });
                break;
              case crud.update:
                var personal = response.data.data;
                dispacth({ type: itemType, payload: personal });
                openNotification("success", trans.successTitle, trans.successBody, 3);
                break;
              default:
                break;
            }
          }
        })
        .catch((reason) => {
          if (request.spinner) dispacth(spinning());
          if (request.loader) dispacth(loading());
          openNotification("error", trans.errorTitle, trans.errorBody, 3);
        });
    } catch (error) {
      if (request.spinner) dispacth(spinning());
      if (request.loader) dispacth(loading());
      openNotification("error", trans.errorTitle, trans.errorBody, 3);
    }
  };
};

export const fetchEmployee = (data, trans = undefined) => {
  let headers = axiosConfig.headers;
  let url = `${baseUrl}/api/users/register`;
  if (localStorage.getItem("token")) {
    headers = { ...headers, authorization: `Bearer ${localStorage.getItem("token")}` };
  }

  if (data.photo && !data.photo.includes("/personal")) data.photo = data.photo.replace(/^data:image\/[a-z]+;base64,/, "");

  if (data.roles !== null && data.roles !== undefined && data.roles.length > 0) {
    var roleIds = [];
    for (let index = 0; index < data.roles.length; index++) {
      const element = data.roles[index];
      roleIds.push(element.value);
    }
    data.roleIds = roleIds;
    data.roles = null;
  }

  return (dispacth) => {
    try {
      dispacth(loading());
      axios({
        method: "POST",
        url,
        headers,
        data,
      })
        .then((response) => {
          if (response.data.success) {
            dispacth({
              type: addEmployeeType,
              payload: response.data.data,
            });
            dispacth(success(response.data.success));
            openNotification("success", trans.successTitle, trans.successBody, 3);
          } else {
            //AlreadyInUse
            if (response.data.message === "AlreadyInUse") {
              openNotification("error", "Error", trans.AlreadyInUse);
            } else openNotification("error", trans.errorTitle, trans.errorBody, 3);
          }
          dispacth(loading());
        })
        .catch((reason) => {
          console.log(reason.data);
          dispacth(success(false));
          dispacth(loading());
          openNotification("error", trans.errorTitle, trans.errorBody, 3);
        });
    } catch (error) {
      console.log(error);
      dispacth(loading());
      dispacth(success(false));
      openNotification("error", trans.errorTitle, trans.errorBody, 3);
    }
  };
};

export const fetchEmployeeDetail = (id) => {
  let headers = axiosConfig.headers;
  let url = `${baseUrl}/api/users`;
  if (localStorage.getItem("token")) {
    headers = { ...headers, authorization: `Bearer ${localStorage.getItem("token")}` };
  }
  var data = { request: { id }, requestType: 2 };
  return (dispacth) => {
    try {
      dispacth(loading());
      axios({
        method: "POST",
        url,
        headers,
        data,
      })
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            var roles = [];
            var personal = response.data;
            if (personal.roleDtos && personal.roleDtos.length > 0) {
              for (let index = 0; index < personal.roleDtos.length; index++) {
                const element = personal.roleDtos[index];
                roles.push({ label: element.name, value: element.id });
              }
              personal.roles = roles;
            }
            dispacth({
              type: itemType,
              payload: personal,
            });

            // dispacth(clearResult())
          } else {
            // dispacth(setResult(response.data))
          }
          dispacth(loading());
        })
        .catch((reason) => {
          // dispacth(setResult(.data))
          dispacth(loading());
        });
    } catch (error) {
      console.log(error);
      dispacth(loading());
    }
  };
};

export const fetchTc = (data) => {
  let headers = axiosConfig.headers;
  let url = `${baseUrl}/tc/${data}`;
  return (dispacth) => {
    try {
      axios({
        method: "GET",
        url,
        headers,
      })
        .then((response) => {
          if (response.status === 200) {
            dispacth({ type: tcType, payload: response.data });
          }
          console.log(response);
        })
        .catch((reason) => {});
    } catch (error) {
      console.log(error);
    }
  };
};
