//theme
export const isDarkType = 'isDarkType'
export const isLightType = 'isLightType'

//current
export const breadcrumbType = 'breadcrumbType'
export const addBreadcrumbType = 'addBreadcrumbType'
export const pageTitleType = 'pageTitleType'
export const barListType = 'barListType'
export const gridListType = 'gridListType'
export const boardListType = 'boardListType'
export const pageCountType = 'pageCountType'
export const tcType = 'tcType'
export const usersType = 'usersType'
export const isComingDataType = 'isComingDataType'
export const isCameDataType = 'isCameDataType'



//size
export const isOpenLeftMenuType = 'isOpenLeftMenuType'
export const isOpenLeftMenuItemType = 'isOpenLeftMenuItemType'
export const isOpenTextType = 'isOpenTextType'
export const isOpenTextValueType = 'isOpenTextValueType'
export const leftMenuValueType = 'leftMenuValueType'
export const leftMenuItemValueType = 'leftMenuItemValueType'


//User
export const getUserType = 'getUserType'

//langs
export const langsType = 'langsType'
export const transType = 'transType'

//loader
export const isLoaderType = 'isLoadingType'
export const isSpinType = 'isSpinType'

//employee
export const employeeListType = 'employeeListType'
export const addEmployeeType = 'addEmployeeType'
export const insideMenuType = 'insideMenuType'

//positions
export const positionsType = 'positionsType'
//labels
export const labelType = 'labelType'
export const lineListType = 'lineListType'

//result
export const resultType = 'resultType'
export const result2Type = 'result2Type'
export const responseType = 'responseType'

//item
export const itemType = 'itemType'
export const itemHoursType = 'itemHoursType'
export const usedLeaveCountType = 'usedLeaveCountType'

//leave
export const leaveType = 'leaveType'
export const addleaveType = 'addleaveType'

//inOut
export const inOutType = 'inOutType'
export const addInOutType = 'addInOutType'
export const updateInOutType = 'updateInOutType'
export const avgInOutType = 'avgInOutType'

//customer
export const customerListType = 'customerListType'
export const addCustomerType = 'addCustomerType'
export const addResponsibleType = 'addResponsibleType'
export const responsibleType = 'responsibleType'
export const newResponsibleType = 'newResponsibleType'
export const customerMenus = 'customerMenus'

//overtime
export const overtimeType = 'overtimeType'
export const addOvertimeType = 'addOvertimeType'
export const totalhourType = 'totalhourType'

//debit
export const debitType = 'debitType'
export const adddebitType = 'adddebitType'

//event
export const eventType = 'eventType'
export const addeventType = 'addeventType'
export const updateeventType = 'updateeventType'

//customerevent
export const customerEventType = 'customerEventType'
export const customerAddEventType = 'customerAddEventType'
export const customerUpdateEventType = 'customerUpdateEventType'

//document
export const employeeDocumentGroupType = 'employeeDocumentGroupType'
export const employeeDocumentType = 'employeeDocumentType'
export const addEmployeeDocumentType = 'addEmployeeDocumentType'
export const updateEmployeeDocumentType = 'updateEmployeeDocumentType'
export const requiredDocumentsType = 'requiredDocumentsType'

//projectGroup
export const projectGroupType = 'projectGroupType'
export const addProjectGroupType = 'addProjectGroupType'
export const updateProjectGroupType = 'updateProjectGroupType'

//department
export const departmentType = 'departmentType'
export const addDepartmentType = 'addDepartmentType'
export const updateDepartmentType = 'updateDepartmentType'

//holiday
export const holidayType = 'holidayType'
export const addHolidayType = 'addHolidayType'
export const updateHolidayType = 'updateHolidayType'

//userfileType
export const userFileTypeType = 'userFileTypeType'
export const addUserFileTypeType = 'addUserFileTypeType'
export const updateUserFileTypeType = 'updateUserFileTypeType'

//autorization
export const autorizationType = 'autorizationType'
export const addAutorizationType = 'addAutorizationType'
export const updateAutorizationType = 'updateAutorizationType'
export const autorizationMenuType = 'autorizationMenuType'

//Autohrization control
export const isLoginType = 'isLoginType'

//Expired
export const hasExpiredType = 'hasExpiredType'

//project
export const projectType = 'projectType'
export const projectBoardType = 'projectBoardType'
export const addProjectType = 'addProjectType'
export const updateProjectType = 'updateProjectType'
export const insideMenuTypeInProject = 'insideMenuTypeInProject'
export const updateProjectBoardListType = 'updateProjectBoardListType'


//workorder

export const workorderPanoType = 'workorderPanoType'
export const workorderType = 'workorderType'
export const workorderBoardType = 'workorderBoardType'
export const addWorkorderBoardType = 'addWorkorderBoardType'
export const updateWorkorderBoardType = 'updateWorkorderBoardType'
export const addWorkorderType = 'addWorkorderType'
export const updateWorkorderType = 'updateWorkorderType'
export const insideMenuTypeInWorkOrder = 'insideMenuTypeInWorkOrder'
export const addWorkorderCommentType = 'addWorkorderCommentType'
export const workorderCommentType = 'workorderCommentType'
export const updateWorkOrderUserTimeType = 'updateWorkOrderUserTimeType'
export const addWorkOrderUserTimeType = 'addWorkOrderUserTimeType'
export const deleteWorkOrderUserTimeType = 'deleteWorkOrderUserTimeType'

//
export const payrollType = 'payrollType'
export const addpayrollType = 'addpayrollType'
export const updatepayrollType = 'updatepayrollType'

//calendar
export const calendarType = 'calendarType'
export const addCalendarType = 'addCalendarType'
export const updateCalendarType = 'updateCalendarType'
// meetingGroup
export const meetingGroupType = 'meetingGroupType'
export const addMeetingGroupType = 'addMeetingGroupType'
export const updateMeetingGroupType = 'updateMeetingGroupType'

// workorderGroup
export const workOrderGroupType = 'workOrderGroupType'
export const addWorkOrderGroupType = 'addWorkOrderGroupType'
export const updateWorkOrderGroupType = 'updateWorkOrderGroupType'

// meeting
export const meetingType = 'meetingType'
export const addMeetingType = 'addMeetingType'
export const updateMeetingType = 'updateMeetingType'

//suggestionBox
export const suggestionType = 'suggestionType'
export const addSuggestionType = 'addSuggestionType'
export const updateSuggestionType = 'updateSuggestionType'
export const changeSuggestionStatusType = 'changeSuggestionStatusType'

//Company Culture
export const companyCultureType = 'companyCultureType'
export const addCompanyCultureType = 'addCompanyCultureType'
export const updateCompanyCultureType = 'updateCompanyCultureType'

//EmployeePerformance
export const employeePerformanceType = 'employeePerformanceType'
export const performanceEmployeeType = 'performanceEmployeeType'
export const performanceDateType = 'performanceDateType'
export const performanceCriterionType = 'performanceCriterionType'
export const performanceScoreType = 'performanceScoreType'
export const performanceNumbersType = 'performanceNumbersType'
export const updatePerformanceScoreType = 'updatePerformanceScoreType'
export const addPerformanceCriterionType = 'addPerformanceCriterionType'
export const updatePerformanceCriterionType = 'updatePerformanceCriterionType'
export const deletePerformanceCriterionType = 'deletePerformanceCriterionType'
export const updateQuestionType = 'updateQuestionType'
export const updateScoreType = 'updateScoreType'

//file
export const fileType = 'fileType'
export const addFileType = 'addFileType'
export const updateFileType = 'updateFileType'

//projectcost
export const costTypeType = 'costTypeType'
export const budgetType = 'budgetType'
export const addBudgetType = 'addBudgetType'
export const updateBudgetType = 'updateBudgetType'
export const budgetDetailType = 'budgetDetailType'
export const addBudgetDetailType = 'addBudgetDetailType'
export const budgetItemType = 'budgetItemType'
export const requestBudgetType = 'requestBudgetType'
export const totalCostType = 'totalCostType'
// export const  incomeType =  'incomeType'
// export const  updateIncomeType =  'updateIncomeType'
// export const  expenseType =  'expenseType'
// export const  updateExpenseType =  'updateExpenseType'
// export const  getIncomeType =  'getIncomeType'
// export const  getUpdateIncome =  'getUpdateIncome'
// export const  incomeRespType =  'incomeRespType'
// export const  updateIncomeRespType =  'updateIncomeRespType'
// export const  deleteIncome =  'deleteIncome'
// export const  getIncomeTypeAdd =  'getIncomeTypeAdd'
// export const  projectIncomeAdd =  'projectIncomeAdd'
// export const  projectExpenseAdd =  'projectExpenseAdd'
// export const  projectIncomeRespAdd =  'projectIncomeRespAdd'
// export const  pageCountType2 =  'pageCountType2'
// export const  pageCountType3 =  'pageCountType3'
// export const  pageCountType4 =  'pageCountType4'
// export const  currentCostId =  'currentCostId'
// export const  currentIncomeId =  'currentIncomeId'
// export const  removeExpenseList =  'removeExpenseList'
// export const  removeIncomeList =  'removeIncomeList'
//gantt
export const ganttUnSavedChanges = 'ganttUnSavedChanges'
export const ganttCountType = 'ganttCountType'
export const ganttType = 'ganttType'
export const ganttHeaderType = 'ganttHeaderType'
export const changeGanttType = 'changeGanttType'
export const treeType = 'treeType'
export const addGanttType = 'addGanttType'
export const updateGanttType = 'updateGanttType'
export const ganttItemType = 'ganttItemType'
export const assignPersonalType = 'assignPersonalType'
export const assignPersonalsType = 'assignPersonalsType'


//Company Setting
export const settingType = 'settingType'
export const updateSettingType = 'settingUpdateType'
export const updateWorkingHoursType = 'updateWorkingHoursType'

//Notifications
export const notificationType = 'notificationType'
export const notificationAllType = 'notificationAllType'
export const notificationCountType = 'notificationCountType'
//Report
export const dashboardType = 'dashboardType'
export const reportType = 'reportType'
export const isPlannedType = 'isPlannedType'
//Demo Request Form
export const requestFormType = 'requestFormType'

//Project Work Order
export const projectWorkOrderType = 'projectWorkOrderType'
export const addProjectWorkOrderType = 'addProjectWorkOrderType'
export const projectWorkorderBoardType = 'projectWorkorderBoardType'