import React, { useEffect, useState } from 'react'

export default function TermOfUseDetail(props){
    return (
        <div className="termofusemodal">
            <h1>Kişisel Verilerin İşlenmesine İlişkin Aydınlatma Metni</h1>
            <h2>Veri Sorumlusu</h2>
            
            <p>Zetagem Bilişim Teknolojileri Anonim Şirketi (“Zetagem Bilişim” veya “Şirket”)</p>
            <p>Zetagem Bilişim olarak 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) ile ilgili mevzuat ve
                 yasal düzenlemelerden kaynaklanan faaliyetlerimiz çerçevesinde kişisel verilerinizin işlenmesi,
                  saklanması ve aktarılmasına ilişkin veri sahibi ilgili kişileri aydınlatmak amacıyla işbu Kişisel 
                  Verilerin İşlenmesi Aydınlatma Metni’ni (“Aydınlatma Metni”) hazırladık.</p>
            <p>Bunlara ek olarak internet sitemizi, mobil sitemizi ve/veya mobil uygulamamızı ziyaret etmeniz durumunda
                 kullanılan çerez ve SDK’lar hakkında ayrıntılı bilgiler Gizlilik ve Çerez Politikası’nda yer almaktadır. 
                 Bunlar aracılığıyla işlenen kişisel veriler ise bu Aydınlatma Metni’nde açıklanmaktadır.</p>
            <p>Aydınlatma Metni, Zetagem Bilişim tarafından yayımlandığı tarih itibariyle geçerli olacaktır.
                 Zetagem Bilişim, Aydınlatma Metni’nde gerekli olduğu takdirde her zaman değişiklik yapabilir. 
                 Yapılacak değişiklikler, Aydınlatma Metni’nin http://www.timezeta.com/ adresinde yayımlanmasıyla birlikte derhal geçerlilik kazanır.</p>
          <h2>İlgili Kişiler</h2>
          
          <strong>1-Çevrimiçi Kullanıcılar: </strong>
          <br/>
          <table>
               <thead>
                    <tr>
                    <td>Veri Kategorisi</td>
                    <td></td>
                    <td>İşlenen Kişisel Veriler</td>
                    </tr>
               </thead>
          <tbody>
               <tr>
                    <td>İşlem Güvenliği Bilgileri</td>
                    <td>:</td>
                    <td>IP Adresi Bilgileri, İnternet Sitesi Giriş Çıkış Bilgileri, Şifre ve Parola Bilgileri Bilgisayar Ekran Kayıtları, Cihaz IMEI Numarası, Cihaz MAC Adresi</td>
               </tr>
          </tbody>
          </table>
          <strong>2-Müşteriler:</strong>
          <table >
          <thead>
                    <tr>
                    <td>Veri Kategorisi</td>
                    <td></td>
                    <td>İşlenen Kişisel Veriler</td>
                    </tr>
               </thead>
          <tbody>
               <tr>
                    <td>Kimlik Bilgileri</td>
                    <td>:</td>
                    <td>Ad – Soyad, Doğum Tarihi, Cinsiyet, T.C. Kimlik Numarası</td>
               </tr>
               <tr>
                    <td>İletişim Bilgileri</td>
                    <td>:</td>
                    <td>E-Posta Adresi, Fatura & Teslimat Adresleri, Posta kodu, Cep Telefonu Numarası, Sabit Telefon Numarası</td>
               </tr>
               <tr>
                    <td>Hukuki İşlem Bilgileri</td>
                    <td>:</td>
                    <td>Verilen hizmetin başlama ve bitiş zamanı, yararlanılan hizmetin türü,
                         aktarılan veri miktarı, İlgili Kişi’nin elektronik ortamda verdiği ticari elektronik ileti izni,
                         onay verdiği üyelik sözleşmesi,
                         Zetagem Bilişim tarafından sunulan hizmetlerden faydalanılmasını sağlayan sair hukuki metinler ve sözleşmeler,
                         Uyuşmazlık Olması Halinde Dava Dosyasındaki Bilgiler, İhtarnameler,
                          Adli ve İdari Makamlarla Yazışmalardaki Bilgiler</td>
               </tr>
               <tr>
                    <td>Müşteri İşlem Bilgileri</td>
                    <td>:</td>
                    <td>Fatura Bilgileri,
                          Talep/Şikayet Bilgileri ile bunların değerlendirilmesi veya yönetimi sürecinde yapılan işlemlere ilişkin kayıtlar,
                           Sipariş Bilgileri, Müşteri Yorumları, Üyelik Bilgileri, Ticari iletişim izni, faydalanılan kampanyalar/yarışmalar, kullanılan kuponlar</td>
               </tr>
               <tr>
                    <td>İşlem Güvenliği Bilgileri</td>
                    <td>:</td>
                    <td>IP Adresi Bilgileri, İnternet Sitesi Giriş Çıkış Bilgileri, Kullanıcı Adı Bilgileri, Şifre Bilgileri, Trafik Verileri (Bağlantı Zamanı / Süresi vb.)</td>
               </tr>
               <tr>
                    <td>Pazarlama Bilgisi</td>
                    <td>:</td>
                    <td>Çerez kayıtları, hedefleme bilgileri, alışkanlık ve beğenileri gösteren değerlendirmeler</td>
               </tr>
               <tr>
                    <td>Doğrudan Pazarlama Bilgisi</td>
                    <td>:</td>
                    <td>İlgili kişinin verdiği ticari elektronik ileti iznine istinaden gönderilen pazarlama amaçlı sms, e-posta mesajları veya çağrı merkezi tarafından yapılan aramalar</td>
               </tr>
          </tbody>
          </table>
          <strong>3-E-Bülten Üyeleri:</strong>
          <table  >
          <thead>
                    <tr>
                    <td>Veri Kategorisi</td>
                    <td></td>
                    <td>İşlenen Kişisel Veriler</td>
                    </tr>
               </thead>
          <tbody>
               <tr>
                    <td>İletişim Bilgileri</td>
                    <td>:</td>
                    <td>E-Posta Adresi, Cep Telefonu Numarası</td>
               </tr>
          </tbody>
          </table>
          <h1>Kişisel Veri İşleme Amaçları</h1>
         
          <ul className="termofuseul">
               <li>
                    Firma, Ürün ve Hizmetlere Bağlılık Süreçlerinin Yürütülmesi;
               </li>
               <li>
                    Hukuk İşlerinin Takibi ve Yürütülmesi; 
               </li>
               <li>
                    İş Sürekliliğinin Sağlanması Faaliyetlerinin Yürütülmesi; 
               </li>
               <li>
                    İş Faaliyetlerinin Yürütülmesi ve Denetimi; 
               </li>
               <li>
                    Mal / Hizmet Satış Süreçlerinin Yürütülmesi; 
               </li>
               <li>
                    Mal / Hizmet Satış Sonrası Destek Hizmetlerinin Yürütülmesi;
               </li>
               <li>
                    Mal / Hizmet Üretim ve Operasyon Süreçlerinin Yürütülmesi; 
               </li>
               <li>
                    İade ve Değişim Süreçlerinin Yürütülmesi; 
               </li>
               <li>
                    Pazarlama Analiz Çalışmalarının Yürütülmesi; 
               </li>
               <li>
                    Ürün / Hizmetlerin Pazarlama Süreçlerinin Yürütülmesi; 
               </li>
               <li>
                    Reklam, Kampanya ve Promosyon Süreçlerinin Yürütülmesi;  
               </li>
               <li>
                    İade ve Değişim Süreçlerinin Yürütülmesi; 
               </li>
               <li>
                    Müşteri İlişkileri Yönetimi Süreçlerinin Yürütülmesi; 
               </li>
               <li>
                    Müşteri Memnuniyetine Yönelik Aktivitelerin Yürütülmesi;  
               </li>
               <li>
                    Tedarik Zinciri Yönetimi Süreçlerinin Yürütülmesi; 
               </li>
               <li>
                    Saklama ve Arşiv Faaliyetlerinin Yürütülmesi; 
               </li>
               <li>
                    Risk Yönetimi Süreçlerinin Yürütülmesi; 
               </li>
               <li>
                    Sözleşme ve Üyelik Süreçlerinin Yürütülmesi; 
               </li>
               <li>
                    Talep ve Şikayetlerin Takibi ve Çözümlenmesi;  
               </li>
               <li>
                    Ticari ve/veya İş Stratejilerinin Planlanması ve İcrası; 
               </li>
               <li>
                    Sunulan Ürün ve Hizmetlerden Faydalandırmak için Gerekli Çalışmaların İş Birimleri Tarafından Yapılması ve İlgili İş Süreçlerinin Yürütülmesi; 
               </li>
               <li>
                    Yetkili Kişi, Kurum ve Kuruluşlara Bilgi Verilmesi; 
               </li>
               <li>
                    Veri Sorumlusu Operasyonlarının Güvenliğinin Temini; 
               </li>
               <li>
                    Bilgi Güvenliği Süreçlerinin Yürütülmesi; 
               </li>
               <li>
                    Denetim / Etik Faaliyetlerin Yürütülmesi;
               </li>
               <li>
                    İdari ve adli makamlardan gelen bilgi taleplerinin cevaplandırılması ;
               </li>
               <li>
                    5651 sayılı Kanun kapsamında çevrimiçi ziyaretçi verilerinin işlenmesi;
               </li>
               <li>
                    Faaliyetlerin Mevzuata Uygun Yürütülmesi; 
               </li>
          </ul>
          <h1>Kişisel Veri İşlemenin Hukuki Sebepleri</h1>
         
          Bu kapsamdaki kişisel verileriniz, 
          Zetagem Bilişim tarafından yukarıda sayılan amaçların gerçekleştirilmesi için ilgili mevzuat ve KVKK’nın 5’inci maddesinde belirtilen;
          <ul className="termofuseul">
               <li>
                    Kanunlarda açıkça öngörülmesi,
               </li>
               <li>
                    Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması,
               </li>
               <li>
                    İlgili kişinin kendisi tarafından alenileştirilmiş olması,
               </li>
               <li>
                    Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması,
               </li>
               <li>
                    İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması,
               </li>
               <li>
                    Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması
               </li>
               <li>
                    Açık Rıza hukuki sebeplerine dayanılarak işlenmektedir. 
               </li>
          </ul>
          <h1>Kişisel Verilerin Toplanma Yöntemleri</h1>
         
          <ul className="termofuseul">
               <li>
                    Yukarıda belirtilen kişisel veriler internet sitemizdeki üyelik formları,
                     üyelik sayfaları,
                      hesabım sayfası başta olmak üzere internet sitemizin içeriğinde yer alan ve tercihinize bağlı doldurulabilecek alanlar,
                      talep ve başvurularınız, sözleşmeler, 
                      kampanyalar ve üçüncü taraf kimlik doğrulama sistemleri 
                      (Ör: Google Login, Facebook Login, Twitter Login vb.) vasıtasıyla,
               </li>
               <li>
                    Adli ve idari makamlardan gelen resmi yazılar ve basılı / elektronik diğer belgeler vasıtasıyla,
               </li>
               <li>
                    Basılı/elektronik formlar, çağrı merkezi kayıtları, e-postalar, internet sitesi içerisindeki talepler ile mesajlar ve SAP programları vasıtasıyla,
               </li>
               <li>
                    Bilgi güvenliği sistemleri ve elektronik cihazlar aracılığıyla,
               </li>
               <li>
                    Çağrı merkezimize yapılan aramalar sırasında elektronik ortamda,
               </li>
               <li>
                    Kullandığınız internet tarayıcısındaki tercihinize göre,
               </li>
               <li>
                    İnternet uygulamaları, 
                    bilgi sistemleri ve elektronik cihazlar (telekomünikasyon altyapısı, bilgisayar ve telefonlar vb.)
                    ve tarafınızca beyan edilen diğer belgeler vasıtasıyla, toplanmaktadır.
               </li>
          </ul>

          <h1>Kişisel Verilerin Aktarılması</h1>
         
          Müşterilere ve çevrimiçi kullanıcı üyelerine ait kişisel veriler
           KVKK’nın “Kişisel Verilerin Aktarımı” başlıklı 8. maddesindeki ve/veya “Kişisel Verilerin Yurt Dışına Aktarılması” 
           başlıklı 9. maddesindeki kurallara uyularak ve gerekli teknik ve idari tedbirler alınarak, 
           sadece ilgili amacın gerçekleşmesi için gerekli olduğu ölçüde;
          <ul className="termofuseul">
               <li>
                    Yukarıda sayılan amaçlarla sınırlı olarak iştirakler ve bağlı ortaklıklarımıza,
               </li>
               <li>
                    Yine yukarıda sayılan amaçlarla aramızdaki sözleşmeler kapsamında mevzuata uygun bir şekilde
                    (ürüne ilişkin ödemenin alınabilmesi için) bankalar ve (teslimat vb. hususlarda bilgi vermek üzere)
                    toplu SMS / e-posta gönderiminde çalışılan iş ortaklarımıza ve 
                    (gerektiğinde satış sonrası hizmetler için) tedarikçilerimize ve ürün yetkili servislerine,
               </li>
               <li>
                    Müşteri ve E-Bülten Üyelerine ait cep telefonu numarası ve/veya e-posta adresi;
                     ticari elektronik ileti onayına istinaden alışveriş tercih, beğeni ve alışkanlıkları
                      doğrultusunda tanıtım, reklam yapılabilmesi, fayda ve fırsat sunulabilmesi amacıyla
                       ticari elektronik ileti aracı hizmet sağlayıcısı ile paylaşılmaktadır.
               </li>
               <li>
                    Web sitesi kullanım tercihleri ve gezinti geçmişi, segmentasyon yapılması ve
                     Müşteri ile beğeni ve tercihleri doğrultusunda iletişime geçilmesi amacıyla,
                      çerez (cookie) hizmeti alınan yurt içi/yurt dışında bulunan iş ortaklarımızla paylaşılmaktadır.
                       Bu kapsamda gerçekleştirilen kişisel veri aktarımları,
                        ilgili üçüncü tarafın sunduğu güvenli ortam ve kanallar aracılığıyla gerçekleşmektedir. 
               </li>
               <li>
                    Müşteri memnuniyetinin ve bağlılığının artırılması amacıyla, Müşteri’ye ait veriler pazar araştırması yapacak şirketlerle paylaşılmaktadır.
               </li>
               <li>
                    Kişisel verileriniz ek olarak iş geliştirme hizmetlerinin sağlanması, 
                    istatiksel ve teknik hizmetlerin temini ve müşteri ilişkilerinin
                     yürütülmesi amaçlarıyla yurt dışındaki iş ortaklarımızla paylaşılacaktır.
               </li>
               <li>
                    Müşteri/ Çevrimiçi Ziyaretçi, Zetagem Bilişim çalışanlarına Whatsapp hattı üzerinden ulaşması halinde,
                     Whatsapp platformunun yurt dışından sunulan bir hizmet olması dolayısıyla,
                      kişisel verilerini yurt dışına göndermiş olacaktır. 
               </li>
               <li>
                    üşteri/ Çevrimiçi Ziyaretçi, kişisel verilerini Whatsapp kullanmak suretiyle 
                    yurt dışına göndermek istememesi halinde, Zetagem Bilişim’in sunduğu diğer iletişim imkanlarını kullanabilecektir.
               </li>
               <li>
                    Hukuki yükümlülüğümüzü yerine getirebilmek için yasal düzenlemeler ve mevzuat gereği yetkili kişi ve resmi kurumlara aktarılabilmektedir.
               </li>
               <li>
                    Ürün tedariklerinin sağlanması ve müşteriye ulaştırılması amacıyla gerektiğinde yurt dışı merkezli iş ortaklarımıza aktarılabilmektedir.
               </li>
               <li>
                    Teknoloji alanındaki altyapı tedarikçilerimiz tarafından sağlanan / kullanımımıza sunulan programlarımıza ve/veya sistemlerimize kaydedilebilmektedir.
               </li>
               <li>
                    Yukarıda belirtildiği şekilde bilgi paylaşılması sırasında Türkiye dışındaki ülkelere kişisel bilgileri aktarırken,
                     verilerin bu politikaya uygun olarak ve veri korumaya ilişkin uygulanacak hukukun izin verdiği şekilde aktarılması sağlanmaktadır.
               </li>
          </ul>

          <h1>Çerez (Cookie) Kullanımı ve Yönetimi</h1>
         
          Zetagem Bilişim tarafından kullanılan çerezler, çerez çeşitleri, amaçları, üreleri ve 
          çerez yönetimi ile ilgili detaylı bilgi için Gizlilik ve Çerez Politikamızı inceleyebilirsiniz.
          <h1>Haklarınız ve Başvuru :</h1>
          <strong>Haklarınız :</strong>
          6698 Sayılı Kişisel Verilerin Korunması Kanunu’nda (“KVKK”) ilgili kişi olarak tanımlanan
           kişisel veri sahiplerine (“Bundan sonra “Başvuru Sahibi” olarak anılacaktır),
            KVKK’nın 11’inci maddesinde kişisel verilerinin işlenmesine ilişkin birtakım
             taleplerde bulunma hakkı tanınmıştır
          Başvuru Sahibi’nin hakları aşağıdaki gibidir:
          <ul className="termofuseul">
               <li>
                    Kişisel verilerinizin işlenip işlenmediğini öğrenme,
               </li>
               <li>
               	Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
               </li>
               <li>
               	Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,
               </li>
               <li>
               	Kişisel verilerinizin aktarıldığı üçüncü kişileri bilme,
               </li>
               <li>
               	Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme,
               </li>
               <li>
               	KVKK mevzuatında öngörülen şartlar çerçevesinde kişisel verilerinizin silinmesini veya yok edilmesini isteme,
               </li>
               <li>
               	Eksik veya yanlış verilerin düzeltilmesi ile kişisel verilerinizin silinmesi veya yok edilmesini talep ettiğinizde,
                     bu durumun kişisel verilerinizi aktardığımız üçüncü kişilere bildirilmesini isteme,
               </li>
               <li>
               	İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,
               </li>
               <li>
               	Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde bu zararın giderilmesini talep etme.
               </li>
          </ul>
          <strong>Başvuru :</strong>
          KVKK’nın 13’üncü maddesinin birinci fıkrası uyarınca; 
          Veri Sorumlusu olan Zetagem Bilişim’e bu haklara ilişkin olarak yapılacak başvuruların aşağıdaki 
          başvuru yöntemleri veya Kişisel Verilerin Koruma Kurulu (“Kurul”) tarafından belirlenen diğer yöntemlerle tarafımıza iletilmesi gerekmektedir.

          <ul className="termofuseul">
               <li>
               	Kişisel verileriniz ile ilgili başvuru ve taleplerinizi dilerseniz İlgili Kişi Başvuru Formu’nu kullanarak;
               </li>
               <li>
               	Islak imzalı ve kimlik fotokopisi ile Ulutek Teknoloji Geliştirme Bölgesi Ar-Ge Binası No:216 Nilüfer/Bursa adresine göndererek,
               </li>
               <li>
               	Geçerli bir kimlik belgesi ile birlikte Şirketimize bizzat başvurarak,
               </li>
               <li className="informtermofuse">
               	Kayıtlı elektronik posta (KEP) adresi ve güvenli elektronik imza ya da mobil imza kullanmak
                     suretiyle info@zetagem.com.tr  kayıtlı elektronik posta adresimize göndererek Zetagem Bilişim’e iletebilirsiniz.
               </li>

          </ul>
          <p>
               Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ uyarınca ilgili
               kişilerin bu başvurularında ad – soyad, başvuru yazılı ise imza,
               T.C. kimlik numarası (başvuruda bulunan kişinin yabancı olması halinde pasaport numarası),
               tebligata esas yerleşim yeri veya iş yeri adresi, varsa bildirime esas elektronik
               posta adresi, telefon numarası ve faks numarası ile talep konusuna dair bilgilerin bulunması zorunludur.
          </p>
          <p>
               Veri sahibi ilgili kişi, yukarıda belirtilen hakları
               kullanmak için yapacağı ve kullanmayı talep ettiği
               hakka ilişkin açıklamaları içeren başvuruda talep edilen
               hususu açık ve anlaşılır şekilde belirtilmelidir.
               Başvuruya ilişkin bilgi ve belgelerin başvuruya eklenmesi gerekmektedir.
          </p>
          <p>
               Talep konusunun başvuranın şahsı ile ilgili olması gerekmekle birlikte,
                başkası adına hareket ediliyor ise başvuruyu yapanın bu konuda özel,
                olarak yetkili olması ve bu yetkinin belgelendirilmesi (özel vekâletname) gerekmektedir.
                 Ayrıca başvurunun kimlik ve adres bilgilerini içermesi ve başvuruya kimliği doğrulayıcı belgelerin eklenmesi gerekmektedir.
          </p>
          <p>
               Yetkisiz üçüncü kişilerin başkası adına yaptığı talepler değerlendirmeye alınmayacaktır.
          </p>
          <p>
               Kişisel verilerinize ilişkin hak talepleriniz değerlendirilerek,
                Şirketimize ulaştığı tarihten itibaren en geç 30 gün içerisinde cevaplanır.
                 Başvurunuzun olumsuz değerlendirilmesi halinde, gerekçeli ret sebepleri başvuruda
                  belirttiğiniz adrese elektronik posta veya posta yolu başta olmak üzere eğer
                   mümkünse talebin yapıldığı usul vasıtasıyla size iletilir.
          </p>
          <p>
               Kişisel verilerin güvenliğinin sağlanması ve hukuka aykırı veri paylaşımlarının önlenmesi
               amacıyla Zetagem Bilişim, Başvuru Sahibi’nden kimlik doğrulaması için bilgi talebinde bulunabilir.
               Zetagem Bilişim ayrıca başvurunun talebe uygun bir biçimde sonuçlandırılmasını sağlamak adına,
               Başvuru Sahibi’ne başvurusu ile ilgili soru yöneltebilir.
               Talebin ayrıca bir maliyet gerektirmesi durumunda Kurul tarafından belirlenecek olan tarifedeki
               ücret Başvuru Sahibi’nden alınacaktır. (Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ- Madde 7).
          </p>
        </div>
        
        
        
        )
}