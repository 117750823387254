import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Layout from "../../components/Container/Layout";
import { barListType, gridListType, isPlannedType, itemType } from "../../redux/action_types";
import { LeftIcon, RightIcon, BarIcon, ChartIcon } from "../../components/icons";
import ReportBar from "../../components/report/ReportBar";
import ReportList from "../../components/report/ReportList";
import { fetchReport, fetchReportDay, fetchWorkDays, getThy } from "../../redux/actions/report_ac";
import { ConfigProvider, Select, Spin } from "antd";
import "antd/dist/antd.css";
import { Button, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { fetchEmployees } from "../../redux/actions/employee_ac";
import { crud } from "../../config/constants";
import { fetchCustomers } from "../../redux/actions/customer_ac";
import { fetchProject } from "../../redux/actions/project_ac";
import { fetchDepartment } from "../../redux/actions/department_ac";
import "./style.css";
import "antd/dist/antd.less";

import ReportList2 from "../../components/report/ReportList2";
var toDate = new Date();
var year = toDate.getFullYear();
var month = toDate.getMonth() + 1;
var toDateString = year + (month.toString().length > 1 ? "-" : "-0") + month;
// var daysInSelectedMonth = []
// var getDaysInMonth = function (month, year) {
//     return new Date(year, month, 0).getDate();
// };
const { Option, OptGroup } = Select;
export default function Report2() {
  const dispatch = useDispatch();
  const trans = useSelector((state) => state.trans);
  const theme = useSelector((state) => state.theme);
  const current = useSelector((state) => state.current);

  //ant.d 4.x v theme dark llight from theme state

  // const employee = useSelector((state) => state.employee);
  // const department = useSelector((state) => state.department);
  // const customer = useSelector((state) => state.customer);
  const project = useSelector((state) => state.project);
  const loader = useSelector((state) => state.loader);
  useEffect(() => {
    console.log(theme);
    dispatch({ type: itemType, payload: { reportType: "0" } });
  }, []);

  const [date, setDate] = useState(toDateString);
  useEffect(() => {
    var month = parseInt(date.substr(5, 2)) - 1;
    dispatch(fetchReport({ rangeDate: new Date(date.substr(0, 4), month), reportType: "0" }, trans));
  }, [date]);

  // const showBar = () => dispatch({ type: barListType });
  // const showGrid = () => dispatch({ type: gridListType });
  // const [isPlanned, setIsPlanned] = useState("0")
  // const selectMultiUserIds = (userIds) => dispatch({ type: itemType, payload: { ...current.item, userIds } });
  // const selectMultiCustomerIds = (customerIds) => dispatch({ type: itemType, payload: { ...current.item, customerIds } });
  const selectMultiProjectIds = (projectIds) => dispatch({ type: itemType, payload: { ...current.item, projectIds } });
  // const selectMultiDepartmentIds = (departmentIds) => dispatch({ type: itemType, payload: { ...current.item, departmentIds } });

  // const fetchUser = (search) => dispatch(fetchEmployees({ search, isSmallDto: true, spinner: true }, crud.list, {}, trans));
  // const fetchCustomer = (search) => dispatch(fetchCustomers({ search, isSmallDto: true, spinner: true }, crud.list, {}, trans));
  const searchProject = (search) => dispatch(fetchProject({ search, isSmallDto: true, spinner: true }, crud.list, {}, trans));
  // const searchDepartment = (search) => dispatch(fetchDepartment({ search, type: -1, isSmallDto: true, spinner: true }, crud.list, {}, trans));
  const filter = () => {
    var month = parseInt(date.substr(5, 2)) - 1;
    dispatch(
      fetchReport(
        {
          rangeDate: new Date(date.substr(0, 4), month),
          reportType: current.item.reportType,
          userIds: current.item.userIds,
          customerIds: current.item.customerIds,
          departmentIds: current.item.departmentIds,
          projectIds: current.item.projectIds,
        },
        trans
      )
    );
  };
  return (
    <Layout>
      <div className="table_properties">
        <div className="filters">
          {/* <div className="filter_group">
            <Select
              mode="multiple"
              allowClear
              maxTagCount={1}
              onSearch={fetchUser}
              maxTagTextLength={4}
              optionFilterProp="children"
              onChange={selectMultiUserIds}
              style={{ width: "100%" }}
              notFoundContent={loader.spinning ? <Spin size="small" /> : null}
              placeholder={trans.personals}
              filterOption={(input, option) => (option.children ? option.children.toLocaleLowerCase("tr").indexOf(input.toLocaleLowerCase("tr")) >= 0 : false)}
              // filterSort={(input, option) =>
              //     input.children.toLowerCase().localeCompare(option.children.toLowerCase())
              //   }
              value={current.item.userIds}
            >
              {employee.list.map((l, i) => {
                return (
                  <Option key={"userId" + i} value={l.id}>
                    {l.name}
                  </Option>
                );
              })}
            </Select>
          </div> */}

          {/* <div className="filter_group">
            <Select
              mode="multiple"
              allowClear
              maxTagCount={1}
              onSearch={searchProject}
              maxTagTextLength={4}
              optionFilterProp="children"
              onChange={selectMultiProjectIds}
              style={{ width: "100%" }}
              notFoundContent={loader.spinning ? <Spin size="small" /> : null}
              placeholder={trans.project}
              filterOption={(input, option) => (option.children ? option.children.toLocaleLowerCase("tr").indexOf(input.toLocaleLowerCase("tr")) >= 0 : false)}
              // filterSort={(input, option) =>
              //     input.children.toLowerCase().localeCompare(option.children.toLowerCase())
              //   }
              value={current.item.projectIds}
            >
              {project.list.map((l, i) => {
                return (
                  <Option key={"projectId" + i} value={l.id}>
                    {l.name}
                  </Option>
                );
              })}
            </Select>
          </div> */}

          {/* <div className="filter_group">
            <Button type="primary" icon={<SearchOutlined />} onClick={filter}>
              {trans.search}
            </Button>
          </div> */}
        </div>
        <div className="bar_grid">
          {current.grid && (
            <select
              style={{ marginRight: "1rem" }}
              onChange={(e) => {
                dispatch({ type: isPlannedType });
              }}
            >
              <option value="0">Planlanan</option>
              <option value="1">Gerçekleşen</option>
            </select>
          )}
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <input type="month" onChange={(e) => setDate(e.target.value)} value={date} />

            <Tooltip title="Önceki Ay">
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  var d = new Date(date);
                  d.setMonth(d.getMonth() - 1);
                  var month = d.getMonth() + 1;
                  var toDateString = d.getFullYear() + (month.toString().length > 1 ? "-" : "-0") + month;
                  setDate(toDateString);
                }}
              >
                <LeftIcon />
              </div>
            </Tooltip>
            <Tooltip title="Sonraki Ay">
              <div
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  var d = new Date(date);
                  d.setMonth(d.getMonth() + 1);
                  var month = d.getMonth() + 1;
                  var toDateString = d.getFullYear() + (month.toString().length > 1 ? "-" : "-0") + month;
                  setDate(toDateString);
                }}
              >
                <RightIcon />
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="reports_container">
        <ReportList2 date={date} />
      </div>
    </Layout>
  );
}
