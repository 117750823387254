import React, { useEffect, useState } from "react";
import Layout from "../../components/Container/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Pagination from "../../components/anBrains/Pagination";
import Input from "../../components/anBrains/Input";
import { SortIcon, Search2Icon, BarIcon, GridIcon } from "../../components/icons";
import man from "../../images/profiles/man.png";
import woman from "../../images/profiles/woman.png";
import ContentLoader from "react-content-loader";
import { success } from "../../redux/actions/loader_ac";
import { crud } from "../../config/constants";
import { gridListType, barListType, addBreadcrumbType, breadcrumbType, performanceDateType, performanceEmployeeType } from "../../redux/action_types";
import "react-input-range/lib/css/index.css";
import { fetchEmployeesScore } from "../../redux/actions/employee_performance_ac";
import { baseUrl } from "../../config";
import manprofile from "../../images/profiles/man.png";
import womanprofile from "../../images/profiles/woman.png";

export default function EmployeePerformance(props) {
  const dispatch = useDispatch();
  const trans = useSelector((state) => state.trans);
  const theme = useSelector((state) => state.theme);
  const current = useSelector((state) => state.current);
  const result = useSelector((state) => state.result);
  const performancemanagement = useSelector((state) => state.performancemanagement);
  const { breadcrumbs } = useSelector((state) => state.current);
  const [searchText, setSearchText] = useState("");
  const loader = useSelector((state) => state.loader);
  const [selectedPage, setSelectedPage] = useState(1);
  const [isAddCriterionOpen, setIsAddCriterionOpen] = useState(false);
  const [request, setRequest] = useState({ loader: true, rowCount: 14, pageCount: 0, search: "", sort: "id", desc: false, data: {} });

  useEffect(() => {
    var month = parseInt(performancemanagement.date.substr(5, 2)) - 1;
    dispatch(fetchEmployeesScore({ ...request, rangeDate: new Date(performancemanagement.date.substr(0, 4), month) }, crud.userAndCriterions));
  }, [performancemanagement.date]);

  useEffect(() => {
    if (result.success === true) {
      setIsAddCriterionOpen(false);
      //dispatch(success(false))
    }
  }, [result.success]);

  const history = useHistory();
  const employeScoreDetail = (e) => {
    if (breadcrumbs && breadcrumbs.some((b) => b.name === e.name)) {
    }
    if (breadcrumbs.length > 1) {
      var newBreadCrumbs = [];
      newBreadCrumbs.push(breadcrumbs[0]);
      newBreadCrumbs.push({ name: e.name, path: "/personal-performance/personal-score-detail" });
      dispatch({
        type: breadcrumbType,
        payload: newBreadCrumbs,
      });
    } else {
      dispatch({
        type: addBreadcrumbType,
        payload: { name: e.name, path: "/personal-performance/personal-score-detail" },
      });
    }
    history.push("/personal-performance/personal-score-detail/" + e.id);
  };

  const searchChange = (text) => {
    setSearchText(text);
    if (text.length > 2) {
      dispatch(fetchEmployeesScore({ ...request, isScoreDto: true, search: text, loader: false }, crud.userAndCriterions));
    }
  };

  const searchChangeKeyPress = (text, kcode) => {
    if (kcode === 8) {
      dispatch(fetchEmployeesScore({ ...request, isScoreDto: true, search: text, loader: false }, crud.userAndCriterions));
    }
  };

  const [desc, setDesc] = useState(false);

  const sortbyField = (column) => {
    // setRequest({ ...request, isScoreDto: true, sort: f, desc })
    // dispatch(fetchEmployeesScore({ ...request, sort: f, desc, loader: false }, crud.userAndCriterions));
    if (desc) {
      performancemanagement.list.sort((a, b) => (a[column] > b[column] ? 1 : -1));
    } else {
      performancemanagement.list.reverse((a, b) => (a[column] > b[column] ? 1 : -1));
    }
    // dispatch({type:performanceEmployeeType,payload:performancemanagement.list})
    setDesc(!desc);
  };

  const setSelectedPageFilter = (n) => {
    setSelectedPage(n);
    setRequest({ ...request, isScoreDto: true, pageCount: n - 1 });
    dispatch(fetchEmployeesScore({ ...request, pageCount: n - 1, loader: false }, crud.userAndCriterions));
  };

  const showBar = () => dispatch({ type: barListType });

  const showGrid = () => dispatch({ type: gridListType });

  return (
    <Layout>
      <div className="table_properties">
        <input type="month" onChange={(e) => dispatch({ type: performanceDateType, payload: e.target.value })} value={performancemanagement.date} />
        {/* <Input
                    icon={<Search2Icon
                        color={(theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")} />}
                    placeholder={trans.search}
                    value={searchText}
                    onChange={e => searchChange(e.target.value)}
                    onKeyUp={e => searchChangeKeyPress(e.target.value, e.keyCode)} /> */}
        <div className="bar_grid">
          <button onClick={showBar}>
            <BarIcon color={current.bar ? (theme === "dark" ? "#3e9fbe" : "#1a9bc4") : theme === "dark" ? "#e5e5e5" : "#5d5d5d"} />
          </button>
          <button onClick={showGrid}>
            <GridIcon color={current.grid ? (theme === "dark" ? "#3e9fbe" : "#1a9bc4") : theme === "dark" ? "#e5e5e5" : "#5d5d5d"} />
          </button>
        </div>
      </div>
      {current.bar &&
        (!loader.loading ? (
          <div className="project_container">
            <div className="project_group">
              <h2>{trans.statistics}</h2>
              <div className="meeting_group_container">
                <div className="average-score">
                  <label>{trans.teamAveragePoint}</label>
                  <h1>{performancemanagement && performancemanagement.numbers ? performancemanagement.numbers.userAvgScore : 0}</h1>
                  <div className="mt-2">
                    <div className="info-line">
                      <span>{trans.totalQuestionCount}</span>
                      <span>{performancemanagement && performancemanagement.numbers ? performancemanagement.numbers.totalQuestionCount : 0}</span>
                    </div>
                    <div className="info-line">
                      <span>{trans.totalPersonCount}</span>
                      <span>{performancemanagement && performancemanagement.numbers ? performancemanagement.numbers.usersCount : 0}</span>
                    </div>
                    <div className="info-line">
                      <span>{trans.totalQuestionPoint}</span>
                      <span>{performancemanagement && performancemanagement.numbers ? performancemanagement.numbers.totalQuestionScore : 0}</span>
                    </div>
                    <div className="info-line">
                      <span>{trans.factor}</span>
                      <span>{performancemanagement && performancemanagement.numbers ? performancemanagement.numbers.userAvgFactor : 0}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="project_view">
              <div className="table">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th onClick={() => sortbyField("name")}>
                        {trans.staff} <SortIcon color="#5d5d5d" />
                      </th>
                      <th>{trans.stafftask}</th>
                      <th onClick={() => sortbyField("totalScore")}>
                        {trans.personalPoint} <SortIcon color="#5d5d5d" />
                      </th>
                      <th onClick={() => sortbyField("workOrderPoint")}>
                        {trans.jobscore} <SortIcon color="#5d5d5d" />
                      </th>
                      <th onClick={() => sortbyField("suggestionCount")}>
                        {trans.suggestionPoint} <SortIcon color="#5d5d5d" />
                      </th>
                      <th onClick={() => sortbyField("xtotalScore")}>
                        {trans.simetriktotalscore} <SortIcon color="#5d5d5d" />
                      </th>
                      <th onClick={() => sortbyField("factor")}>
                        {trans.factor} <SortIcon color="#5d5d5d" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {performancemanagement &&
                      performancemanagement.list &&
                      performancemanagement.list.length > 0 &&
                      performancemanagement.list.map((e, i) => {
                        return (
                          <tr className="row" key={i} onClick={() => employeScoreDetail(e)}>
                            <td>
                              {e.photo && e.photo.length > 5 ? (
                                <img src={e.photo ? baseUrl + e.photo : e && e.gender ? manprofile : womanprofile} alt="" className="profile_list_img" />
                              ) : e.gender ? (
                                <img src={man} alt="" className="profile_list_img" />
                              ) : (
                                <img src={woman} alt="" className="profile_list_img" />
                              )}
                            </td>
                            <td>{e.name}</td>
                            <td>
                              {e.roleDtos &&
                                e.roleDtos.length &&
                                e.roleDtos.map((r, j) => {
                                  return (
                                    <span key={j} className="personal_task d-inline-block">
                                      {r.name}
                                    </span>
                                  );
                                })}
                            </td>
                            <td>
                              <h1 className="totalScore">{e.totalScore === 0 ? "-" : e.totalScore}</h1>
                            </td>
                            <td>
                              <h1 className="totalScore">{e.workOrderPoint === 0 ? "-" : e.workOrderPoint}</h1>
                            </td>
                            <td>
                              <h1 className="totalScore">{e.suggestionCount === 0 ? "-" : e.suggestionCount}</h1>
                            </td>
                            <td>
                              <h1 className="totalScore">{e.xtotalScore}</h1>
                            </td>
                            <td>
                              <h1 className="totalScore">{e.factor}</h1>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {current && current.pageCount > 0 && <Pagination setSelectedPage={setSelectedPageFilter} selectedPage={selectedPage} />}
            </div>
          </div>
        ) : (
          <div className="project_container">
            <div className="project_group">
              <h2>{trans.statistics}</h2>
              <div className="meeting_group_container">
                <div className="average-score">
                  <label>Ortalama Puan</label>
                  <h1>0</h1>
                  <div className="mt-2">
                    <div className="info-line">
                      <span>{trans.totalQuestionCount}</span>
                      <span>0</span>
                    </div>
                    <div className="info-line">
                      <span>{trans.totalPersonCount}</span>
                      <span>0</span>
                    </div>
                    <div className="info-line">
                      <span>{trans.totalQuestionPoint}</span>
                      <span>0</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="project_view">
              <div className="table">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>{trans.staff}</th>
                      <th>{trans.stafftask}</th>
                      <th>{trans.averagescore}</th>
                      <th>{trans.totalScore}</th>
                      <th>{trans.totalQuestionPoint}</th>
                      <th>{trans.jobscore}</th>
                      <th>{trans.numberofsuggestion}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="row">
                      <td colSpan={8}>
                        <ContentLoader
                          preserveAspectRatio="none"
                          backgroundColor={theme === "dark" ? "#666666" : "#efefef"}
                          foregroundColor={theme === "dark" ? "#222222" : "#cccccc"}
                          viewBox="0 0 1000 60"
                          height={60}
                          width="100%"
                          style={{ width: "100%" }}
                          speed={2}
                          {...props}
                        >
                          <rect x="110" y="21" rx="4" ry="4" width="754" height="6" />
                          <rect x="111" y="41" rx="3" ry="3" width="185" height="7" />
                          <rect x="304" y="-46" rx="3" ry="3" width="350" height="6" />
                          <rect x="371" y="-45" rx="3" ry="3" width="380" height="6" />
                          <rect x="484" y="-45" rx="3" ry="3" width="201" height="6" />
                        </ContentLoader>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ))}

      {current.grid &&
        (!loader.loading ? (
          <div className="project_container">
            <div className="project_group">
              <h2>{trans.statistics}</h2>
              <div className="meeting_group_container">
                <div className="average-score">
                  <label>Ortalama Puan</label>
                  <h1>{performancemanagement && performancemanagement.numbers ? performancemanagement.numbers.userAvgScore : 0}</h1>
                  <div className="mt-2">
                    <div className="info-line">
                      <span>{trans.totalQuestionCount}</span>
                      <span>{performancemanagement && performancemanagement.numbers ? performancemanagement.numbers.totalQuestionCount : 0}</span>
                    </div>
                    <div className="info-line">
                      <span>{trans.totalPersonCount}</span>
                      <span>{performancemanagement && performancemanagement.numbers ? performancemanagement.numbers.usersCount : 0}</span>
                    </div>
                    <div className="info-line">
                      <span>{trans.totalQuestionPoint}</span>
                      <span>{performancemanagement && performancemanagement.numbers ? performancemanagement.numbers.totalQuestionScore : 0}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="project_view">
              <div className="grid_container">
                {!loader.loading ? (
                  performancemanagement &&
                  performancemanagement.list &&
                  performancemanagement.list.length > 0 &&
                  performancemanagement.list.map((e, i) => {
                    return (
                      <div className="grid_card" key={"grid_card" + i} onClick={() => employeScoreDetail(e)}>
                        <div className="grid_card_profile_img_container">
                          {e.photo && e.photo.length > 5 ? (
                            <img src={e.photo ? baseUrl + e.photo : e && e.gender ? manprofile : womanprofile} alt="" className="grid_card_profile_img" />
                          ) : e.gender ? (
                            <img src={man} alt="" className="grid_card_profile_img" />
                          ) : (
                            <img src={woman} alt="" className="grid_card_profile_img" />
                          )}
                        </div>
                        <div className="grid_card_information_container_score">
                          <div className="grid_card_information_score">
                            <span className="grid_card_name">{e.name}</span>
                            {e.roleDtos && e.roleDtos.length > 0 ? (
                              <span className="grid_card_personal_perfomance">
                                <span className="personal_task badge">{e.roleDtos[0].name}</span>
                              </span>
                            ) : null}
                            <span className="grid_card_personal_perfomance">
                              <span className="average">{trans.simetriktotalscore}</span>
                            </span>
                            <span className="grid_card_personal_perfomance">
                              <span
                                className="total_point"
                                style={
                                  e.totalScore <= 100
                                    ? { color: "var(--secondary)" }
                                    : "" || (e.totalScore > 100 && e.totalScore <= 200)
                                    ? { color: "var(--primary)" }
                                    : "" || e.totalScore > 200
                                    ? { color: "var(--bg-danger)" }
                                    : ""
                                }
                                title={trans.totalQuestionPoint}
                              >
                                {e.xtotalScore}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <ContentLoader
                    preserveAspectRatio="none"
                    viewBox="0 0 1000 600"
                    height={600}
                    width={"100%"}
                    speed={1}
                    backgroundColor={theme === "dark" ? "#666666" : "#efefef"}
                    foregroundColor={theme === "dark" ? "#222222" : "#cccccc"}
                  >
                    <rect x="0" y="56" rx="2" ry="2" width="1000" height="211" />
                    <rect x="0" y="300" rx="2" ry="2" width="1000" height="211" />
                  </ContentLoader>
                )}
              </div>
              {current && current.pageCount > 0 && <Pagination setSelectedPage={setSelectedPageFilter} selectedPage={selectedPage} />}
            </div>
          </div>
        ) : (
          <div className="project_container">
            <div className="project_group">
              <div className="meeting_group_container"></div>
            </div>
            <div className="project_view">
              <div className="table">
                <table>
                  <thead>
                    <tr>
                      <th>
                        {trans.criteriaQuestion} <SortIcon color="#5d5d5d" />
                      </th>
                      <th>{trans.settings}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="row">
                      <td colSpan={8}>
                        <ContentLoader
                          preserveAspectRatio="none"
                          backgroundColor={theme === "dark" ? "#666666" : "#efefef"}
                          foregroundColor={theme === "dark" ? "#222222" : "#cccccc"}
                          viewBox="0 0 1000 60"
                          height={60}
                          width="100%"
                          style={{ width: "100%" }}
                          speed={2}
                          {...props}
                        >
                          <rect x="110" y="21" rx="4" ry="4" width="754" height="6" />
                          <rect x="111" y="41" rx="3" ry="3" width="185" height="7" />
                          <rect x="304" y="-46" rx="3" ry="3" width="350" height="6" />
                          <rect x="371" y="-45" rx="3" ry="3" width="380" height="6" />
                          <rect x="484" y="-45" rx="3" ry="3" width="201" height="6" />
                        </ContentLoader>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ))}
    </Layout>
  );
}
