import React, { useEffect, useState } from "react";
import Layout from "../../components/Container/Layout";
import { useDispatch, useSelector } from "react-redux";
import { fetchHoliday } from "../../redux/actions/holiday_ac";
import { crud } from "../../config/constants";
import Button from "../../components/anBrains/Button";
import Input from "../../components/anBrains/Input";
import { Search2Icon, PlusIcon, SortIcon, EditIcon, TrushIcon } from "../../components/icons";
import { success } from "../../redux/actions/loader_ac";
import Modal from "../../components/anBrains/Modal";
import ContentLoader from "react-content-loader";
import Pagination from "../../components/anBrains/Pagination";
import { holidayType } from "../../redux/action_types";

export default function Holiday(props) {
  const dispatch = useDispatch();
  const trans = useSelector((state) => state.trans);
  const theme = useSelector((state) => state.theme);
  const current = useSelector((state) => state.current);
  const result = useSelector((state) => state.result);
  const holiday = useSelector((state) => state.holiday);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [item, setItem] = useState({});
  const [request, setRequest] = useState({ type: -1, rowCount: 14, pageCount: 0, search: "", sort: "id", desc: false, data: {} });
  const loader = useSelector((state) => state.loader);

  useEffect(() => {
    dispatch(fetchHoliday(request, crud.list, {}, trans));
    var curr = new Date();
    curr.setDate(curr.getDate());
    var date = curr.toISOString().substr(0, 10);
    setField({ ...field, endDate: date, startDate: date });
  }, []);

  const [selectedPage, setSelectedPage] = useState(1);
  const deleteConfirm = (e) => {
    setIsDeleteOpen(true);
    setItem(e);
  };

  const deleteItem = () => {
    setIsDeleteOpen(false);
    dispatch(fetchHoliday(request, crud.delete, item, trans));
    let holidays = holiday.list.filter((e) => {
      return e.id !== item.id;
    });
    dispatch({ type: holidayType, payload: holidays });
  };

  const setSelectedPageFilter = (n) => {
    setSelectedPage(n);
    setRequest({ ...request, pageCount: n - 1 });
    dispatch(fetchHoliday({ ...request, pageCount: n - 1 }, crud.list, {}, trans));
  };
  const [desc, setDesc] = useState(false);
  const sortbyField = (f) => {
    setRequest({ ...request, sort: f, desc });
    dispatch(fetchHoliday({ ...request, sort: f, desc }, crud.list, {}, trans));
    setDesc(!desc);
  };

  const searchChange = (text) => {
    setSearchText(text);
    if (text.length > 2) {
      dispatch(fetchHoliday({ ...request, search: text }, crud.list, {}, trans));
    }
  };

  const searchChangeKeyPress = (text, kcode) => {
    if (kcode === 8) {
      dispatch(fetchHoliday({ ...request, search: text }, crud.list, trans));
    }
  };

  useEffect(() => {
    if (result.success === true) {
      setIsAddOpen(false);
      dispatch(success(false));
    }
  }, [result.change]);

  const [searchText, setSearchText] = useState("");

  var fields = {
    name: "",
    startDate: "",
    endDate: "",
  };
  const [field, setField] = useState(fields);

  const errors = {
    nameErr: "",
  };
  const [err, setErr] = useState(errors);

  let validate = () => {
    if (field.name.length < 3 || field.name.length > 20) {
      setErr({ ...errors, nameErr: trans.toShort });
      return false;
    }

    return true;
  };
  const register = (e) => {
    e.preventDefault();
    if (validate()) {
      setErr(errors);
      if (!isEdit) dispatch(fetchHoliday({}, crud.create, field, trans));
      else {
        dispatch(fetchHoliday({}, crud.update, field, trans));
        setIsEdit(false);
      }
    }
  };

  const editItem = (e) => {
    setField({ ...e, endDate: e.endDateString, startDate: e.startDateString });
    setIsAddOpen(true);
    setIsEdit(true);
  };
  const [isEdit, setIsEdit] = useState(false);

  const openForm = () => {
    setIsEdit(false);
    setField({});
    setIsAddOpen(true);
    var curr = new Date();
    curr.setDate(curr.getDate());
    var date = curr.toISOString().substr(0, 10);
    setField({ name: "", endDate: date, startDate: date });
  };
  return (
    <Layout>
      <Modal isOpen={isAddOpen} withClose setIsOpen={setIsAddOpen} classes={"modal_add_leave"}>
        <div className="leaveForm">
          <Input
            placeholder={trans.definition}
            name={trans.definition}
            header={trans.definition}
            value={field.name}
            onChange={(e) => setField({ ...field, name: e.target.value })}
            error={err.nameErr}
          />
          <Input
            type={"date"}
            header={trans.startdate}
            value={field.startDate}
            onChange={(e) => setField({ ...field, startDate: e.target.value })}
            error={err.startDateError}
          />
          <Input
            type={"date"}
            header={trans.enddate}
            value={field.endDate}
            onChange={(e) => setField({ ...field, endDate: e.target.value })}
            error={err.endDateError}
          />
          <Button classes={"btn-primary"} onClick={register}>
            {trans.save}
          </Button>
        </div>
      </Modal>
      <Modal isOpen={isDeleteOpen} withClose setIsOpen={setIsDeleteOpen} classes={"modal_delete"}>
        {trans.areyousureyouwanttodelete}
        <Button classes="btn-red" onClick={() => deleteItem()}>
          {trans.confirm}
        </Button>
      </Modal>
      <div className="table_properties">
        <Input
          icon={<Search2Icon color="rgba(96, 168, 221, 1)" />}
          placeholder={trans.search}
          value={searchText}
          onChange={(e) => searchChange(e.target.value)}
          onKeyUp={(e) => searchChangeKeyPress(e.target.value, e.keyCode)}
        />
        <Button icon={<PlusIcon color="rgba(96, 168, 221, 1)" />} classes="btn-basic" onClick={() => openForm()}>
          {trans.add}
        </Button>
        {/* <div className="bar_grid">
                    <button onClick={showBar}>
                        <BarIcon color={current.bar ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "#1a9bc4") : (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")}
                        />
                    </button>
                    <button onClick={showGrid}>
                        <GridIcon color={current.grid ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "#1a9bc4") : (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")}
                        />
                    </button>
                </div> */}
      </div>
      {!loader.loading ? (
        <div className="table">
          <table>
            <thead>
              <tr>
                <th onClick={() => sortbyField("name")}>
                  {trans.definition}
                  <SortIcon color="rgba(96, 168, 221, 1)" />
                </th>
                <th onClick={() => sortbyField("startDate")}>
                  {trans.entrydate}
                  <SortIcon color="rgba(96, 168, 221, 1)" />
                </th>
                <th onClick={() => sortbyField("endDate")}>
                  {trans.exitdate}
                  <SortIcon color="rgba(96, 168, 221, 1)" />
                </th>
                <th>{trans.settings}</th>
              </tr>
            </thead>
            <tbody>
              {holiday.list &&
                holiday.list.length > 0 &&
                holiday.list.map((e, i) => {
                  return (
                    <tr className="row" key={i}>
                      <td>{e.name}</td>
                      <td>{e.startDateString}</td>
                      <td>{e.endDateString}</td>
                      <td>
                        <button onClick={() => editItem(e)}>
                          <EditIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />
                        </button>
                        <button onClick={() => deleteConfirm(e)}>
                          <TrushIcon color={theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)"} />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>{trans.definition}</th>
              <th>{trans.exitdate}</th>
              <th>{trans.entrydate}</th>
              <th>{trans.settings}</th>
            </tr>
          </thead>
          <tbody>
            <tr className="row">
              <td colSpan={4}>
                <ContentLoader
                  preserveAspectRatio="none"
                  backgroundColor={theme === "dark" ? "#666666" : "#efefef"}
                  foregroundColor={theme === "dark" ? "#222222" : "#cccccc"}
                  viewBox="0 0 1000 60"
                  height={60}
                  width="100%"
                  style={{ width: "100%" }}
                  speed={2}
                >
                  <rect x="110" y="21" rx="4" ry="4" width="754" height="6" />
                  <rect x="111" y="41" rx="3" ry="3" width="185" height="7" />
                  <rect x="304" y="-46" rx="3" ry="3" width="350" height="6" />
                  <rect x="371" y="-45" rx="3" ry="3" width="380" height="6" />
                  <rect x="484" y="-45" rx="3" ry="3" width="201" height="6" />
                </ContentLoader>
              </td>
            </tr>
          </tbody>
        </table>
      )}

      {current && current.pageCount > 0 && <Pagination setSelectedPage={setSelectedPageFilter} selectedPage={selectedPage} />}
    </Layout>
  );
}
