import React, { useEffect, useState } from "react";
import Layout from "../../components/Container/Layout";
import man from "../../images/profiles/man.png";
import woman from "../../images/profiles/woman.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchEmployees } from "../../redux/actions/employee_ac";
import Switch from "../../components/anBrains/Switch";
import CheckBox from "../../components/anBrains/CheckBox";
// import Pagination from '../../components/anBrains/Pagination';
import Button from "../../components/anBrains/Button";
import Input from "../../components/anBrains/Input";
import { barListType, gridListType, employeeListType, addBreadcrumbType, breadcrumbType } from "../../redux/action_types";
import Modal from "../../components/anBrains/Modal";
import { PlusIcon, EditIcon, TrushIcon, GridIcon, BarIcon, SortIcon, Search2Icon } from "../../components/icons";
import AddEmployee from "../../components/employee/EmployeeForm";
import ContentLoader from "react-content-loader";
import { Link, useHistory } from "react-router-dom";
import { success } from "../../redux/actions/loader_ac";
import { baseUrl } from "../../config";
import { crud } from "../../config/constants";
import manprofile from "../../images/profiles/man.png";
import womanprofile from "../../images/profiles/woman.png";
import { fetchRoles } from "../../redux/actions/position_ac";
import { Pagination } from "antd";
export default function EmployeeManagement(props) {
  // const { staticContext } = props
  const dispatch = useDispatch();
  const trans = useSelector((state) => state.trans);
  const theme = useSelector((state) => state.theme);
  const current = useSelector((state) => state.current);
  const employee = useSelector((state) => state.employee);
  const [isAddEmployeeOpen, setIsAddEmployeeOpen] = useState(false);
  const [isDeleteEmployeeOpen, setIsDeleteEmployeeOpen] = useState(false);
  const [item, setItem] = useState({});
  const [request, setRequest] = useState({ rowCount: 14, pageCount: 0, search: "", sort: "id", desc: false, data: {} });
  const loader = useSelector((state) => state.loader);
  const { breadcrumbs } = useSelector((state) => state.current);
  const result = useSelector((state) => state.result);

  useEffect(() => {
    if (current.board === true) {
      dispatch({ type: gridListType });
    }
  }, [result.change]);

  useEffect(() => {
    dispatch(fetchRoles());
  }, []);

  useEffect(() => {
    dispatch(fetchEmployees(request, crud.list, {}, trans));
  }, []);

  const toggleForemanFilter = (checked) => {
    setRequest({ ...request, isForeman: checked });
    dispatch(fetchEmployees({ ...request, isForeman: checked }, crud.list, {}, trans));
  };

  const changeStatus = (a, e) => {
    let updateItem = {};
    for (let index = 0; index < employee.list.length; index++) {
      const selectedItem = employee.list[index];
      if (selectedItem.id === e.id) {
        employee.list[index].active = a.target.checked;
        updateItem = employee.list[index];
      }
    }
    dispatch({ type: employeeListType, payload: employee.list });
    dispatch(fetchEmployees({}, crud.update, { ...updateItem, roles: null }, trans));
  };

  const changeForeman = (a, e) => {
    let updateItem = {};
    for (let index = 0; index < employee.list.length; index++) {
      const selectedItem = employee.list[index];
      if (selectedItem.id === e.id) {
        employee.list[index].isForeman = a.target.checked;
        updateItem = employee.list[index];
      }
    }
  };
  const showBar = () => {
    dispatch({ type: barListType });
  };
  const showGrid = () => {
    dispatch({ type: gridListType });
  };
  const [selectedPage, setSelectedPage] = useState(1);
  const deleteConfirm = (e) => {
    setIsDeleteEmployeeOpen(true);
    setItem(e);
  };

  const deleteItem = () => {
    setIsDeleteEmployeeOpen(false);
    dispatch(fetchEmployees(request, crud.delete, item, trans));
    let employees = employee.list.filter((e) => {
      return e.id !== item.id;
    });
    dispatch({ type: employeeListType, payload: employees });
  };

  const setSelectedPageFilter = (n) => {
    setSelectedPage(n);
    setRequest({ ...request, pageCount: n - 1 });
    dispatch(fetchEmployees({ ...request, pageCount: n - 1 }, crud.list, {}, trans));
  };
  const [desc, setDesc] = useState(false);
  const sortbyField = (f) => {
    setRequest({ ...request, sort: f, desc });
    dispatch(fetchEmployees({ ...request, sort: f, desc }, crud.list, {}, trans));
    setDesc(!desc);
  };

  const searchChange = (text) => {
    setSearchText(text);
    if (text.length > 2) {
      dispatch(fetchEmployees({ ...request, search: text }, crud.list, {}, trans));
    }
  };

  const searchChangeKeyPress = (text, kcode) => {
    if (kcode === 8) {
      dispatch(fetchEmployees({ ...request, search: text }, crud.list, {}, trans));
    }
  };

  const history = useHistory();
  const editItem = (e) => {
    if (breadcrumbs && breadcrumbs.some((b) => b.name === e.name)) {
    }
    if (breadcrumbs.length > 1) {
      var newBreadCrumbs = [];
      newBreadCrumbs.push(breadcrumbs[0]);
      newBreadCrumbs.push({ name: e.name, path: "/personal-management/personal-detail" });
      dispatch({
        type: breadcrumbType,
        payload: newBreadCrumbs,
      });
    } else dispatch({ type: addBreadcrumbType, payload: { name: e.name, path: "/personal-management/personal-detail" } });

    history.push("/personal-management/personal-detail/" + e.id);
  };

  useEffect(() => {
    if (result.success === true) {
      setIsAddEmployeeOpen(false);
      dispatch(success(false));
    }
  }, [result.success]);
  const [searchText, setSearchText] = useState("");

  return (
    <Layout>
      <Modal isOpen={isAddEmployeeOpen} withClose setIsOpen={setIsAddEmployeeOpen} classes={"modal_add_employee"}>
        <AddEmployee />
      </Modal>
      <Modal isOpen={isDeleteEmployeeOpen} withClose setIsOpen={setIsDeleteEmployeeOpen} classes={"modal_delete"}>
        {trans.areyousureyouwanttodelete}
        <Button classes="btn-red" onClick={() => deleteItem()}>
          {trans.confirm}
        </Button>
      </Modal>
      <div className="table_properties">
        <div className="d-flex align-items-center">
          <span>{trans.foreman}</span>
          <Switch id="foremanFilter" value={request.isForeman} onChange={(e) => toggleForemanFilter(e.target.checked)} />
        </div>
        <Input
          icon={<Search2Icon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />}
          placeholder={trans.search}
          value={searchText}
          onChange={(e) => searchChange(e.target.value)}
          onKeyUp={(e) => searchChangeKeyPress(e.target.value, e.keyCode)}
        />
        <Button
          icon={<PlusIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />}
          classes="btn-basic"
          onClick={() => setIsAddEmployeeOpen(true)}
        >
          {trans.add_employee}
        </Button>
        <div className="bar_grid">
          <button onClick={showBar}>
            <BarIcon color={current.bar ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#5d5d5d"} />
          </button>
          <button onClick={showGrid}>
            <GridIcon
              color={current.grid ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#5d5d5d"}
            />
          </button>
        </div>
      </div>
      {current.bar && (
        <div className="table">
          <table>
            <thead>
              <tr>
                <th></th>
                <th onClick={() => sortbyField("name")}>
                  {trans.name + ", " + trans.surname}
                  <SortIcon color="rgba(96, 168, 221, 1)" />
                </th>
                <th> {trans.title}</th>
                <th onClick={() => sortbyField("phone")}>
                  {trans.mobile}
                  <SortIcon color="rgba(96, 168, 221, 1)" />
                </th>
                <th onClick={() => sortbyField("email")}>
                  {trans.email}
                  <SortIcon color="rgba(96, 168, 221, 1)" />
                </th>
                <th>{trans.status}</th>
                {/* <th>{trans.foreman ?? "Foreman"}</th> */}

                <th>{trans.document}</th>
                <th>{trans.settings}</th>
              </tr>
            </thead>
            <tbody>
              {employee.list &&
                employee.list.length > 0 &&
                employee.list.map((e, i) => {
                  return (
                    <tr className="row" key={i}>
                      <td onClick={() => editItem(e)}>
                        {e.photo && e.photo.length > 5 ? (
                          <img src={e.photo ? baseUrl + e.photo : e && e.gender ? manprofile : womanprofile} alt="" className="profile_list_img" />
                        ) : e.gender ? (
                          <img src={man} alt="" className="profile_list_img" />
                        ) : (
                          <img src={woman} alt="" className="profile_list_img" />
                        )}
                      </td>
                      <td onClick={() => editItem(e)}>{e.name}</td>
                      <td onClick={() => editItem(e)} title={e.roles && (e.roles.length > 0 ? e.roles.map((p) => " " + p) : "")}>
                        {e.roles && (e.roles.length > 0 ? e.roles[0] : null)}
                      </td>
                      <td onClick={() => editItem(e)}>{e.phone}</td>
                      <td onClick={() => editItem(e)}>{e.email}</td>
                      <td>
                        <Switch id={"employeStatusSwitch" + e.id} value={e.active} onChange={(a) => changeStatus(a, e)} />
                      </td>
                      {/* <td>
                        <Switch id={"employeeForemanSwitch" + e.id} value={e.isForeman} disabled={true} />
                      </td> */}
                      <td>
                        <CheckBox value={e.documentCompleted} />
                      </td>
                      <td>
                        <button onClick={() => deleteConfirm(e)}>
                          <TrushIcon color={theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)"} />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
      {current.grid && (
        <div className="grid_container">
          {employee &&
            employee.list.length > 0 &&
            employee.list.map((e, i) => {
              return (
                <div className="grid_card" key={"grid_card" + i}>
                  <div className="grid_card_profile_img_container" onClick={() => editItem(e)}>
                    {e.photo && e.photo.length > 5 ? (
                      <img src={e.photo ? baseUrl + e.photo : e && e.gender ? manprofile : womanprofile} alt="" className="grid_card_profile_img" />
                    ) : e.gender ? (
                      <img src={man} alt="" className="grid_card_profile_img" />
                    ) : (
                      <img src={woman} alt="" className="grid_card_profile_img" />
                    )}
                  </div>
                  <div className="grid_card_information_container" onClick={() => editItem(e)}>
                    <div className="grid_card_information">
                      <span className="grid_card_name">{e.name}</span>
                      {e.roles && e.roles.length > 0 ? (
                        <span className="grid_card_position">
                          <span className="badge">{e.roles[0]}</span>
                        </span>
                      ) : null}
                      <span className="grid_card_phone"> {e.phone}</span>
                      <span className="grid_card_email">{e.email}</span>
                    </div>
                    <div className="grid_card_settings">
                      <div>
                        {trans.status}
                        <Switch id={"employeStatusSwitch" + e.id} value={e.active} onChange={(a) => changeStatus(a, e)} />
                      </div>
                      <div>
                        {trans.foreman ?? "Foreman"}
                        <Switch id={"employeeForemanSwitch" + e.id} value={e.isForeman} disabled={true} />
                      </div>
                      <div>
                        {trans.document}
                        <CheckBox value={e.documentCompleted} />
                      </div>
                    </div>
                  </div>
                  <div className="grid_card_edit_buttons">
                    {/* <Link to={"/employee-management/employee-detail/" + e.id}
                                    >
                                    <EditIcon color={(theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")} />
                                </Link> */}
                    <button onClick={() => deleteConfirm(e)}>
                      <TrushIcon color={theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)"} />
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {current && current.pageCount > 0 && (
        <Pagination
          defaultCurrent={selectedPage}
          onChange={setSelectedPageFilter}
          onShowSizeChange={(pageCount, rowCount) => {
            dispatch(fetchEmployees({ ...request, pageCount, rowCount }, crud.list, {}, trans));
            setRequest({ ...request, pageCount, rowCount });
          }}
          total={current.pageCount * request.rowCount}
        />
      )}
    </Layout>
  );
}
