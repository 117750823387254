import axios from 'axios'
import { axiosConfig, baseUrl, openNotification } from '../../config'
import { pageCountType, customerEventType, customerAddEventType, customerUpdateEventType,isLoginType } from '../action_types'
import { success, calculatePageNumber, loading } from './loader_ac'
import { crud } from '../../config/constants'

export const fetchCustomerEvents = (request = { rowCount: 4, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}, trans = undefined) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/customerevents`

    if (localStorage.getItem("token"))
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
  
    return dispatch => {
        try {
            axios({
                method: "POST",
                url,
                headers,
                data: { request, requestType, data }
            })
                .then(response => {
                    console.log(response);
                    if (response.data.success) {
                        switch (requestType) {
                            case crud.create:
                                dispatch({
                                    type: customerAddEventType,
                                    payload: response.data.data
                                })
                                dispatch(success(true))
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.read:
                                break;
                            case crud.update:
                                dispatch({
                                    type: customerUpdateEventType,
                                    payload: response.data.data
                                })

                                dispatch(success(true))
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.list:
                                dispatch({
                                    type: customerEventType,
                                    payload: response.data.data
                                })
                                dispatch({
                                    type: pageCountType,
                                    payload: calculatePageNumber(response.data.count, request.rowCount)
                                })
                                break;
                            case crud.delete:
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                            default:
                                break;
                        }
                    }
                    else {
                        dispatch(success(false))
                        openNotification('error', trans.errorTitle, trans.errorBody, 3)
                    }

                })
                .catch(reason => {
                    dispatch(success(false))
                    openNotification('error', trans.errorTitle, trans.errorBody, 3)
                })
        } catch (error) {
            dispatch(success(false))
            openNotification('error', trans.errorTitle, trans.errorBody, 3)
        }
    }
}