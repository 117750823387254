import { addGanttType, ganttType, treeType, updateGanttType, changeGanttType, ganttCountType, ganttItemType, assignPersonalType, assignPersonalsType, ganttUnSavedChanges, ganttHeaderType } from '../action_types'
const reducer = (state = {
    list: [], headerList: [], tree: [], changeList: [], count: 0, assigns: [], unsavedChanges: false, item: {
        name: "",
        description: "", startDateString: "", endDateString: "", day: "", workOrderUsers: []
    }
}, action) => {
    switch (action.type) {
        case ganttCountType:
            return { ...state, count: action.payload };
        case assignPersonalType:
            return { ...state, assigns: [state.assigns, ...action.payload] };
        case assignPersonalsType:
            return { ...state, assigns: action.payload };
        case ganttItemType:
            return { ...state, item: action.payload };
        case addGanttType:
            return { ...state, list: [...state.list, action.payload] };
        case ganttType:
            return { ...state, list: action.payload };
        case ganttHeaderType:
            return { ...state, headerList: action.payload };
        case ganttUnSavedChanges:
            return { ...state, unsavedChanges: action.payload };
        case changeGanttType:
            return { ...state, changeList: action.payload };
        case treeType:
            return { ...state, tree: action.payload };
        case updateGanttType:
            var i = state.list.findIndex(x => x.id === action.payload.id);
            state.list[i] = action.payload;
            return { ...state, list: [...state.list] };
        default:
            return state;
    }
};
export default reducer;