import {
    performanceEmployeeType,
    performanceScoreType,
    performanceCriterionType,
    updatePerformanceCriterionType,
    addPerformanceCriterionType,
    performanceNumbersType,
    updateQuestionType,
    updateScoreType,
    updatePerformanceScoreType,
    deletePerformanceCriterionType,
    performanceDateType
} from '../action_types'
var toDate = new Date();
var year = toDate.getFullYear();
var month = toDate.getMonth() + 1;
var date = year + (month.toString().length > 1 ? "-" : "-0") + month;
const reducer = (state = { list: [], user: null, question: {}, score: { score: 1 }, date }, action) => {
    switch (action.type) {
        case performanceEmployeeType:
            return { ...state, list: action.payload };
        case performanceDateType:
            return { ...state, date: action.payload };
        case performanceScoreType:
            return { ...state, list: action.payload };
        case performanceNumbersType:
            return { ...state, numbers: action.payload };
        case performanceCriterionType:
            return { ...state, ...action.payload };
        case updateQuestionType:
            return { ...state, question: action.payload };
        case updatePerformanceScoreType:
            const data = action.payload;
            const newList = state.list;
            newList.find(x => x.id === data.criterionId).avgScore = data.avgScore;
            return { ...state, user: { ...state.user, totalPersonalPoint: data.totalPersonalPoint, totalPersonalAvgPoint: data.totalPersonalAvgPoint }, list: [...newList] };
        case updateScoreType:
            return { ...state, score: action.payload };
        case addPerformanceCriterionType:
            return { ...state, list: [...state.list, action.payload.item], user: { ...state.user, questionScore: action.payload.totalQuestionPoint } };
        case updatePerformanceCriterionType:
            var itemIndex = state.list.findIndex(x => x.id === action.payload.item.id);
            state.list[itemIndex] = action.payload.item;
            return { ...state, list: [...state.list] };
        case deletePerformanceCriterionType:
            return { ...state, list: action.payload }
        default:
            return state;
    }
};
export default reducer;
