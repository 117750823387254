import axios from 'axios'
import { axiosConfig, baseUrl,openNotification } from '../../config'
import { pageCountType, addCompanyCultureType, companyCultureType, updateCompanyCultureType, itemType, labelType, departmentType,isLoginType } from '../action_types'
import { success, calculatePageNumber, loading } from './loader_ac'
import { crud } from '../../config/constants'
export const fetchCompanyCulture = (request = { rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}, trans= undefined) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/CompanyCultures`
    if (localStorage.getItem("token")) 
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    
    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            }).then(response => {
                dispacth(loading())
                if (response.data.success) {
                    switch (requestType) {
                        case crud.create:
                            dispacth({
                                type: addCompanyCultureType,
                                payload: response.data.data,
                            })
                            dispacth(success(true))
                            openNotification('success', trans.successTitle, trans.successBody, 3)
                            break;
                        case crud.read:
                            break;
                        case crud.update:
                            dispacth({
                                type: updateCompanyCultureType,
                                payload: response.data.data,
                            })
                            var departments = [];
                            var personal = response.data.data;
                            if (personal.departments && personal.departments.length > 0) {
                                for (let index = 0; index < personal.departments.length; index++) {
                                    const element = personal.departments[index];
                                    departments.push({ label: element.name, value: element.id })
                                }
                                personal.departments = departments;
                            }
                            dispacth({
                                type: itemType,
                                payload: personal
                            })
                            console.log(response);
                            dispacth(success(true))
                            openNotification('success', trans.successTitle, trans.successBody, 3)
                            break;
                        case crud.delete:
                            openNotification('success', trans.successTitle, trans.successBody, 3)
                            break;
                        case crud.list:
                            dispacth({
                                type: companyCultureType,
                                payload: response.data.data,
                            })
                            dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) })
                            dispacth(success(true))
                            break;
                        case crud.with:
                            dispacth({
                                type: companyCultureType,
                                payload: response.data.data2,
                            })
                            
                            if (response.data.data && response.data.data.length > 0)
                                response.data.data[0].selected = true;

                            dispacth({
                                type: departmentType,
                                payload: response.data.data,
                            })
                            dispacth({ type: companyCultureType, payload: response.data.data2 })
                            dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) })

                            break;
                        default:
                            break;
                    }
                    
                }
                else
                    dispacth(success(false))
            }).catch(reason => {
                
                console.log();
                dispacth(loading())
                openNotification('error', trans.errorTitle, trans.errorBody,3)
               
            })
        } catch (error) {
            console.log(error)
            dispacth(loading())
            openNotification('error', trans.errorTitle, trans.errorBody,3)
        }
    }
}