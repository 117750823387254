import React, { useState, useEffect } from 'react'
import { CheckCircleIcon } from '../icons'
import { useSelector } from 'react-redux'
export default function CheckBox({ onChange, children, value, classes }) {
	const [isChecked, setIsChecked] = useState(false)
	const theme = useSelector(state => state.theme)
	useEffect(() => {
		if (value && isChecked !== value) {
			setIsChecked(value)
		}
	}, [value, isChecked])

	const handleChange = () => {
		if (onChange) {
			onChange(!isChecked)
		}
		setIsChecked(!isChecked)
	}

	return (
		// <img
		// 	onClick={handleChange}
		// 	src={isChecked ? check : uncheck}
		// 	className="settings_icons"
		// 	alt=""
		// />
		<CheckCircleIcon
			onClick={handleChange}
			color={isChecked ? (theme === "dark" ? "#3e9fbe" : "#1a9bc4") : (theme === "dark" ? "#5d5d5d" : "#e5e5e5")}
		/>
	)
}
