import axios from 'axios'
import { axiosConfig, baseUrl, openNotification } from '../../config'
import {
    pageCountType, addWorkorderType, workorderType, updateWorkorderType, workorderBoardType,
    itemType, labelType, employeeListType, addWorkorderCommentType, startWorkorderUserTimesType,
    endWorkorderUserTimesType, endWorkorderForUserType, isCameDataType, calendarType, addCalendarType,
    updateCalendarType, ganttType, addGanttType, updateGanttType, changeGanttType, updateWorkOrderUserTimeType,
    addWorkOrderUserTimeType, lineListType, ganttUnSavedChanges, ganttItemType, workOrderGroupType,
    isLoginType,
    addWorkorderBoardType,
    updateWorkorderBoardType,
    ganttHeaderType
} from '../action_types'
import { success, calculatePageNumber, loading } from './loader_ac'
import { crud } from '../../config/constants'
// import moment from 'moment'
export const fetchWorkorder = (request = { isAgenda: false, isWorkOrder: false, isMeeting: false, projectId: 0, loader: true, rowCount: 14, pageCount: 0, sort: "id", desc: true, onlyList: false }, requestType = 5, data = {}, trans = undefined) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/workorders`
    if (localStorage.getItem("token"))
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }

    return dispacth => {
        try {
            if (request.loader)
                dispacth(loading())

            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            })
                .then(response => {

                    if (request.loader)
                        dispacth(loading())

                    if (response.data.success) {
                        switch (requestType) {
                            case crud.create:
                                if (request.projectId && request.projectId > 0) {
                                    // response.data.data.index = data.index;
                                    dispacth({
                                        type: addGanttType,
                                        payload: response.data.data,
                                    })
                                }
                                else if (request.isAgendaDetail) {
                                    dispacth({ type: itemType, payload: response.data.data })
                                }
                                else {
                                    dispacth({
                                        type: addWorkorderType,
                                        payload: response.data.data,
                                    })
                                }
                                response.data.data.isAgendaHappend = false;
                                response.data.data.title = response.data.data.name;
                                response.data.data.start = new Date(response.data.data.startDate);
                                response.data.data.end = new Date(response.data.data.endDate)
                                response.data.data.id = response.data.data.id.toString();
                                response.data.data.parentId = response.data.data.parentId === 0 ? null : response.data.data.parentId;
                                response.data.data.dependencies = (response.data.data.parentId === 0 || response.data.data.parentId === null) ? "" : response.data.data.parentId.toString();

                                dispacth({
                                    type: addCalendarType,
                                    payload: response.data.data
                                })
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.read:
                                break;
                            case crud.update:

                                if (request.isGantt)
                                    dispacth({
                                        type: updateGanttType,
                                        payload: response.data.data,
                                    })
                                else if (request.isAgenda) {
                                    response.data.data.title = response.data.data.name;
                                    response.data.data.isAgendaHappend = response.data.data.isAgendaHappend;
                                    response.data.data.start = new Date(response.data.data.startDate);
                                    response.data.data.end = new Date(response.data.data.endDate)
                                    dispacth({
                                        type: updateCalendarType,
                                        payload: response.data.data,
                                    })
                                }
                                else dispacth({ type: itemType, payload: { ...response.data.data, isStarted: data.isStarted } })
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.delete:
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.list:
                                if (response.data.data.length > 0) {
                                    if (request.projectId > 0) {
                                        var gantData = []
                                        var headerList = []
                                        for (let index = 0; index < response.data.data.length; index++) {
                                            var gantRow = response.data.data[index];
                                            gantRow.id = response.data.data[index].id.toString();
                                            gantRow.parentId = response.data.data[index].parentId === 0 ? null : response.data.data[index].parentId;
                                            gantRow.dependencies = (gantRow.parentId === 0 || gantRow.parentId === null) ? "0" : gantRow.parentId.toString();
                                            gantRow.start = response.data.data[index].startDate
                                            gantRow.end = response.data.data[index].endDate
                                            // gantRow.index = index;
                                            if (gantRow.isHeader) {
                                                gantRow.progress = 0;
                                                gantRow.custom_class = "frappe_custom_bar"
                                                // headerList.push({ ...gantRow })
                                            }
                                            gantData.push(gantRow)
                                        }
                                        
                                        dispacth({
                                            type: ganttType,
                                            payload: gantData,
                                        })
                                        // dispacth({
                                        //     type: ganttHeaderType,
                                        //     payload: headerList,
                                        // })

                                    }
                                    if (request.isAgenda || request.isWorkOrder || request.isMeeting) {


                                        if (request.isWorkOrder || request.isMeeting) {
                                            dispacth({
                                                type: workorderType,
                                                payload: response.data.data,
                                            })

                                            //dispacth({ type: workorderBoardType, payload: response.data.data })
                                        }

                                        if (request.isAgenda) {
                                            for (let w = 0; w < response.data.data.length; w++) {
                                                response.data.data[w].title = response.data.data[w].name;
                                                response.data.data[w].start = new Date(response.data.data[w].startDate);
                                                response.data.data[w].end = new Date(response.data.data[w].endDate)
                                            }
                                            dispacth({
                                                type: calendarType,
                                                payload: response.data.data,
                                            })
                                        }
                                    }
                                    dispacth({
                                        type: pageCountType,
                                        payload: calculatePageNumber(response.data.count, request.rowCount)
                                    })
                                }
                                else {
                                    dispacth({
                                        type: calendarType,
                                        payload: [],
                                    })
                                    dispacth({
                                        type: workorderBoardType,
                                        payload: []
                                    })
                                    dispacth({
                                        type: workorderType,
                                        payload: []
                                    })
                                    dispacth({
                                        type: ganttType,
                                        payload: [],
                                    })
                                    dispacth({
                                        type: pageCountType,
                                        payload: calculatePageNumber(0, request.rowCount)
                                    })
                                }
                                if (request.includeDetail) {

                                    var options = [
                                        {
                                            label: request.lang === "tr" ? "Projeler" : "Projects",
                                            options: response.data.data2.projects
                                        },
                                        {
                                            label: request.lang === "tr" ? "Müşteriler" : "Customers",
                                            options: response.data.data2.customers
                                        },
                                        {
                                            label: request.lang === "tr" ? "Departmanlar" : "Departments",
                                            options: response.data.data2.departments
                                        },
                                    ];

                                    dispacth({
                                        type: labelType,
                                        payload: options
                                    })

                                    dispacth({
                                        type: lineListType,
                                        payload: [...response.data.data2.projects, ...response.data.data2.customers, ...response.data.data2.departments]
                                    })

                                    dispacth({
                                        type: employeeListType,
                                        payload: response.data.data2.users
                                    })
                                }
                                break;
                            case crud.change:

                                dispacth({ type: updateWorkorderBoardType, payload: response.data.data });
                            case crud.kanban:

                                if (response.data.data && response.data.data.length > 0) {
                                    if (request.addKanban) {
                                        dispacth({ type: addWorkorderBoardType, payload: response.data.data })
                                    }
                                    else {
                                        dispacth({ type: workorderBoardType, payload: response.data.data })
                                    }
                                }

                            default:
                                break;
                        }

                        dispacth(success(true))
                    }
                })
                .catch(reason => {

                    openNotification('error', trans.errorTitle, trans.errorBody, 3)
                    if (request.loader)
                        dispacth(loading())



                })
        } catch (error) {
            console.log(error)
            if (request.loader)
                dispacth(loading())

            openNotification('error', trans.errorTitle, trans.errorBody, 3)
        }
    }
}

export const fetchWorkorderDetail = (request = { lang: "tr", rowCount: 14, pageCount: 0, sort: "id", desc: true, isStep: false, isAgenda: false },
    requestType = 5, data = {}, trans = undefined) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/workorders`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    // //
    return dispacth => {
        try {
            if (request.loader)
                dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            })
                .then(response => {
                    if (request.loader)
                        dispacth(loading())

                    if (response.data.success) {
                        var workorderdetail = response.data.data[0];
                        console.log(response.data.data, "worked");
                        if (request.isGantStepDetail === true) {
                            for (let i = 0; i < workorderdetail.workOrderUsers.length; i++) {
                                workorderdetail.workOrderUsers[i].photo = workorderdetail.users[i].photo;
                            }
                            dispacth({ type: ganttItemType, payload: workorderdetail })
                        }
                        else {
                            dispacth({ type: itemType, payload: workorderdetail })

                            if (request.includeDetail) {
                                var options = [
                                    {
                                        label: request.lang === "tr" ? "Projeler" : "Projects",
                                        options: response.data.data2.projects
                                    },
                                    {
                                        label: request.lang === "tr" ? "Müşteriler" : "Customers",
                                        options: response.data.data2.customers
                                    },
                                    {
                                        label: request.lang === "tr" ? "Departmanlar" : "Departments",
                                        options: response.data.data2.departments
                                    },
                                ];
                                dispacth({
                                    type: labelType,
                                    payload: options
                                })

                                dispacth({
                                    type: lineListType,
                                    payload: [...response.data.data2.projects, ...response.data.data2.customers, ...response.data.data2.departments]
                                })
                                var users = response.data.data2.users;

                                dispacth({
                                    type: employeeListType,
                                    payload: users
                                })
                                var groups = response.data.data2.groups;
                                dispacth({
                                    type: workOrderGroupType,
                                    payload: groups
                                })
                            }

                        }

                        dispacth(success(true))
                    }
                })
                .catch(reason => {
                    console.log();
                    openNotification('error', trans.errorTitle, trans.errorBody, 3)
                    if (request.loader)
                        dispacth(loading())

                })
        } catch (error) {
            openNotification('error', trans.errorTitle, trans.errorBody, 3)
            console.log(error)
            if (request.loader)
                dispacth(loading())
        }
    }
}

export const fetchWorkordercomments = (request = { loader: true, rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}, trans = undefined) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/workordercomments`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    return dispacth => {
        try {
            if (request.loader)
                dispacth(loading())

            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            })
                .then(response => {

                    if (request.loader)
                        dispacth(loading())

                    if (response.data.success) {
                        console.log(response.data.data);

                        switch (requestType) {
                            case crud.create:

                                dispacth({ type: addWorkorderCommentType, payload: response.data.data })
                                break;
                            case crud.read:
                                break;
                            case crud.update:
                                dispacth({
                                    type: updateWorkorderType,
                                    payload: response.data.data,
                                })
                                const item = response.data.data;
                                dispacth({
                                    type: itemType,
                                    payload: item
                                })
                                break;
                            case crud.delete:
                                break;
                            case crud.list:
                                dispacth({
                                    type: workorderType,
                                    payload: response.data.data,
                                })
                                dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) })
                                dispacth({ type: workorderBoardType, payload: response.data.data })
                                break;
                            default:
                                break;
                        }

                        dispacth(success(true))

                    }
                })
                .catch(reason => {
                    console.log();
                    openNotification('error', trans.errorTitle, trans.errorBody, 3)
                    if (request.loader)
                        dispacth(loading())

                })
        } catch (error) {
            openNotification('error', trans.errorTitle, trans.errorBody, 3)
            console.log(error)
            if (request.loader)
                dispacth(loading())
        }
    }
}

export const fetchWorkorderTimes = (request = { loader: true, rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}, trans = undefined) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/workorderusertimes`
    if (localStorage.getItem("token"))
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }

    return dispacth => {
        try {
            if (request.loader)
                dispacth(loading())

            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            })
                .then(response => {

                    if (request.loader)
                        dispacth(loading())

                    if (response.data.success) {
                        switch (requestType) {
                            case crud.create:
                                dispacth({
                                    type: addWorkOrderUserTimeType,
                                    payload: response.data.data,
                                })
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.read:
                                break;
                            case crud.update:
                                console.log(response);
                                dispacth({
                                    type: updateWorkOrderUserTimeType,
                                    payload: response.data.data,
                                })
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.delete:
                                break;
                            case crud.list:
                                break;
                            case crud.end:
                                dispacth({
                                    type: updateWorkOrderUserTimeType,
                                    payload: response.data.data,
                                })
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            default:
                                break;
                        }

                        // dispacth(success(true))
                    }
                })
                .catch(reason => {
                    console.log();
                    if (request.loader)
                        dispacth(loading())

                    openNotification('error', trans.errorTitle, trans.errorBody, 3)

                })
        } catch (error) {
            console.log(error)
            if (request.loader)
                dispacth(loading())

            openNotification('error', trans.errorTitle, trans.errorBody, 3)
        }
    }
}

export const fetchWorkorderFullUpdate = (data, trans = undefined) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/workorders/fullUpdate`
    if (localStorage.getItem("token"))
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }

    return dispacth => {
        try {

            dispacth(loading())

            axios({
                method: 'POST',
                url,
                headers,
                data
            })
                .then(response => {

                    dispacth(loading())
                    openNotification('success', trans.successTitle, trans.successBody, 3)
                    //if (response.data.success)
                    //dispacth({ type: ganttUnSavedChanges, payload: true })
                    //
                })
                .catch(reason => {
                    console.log();
                    openNotification('error', trans.errorTitle, trans.errorBody, 3)
                    dispacth(loading())

                })
        } catch (error) {
            console.log(error)
            openNotification('error', trans.errorTitle, trans.errorBody, 3)
            dispacth(loading())
        }
    }
}

export const fetchProjectStepSave = (data) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/workorders/humanresource`
    if (localStorage.getItem("token"))
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    return dispacth => {
        try {

            dispacth(loading())

            axios({
                method: 'POST',
                url,
                headers,
                data
            })
                .then(response => {


                    dispacth(loading())

                    if (response.data.success)
                        console.log(response)

                })
                .catch(reason => {
                    console.log();
                    dispacth(loading())
                })
        } catch (error) {
            console.log(error)

            dispacth(loading())
        }
    }
}