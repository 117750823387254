import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom';
import video from '../../images/login.mp4';
import { GoBack } from '../../components/icons'
import Button from '../../components/anBrains/Button';
export default function NotFound() {
    const trans = useSelector(state => state.trans)
    const history = useHistory();
    const dispatch = useDispatch()
    return (
        <>
            <div className="login_container">
                <div className="login_image">
                    <video src={video} autoPlay muted loop> </video>
                    {/* <div className="overlay"></div> */}
                    {/* <img src="https://timezeta.com/wp-content/uploads/2020/12/6-seffaf-3.gif" /> */}
                    <div className="anonymous_container">
                        <h1>404 :(</h1>
                        <p>Sayfa bulunamadı.. <br /><button style={{ fontWeight: 800, fontSize: "1.5rem" }} onClick={e => history.goBack()}> <GoBack /> Geri git</button>
                     </p>
                    </div>
                </div>
            </div></>
    )
}
