import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { baseUrl } from '../../config';
import firm from '../../images/firm.png'
import Switch from '../anBrains/Switch';
import { projectType, breadcrumbType, addBreadcrumbType } from '../../redux/action_types';
import { fetchProject } from '../../redux/actions/project_ac';
import { crud, Priorities } from '../../config/constants';
import ContentLoader from 'react-content-loader'
import { useHistory } from 'react-router-dom';
import manprofile from '../../images/profiles/man.png';
import womanprofile from '../../images/profiles/woman.png';
export default function WorkorderBoardCard(props) {
    const { labels, name, priority, progress, users, id, point } = props;
    const dispatch = useDispatch();
    const trans = useSelector(state => state.trans)
    const theme = useSelector(state => state.theme)

    const renderPriority = (s) => {

        return (<span
            className={"priority"}
            style={{ backgroundColor: Priorities[s].color }}>
            {
                Priorities[s][trans.lang]
            }
        </span>)

    }
    const { breadcrumbs } = useSelector(state => state.current)
    const history = useHistory();
    const selectRow = (name, id) => {

        if (breadcrumbs && breadcrumbs.some(b => b.name === name)) { }
        if (breadcrumbs.length > 1) {
            var newBreadCrumbs = [];
            newBreadCrumbs.push(breadcrumbs[0]);
            newBreadCrumbs.push({
                name: name,
                path: "/workorders/workorder-detail" + id
            });
            dispatch({
                type: breadcrumbType,
                payload: newBreadCrumbs
            })
        }
        else {
            dispatch({
                type: addBreadcrumbType,
                payload: { name: name, path: "/workorders/workorder-detail" + id }
            })
        }
        history.push("/workorders/workorder-detail/" + id)

    }
    return (
        // !loader.loading ?
        <div className="board_card" >
            <div className="workorder_card_information_container">
                <div className="workorder_grid_card_information"
                    onClick={() => selectRow(name, id)}>
                    <span className="grid_card_name">{name}</span>
                    <span className="grid_card_position">
                        {(labels && labels.length > 0)
                            ?
                            labels.map((e, i) => {
                                return (<span key={"label" + i} className="grid_label_container">{e.name}</span>)
                            })
                            : null}
                        {renderPriority(priority)}
                        <div
                            className={"priority"}
                            style={theme === "dark"
                                ? { backgroundColor: "rgba(247, 202, 49, 1)", color: "#000" }
                                : { backgroundColor: "rgba(247, 202, 49, .7)", color: "#000" }}
                        >
                            {trans.lang === "en" ? "Point: " : "Puan: " + point}
                        </div>
                    </span>
                    <span className="grid_card_phone">
                        <div className="progress_container">
                            <div className="progress_bar"
                                style={{ width: (progress > 100 ? 100 : progress) + "%" }}>
                                {(progress > 100 ? 100 : progress) + "%"}
                            </div>
                        </div>
                    </span>
                </div>
                <div className="workorder_card_settings">
                    {(users && users.length > 0) ? users.map((u, i) => {
                        return (
                            <img key={"workorder_row_profile" + i} className="workorder_row_profile" src={u.photo ? baseUrl + u.photo : (u && u.gender) ? manprofile : womanprofile} alt={u.name}
                                title={u.name} />
                        )
                    }) : null}

                </div>
            </div>
            {/* <div className="grid_card_edit_buttons">
            <Link to={"/project-management/project-detail/" + e.id}
                onClick={() => editItem(e)}>
                <EditIcon color={(theme === "dark" ? "#e5e5e5" : "#000")} />
            </Link>
            <button onClick={() => deleteConfirm(e)}>
                <TrushIcon
                    color={(theme === "dark" ? "#e5e5e5" : "#000")}
                /></button>
        </div> */}
        </div>
        // : <ContentLoader
        //     preserveAspectRatio="none"
        //     viewBox="0 0 400 200"
        //     height={200}
        //     width={"100%"}
        //     speed={1}
        //     backgroundColor={theme === "dark" ? "#666666" : "#efefef"}
        //     foregroundColor={theme === "dark" ? "#222222" : "#cccccc"}>
        //     <rect x="0" y="56" rx="2" ry="2" width="100%" height="200" />
        //     {/* <rect x="0" y="300" rx="2" ry="2" width="1000" height="211" /> */}
        // </ContentLoader>
    )
}