import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { baseUrl } from '../../config';
import manprofile from '../../images/profiles/man.png';
import womanprofile from '../../images/profiles/woman.png';
import { TrushIcon, EditIcon } from '../../components/icons';
import Modal from '../../components/anBrains/Modal';
import UserTimesForm from './UserTimesForm';
import { itemType, deleteWorkOrderUserTimeType } from '../../redux/action_types'
import Button from '../../components/anBrains/Button';
import { crud } from '../../config/constants'
import { fetchWorkorderTimes } from '../../redux/actions/workorder_ac'
export default function UserTimes() {

    const theme = useSelector(state => state.theme)
    const trans = useSelector(state => state.trans)
    const current = useSelector(state => state.current)
    const { change } = useSelector(state => state.result)
    const dispatch = useDispatch()

    const [isedit, setisedit] = useState()

    const [selectedItem, setselectedItem] = useState()

    const editItem = (e) => {
        //console.log(e)
        setisedit(true)
        //dispatch({ type: itemType, payload: e})
        setselectedItem(e)
    }

    const [isDeleteWorkOrderOpen, setIsDeleteWorkOrderOpen] = useState(false)

    const deleteConfirm = (e) => {
        setIsDeleteWorkOrderOpen(true)
        setselectedItem(e);
    }

    const deleteItem = () => {
        setIsDeleteWorkOrderOpen(false)
        dispatch(fetchWorkorderTimes({}, crud.delete, selectedItem, trans))
        let workordertimes = current.item.userTimes.filter((e) => {
            return e.id !== selectedItem.id
        });
        dispatch({ type: deleteWorkOrderUserTimeType, payload: workordertimes })
    }

    useEffect(() => {

    }, [change])

    return (
        <>
            <Modal
                isOpen={isDeleteWorkOrderOpen}
                withClose
                setIsOpen={setIsDeleteWorkOrderOpen}
                classes={"modal_delete"}>
                {trans.areyousureyouwanttodelete}
                <Button classes="btn-red" onClick={() => deleteItem()}>{trans.confirm}</Button>
            </Modal>
            <Modal
                isOpen={isedit}
                withClose
                setIsOpen={setisedit}
                classes={"modal_add_employee"}>
                <UserTimesForm Id={selectedItem && selectedItem.id} setisedit={setisedit} />
            </Modal>
            <div className="usertimes_container">
                <div className="table tablewithoutshdow" style={{ borderBottom: "none" }}>
                    <table>
                        <thead>
                            <tr>
                                <th>{trans.personals}</th>
                                <th>{trans.startdate}</th>
                                <th>{trans.enddate}</th>
                                <th>{trans.timespent}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {current.item && current.item.userTimes &&
                                current.item.userTimes.length > 0 &&
                                current.item.userTimes.map((e, i) => {
                                    return (
                                        <tr className="row" key={"user" + e.userId + i}>
                                            <td style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                                {e.user && <img src={e.user.photo ? baseUrl + e.user.photo : (e && e.user.gender) ? manprofile : womanprofile} />}
                                                {e.user && e.user.name}
                                            </td>
                                            <td >{e.startDateString}</td>
                                            <td>{e.endDateString}</td>
                                            <td>
                                                {e.totalTime}
                                            </td>
                                            {e.endDateString !== null ? <td>
                                                <button onClick={() => editItem(e)}>
                                                    <EditIcon color={(theme === "dark" ? "#e5e5e5" : "#000")} />
                                                </button>
                                                <button onClick={() => deleteConfirm(e)}>
                                                    <TrushIcon color={(theme === "dark" ? "#e5e5e5" : "#000")} />
                                                </button>
                                            </td> : <td></td>}
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
