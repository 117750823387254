import { payrollType, addpayrollType, updatepayrollType } from '../action_types'
const reducer = (state = [], action) => {
    switch (action.type) {
        case payrollType:
            return action.payload;
        case addpayrollType:
            return [action.payload, ...state];
        case updatepayrollType:
            for (let index = 0; index < state.length; index++) {
                state[index].emblezeditem = action.payload.emblezeditem;
                state[index].emblezzlementdate = action.payload.emblezzlementdate;
            }
            console.log(state);
            
            return state;
        default:
            return state;
    }
};
export default reducer;