import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { fetchWorkorder, fetchWorkorderDetail } from "../../redux/actions/workorder_ac";
import { crud, schedulerType } from "../../config/constants";
import Modal from "../anBrains/Modal";
import { Input } from "antd";
import Button from "../anBrains/Button";

const localizer = momentLocalizer(moment);

export default function ReadOnlyCalendar(props) {
  const dispatch = useDispatch();
  const DragAndDropCalendar = withDragAndDrop(Calendar);
  const trans = useSelector((state) => state.trans);
  const calendar = useSelector((state) => state.calendar);
  const user = useSelector((state) => state.user);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isPointModalOpen, setIsPointModalOpen] = useState(false);
  const [newPoint, setNewPoint] = useState(0);
  const [request] = useState({ loader: true, isAgenda: true, includeDetail: false });
  const [calendarView, setCalendarView] = useState("week");
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(
      fetchWorkorder(
        {
          rangeDate: new Date().toISOString().split("T")[0],
          currentUserId: props.employeeid,
          ...request,
          includeDetail: true,
        },
        crud.list,
        {},
        trans
      )
    );
  }, []);

  const navigateDate = (date) => {
    dispatch(
      fetchWorkorder(
        {
          rangeDate: date.toISOString().split("T")[0],
          currentUserId: props.employeeid,
          ...request,
        },
        crud.list,
        {},
        trans
      )
    );
  };

  const handlePointUpdate = () => {
    // Point güncelleme işlemi
    dispatch(
      fetchWorkorder(
        request,
        crud.update,
        {
          ...selectedEvent,
          point: newPoint,
        },
        trans
      )
    );
    setIsPointModalOpen(false);
  };

  const onSelectEvent = (e) => {
    setSelectedEvent(e);
    console.log(e);
    setNewPoint(e.point || 0);
    const isAdmin = user.roles.some((role) => role === "Admin");
    if (isAdmin) {
      setIsPointModalOpen(true);
    }
  };

  // Stil ve görünüm ayarları BigCalendar ile aynı
  const eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = "#000";
    var color = "#000";
    if (event.isAgendaHappend) {
      backgroundColor = "#28a745";
      color = "#fff";
    } else {
      switch (event.type) {
        case schedulerType.onlyAgenda:
          backgroundColor = "rgba(96, 168, 221, 1)";
          color = "#fff";
          break;
        case schedulerType.workOrder_Agenda:
          backgroundColor = "rgba(247, 202, 49, 1)";
          color = "#222222";
          break;
        case schedulerType.projectStep_Workorder:
          backgroundColor = "#7231F5";
          color = "#fff";
          break;
        case schedulerType.humanResource:
          backgroundColor = "#E62020";
          color = "#fff";
          break;
        case schedulerType.meeting:
          backgroundColor = "#E62020";
          color = "#fff";
          break;
        default:
          break;
      }
    }
    return {
      style: {
        backgroundColor,
        borderRadius: "5px",
        opacity: 0.9,
        color,
        border: "0px",
        display: "block",
      },
    };
  };
  const messages = {
    tr: {
      allDay: "Tüm Gün",
      previous: "Önceki",
      next: "Sonraki",
      today: "Bugün",
      month: "Ay",
      week: "Hafta",
      day: "Gün",
      work_week: "Çalışma Günleri",
      agenda: "Agenda",
      date: "Tarih",
      time: "Saat",
      event: "Olay",
      showMore: (total) => `+ Toplam Olay  (${total})`,
    },
    en: {
      allDay: "All Day",
      previous: "Previous",
      next: "Next",
      today: "Today",
      month: "Month",
      week: "Week",
      work_week: "Work Week",
      day: "Day",
      agenda: "Agenda",
      date: "Date",
      time: "Time",
      event: "Event",
      showMore: (total) => `+ Total Event  (${total})`,
    },
  };
  return (
    <>
      <Modal isOpen={isPointModalOpen} withClose setIsOpen={setIsPointModalOpen}>
        <div className="point-update-container" style={{ backgroundColor: "white", padding: 20, borderRadius: 10 }}>
          <h3>{selectedEvent?.name}</h3>
          <div className="input-container">
            <span className="input_header">{trans.point}</span>
            <select className="input select" onChange={(e) => setNewPoint(parseInt(e.target.value))} value={newPoint}>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="5">5</option>
              <option value="8">8</option>
              <option value="13">13</option>
              <option value="21">21</option>
            </select>
          </div>
          <Button onClick={handlePointUpdate} classes="btn-primary">
            {trans.update}
          </Button>
        </div>
      </Modal>

      <div className="scheduler_container" style={{ padding: 10 }}>
        <DragAndDropCalendar
          selectable={false}
          resizable={false}
          localizer={localizer}
          events={calendar.list}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 820 }}
          date={defaultDate}
          onNavigate={(date) => {
            setDefaultDate(date);
            navigateDate(date);
          }}
          views={["month", "week", "day"]}
          view={calendarView}
          onView={setCalendarView}
          eventPropGetter={eventStyleGetter}
          onSelectEvent={onSelectEvent}
          messages={messages[trans.lang]}
          draggableAccessor={() => false}
        />
      </div>
    </>
  );
}
