import React from 'react'
import Button from './Button'
import { TimesIcon } from '../icons'

export default function Modal({ isOpen, setIsOpen, children, withClose, classes }) {
	if (isOpen) {
		return (
			<div className="modal">
				<div className={`modal-content ${classes ? classes : ''}`}>
					{withClose && (
						<Button classes= {"btn-close"} onClick={() => setIsOpen(false)}>
							<TimesIcon color="#31363e" />
						</Button>
					)}
					{children}
				</div>
			</div>
		)
	} else {
		return null
	}
}
