import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../anBrains/Button';
import { PlusIcon, TrushIcon, SortIcon, DocumentIcon } from '../icons';
import Modal from '../anBrains/Modal';
import { payrollType } from '../../redux/action_types';
import Input from '../anBrains/Input';
import Pagination from '../anBrains/Pagination'
import { fetchpayrolls, fetchpayrollform } from '../../redux/actions/payroll_ac';
import FileUploader from '../anBrains/FileUploader';
import { crud } from '../../config/constants';
import { baseUrl } from '../../config';
export default function PayrollTab({ employeeid, isEmploye }) {

    const trans = useSelector(state => state.trans)
    const payroll = useSelector(state => state.payroll)
    const theme = useSelector(state => state.theme)
    const result = useSelector(state => state.result)
    const current = useSelector(state => state.current)
    const dispatch = useDispatch()
    const [item, setItem] = useState({})
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)

    useEffect(() => {
        if (result.success) {
            setIsAddOpen(false);
            setField(fields)
            dispatch(fetchpayrolls({ ...listParameters }));

        }
    }, [result.change])

    const deleteConfirm = (e) => {
        setIsDeleteOpen(true)
        setItem(e);
    }
    const deleteItem = () => {
        setIsDeleteOpen(false)
        dispatch(fetchpayrolls({}, crud.delete, item))
        let payrolls = payroll.filter((e) => {
            return e.id !== item.id
        });
        dispatch({ type: payrollType, payload: payrolls })
    }
    // const editItem = (e) => {
    //     setField(e);
    //     setIsAddOpen(true);
    // }
    const [isAddOpen, setIsAddOpen] = useState(false)
    const [listParameters, setListParameters] = useState({ rowCount: 14, pageCount: 0, sort: "id", desc: true, userId: employeeid }, crud.list, {})
    const [asc, setAsc] = useState(false)
    const sortbyField = (f) => {
        setListParameters({ ...listParameters, sort: f, desc: asc ? false : true })
        setAsc(!asc)
        dispatch(fetchpayrolls({ ...listParameters, sort: f, desc: asc ? false : true }));
    }

    var fields = {
        // id: 0,
        userId: employeeid,
        name: "",
        period: "",
        path: null
    }
    const [field, setField] = useState(fields)

    const errors = {
        nameErr: "",
        periodError: "",
        pathError: "",
    }
    const [err, setErr] = useState(errors)

    useEffect(() => {
        var curr = new Date();
        curr.setDate(curr.getDate());
        var date = curr.toISOString().substr(0, 10);
        setField({ ...field, period: date })
    }, [])

    let validate = () => {
        if (field.path === null || field.path === undefined) {
            setErr({ ...errors, pathError: trans.pleaseEnterValid })
            return false
        }

        if (field.name.length < 3 || field.name.length > 30) {
            setErr({ ...errors, nameErr: trans.toShort })
            return false
        }

        if (field.period === "" || field.period === undefined) {
            setErr({ ...errors, periodError: trans.pleaseEnterValidDate })
            return false
        }
        return true
    }
    const register = e => {
        e.preventDefault();
        if (validate()) {
            field.path = "";
            formdata.append("data", JSON.stringify(field))
            formdata.append("requestType", crud.create)
            formdata.append("request", "{}")
            setErr(errors)
            for (var value of formdata.values()) {
                console.log(value);
            }
            dispatch(fetchpayrollform(formdata,trans))
        }
    }

    const [selectedPage, setSelectedPage] = useState(1)
    const setSelectedPageFilter = (n) => {
        setSelectedPage(n)
        setListParameters({ ...listParameters, pageNumber: n - 1 })
        dispatch(fetchpayrolls({ ...listParameters, pageNumber: n - 1 }));
    }
    const [formdata, setFormdata] = useState(null)
    const loadedFile = data => {
        setField({ ...field, path: true })
        setFormdata(data);
    }
    return (
        <>
            <div className="payroll">
                <div className="table_properties">
                    {isEmploye ?
                        <Button
                            icon={<PlusIcon color={theme === "dark" ? "#3e9fbe" : "#1a9bc4"} />}
                            classes="btn btn-basic"
                            onClick={() => setIsAddOpen(true)}>{trans.add}</Button> : null}
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>{trans.document}</th>
                            <th onClick={() => sortbyField("description")}>{trans.description} <SortIcon color="#5d5d5d" /></th>
                            <th onClick={() => sortbyField("period")}>{trans.date}
                                <SortIcon color="#5d5d5d" /></th>
                            {isEmploye ?
                                <th>{trans.settings}</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {payroll && payroll.length > 0 && payroll.map((e, i) => {
                            return (
                                <tr className="row" key={e.id}>
                                    <td> <a target="_blank" href={baseUrl + e.path}>
                                        <DocumentIcon color={(theme === "dark" ? "#e5e5e5" : "#000")} /></a>
                                    </td>
                                    <td>{e.name}</td>
                                    <td >{e.periodString}</td>
                                    {/* <td>{e.end}</td> */}
                                    {isEmploye &&
                                        <td>
                                            {/* <button onClick={() => editItem(e)}>
                                            <EditIcon color={(theme === "dark" ? "#e5e5e5" : "#000")} />
                                        </button> */}
                                            <button onClick={() => deleteConfirm(e)}>
                                                <TrushIcon
                                                    color={(theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)")}
                                                /></button>
                                        </td>}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {current && current.pageCount > 0 &&
                    <Pagination
                        setSelectedPage={setSelectedPageFilter}
                        selectedPage={selectedPage} />}
            </div>
            <Modal
                isOpen={isAddOpen}
                withClose
                setIsOpen={setIsAddOpen}
                classes={"modal_add_leave"}>
                <div className="leaveForm">
                    <FileUploader text="asdf" loadedFile={loadedFile} err={err.pathError} />
                    <Input placeholder={trans.description}
                        name={trans.description}
                        header={trans.description}
                        value={field.name}
                        onChange={e => setField({ ...field, name: e.target.value })}
                        error={err.nameErr} />
                    <Input
                        type={"date"}
                        header={trans.date}
                        value={field.period}
                        onChange={e => setField({ ...field, period: e.target.value })}
                        error={err.periodError} />
                    {/* <Input
                        type={"date"}
                        header={trans.enddate}
                        value={field.end}
                        onChange={e => setField({ ...field, end: e.target.value })}
                        error={err.endError} /> */}
                    <Button classes={"btn-primary"}
                        onClick={register}>{trans.save}</Button>
                </div>
            </Modal>
            <Modal
                isOpen={isDeleteOpen}
                withClose
                setIsOpen={setIsDeleteOpen}
                classes={"modal_delete"}>
                {trans.areyousureyouwanttodelete}
                <Button classes="btn-red" onClick={() => deleteItem()}>{trans.confirm}</Button>
            </Modal>
        </>
    )
}
