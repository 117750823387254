import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducer from './reducers';
// import logger from 'redux-logger'
// export const makeStore = (initialState, options) => {
//     return createStore(reducer, initialState, composeWithDevTools(applyMiddleware(thunk)));
// };

export const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));
