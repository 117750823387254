import { isLoaderType, isSpinType, resultType } from '../action_types'
export const loading = () => dispacth => { dispacth({ type: isLoaderType }) }
export const spinning = () => dispacth => { dispacth({ type: isSpinType }) }

export const success = (payload) => dispacth => { dispacth({ type: resultType, payload }) }

export const calculatePageNumber = (count, countPerPage) => {
    var pageCount = Math.ceil(count / countPerPage)
    if (pageCount === 1)
        return 0
    return pageCount
}
