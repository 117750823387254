import { inOutType, avgInOutType, addInOutType, updateInOutType } from '../action_types'
const reducer = (state = { list: [], avg: {} }, action) => {
    switch (action.type) {
        case inOutType:
            return { ...state, list: action.payload };
        case addInOutType:
            return { ...state, list: [...state.list, action.payload] }
        case updateInOutType:
            var inOuts = [];
            state.list.forEach((e) => {
                if (e.id === action.payload.id)
                    inOuts.push(action.payload)
                else inOuts.push(e)
            })
            return { ...state, list: inOuts }
        case avgInOutType:
            return { ...state, avg: action.payload };
        default:
            return state;
    }
};
export default reducer;