import firebase from "firebase"
import 'firebase/messaging'
const config = {
    apiKey: "AIzaSyCpkerPVtkZBWu7IRTDilJRyjsfos_9NlE",
    authDomain: "timezeta-c51b1.firebaseapp.com",
    projectId: "timezeta-c51b1",
    storageBucket: "timezeta-c51b1.appspot.com",
    messagingSenderId: "162729518180",
    appId: "1:162729518180:web:f5d6d70837cfc774d02b71",
    measurementId: "G-PEEFYZ4WTR"
}
let messaging = null;
export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging.onMessage((payload) => {
            console.log(payload);
            resolve(payload);
        });
    });

if (firebase.messaging.isSupported()) {
    firebase.initializeApp(config);
    messaging = firebase.messaging();
    // messaging.onBackgroundMessage(function (payload) {
    //     console.log(payload);
    // });
    onMessageListener();
}
export const getToken = () => {
    return messaging.getToken({ vapidKey: 'BL0rtrjDkXsPh1tqkWtgibyyelQJvC9jCoW1WpI_e9DqmJ97yzHKjao0MqfBvv85Tm1SChLF-4IH_NZiULsWVvo' })
        .then((token) => {
            if (token) return token;
            else return undefined;
        }).catch((err) => {
            return undefined
        });

}

