import { debitType, adddebitType } from '../action_types'
const reducer = (state = [], action) => {
    switch (action.type) {
        case debitType:
            return action.payload;
        case adddebitType:
            return [action.payload, ...state];
        default:
            return state;
    }
};
export default reducer;