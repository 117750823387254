import { calendarType, addCalendarType, updateCalendarType } from '../action_types'
const reducer = (state = { list: [] }, action) => {
    switch (action.type) {
        case calendarType:
            return { ...state, list: action.payload };
        case addCalendarType:
            return { ...state, list: [...state.list, action.payload] };
        case updateCalendarType:
            var list = state.list;
            for (let l = 0; l < list.length; l++) {
                const e = list[l];
                if (e.id === action.payload.id) {
                    list[l] = action.payload;
                }
            }
            return { ...state, list };
        default:
            return state;
    }
};
export default reducer;