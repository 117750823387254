import axios from 'axios'
import { axiosConfig, baseUrl, openNotification } from '../../config'
import { addpayrollType, pageCountType, employeeDocumentGroupType, addEmployeeDocumentType, employeeDocumentType, requiredDocumentsType,isLoginType } from '../action_types'
import { loading, success, calculatePageNumber } from './loader_ac'
import { crud } from '../../config/constants'
export const fetchEmployeeDocuments = (request = { rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}) => {
    let headers = axiosConfig.headers

    let url = `${baseUrl}/api/userfiles`

    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    console.log(data);

    return dispacth => {
        try {
            // dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            })
                .then(response => {
                    if (response.data.success) {
                        switch (requestType) {
                            case crud.create:
                                dispacth({
                                    type: addEmployeeDocumentType,
                                    payload: response.data.data,
                                })

                                dispacth(success(true))
                                break;
                            case crud.read:
                                break;
                            case crud.update:
                                break;
                            case crud.delete:
                                break;
                            case crud.list:
                                dispacth({
                                    type: employeeDocumentType,
                                    payload: response.data.data,
                                })
                                dispacth({
                                    type: employeeDocumentGroupType,
                                    payload: response.data.data2.types,
                                })
                                dispacth({
                                    type: requiredDocumentsType,
                                    payload: response.data.data2.requiredDocuments,
                                })

                                dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) })
                                break;
                            default:
                                break;
                        }
                    }
                    console.log("LİST: ", response);

                })
                .catch(reason => {
                    
                    
                })
        } catch (error) {
            console.log(error)
            // dispacth(loading())
        }
    }
}
export const fetchEmployeeDocument = (data, trans = undefined) => {
    let headers = axiosConfig.headers

    let url = `${baseUrl}/api/userfiles/form`

    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    return dispacth => {
        try {
            // dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data
            })
                .then(response => {
                    if (response.data.success) {

                        dispacth({
                            type: addpayrollType,
                            payload: response.data.data,
                        })
                        dispacth(success(true))
                        openNotification('success', trans.successTitle, trans.successBody, 3)
                    }
                    console.log(response);

                })
                .catch(response => {
                    console.log(response.response);
                    openNotification('error', trans.errorTitle, trans.errorBody, 3)
                })
        } catch (error) {
            console.log(error)
            openNotification('error', trans.errorTitle, trans.errorBody, 3)
            // dispacth(loading())
        }
    }
}

export const fetchDocumentGroup = () => {
    let headers = axiosConfig.headers

    let url = `${baseUrl}/staff/RequiredDocument/list`

    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }


    return dispacth => {
        try {
            // dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers
            })
                .then(response => {
                    if (response.data.status === true) {
                        dispacth({
                            type: employeeDocumentGroupType,
                            payload: response.data.data,
                        })

                    }
                    console.log(response);

                })
                .catch(reason => {
                    
                })
        } catch (error) {
            console.log(error)
            // dispacth(loading())
        }
    }
}