import React, { useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { profileMenu } from '../../dummyData'
import Switch from '../anBrains/Switch'
import { useHistory, Link } from 'react-router-dom';
import { addBreadcrumbType, breadcrumbType, getUserType, pageTitleType, isLoginType, usersType } from '../../redux/action_types'

export default function ProfileMenu(props) {
    const dispatch = useDispatch()
    const trans = useSelector(state => state.trans)
    const user = useSelector(state => state.user)
    const { changeLang, theme, changeTheme } = props
    const { breadcrumbs } = useSelector(state => state.current)
    const history = useHistory();

    const handleClickOutside = () => {
        props.setIsOpenProfileMenu(false)
    }
    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef, handleClickOutside)

    const logout = (i) => {
        if (i === 3) {
            dispatch({ type: isLoginType, payload:  false})
            localStorage.removeItem("token")
            localStorage.removeItem("user")
            history.push("/")
        }
    }

    const Profile = (name, e) => {
        if (breadcrumbs.length == 1) {
            dispatch({ type: breadcrumbType, payload: [{ name: name, path: "/profile" }] })
            dispatch({ type: pageTitleType, payload: name })
        }
        else {
            dispatch({ type: addBreadcrumbType, payload: { name: name, path: "/profile" } })
            dispatch({ type: pageTitleType, payload: name })
        }
        history.push("/profile/" + user.id);
    }

    return (
        <div className="profile_menu" ref={wrapperRef}>
            <ul>
                {profileMenu.map((n, i) => {
                    return (
                        <li key={i} className={n.color ? "licolor" : ""}>
                            {n.id === "profile" ?
                                <span onClick={() => Profile(n.name[trans.lang], n.id)}>{n.name[trans.lang]}</span>
                                //<Link to={`/profile/` + userId} color={(theme === "dark" ? "#9f9f9f" : "#5d5d5d")}>{n.name[trans.lang]}</Link> 
                                : null}
                            {n.id !== "profile" ?
                                <span onClick={() => logout(i)}>{n.name[trans.lang]}</span> : null}
                            {n.id === "lang" ? <Switch
                                id={"langSwitch"}
                                value={trans.lang === "tr" ? false : true}
                                onChange={changeLang} /> : null}
                            {n.id === "darkmode" ? <Switch
                                id={"themeSwitch"}
                                value={theme === "light" ? false : true}
                                onChange={changeTheme} /> : null}
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
function useOutsideAlerter(ref, onClick) {
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            onClick()
        }
    }

    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    })
}