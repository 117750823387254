import React, { useState, useEffect } from 'react'
import ImagePicker from '../anBrains/ImagePicker'
import { Bar, Pie } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

export default function ReportBar({ }) {
	const { reports, dataset, dataset2, isPlanned } = useSelector(state => state.report)
	const current = useSelector(state => state.current)
	const trans = useSelector(state => state.trans)
	return (
		<div className='report_chart_container'>
			{current.item.reportType === "0" && trans.minute}
			{current.item.reportType === "1" && trans.point}
			<Bar
				key={new Date().getSeconds()}
				data={isPlanned ? dataset : dataset2}
				options={{
					responsive: true,
					aspectRatio: 1.5,
					legend: {
						display: window.innerWidth < 1000 ? false : true,
						position: 'top',
						align: 'start',
						padding: {
							bottom: 50
						}
					},
					scales: {
						xAxes: [{
							stacked: true,
							ticks: {
								beginAtZero: true,
								maxRotation: 0,
								minRotation: 0
							}
						}],
						yAxes: [{
							stacked: true
						}]
					},
					layout: {
						padding: {
							top: 10
						}
					}
				}}
			/>
		</div>
	)
}
