import axios from 'axios'
import { axiosConfig, baseUrl,openNotification } from '../../config'
import { payrollType, addpayrollType, pageCountType,isLoginType } from '../action_types'
import { success, calculatePageNumber } from './loader_ac'
import { crud } from '../../config/constants'
export const fetchpayrolls = (request = { rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}) => {
    let headers = axiosConfig.headers

    let url = `${baseUrl}/api/payrolls`

    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    console.log(data);

    return dispacth => {
        try {
            // dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            })
                .then(response => {
                    if (response.data.success) {
                        switch (requestType) {
                            case crud.create:
                                dispacth({
                                    type: addpayrollType,
                                    payload: response.data.data,
                                })

                                dispacth(success(true))
                                break;
                            case crud.read:
                                break;
                            case crud.update:
                                break;
                            case crud.delete:
                                break;
                            case crud.list:
                                dispacth({
                                    type: payrollType,
                                    payload: response.data.data,
                                })
                                console.log(response);

                                dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) })
                                break;
                            default:
                                break;
                        }
                    }
                })
                .catch(reason => {
                    

                  

                })
        } catch (error) {
            console.log(error)
            // dispacth(loading())
        }
    }
}
export const fetchpayrollform = (data,trans= undefined) => {
    let headers = axiosConfig.headers

    let url = `${baseUrl}/api/payrolls/form`

    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
console.log(data);

    return dispacth => {
        try {
            // dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data
            })
                .then(response => {
                    if (response.data.success) {

                        dispacth({
                            type: addpayrollType,
                            payload: response.data.data,
                        })
                        dispacth(success(true))
                        openNotification('success', trans.successTitle, trans.successBody, 3)
                    }
                })
                .catch(reason => {
                    
                    openNotification('error', trans.errorTitle, trans.errorBody,3)

                  

                })
        } catch (error) {
            console.log(error)
            openNotification('error', trans.errorTitle, trans.errorBody,3)
            // dispacth(loading())
        }
    }
}
