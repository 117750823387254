import axios from 'axios'
import { axiosConfig, baseUrl, openNotification } from '../../config'
import { pageCountType, itemType, customerListType, usersType, addCustomerType, addResponsibleType, newResponsibleType, isLoginType } from '../action_types'
import { loading, calculatePageNumber, success } from './loader_ac'
import { crud } from '../../config/constants'
import { active } from 'sortablejs'
import { bindActionCreators } from 'redux'
export const fetchCustomers = (request = { detail: false, rowCount: 14, pageCount: 0 }, requestType = 5, data = {}, trans = undefined) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/customers`
    if (localStorage.getItem("token"))
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }


    if (!data.photo?.includes("/customer"))
        data.photo = data.photo?.replace(/^data:image\/[a-z]+;base64,/, "")

    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            })
                .then(response => {

                    if (response.data.success) {
                        switch (requestType) {
                            case crud.create:
                                if (response.data.success) {
                                    dispacth({
                                        type: addCustomerType,
                                        payload: response.data.data,
                                    })
                                }
                                dispacth(success(response.data.success))
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                // dispacth(loading())
                                break;
                            case crud.read:
                                break;
                            case crud.delete:
                                break;
                            case crud.list:
                                if (request.isCustomerDetail) {
                                    dispacth({ type: itemType, payload: response.data.data[0] });
                                }
                                else {
                                    dispacth({
                                        type: customerListType,
                                        payload: response.data.data
                                    })
                                    dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) })
                                }
                                dispacth({
                                    type: usersType,
                                    payload: response.data.data2.users
                                })
                                break;
                            case crud.update:
                                dispacth({
                                    type: itemType,
                                    payload: response.data.data
                                })
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            default:
                                break;
                        }
                    }
                    dispacth(loading())
                    dispacth(success(true))

                })
                .catch(reason => {

                    dispacth(loading())

                })
        } catch (error) {
            console.log(error)
            dispacth(loading())

        }
    }
}

export const fetchResponsible = (request = { rowCount: 14, pageCount: 0 }, requestType = 5, data = {}, trans = undefined) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/ResponsiblePersons`
    if (localStorage.getItem("token"))
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }

    return dispatch => {
        try {
            dispatch(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            })
                .then(response => {
                    dispatch(loading())
                    if (response.data.success) {
                        dispatch(success(true))
                        switch (requestType) {
                            case crud.create:
                                dispatch({
                                    type: addResponsibleType,
                                    payload: response.data.data,
                                })
                                console.log(response);
                                dispatch(success(true))
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.read:
                                break;
                            case crud.delete:
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.list:
                                break;
                            case crud.update:
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            default:
                                break;
                        }
                    }
                    else {
                        dispatch(success(false))
                        openNotification('error', trans.errorTitle, trans.errorBody, 3)
                    }

                })
                .catch(reason => {
                    console.log();
                    dispatch(loading())
                    openNotification('error', trans.errorTitle, trans.errorBody, 3)
                })
        } catch (error) {
            console.log(error)
            dispatch(loading())
            openNotification('error', trans.errorTitle, trans.errorBody, 3)
        }
    }
}
