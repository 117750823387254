import React, { useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Switch from '../anBrains/Switch'
import { fetchEmployees } from '../../redux/actions/employee_ac'
import { crud, getTableType } from '../../config/constants'
import { employeeListType, labelType } from '../../redux/action_types'
import { fetchLabels } from '../../redux/actions/label_ac'
import Input from '../anBrains/Input'
export default function FilterByLabels(props) {
    const trans = useSelector(state => state.trans)
    const label = useSelector(state => state.label)
    const dispatch = useDispatch();
    useEffect(() => {
        if (label.list.length === 0) {
            dispatch(fetchLabels({}, crud.list, {}, trans))
        }
    }, [])

    const change = (e, n) => {

        var selectedLabels = []
        for (let index = 0; index < label.list.length; index++) {
            for (let ci = 0; ci < label.list[index].options.length; ci++) {
                if (label.list[index].options[ci].value === n.value && label.list[index].options[ci].label === n.label) {
                    label.list[index].options[ci].selected = (label.list[index].options[ci].selected === true ? false : true);
                }
                if (label.list[index].options[ci].selected === true) {
                    label.list[index].options[ci].tableType = getTableType("en", label.list[index].label)
                    selectedLabels.push({
                        tableId: label.list[index].options[ci].value,
                        tableType: label.list[index].options[ci].tableType
                    })
                }
            }
        }
        dispatch({ type: labelType, payload: label.list })
        props.filterByLabel(selectedLabels)
    }

    const [search, setSearch] = useState("")
    return (
        <div className="filter_by_label">
            <Input placeholder={trans.search}
                name={trans.projectName}
                // header={trans.projectName}
                value={search}
                onChange={e => setSearch(e.target.value)} />
            <ul>
                {label && label.list && label.list.map((n, i) => {
                    return (
                        <li key={i} className={n.color ? "licolor label-title" : "label-title"}>
                            <span style={{ fontWeight: "bold" }}>{n.label}</span>
                            <ul className="label-item-list" style={{ overflow: "unset" }}>
                                {n && n.options && n.options.filter(x => x.label.toLocaleLowerCase('tr').includes(search)).map((o, oi) => {
                                    return (<li key={"li" + oi + i}>
                                        <Switch
                                            id={"langSwitch" + oi + i}
                                            value={o.selected}
                                            onChange={e => change(e, o)} />
                                        {o.label}
                                    </li>)
                                })}
                            </ul>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}
