import { projectType, addProjectType, insideMenuTypeInProject, projectBoardType, updateProjectBoardListType } from '../action_types'
import { ProjectStatus } from '../../config/constants';
const reducer = (state = {
    list: [], totalhour: 0, boardlist: { lanes: [] }, kanban: [], inside_menu: [
        { id: 30, content: "detail", name: { tr: "Detay", en: "Detail" }, active: false },
        { id: 33, content: "event", name: { tr: "Olay", en: "Event" }, active: false },
        { id: 36, content: "planning", name: { tr: "Planlama", en: "Planning" }, active: false },
        { id: 37, content: "cost", name: { tr: "Maliyet", en: "Cost" }, active: false },
        { id: 38, content: "file", name: { tr: "Dosya", en: "File" }, active: false },
        { id: 55, content: "workorder", name: { tr: "İş Listesi", en: "Work Order" }, active: false }
    ]
}, action) => {
    switch (action.type) {
        case projectType:
            return { ...state, list: action.payload };
        case projectBoardType:
            return { ...state, boardlist: getTrello(action.payload) };
        case updateProjectBoardListType:
            // var projectListCount = action.payload.length;
            if (action.payload.projectStatus || action.payload.projectStatus === 0) {
                //
                var lane = getTrello(action.payload.data).lanes[action.payload.projectStatus];
                var trellolist = lane.cards;
                var labelCount = lane.label;
                var currentLabelCount = state.boardlist.lanes.find(x => x.id === action.payload.projectStatus.toString()).label;
                
                state.boardlist.lanes.find(x => x.id === action.payload.projectStatus.toString()).label = (parseInt(currentLabelCount) + parseInt(labelCount)).toString();
                // state.boardlist.lanes.find(x => x.id === action.payload.projectStatus.toString()).latestLabelCount = parseInt(labelCount);
                state.boardlist.lanes.find(x => x.id === action.payload.projectStatus.toString()).currentPage++;
                
                trellolist.map((e, i) => {
                    // if (!state.boardlist.lanes.find(x => x.id === action.payload.projectStatus.toString()).cards.find(x => x.id === e.id))
                        state.boardlist.lanes.find(x => x.id === action.payload.projectStatus.toString()).cards.push(e);
                });
            }
            else {
                var trellolist = getTrello(action.payload.data)
                state.boardlist = trellolist;
            }
            return { ...state, boardlist: { ...state.boardlist } };
        case addProjectType:
            if (state.list.length > 0 && state.list.some(x => x.projectGroupId === action.payload.projectGroupId)) {
                var statelist = [...state.list, action.payload];
                return { ...state, list: statelist, boardlist: getTrello(statelist) };
            }
            else {
                state.list.push(action.payload);
                return state;
            }
        case insideMenuTypeInProject:
            return { ...state, inside_menu: action.payload };
        default:
            return state;
    }
};
export default reducer;

const getTrello = (data) => {
    var project = data;
    var trello = { lanes: [] };
    for (let index = 0; index < ProjectStatus.length; index++) {
        var projectlistbystatus = project.filter(x => x.status === ProjectStatus[index].id)
        var projectData =
        {
            ...ProjectStatus[index],
            id: ProjectStatus[index].id.toString(),
            title: ProjectStatus[index].tr,
            label: projectlistbystatus.length.toString(),
            style: {
                borderColor: ProjectStatus[index].color
            },
            cards: projectlistbystatus,
        };
        trello.lanes.push(projectData);
    }
    return trello
}