import React, { useState, useEffect } from 'react'
import manprofile from '../../images/profiles/man.png';
import womanprofile from '../../images/profiles/woman.png';
import { useSelector, useDispatch } from 'react-redux';
import { baseUrl } from '../../config';
import { fetchReport } from '../../redux/actions/report_ac';
var daysInSelectedMonth = []
var getDaysInMonth = function (month, year) {
	return new Date(year, month, 0).getDate();
};
var toDate = new Date();
export default function ReportList(props) {
	const dispatch = useDispatch();
	const theme = useSelector(state => state.theme)
	const trans = useSelector(state => state.trans)
	const { reports } = useSelector(state => state.report)
	const current = useSelector(state => state.current)
	const [date, setDate] = useState(toDate.getFullYear() + "-" + toDate.getMonth() + 1)

	useEffect(() => {
		dayBlankColumn(date.substr(5, 2), date.substr(0, 4))

	}, [])
	const dayBlankColumn = (month, year) => {
		daysInSelectedMonth = [];
		var dNumber = getDaysInMonth(month, year);
		// //
		for (let i = 0; i < dNumber; i++)
			daysInSelectedMonth.push(i + 1);
		return
	}
	return (
		<table>
			<thead>
				{/* <tr>

					<th colSpan={(reports && reports.length > 0) ? reports[0].user.days.length - 2 : getDaysInMonth(date.substr(5, 2), date.substr(0, 4))}><input type="month" onChange={e => setDate(e.target.value)} value={date} /></th>

					{(reports && reports.length > 0) ? <th colSpan={reports[0].user.days.length - 2}></th> : null}
				</tr> */}
				<tr>
					<th rowSpan='2'>Personel</th>
					<th rowSpan='2'>
						{current.item.reportType==="0"&&trans.hour}
						{current.item.reportType==="1"&&trans.point}
						</th>
					{(reports && reports.length > 0) ? reports[0].user.days.map((d, i) => <th key={"th" + i}>{d.dayNumber}</th>) : daysInSelectedMonth.map((d, i) => <th key={"th" + i}>{d}</th>)}
				</tr>
			</thead>
			<tbody>
				{(reports && reports.length > 0) ? reports.map((r, i) => {
					return (
						<tr key={"tr" + i}>
							<td style={{ textAlign: "left", paddingLeft: "1rem" }} >
								<img src={(r.user && r.user.photo)
									? baseUrl + r.user.photo
									: (r.user && r.user.gender)
										? manprofile
										: womanprofile} className='report_profile' />
								<span style={{ display: 'inline-block' }}>{r.user.name}</span>
							</td>
							<td>
								{current.item.reportType === "0" && <b><span style={{ opacity: .5 }}>{r.user.totalPlanned}</span>  <br />{r.user.totalCompleted}</b>}
								{current.item.reportType === "1" && <b>{r.user.totalPoint === 0 ? "-" : r.user.totalPoint}</b>}
							</td>
							{current.item.reportType === "0" && r.user.days.map((d, i) =>
								<td key={"td" + i}><b><span style={{ opacity: .5 }}>{d.hourPlanned}</span>  <br />{d.hourCompleted}</b></td>)}
							{current.item.reportType === "1" && r.user.days.map((d, i) =>
								<td key={"td" + i}><b>{d.totalPoint === 0 ? "-" : d.totalPoint}</b></td>)}

						</tr>
					)
				}) :
					((current.users && current.users.length > 0)
						? current.users.map((r, i) => {
							return (
								<tr key={"tr" + i}>
									<td style={{ textAlign: "left", paddingLeft: "1rem" }}>
										<img src={(r && r.photo)
											? baseUrl + r.photo
											: (r && r.gender)
												? manprofile
												: womanprofile} className='report_profile' />
										<span>{r.name}</span>
									</td>
									<td>{"0"}</td>
									{daysInSelectedMonth.map((d, i) => <td key={"td" + i}>{"-"}</td>)}

								</tr>
							)
						}) : null)}
			</tbody>
			{/* <tfoot>
				<tr>
					<th></th>
					<th></th>
					<th>1</th>
					<th>2</th>
					<th>3</th>
					<th>4</th>
					<th>5</th>
					<th>6</th>
					<th>7</th>
					<th>8</th>
					<th>9</th>
					<th>10</th>
					<th>11</th>
					<th>12</th>
					<th>13</th>
					<th>14</th>
					<th>15</th>
					<th>16</th>
					<th>17</th>
					<th>18</th>
					<th>19</th>
					<th>20</th>
					<th>21</th>
					<th>22</th>
					<th>23</th>
					<th>24</th>
					<th>25</th>
					<th>26</th>
					<th>27</th>
					<th>28</th>
					<th>29</th>
					<th>30</th>
				</tr>
			</tfoot> */}
		</table>


	)
}
