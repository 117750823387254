import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Input from '../anBrains/Input'
import Button from '../anBrains/Button'
import { registerLocale } from 'react-datepicker'
import { fetchWorkorderTimes } from '../../redux/actions/workorder_ac'
import { updateWorkOrderUserTimeType } from '../../redux/action_types'
import { crud } from '../../config/constants'
export default function UserTimesForm(props) {

    const trans = useSelector(state => state.trans)
    const currentItem = useSelector(state => state.current.item)
    const dispatch = useDispatch()
    const userTime = useSelector(state => state.current.item.userTimes.find(x => x.id === props.Id))
    console.log(props)
    let validate = () => {
        var sDate = new Date(currentItem.startDate)
        var eDate = new Date(currentItem.endDate)
        var tDate = eDate - sDate
        console.log(tDate)
        if (tDate < 0) {
            setErr({ ...errors, endDateError: trans.dateRangeError })
            //setMessage(trans.dateRangeError)
            return false
        }
        return true
    }

    const errors = {
        nameError: "",
        userError: "",
        endDateError: "",
        startDateError: "",
        labelError: ""
    }
    const [err, setErr] = useState(errors)

    const register = () => {

        //e.preventDefault();
        if (validate()) {
            console.log(userTime)
            dispatch(fetchWorkorderTimes({}, crud.update, userTime, trans))
            props.setisedit(false)
        }
    }

    return (
        <div> 
            <div className="input-container workorder_usertimes">
                <span className="input_header">{trans.startdate}</span>
                <div className="input">
                    <Input
                        type={"datetime-local"}
                        value={userTime.startDate}
                        onChange={e => {
                            userTime.startDate = e.target.value;
                            dispatch({ type: updateWorkOrderUserTimeType, payload: { currentItem, userTime } })
                        }}
                        autoComplate={"off"} />
                </div>
            </div>
            <div className="input-container workorder_usertimes">
                <span className="input_header">{trans.enddate}</span>
                <div className={`input ${err.endDateError ? 'input-error' : ''}`}>
                    <Input
                        type={"datetime-local"}
                        value={userTime.endDate}
                        onChange={e => {
                            userTime.endDate = e.target.value;
                            dispatch({ type: updateWorkOrderUserTimeType, payload: { currentItem, userTime } })
                        }}
                        autoComplate={"off"} />
                </div>
            </div>
            <div>
                <Button classes={"btn btn-primary"} style={{ float: "right" }} onClick={() => register()}>{trans.save}</Button>
            </div>
        </div>
    )
}
