import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { PlayButton2Icon, PauseButton2Icon } from '../icons';
import Timer from 'react-compound-timer'
import { fetchWorkorderTimes } from '../../redux/actions/workorder_ac';
import { crud } from '../../config/constants';
import { itemType } from '../../redux/action_types';
export default function TimerForUser() {

    const trans = useSelector(state => state.trans)
    const theme = useSelector(state => state.theme)
    const current = useSelector(state => state.current)
    const user = useSelector(state => state.user)
    const { change } = useSelector(state => state.result)
    const loader = useSelector(state => state.loader)
    const dispatch = useDispatch()
    useEffect(() => {
        // //

        // 
        if (current.item && current.item.userTimes === null) {
            current.item.userTimes = [];
            current.item.isStarted = undefined;
            dispatch({ type: itemType, payload: current.item })
        }

        if (current.item &&
            current.item.userTimes &&
            current.item.userTimes.some(x => x.endDate === null && x.userId === user.id)) {
            var startdate = Date.parse(current.item.userTimes.find(x => x.endDate === null && x.userId === user.id).startDate);
            var now = new Date()
            current.item.currentTime = now.getTime() - startdate
            current.item.isStarted = true
            dispatch({ type: itemType, payload: current.item })
        }
        else if (current.item &&
            current.item.userTimes &&
            current.item.userTimes.length > 0) {
            current.item.isStarted = false
            dispatch({ type: itemType, payload: current.item })
        }
        else if (current.item &&
            current.item.userTimes &&
            current.item.userTimes.length === 0) {
            current.item.isStarted = false
            dispatch({ type: itemType, payload: current.item })
        }
    }, [current.item.id])

    const terminate = () => {
        
        dispatch(fetchWorkorderTimes({ workOrderId: current.item.id }, crud.end, {}, trans))
    }
    const toggleTimer = (bool, time) => {
        if (time === 0) {
            dispatch(fetchWorkorderTimes({}, crud.create,
                {
                    workOrderId: current.item.id
                }, trans))
        } else {
            if (current.item.userTimes
                && current.item.userTimes.length > 0) {
                var row = current.item.userTimes.find(x => x.endDate === null && x.userId === user.id);
                dispatch(fetchWorkorderTimes({}, crud.update, row, trans))
            }
        }
        current.item.isStarted = bool
        dispatch({ type: itemType, payload: current.item })
    }
    // //
    return (
        <div className="timerforuser">
            <div>{trans.timer}</div>
            <div className="timer_container">

                {(current.item.isStarted === true || current.item.isStarted === false) &&
                    <Timer
                        formatValue={value => `${value < 10 ? `0${value}` : value}`}
                        initialTime={current.item.currentTime}
                        startImmediately={current.item.isStarted}
                        onStart={() => console.log('onStart hook')}
                        onResume={() => console.log('onResume hook')}
                        onPause={() => console.log('onPause hook')}
                        onStop={e => {
                        }}
                        onReset={() => console.log('onReset hook')}
                        timeToUpdate={() => console.log("timeToUpdate")
                        }
                    >
                        {({ start, resume, pause, stop, reset, timerState, getTime }) =>
                        (
                            <>
                                <div className="timer">
                                    <span>  <Timer.Hours /></span>
                                    <span> :<Timer.Minutes /></span>
                                    <span> :<Timer.Seconds /></span>
                                </div>
                                {!current.item.isStarted &&
                                    <button onClick={() => {
                                        reset();
                                        start();
                                        toggleTimer(true, 0)
                                    }} >
                                        <PlayButton2Icon
                                            color={(theme === "dark" ? "#555" : "#fff")}
                                            color2={(theme === "dark" ? "#fff" : "#000")} />
                                    </button>}
                                {current.item.isStarted &&
                                    <button onClick={() => {
                                        stop();
                                        toggleTimer(false, getTime())
                                    }}>
                                        <PauseButton2Icon />
                                    </button>}

                                {!current.item.isStarted && <button className="btnTerminate" onClick={() => {
                                    reset(); terminate()
                                }}>
                                    <div></div> {trans.terminate}
                                </button>}
                            </>
                        )
                        }
                    </Timer>}
            </div>
        </div >
    )
}
