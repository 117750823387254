import { settingType, updateSettingType, updateWorkingHoursType } from '../action_types'
const reducer = (state = { list: [], totalhour: 0 }, action) => {
    switch (action.type) {
        case settingType:
            return { ...state, list: action.payload };
        case updateSettingType:
            var settings = [];
            state.list.forEach(p => {
                if (p.id === action.payload.id)
                    settings.push(action.payload);
                else settings.push(p);
            });
            return { ...state, list: settings };
        case updateWorkingHoursType:
            var hours = [];
            state.list.forEach(p => {
                if (p.id === action.payload.id)
                    hours.push(action.payload);
                else hours.push(p);
            });
            return { ...state, list: hours };
        default:
            return state;
    }
};
export default reducer;
