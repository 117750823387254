import axios from 'axios'
import { axiosConfig, baseUrl,openNotification } from '../../config'
import { pageCountType, addeventType, updateeventType, eventType,isLoginType } from '../action_types'
import { success, calculatePageNumber, loading } from './loader_ac'
import { crud } from '../../config/constants'
export const fetchEvent = (request = { rowCount: 4, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {},trans=undefined) => {
    let headers = axiosConfig.headers

    let url = `${baseUrl}/api/projectevents`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    return dispacth => {
        try {
            // dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            }).then(response => {
                // dispacth(loading())
                if (response.data.success) {
                    switch (requestType) {
                        case crud.create:
                            dispacth({
                                type: addeventType,
                                payload: response.data.data,
                            })
                            dispacth(success(true))
                            openNotification('success', trans.successTitle, trans.successBody, 3)
                            break;
                        case crud.read:
                            break;
                        case crud.update:
                            dispacth({
                                type: updateeventType,
                                payload: response.data.data,
                            })
                            dispacth(success(true))
                            openNotification('success', trans.successTitle, trans.successBody, 3)
                            break;
                        case crud.delete:
                            openNotification('success', trans.successTitle, trans.successBody, 3)
                            break;
                        case crud.list:
                            dispacth({
                                type: eventType,
                                payload: response.data.data,
                            })
                            dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) })
                            break;

                        default:
                            break;
                    }
                }
            })
                .catch(reason => {
                    console.log();
                    openNotification('error', trans.errorTitle, trans.errorBody,3)
                    // dispacth(loading())
                  
                })
        } catch (error) {
            console.log(error)
            openNotification('error', trans.errorTitle, trans.errorBody,3)
            // dispacth(loading())
        }
    }
}

