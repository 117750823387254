import React, { useEffect, useState } from "react";
import Layout from "../../components/Container/Layout";
import firm from "../../images/firm.png";
import { useDispatch, useSelector } from "react-redux";
import Switch from "../../components/anBrains/Switch";
// import Pagination from '../../components/anBrains/Pagination';
import Button from "../../components/anBrains/Button";
import Input from "../../components/anBrains/Input";
import { barListType, gridListType, workorderType, boardListType, breadcrumbType, addBreadcrumbType, itemType } from "../../redux/action_types";
import Modal from "../../components/anBrains/Modal";
import { PlusIcon, GridIcon, BarIcon, SortIcon, Search2Icon, BoardViewIcon, DownIcon } from "../../components/icons";
import ContentLoader from "react-content-loader";
import { success } from "../../redux/actions/loader_ac";
import { baseUrl } from "../../config";
import { crud, ProjectStatus, Priorities } from "../../config/constants";
import { useHistory } from "react-router-dom";
import { fetchWorkorder } from "../../redux/actions/workorder_ac";
import { WorkorderBoardCard, WorkorderBoardView, WorkorderForm } from "../../components/workorder";
import FilterByUsers from "../../components/filter/FilterByUsers";
import FilterByLabels from "../../components/filter/FilterByLabels";
import manprofile from "../../images/profiles/man.png";
import womanprofile from "../../images/profiles/woman.png";
import { Pagination } from "antd";
var defaultItem = {
  name: "",
  description: "",
  id: 0,
};
export default function WorkOrder(props) {
  //  const { staticcontext } = props
  const dispatch = useDispatch();
  const trans = useSelector((state) => state.trans);
  const theme = useSelector((state) => state.theme);
  const current = useSelector((state) => state.current);
  const workorder = useSelector((state) => state.workorder);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isDeleteEmployeeOpen, setIsDeleteEmployeeOpen] = useState(false);
  const [item, setItem] = useState({});
  const [request, setRequest] = useState({
    loader: true,
    rowCount: 14,
    pageCount: 0,
    isWorkOrder: true,
    search: "",
    sort: "id",
    desc: true,
  });
  const loader = useSelector((state) => state.loader);
  const { breadcrumbs } = useSelector((state) => state.current);
  const result = useSelector((state) => state.result);

  useEffect(() => {
    dispatch({ type: itemType, payload: defaultItem });
    dispatch(fetchWorkorder({ ...request, includeDetail: true }, crud.list, {}, trans));
  }, []);

  const showBar = () => dispatch({ type: barListType });

  const showGrid = () => dispatch({ type: gridListType });

  const showBoard = () => {
    dispatch({ type: boardListType });
    dispatch(fetchWorkorder({ ...request, isKanban: true }, crud.kanban, {}, trans));
  };
  const [selectedPage, setSelectedPage] = useState(1);

  const deleteItem = () => {
    setIsDeleteEmployeeOpen(false);
    dispatch(fetchWorkorder(request, crud.delete, item, trans));
    let workorders = workorder.list.filter((e) => {
      return e.id !== item.id;
    });
    dispatch({ type: workorderType, payload: workorders });
  };

  const setSelectedPageFilter = (n) => {
    setSelectedPage(n);
    setRequest({ ...request, pageCount: n - 1 });
    dispatch(fetchWorkorder({ ...request, pageCount: n - 1 }, crud.list, {}, trans));
  };
  const [desc, setDesc] = useState(false);
  const sortbyField = (f) => {
    setRequest({ ...request, sort: f, desc });
    dispatch(fetchWorkorder({ ...request, sort: f, desc }, crud.list, {}, trans));
    setDesc(!desc);
  };

  const searchChange = (text) => {
    setSearchText(text);

    if (text.length > 2) {
      dispatch(fetchWorkorder({ ...request, search: text }, crud.list, {}, trans));
    }
    setRequest({ ...request, search: text });
  };

  const searchChangeKeyPress = (text, kcode) => {
    if (kcode === 8) {
      dispatch(fetchWorkorder({ ...request, search: text }, crud.list, {}, trans));
    }
  };

  const history = useHistory();
  const findProject = (id) => selectRow(workorder.list.find((x) => x.id === id));

  const selectRow = (e) => {
    console.log(e);
    //
    if (breadcrumbs && breadcrumbs.some((b) => b.name === e.name)) {
    }
    if (breadcrumbs.length > 1) {
      var newBreadCrumbs = [];
      newBreadCrumbs.push(breadcrumbs[0]);
      newBreadCrumbs.push({
        name: e.name,
        path: "/workorders/workorder-detail" + e.id,
      });
      dispatch({
        type: breadcrumbType,
        payload: newBreadCrumbs,
      });
    } else {
      dispatch({
        type: addBreadcrumbType,
        payload: { name: e.name, path: "/workorders/workorder-detail" + e.id },
      });
    }
    history.push("/workorders/workorder-detail/" + e.id);
  };

  const [searchText, setSearchText] = useState("");

  const renderProjectStatus = (s) => {
    return (
      <div className={"priority"} style={{ backgroundColor: ProjectStatus[s].color }}>
        {ProjectStatus[s][trans.lang]}
      </div>
    );
  };

  const renderPriority = (s) => {
    return (
      <span className={"priority"} style={{ backgroundColor: Priorities[s].color }}>
        {Priorities[s][trans.lang]}
      </span>
    );
  };

  const [isUsersOpen, setIsUsersOpen] = useState(false);
  const filterByUser = (list) => {
    setRequest({ ...request, userIds: list.map((x) => x.id), pageCount: 0 });
    setSelectedPage(1);
    dispatch(fetchWorkorder({ ...request, userIds: list.map((x) => x.id) }, crud.list, {}, trans));
  };

  const [isLabelsOpen, setIsLabelsOpen] = useState(false);
  const filterByLabel = (labels) => {
    setRequest({ ...request, labels, pageCount: 0 });
    setSelectedPage(1);
    dispatch(fetchWorkorder({ ...request, labels, pageCount: 0 }, crud.list, {}, trans));
  };
  const [selectedPriority, setSelectedPriority] = useState(-1);
  const filterPriorities = (p) => {
    setSelectedPriority(p.target.value);

    dispatch(
      fetchWorkorder(
        {
          ...request,
          priority: p.target.value,
          pageCount: 0,
        },
        crud.list,
        {},
        trans
      )
    );
    setSelectedPage(1);
  };
  const [openPrioritySelect, setOpenPrioritySelect] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState(-1);
  const filterStatus = (p) => {
    setSelectedStatus(p.target.value);
    dispatch(
      fetchWorkorder(
        {
          ...request,
          pageCount: 0,
          status: p.target.value,
        },
        crud.list,
        {},
        trans
      )
    );
    setSelectedPage(1);
  };
  const [openStatusSelect, setOpenStatusSelect] = useState(false);

  const [openProgress, setOpenProgress] = useState(false);
  const [range, setRange] = useState(100);
  const changeRange = (e) => {
    setRange(e.target.value);
    dispatch(
      fetchWorkorder(
        {
          ...request,
          pageCount: 0,
          progress: e.target.value,
        },
        crud.list,
        {},
        trans
      )
    );
    setSelectedPage(1);
  };

  return (
    <Layout>
      <Modal isOpen={isUsersOpen} withClose setIsOpen={setIsUsersOpen} classes={"modal_filter"}>
        <FilterByUsers filterByUser={filterByUser} />
      </Modal>

      <Modal isOpen={isLabelsOpen} withClose setIsOpen={setIsLabelsOpen} classes={"modal_filter"}>
        <FilterByLabels filterByLabel={filterByLabel} />
      </Modal>
      <Modal isOpen={isAddOpen} withClose setIsOpen={setIsAddOpen} classes={"modal_add_employee"}>
        <WorkorderForm setIsAddOpen={setIsAddOpen} />
      </Modal>

      <Modal isOpen={isDeleteEmployeeOpen} withClose setIsOpen={setIsDeleteEmployeeOpen} classes={"modal_delete"}>
        {trans.areyousureyouwanttodelete}
        <Button classes="btn-red" onClick={() => deleteItem()}>
          {trans.confirm}
        </Button>
      </Modal>
      <div className="table_properties">
        <Input
          icon={<Search2Icon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />}
          placeholder={trans.search}
          value={searchText}
          onChange={(e) => searchChange(e.target.value)}
          onKeyUp={(e) => searchChangeKeyPress(e.target.value, e.keyCode)}
        />
        <Button
          icon={<PlusIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />}
          classes="btn-basic"
          onClick={() => setIsAddOpen(true)}
        >
          {trans.add}
        </Button>
        <div className="bar_grid">
          <button onClick={showBar}>
            <BarIcon color={current.bar ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#5d5d5d"} />
          </button>
          <button onClick={showGrid}>
            <GridIcon
              color={current.grid ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#5d5d5d"}
            />
          </button>
          <button onClick={showBoard} style={{ cursor: "pointer" }}>
            <BoardViewIcon
              color={current.board ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#5d5d5d"}
            />
          </button>
        </div>
      </div>
      {current.bar && (
        <div className="project_container">
          <div className="workorder_view">
            <div className="table">
              <table>
                <thead>
                  <tr>
                    <th onClick={() => sortbyField("name")}>
                      {trans.workorder}
                      <SortIcon color="rgba(96, 168, 221, 1)" />
                    </th>
                    <th onClick={() => setIsUsersOpen(true)}>
                      {trans.employees}
                      <span className={`${isUsersOpen === true ? "icon_container transform-180" : "icon_container"}`}>
                        <DownIcon color="rgba(247, 202, 49, 1)" />
                      </span>
                    </th>
                    <th>{trans.creator}</th>
                    <th onClick={() => setIsLabelsOpen(true)}>
                      {trans.labels}
                      <span className={`${isLabelsOpen === true ? "icon_container transform-180" : "icon_container"}`}>
                        <DownIcon color="rgba(247, 202, 49, 1)" />
                      </span>
                    </th>
                    <th>
                      {/* {!openProgress ? */}
                      <span onClick={() => sortbyField("progress")}>
                        {trans.progress}
                        <SortIcon color="rgba(96, 168, 221, 1)" />
                      </span>
                      {/* : */}
                      {/* <input
                                                        className="rangeInput"
                                                        type="range" min="0" max="100" step="20" class="w-100"
                                                        value={range}
                                                        onChange={changeRange} />
                                                } */}
                      <span
                        id="chevronIcon"
                        className={`${openProgress === true ? "icon_container transform-180" : "icon_container"}`}
                        onClick={() => setOpenProgress(!openProgress)}
                      >
                        {/* <DownIcon color="rgba(247, 202, 49, 1)" /> */}
                      </span>
                    </th>
                    <th onClick={() => sortbyField("point")}>
                      {trans.point}
                      <SortIcon color="rgba(96, 168, 221, 1)" />
                    </th>
                    <th onClick={() => sortbyField("startDate")}>
                      {trans.startdate}
                      <SortIcon color="rgba(96, 168, 221, 1)" />
                    </th>
                    <th onClick={() => sortbyField("endDate")}>
                      {trans.enddate}
                      <SortIcon color="rgba(96, 168, 221, 1)" />
                    </th>
                    <th>
                      {!openPrioritySelect ? (
                        <span onClick={() => sortbyField("priority")}>
                          {trans.priority}
                          <SortIcon color="rgba(96, 168, 221, 1)" />
                        </span>
                      ) : (
                        <div className="input-container" style={{ float: "left" }}>
                          <select className="input select selectfilter" onChange={filterPriorities} value={selectedPriority}>
                            <option value={-1}>{trans.all}</option>
                            {Priorities.map((p, i) => {
                              return (
                                <option key={"priority" + i} value={p.id}>
                                  {p[trans.lang]}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      )}
                      <span
                        id="chevronIcon"
                        className={`${openPrioritySelect === true ? "icon_container transform-180" : "icon_container"}`}
                        onClick={() => setOpenPrioritySelect(!openPrioritySelect)}
                      >
                        <DownIcon color="rgba(247, 202, 49, 1)" />
                      </span>
                    </th>
                    <th>
                      {!openStatusSelect ? (
                        <span onClick={() => sortbyField("status")}>
                          {trans.status}
                          <SortIcon color="rgba(96, 168, 221, 1)" />
                        </span>
                      ) : (
                        <div className="input-container" style={{ float: "left" }}>
                          <select className="input select selectfilter" onChange={filterStatus} value={selectedStatus}>
                            <option value={-1}>{trans.all}</option>
                            {ProjectStatus.map((p, i) => {
                              return <option value={p.id}>{p[trans.lang]}</option>;
                            })}
                          </select>
                        </div>
                      )}
                      <span
                        id="chevronIcon"
                        className={`${openStatusSelect === true ? "icon_container transform-180" : "icon_container"}`}
                        onClick={() => setOpenStatusSelect(!openStatusSelect)}
                      >
                        <DownIcon color="rgba(247, 202, 49, 1)" />
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {workorder.list &&
                    workorder.list.length > 0 &&
                    workorder.list.map((e, i) => {
                      return (
                        <tr className="row" key={i}>
                          <td onClick={() => selectRow(e)}>{e.name}</td>
                          <td onClick={() => selectRow(e)}>
                            {" "}
                            {e.users && e.users.length > 0
                              ? e.users.map((u, i) => {
                                  return (
                                    <img
                                      key={"workorder_row_profile" + i}
                                      className="workorder_row_profile"
                                      src={u.photo ? baseUrl + u.photo : u && u.gender ? manprofile : womanprofile}
                                      alt={u.name}
                                      title={u.name}
                                    />
                                  );
                                })
                              : null}
                          </td>
                          <td onClick={() => selectRow(e)}>
                            {e.creator && (
                              <img
                                className="workorder_row_profile"
                                src={e.creator.photo ? baseUrl + e.creator.photo : e && e.creator.gender ? manprofile : womanprofile}
                                alt={e.creator.name}
                                title={e.creator.name}
                              />
                            )}
                          </td>
                          <td
                            onClick={() => selectRow(e)}
                            title={
                              e.labels && e.labels.length > 0
                                ? e.labels.map((e, i) => {
                                    return e.name;
                                  })
                                : null
                            }
                          >
                            {e.labels && e.labels.length > 0 ? <span className="label_container">{e.labels[0].name + " .."}</span> : null}
                          </td>
                          <td onClick={() => selectRow(e)}>
                            <div className="progress_container">
                              <div className="progress_bar" style={{ width: (e.progress > 100 ? 100 : e.progress) + "%" }}>
                                {(e.progress > 100 ? 100 : e.progress) + "%"}
                              </div>
                            </div>
                          </td>
                          <td onClick={() => selectRow(e)} style={{ textAlign: "center" }}>
                            {e.point}
                          </td>
                          <td onClick={() => selectRow(e)}>{e.startDateTimeString}</td>
                          <td onClick={() => selectRow(e)}>{e.endDateTimeString}</td>

                          <td title={Priorities[e.status][trans.lang]}>{renderPriority(e.priority)}</td>
                          <td title={ProjectStatus[e.status][trans.lang]} style={{ paddingRight: "1rem" }}>
                            {renderProjectStatus(e.status)}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {/* {current && current.pageCount > 0 &&
                                <Pagination
                                    setSelectedPage={setSelectedPageFilter}
                                    selectedPage={selectedPage} />} */}

            {current && current.pageCount > 0 && (
              <Pagination
                defaultCurrent={selectedPage}
                onChange={setSelectedPageFilter}
                onShowSizeChange={(pageCount, rowCount) => {
                  dispatch(fetchWorkorder({ ...request, pageCount, rowCount }, crud.list, {}, trans));
                  setRequest({ ...request, pageCount, rowCount });
                }}
                total={current.pageCount * request.rowCount}
              />
            )}
          </div>
        </div>
      )}
      {current.grid && (
        <div className="project_container">
          <div className="workorder_view">
            <div className="grid_container">
              {workorder &&
                workorder.list.length > 0 &&
                workorder.list.map((e, i) => {
                  return (
                    <div className="grid_card grid_card_border" style={{ borderLeftColor: ProjectStatus[e.status].color }} key={"grid_card" + i}>
                      <div className="workorder_card_information_container">
                        <div className="workorder_grid_card_information" onClick={() => selectRow(e)}>
                          <span className="grid_card_name">{e.name}</span>
                          <span className="grid_card_position">
                            {e.labels && e.labels.length > 0
                              ? e.labels.map((e, i) => {
                                  return (
                                    <span key={"label" + e.id} className="grid_label_container">
                                      {e.name}
                                    </span>
                                  );
                                })
                              : null}
                            {renderPriority(e.priority)}
                            <div
                              className={"priority"}
                              style={
                                theme === "dark"
                                  ? { backgroundColor: "rgba(247, 202, 49, 1)", color: "#000" }
                                  : { backgroundColor: "rgba(247, 202, 49, .7)", color: "#000" }
                              }
                            >
                              {trans.lang === "en" ? "Point: " : "Puan: " + e.point}
                            </div>
                          </span>
                          <span className="grid_card_phone">
                            <div className="progress_container">
                              <div className="progress_bar" style={{ width: (e.progress > 100 ? 100 : e.progress) + "%" }}>
                                {(e.progress > 100 ? 100 : e.progress) + "%"}
                              </div>
                            </div>
                          </span>
                        </div>
                        <div className="workorder_card_settings">
                          {e.users && e.users.length > 0
                            ? e.users.map((u, i) => {
                                return (
                                  <img
                                    key={"workorder_row_profile" + i}
                                    className="workorder_row_profile"
                                    src={u.photo ? baseUrl + u.photo : u && u.gender ? manprofile : womanprofile}
                                    alt={u.name}
                                    title={u.name}
                                  />
                                );
                              })
                            : null}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            {current && current.pageCount > 0 && (
              <Pagination
                defaultCurrent={selectedPage}
                onChange={setSelectedPageFilter}
                onShowSizeChange={(pageCount, rowCount) => {
                  dispatch(fetchWorkorder({ ...request, pageCount, rowCount }, crud.list, {}, trans));
                  setRequest({ ...request, pageCount, rowCount });
                }}
                total={current.pageCount * request.rowCount}
              />
            )}
          </div>
        </div>
      )}

      {current.board && (
        <div className="project_container">
          <div className="workorder_view">
            {workorder.boardlist.lanes.length > 0 && <WorkorderBoardView selectRow={findProject} trello={workorder.boardlist} />}
          </div>
          {/* {current && current.pageCount > 0 &&
                        <Pagination
                            setSelectedPage={setSelectedPageFilter}
                            selectedPage={selectedPage} />} */}
        </div>
      )}
    </Layout>
  );
}
