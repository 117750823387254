import { companyCultureType, addCompanyCultureType, updateCompanyCultureType } from '../action_types'
const reducer = (state = { list: [], totalhour: 0 }, action) => {
    switch (action.type) {
        case companyCultureType:
            return { ...state, list: action.payload };
        case addCompanyCultureType:
            if (state.list.length > 0) {
                var departmentId = state.list[0].departmentId;
                if (action.payload.departmentId === departmentId)
                    return { ...state, list: [...state.list, action.payload] };
            } else {
                state.list.push(action.payload)
            }

            return state;
        case updateCompanyCultureType:
            var companyCultures = [];
            //
            state.list.forEach(p => {
                if (p.id === action.payload.id) {
                    if (p.departmentId === action.payload.departmentId) {
                        companyCultures.push(action.payload);
                    }
                }
                else companyCultures.push(p)
            });
            return { ...state, list: companyCultures };
        default:
            return state;
    }
};
export default reducer;
