import { autorizationType, addAutorizationType, updateAutorizationType, autorizationMenuType, hasExpiredType, isLoginType } from '../action_types'
const reducer = (state = { list: [], totalhour: 0, menus: [], hasexpired: false }, action) => {
    switch (action.type) {
        case autorizationMenuType:
            return { ...state, menus: action.payload };
        case autorizationType:
            return { ...state, list: action.payload };
        case addAutorizationType:
            return { ...state, list: [...state.list, action.payload] };
        case hasExpiredType:
            return { ...state, hasexpired: action.payload };
        case isLoginType:
            return { ...state, isLogin: action.payload }
        case updateAutorizationType:
            var projectGroups = [];
            state.list.forEach(p => {
                if (p.id === action.payload.id)
                    projectGroups.push(action.payload);
                else projectGroups.push(p);
            });
            return { ...state, list: projectGroups };
        default:
            return state;
    }
};
export default reducer;