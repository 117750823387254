import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../../components/anBrains/Button';
import { PlusIcon, TrushIcon, SortIcon } from '../icons';
import Modal from '../anBrains/Modal';
import { overtimeType } from '../../redux/action_types';
import moment from 'moment-timezone'
import 'moment-timezone'
import Input from '../anBrains/Input';
import Pagination from '../anBrains/Pagination'
import { crud, dateFormatter } from '../../config/constants';
import { fetchOvertimes } from '../../redux/actions/overtime_ac';
import { success } from '../../redux/actions/loader_ac';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

export default function LeaveTab({ employeeid, isProfile }) {
    const trans = useSelector(state => state.trans)
    const overtime = useSelector(state => state.overtime)
    const theme = useSelector(state => state.theme)
    const result = useSelector(state => state.result)
    const current = useSelector(state => state.current)
    const dispatch = useDispatch()
    const [item, setItem] = useState({})
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [isAddOpen, setIsAddOpen] = useState(false)
    const [listParameters, setListParameters] = useState({ rowCount: 14, pageCount: 0, sort: "id", desc: true, userId: employeeid }, crud.list, {})

    useEffect(() => {
        if (result.success) {
            setIsAddOpen(false);
            setField(fields)
            dispatch(success(false))
        }
    }, [result.change])

    const deleteConfirm = (e) => {
        setIsDeleteOpen(true)
        setItem(e);
    }
    const deleteItem = () => {
        setIsDeleteOpen(false)
        dispatch(fetchOvertimes({}, crud.delete, item))
        let overtimes = overtime.list.filter((e) => {
            return e.id !== item.id
        });
        dispatch({ type: overtimeType, payload: overtimes })
    }

    // const editItem = (e) => {
    //     console.log(e);
    //     setField({...e,startDate:e.startDateString,endDate:e.endDateString});
    //     // dispatch({ type: itemType, payload: e })
    //     setIsAddOpen(true);
    // }

    const [asc, setAsc] = useState(false)
    const sortbyField = (f) => {
        setListParameters({ ...listParameters, sort: f, desc: asc ? false : true })
        setAsc(!asc)
        dispatch(fetchOvertimes({ ...listParameters, sort: f, desc: asc ? false : true }));
    }

    var fields = {
        id: 0,
        userId: employeeid,
        startDate: "",
        endDate: dateFormatter(new Date()),
        name: dateFormatter(new Date())
    }
    const [field, setField] = useState(fields)

    const errors = {
        nameErr: "",
        endDateError: "",
        startDateError: "",
    }
    const [err, setErr] = useState(errors)

    let validate = () => {
        if (field.name.length < 3 || field.name.length > 20) {
            setErr({ ...errors, nameErr: trans.toShort })
            return false
        }

        if (field.startDate === "" || field.startDate === undefined) {
            setErr({ ...errors, startDateError: trans.pleaseEnterValidDate })
            return false
        }

        if (field.endDate === "" || field.endDate === undefined) {
            setErr({ ...errors, endDateError: trans.pleaseEnterValidDate })
            return false
        }
        return true
    }
    const register = e => {
        e.preventDefault();
        if (validate()) {
            setErr(errors)
            dispatch(fetchOvertimes({}, crud.create, { ...field, startDate: moment(field.startDate).utcOffset('GMT+03:00')._d, endDate: moment(field.endDate).utcOffset('GMT+03:00')._d }))
        }
    }

    const [selectedPage, setSelectedPage] = useState(1)
    const setSelectedPageFilter = (n) => {
        setSelectedPage(n)
        setListParameters({ ...listParameters, pageNumber: n - 1 })
        dispatch(fetchOvertimes({ ...listParameters, pageNumber: n - 1 }));
    }

    return (
        <>
            <div className="leave">
                <div className="leave_detail_container">
                    {overtime && overtime.totalhour && <div className="inout_detail_box">
                        <div className="leave_detail_box_number ">
                            {overtime.totalhour}
                        </div>
                        <div className="leave_detail_box_text">
                            {trans.totalhour}
                        </div>
                    </div>}
                </div>
                <div className="table_properties">
                    {/* <Input icon={search} placeholder={trans.search} onChange={e => searchChange(e.target.value)} /> */}
                    {isProfile && <Button
                        icon={<PlusIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />}
                        classes="btn btn-basic"
                        onClick={() => setIsAddOpen(true)}>{trans.add}</Button>}
                </div>
                <div className="table">
                    <table>
                        <thead>
                            <tr>
                                <th onClick={() => sortbyField("name")}>{trans.description}
                                    <SortIcon color="rgba(96, 168, 221, 1)" /></th>
                                <th onClick={() => sortbyField("startDate")}>{trans.day}
                                    <SortIcon color="rgba(96, 168, 221, 1)" /></th>
                                <th onClick={() => sortbyField("startDate")}>{trans.entry}
                                    <SortIcon color="rgba(96, 168, 221, 1)" />
                                </th>
                                <th onClick={() => sortbyField("endDate")}>{trans.exit}

                                    <SortIcon color="rgba(96, 168, 221, 1)" /></th>
                                {isProfile && <th>{trans.settings}</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {overtime && overtime.list.length > 0 && overtime.list.map((e, i) => {
                                return (
                                    <tr className="row" key={e.id}>
                                        <td>{e.name}</td>
                                        <td >{e.startDateString}</td>
                                        <td >{e.startHour}</td>
                                        <td>{e.endHour}</td>
                                        {isProfile &&
                                            <td>
                                                <button onClick={() => deleteConfirm(e)}>
                                                    <TrushIcon
                                                        color={(theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)")}
                                                    /></button>
                                            </td>}
                                    </tr >
                                )
                            })}
                        </tbody >
                    </table >

                </div > {current && current.pageCount > 0 &&
                    <Pagination
                        setSelectedPage={setSelectedPageFilter}
                        selectedPage={selectedPage} />
                }
            </div >
            <Modal
                isOpen={isAddOpen}
                withClose
                setIsOpen={setIsAddOpen}
                classes={"modal_add_leave"}>
                <div className="leaveForm">
                    <Input placeholder={trans.description}
                        name={trans.description}
                        header={trans.description}
                        value={field.name}
                        onChange={e => setField({ ...field, name: e.target.value })}
                        error={err.nameErr} />
                    <div className="input-container">
                        <span className="input_header">{trans.startdate}</span>
                        <div className="input">
                            <Input
                                type={"datetime-local"}
                                autoComplate={"off"}
                                value={field.startDate}
                                onChange={e => setField({ ...field, startDate: e.target.value })} />
                        </div>
                    </div>
                    <div className="input-container">
                        <span className="input_header">{trans.endDate}</span>
                        <div className="input">
                            <Input
                                type={"datetime-local"}
                                autoComplate={"off"}
                                value={field.endDate}
                                onChange={e => setField({ ...field, endDate: e.target.value })} />
                        </div>
                    </div>
                    <Button classes={"btn-primary"}
                        onClick={register}>{trans.save}</Button>
                </div>
            </Modal>
            <Modal
                isOpen={isDeleteOpen}
                withClose
                setIsOpen={setIsDeleteOpen}
                classes={"modal_delete"}>
                {trans.areyousureyouwanttodelete}
                <Button classes="btn-red" onClick={() => deleteItem()}>{trans.confirm}</Button>
            </Modal>
        </>
    )
}
