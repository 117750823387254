import React, { useState, useEffect } from 'react'
import ImagePicker from './ImagePicker'
export default function FileUploader(props) {
	const { text } = props
	const [err, setErr] = useState(null)

	const loadedFile = data => {
		props.loadedFile(data);
		setErr(null)
	}

	const errMsg = (msg) => {
		setErr(msg)
	}

	useEffect(() => {

	}, [])

	return (
		<>
			<div className="file_uploader">
				{/* <img src={(profileImage === null || profileImage === undefined || profileImage.length < 5) ? profile : profileImage} alt="" className="profile_list_img" /> */}
				<ImagePicker
					id="bordro"
					justImage
					accept=".pdf,.xlsx,.xls,.doc,.docx,.pdf,.dwg"
					changeCurrentAvatar={loadedFile}
					errMsg={errMsg}
					fileType="file"
					text={text} />
			</div>
			{(err || props.err) && <div className="max_size">{err || props.err}</div>}
		</>
	)
}
