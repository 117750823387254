import { workorderType, addWorkorderType, insideMenuTypeInWorkOrder, workorderBoardType, workorderPanoType, addWorkorderBoardType, updateWorkorderBoardType } from '../action_types'
import { ProjectStatus } from '../../config/constants';
const reducer = (state = {
    list: [], totalhour: 0, clearBoardList: [], boardlist: { lanes: [] }, panoList: [], inside_menu: [
        { content: "detail", name: { tr: "Detay", en: "Detail" }, active: true }
        // { content: "files", name: { tr: "Dosyalar", en: "Files" }, active: false }
    ]
}, action) => {
    switch (action.type) {
        case workorderType:
            return { ...state, list: action.payload };
        case workorderPanoType:
            return { ...state, panoList: action.payload };
        case workorderBoardType:

            var boardlist = getTrello(action.payload);
            return { ...state, clearBoardList: action.payload, boardlist };
        case addWorkorderBoardType:
            var clearBoardList = [...state.clearBoardList, ...action.payload]
            var boardlist = getTrello(clearBoardList);
            boardlist.lanes.find(x => x.id === action.payload[0].status.toString()).currentPage++;
            return { ...state, clearBoardList, boardlist };
        case updateWorkorderBoardType:
            
            var clearBoardList = state.clearBoardList;
            var cardIndex = clearBoardList.findIndex(x => x.id === action.payload.id);
            clearBoardList.splice(cardIndex, 1);
            clearBoardList.push(action.payload);
            var boardlist = getTrello(clearBoardList);
            return { ...state, clearBoardList, boardlist };
        case addWorkorderType:
            var statelist = [...state.list, action.payload];
            var clearBoardList = [...state.clearBoardList, action.payload];
            var boardlist = getTrello(clearBoardList);
            return { ...state, list: statelist, clearBoardList, boardlist };
        case insideMenuTypeInWorkOrder:
            return { ...state, inside_menu: action.payload };
        default:
            return state;
    }
};
export default reducer;

const getTrello = (data) => {
    var project = data;
    var trello = { lanes: [] };
    for (let index = 0; index < ProjectStatus.length; index++) {
        var projectlistbystatus = project.filter(x => x.status === ProjectStatus[index].id)
        var projectData =
        {
            ...ProjectStatus[index],
            id: ProjectStatus[index].id.toString(),
            title: ProjectStatus[index]["tr"],
            label: projectlistbystatus.length.toString(),
            style: {
                borderColor: ProjectStatus[index].color
            },
            cards: projectlistbystatus
        };
        trello.lanes.push(projectData);
    }
    return trello
}