import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Input from "../anBrains/Input";
import Button from "../anBrains/Button";
import ContentLoader from "react-content-loader";
import { fetchProject } from "../../redux/actions/project_ac";
import { crud, dateFormatter } from "../../config/constants";
import { Select } from "antd";
import { itemType } from "../../redux/action_types";
const { Option, OptGroup } = Select;
var defaultItem = {
  name: "",
  description: "",
  projectGroupId: 0,
  customerId: 0,
  departmentIds: [],
  startDate: dateFormatter(new Date()),
  endDate: dateFormatter(new Date()),
};
export default function ProjectForm(props) {
  const trans = useSelector((state) => state.trans);
  const user = useSelector((state) => state.user);
  const current = useSelector((state) => state.current);
  const department = useSelector((state) => state.department);
  const projectgroup = useSelector((state) => state.projectgroup);
  const customer = useSelector((state) => state.customer);
  const loader = useSelector((state) => state.loader);
  const employee = useSelector((state) => state.employee);
  const dispatch = useDispatch();

  useEffect(() => {
    // current.item = defaultItem;
    var departmentIds = [];
    var projectGroupId = 0;
    if (department.list.length > 0 && projectgroup.list.length > 0) {
      departmentIds = department.list.map((x) => x.id);
      projectGroupId = projectgroup.list.find((x) => x.selected === true).id;
    }
    dispatch({ type: itemType, payload: { ...defaultItem, departmentIds, projectGroupId } });
  }, []);

  const errors = {
    nameError: "",
    customerIdError: "",
    projectGroupIdError: "",
    departmentError: "",
  };

  const [err, setErr] = useState(errors);

  const [message, setMessage] = useState("");

  let validate = () => {
    if (current.item.name.length < 2 || current.item.name.length > 50) {
      setErr({ ...errors, nameError: trans.toShort });
      setMessage(trans.toShort);
      return false;
    }

    if (current.item.customerId <= 0) {
      setErr({ ...errors, customerIdError: trans.pleaseEnterValid });
      setMessage(trans.pleaseEnterValid);
      return false;
    }

    if (current.item.departmentIds === null || current.item.departmentIds.length <= 0) {
      setErr({ ...errors, departmentError: trans.youmustchooseonedepartment });
      setMessage(trans.youmustchooseonedepartment);
      return false;
    }

    if (current.item.projectGroupId === undefined || current.item.projectGroupId <= 0) {
      setErr({ ...errors, projectGroupIdError: trans.pleaseEnterValid });
      setMessage(trans.pleaseEnterValid);
      return false;
    }

    return true;
  };

  const register = (e) => {
    e.preventDefault();
    if (validate()) {
      setErr(errors);
      dispatch(fetchProject({}, crud.create, current.item, trans));
      dispatch({ type: itemType, payload: defaultItem });
    }
  };

  const changeCustomer = (e) => {
    current.item.customerId = parseInt(e.target.value);
    dispatch({ type: itemType, payload: current.item });
  };

  const changeGroup = (e) => {
    current.item.projectGroupId = parseInt(e.target.value);
    dispatch({ type: itemType, payload: current.item });
  };
  const selectMultiDepartments = (departmentIds) => dispatch({ type: itemType, payload: { ...current.item, departmentIds } });
  const selectMultiUserIds = (userIds) => dispatch({ type: itemType, payload: { ...current.item, userIds } });

  return (
    <div className="employeeForm">
      {user && user.roles && user.roles.length > 0 && user.roles.includes("Admin") && (
        <Input
          placeholder={trans.code}
          name={trans.code}
          header={trans.code}
          value={current.item.code}
          onChange={(e) => {
            current.item.code = e.target.value;
            dispatch({ type: itemType, payload: current.item });
          }}
        />
      )}

      <Input
        placeholder={trans.projectName}
        name={trans.projectName}
        header={trans.projectName}
        value={current.item.name}
        onChange={(e) => {
          current.item.name = e.target.value;
          dispatch({ type: itemType, payload: current.item });
        }}
        error={err.nameError}
        maxLength={50}
        minLength={2}
      />
      <div className="input-container">
        <span className="input_header">{trans.partner}</span>
        <select className={`input select ${err.customerIdError ? "input-error" : ""}`} onChange={(e) => changeCustomer(e)} value={current.item.customerId}>
          <option value={0}>{trans.select}</option>
          {customer.list.map((c, i) => {
            return (
              <option key={"customer_key" + i} value={c.id}>
                {c.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className="input-container">
        <span className="input_header">{trans.projectGroup}</span>
        <select className={`input select ${err.projectGroupIdError ? "input-error" : ""}`} onChange={(e) => changeGroup(e)} value={current.item.projectGroupId}>
          <option value={0}>{trans.select}</option>
          {projectgroup.list.map((c, i) => {
            return (
              <option key={"projectgroup_key" + i} value={c.id}>
                {c.name}
              </option>
            );
          })}
        </select>
      </div>

      <div className="mb-5">
        <span className="input_header">{trans.personals}</span>
        {employee.list !== null && employee.list !== undefined && employee.list.length > 0 ? (
          <Select
            mode="multiple"
            allowClear
            optionFilterProp="children"
            maxTagCount={3}
            maxTagTextLength={4}
            onChange={selectMultiUserIds}
            style={{ width: "100%" }}
            placeholder={trans.personals}
            filterOption={(input, option) => (option.children ? option.children.toLocaleLowerCase("tr").indexOf(input.toLocaleLowerCase("tr")) >= 0 : false)}
            value={current.item.userIds}
          >
            {employee.list.map((l, i) => {
              // //
              return (
                <Option key={"userd" + i} value={l.id}>
                  {l.name}
                </Option>
              );
            })}
          </Select>
        ) : null}
      </div>

      <div className="mb-5">
        <span className="input_header">{trans.department}</span>
        {department.list && department.list.length > 0 ? (
          <Select
            mode="multiple"
            allowClear
            maxTagCount={3}
            maxTagTextLength={4}
            optionFilterProp="children"
            onChange={selectMultiDepartments}
            // style={{ width: '100%' }}
            style={
              current.item.departmentIds && current.item.departmentIds.length > 0 && err.departmentError === ""
                ? { width: "100%" }
                : { width: "100%", borderColor: "rgba(232, 63, 64, 1)", borderWidth: 1 }
            }
            placeholder={trans.personals}
            filterOption={(input, option) => (option.children ? option.children.toLocaleLowerCase("tr").indexOf(input.toLocaleLowerCase("tr")) >= 0 : false)}
            value={current.item.departmentIds}
          >
            {department.list.map((l, i) => {
              // //
              return (
                <Option key={"department" + i} value={l.id}>
                  {l.name}
                </Option>
              );
            })}
          </Select>
        ) : null}
      </div>
      {/* add project start date and enddate */}
      <Input
        type={"date"}
        placeholder="Start Date"
        name="startDate"
        header="Start Date"
        value={current.item.startDate}
        onChange={(e) => {
          current.item.startDate = e.target.value;
          dispatch({ type: itemType, payload: current.item });
        }}
      />
      <Input
        type={"date"}
        placeholder="End Date"
        name="endDate"
        header="End Date"
        value={current.item.endDate}
        onChange={(e) => {
          current.item.endDate = e.target.value;
          dispatch({ type: itemType, payload: current.item });
        }}
      />

      <Input
        placeholder={trans.description}
        name={trans.description}
        header={trans.description}
        value={current.item.description}
        textarea={true}
        onChange={(e) => {
          current.item.description = e.target.value;
          dispatch({ type: itemType, payload: current.item });
        }}
        // error={err.descriptionError}
        maxLength={200}
        minLength={3}
      />

      {message && <span className="errorspan">{trans.error + ": " + message}</span>}
      <Button classes={"btn btn-primary"} onClick={register}>
        {trans.add}
      </Button>
    </div>
  );
}
