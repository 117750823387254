import { userFileTypeType, addUserFileTypeType, updateUserFileTypeType } from '../action_types'
const reducer = (state = { list: [], totalhour: 0 }, action) => {
    switch (action.type) {
        case userFileTypeType:
            return { ...state, list: action.payload };
        case addUserFileTypeType:
            return { ...state, list: [...state.list, action.payload] };
        case updateUserFileTypeType:
            var projectGroups = [];
            state.list.forEach(p => {
                if (p.id === action.payload.id)
                    projectGroups.push(action.payload);
                else projectGroups.push(p);
            });
            return { ...state, list: projectGroups };
        default:
            return state;
    }
};
export default reducer;