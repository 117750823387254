import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Input from '../anBrains/Input'
import Button from '../anBrains/Button'
import ContentLoader from 'react-content-loader'
import { fetchEmployees } from '../../redux/actions/employee_ac'
import { fetchLabels } from '../../redux/actions/label_ac'
import { fetchMeeting, fetchMeetingFiles } from '../../redux/actions/meeting_ac'
import { crud, dateFormatter, getTableType, tableType } from '../../config/constants'
import FileUploader from '../anBrains/FileUploader'
import Switch from '../anBrains/Switch'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import 'moment-timezone'
import { Select } from 'antd';
import 'antd/dist/antd.css';
import { itemType } from '../../redux/action_types'
import { fetchWorkorder } from '../../redux/actions/workorder_ac'
const { Option, OptGroup } = Select;
const date = new Date();
const edate = new Date();
const enddate = new Date(edate.setTime(date.getTime() + 60 * 60 * 1000))

var defaultItem = {
    name: "",
    description: "",
    decision: "",
    progress: 0,
    priority: 1,
    point: 1,
    workOrderLabels: [],
    userIds: [],
    labelIds: [],
    startDate: dateFormatter(date),
    endDate: dateFormatter(edate),
    type: 5,
}
export default function MeetingForm(props) {
    const trans = useSelector(state => state.trans)
    const employee = useSelector(state => state.employee)
    const label = useSelector(state => state.label)
    const loader = useSelector(state => state.loader)
    const current = useSelector(state => state.current)
    const workordergroup = useSelector(state => state.workordergroup)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({ type: itemType, payload: { ...defaultItem, workOrderGroupId: current.item.workOrderGroupId } })
    }, [])
    const errors = {
        groupIdError: "",
        nameError: "",
        dateError: "",
        usersError: "",
        labelError: "",
        descriptionError: "",
        desicionError: ""
    }
    const [err, setErr] = useState(errors)

    const [message, setMessage] = useState("");
    let validate = () => {
        if (current.item.name.length < 3 || current.item.name.length > 50) {
            setErr({ ...errors, nameError: trans.toShort })
            setMessage(trans.toShort)
            return false
        }
        else {
            setErr({ ...err, nameError: null })
            setMessage("")
        }

        if (current.item.workOrderGroupId === 0) {
            setErr({ ...errors, groupIdError: trans.pleaseSelectGroup })
            setMessage(trans.pleaseSelectGroup)
            return false
        }

        // if (!current.item.labelIds || current.item.labelIds.length === 0) {
        //     setErr({ ...err, meetingLabelsError: trans.blankPass })
        //     setMessage(trans.blankPass)
        //     return false
        // }


        // if (current.item.labelIds !== null && current.item.labelIds.length > 0) {
        //     var includeDepartment = false;
        //     var includeProject = false;
        //     var includeCustomer = false;
        //     var selectedTableTypes = []
        //     for (let i = 0; i < current.item.labelIds.length; i++)
        //         selectedTableTypes.push(current.item.labelIds[i].split("_")[1]);

        //     for (let index = 0; index < selectedTableTypes.length; index++) {
        //         var t = parseInt(selectedTableTypes[index])
        //         if (t === tableType.department)
        //             includeDepartment = true;

        //         if (t === tableType.project)
        //             includeProject = true;

        //         if (t === tableType.customer)
        //             includeCustomer = true;
        //     }

        //     if (includeDepartment === false ||
        //         (includeDepartment === true && includeProject === false && includeCustomer === false)) {
        //         setErr({ ...errors, labelError: trans.pleaseEnterValid })
        //         setMessage(trans.youmustchooseatleastonedepartment)
        //         return false
        //     }
        // }

        var sDate = new Date(current.item.startDate)
        var eDate = new Date(current.item.endDate)
        var tDate = eDate - sDate
        if (tDate < 5 * 60000) {
            setErr({ ...errors, endDateError: trans.dateRangeError })
            setMessage(trans.dateRangeError)
            return false
        }

        return true
    }
    const changePoint = (e) => {
        current.item.point = parseInt(e.target.value);
        dispatch({ type: itemType, payload: current.item })
    }

    const register = e => {
        e.preventDefault();
        if (validate()) {
            setErr(errors)
            current.item.workOrderLabels = label.lineList.filter(x => current.item.labelIds.includes(x.idx));
            dispatch(fetchWorkorder({}, crud.create, { ...current.item, type: 5 }, trans));
            props.setIsAddMeetingOpen(false)
        }
    }
    // const loadedFile = data => {
    //     dispatch({ type: itemType, payload: current.item })
    //     // setFormdata(data);
    // }

    const changeMeetingGroup = (e) => {
        current.item.workOrderGroupId = parseInt(e.target.value);
        dispatch({ type: itemType, payload: current.item })
    }
    const selectMultiCategories = (labelIds) => {

        current.item.labelIds = labelIds
        dispatch({ type: itemType, payload: current.item })
    };
    const selectMultiUserIds = (userIds) => {
        current.item.userIds = userIds
        dispatch({ type: itemType, payload: current.item })
    };

    return !loader.loading ? (<div className="meetingForm">
        {/* <div className="form-group-file">
            <span className="input_header">
                {trans.file}
            </span>
            <FileUploader text="asdf" loadedFile={loadedFile} err={err.file} />
        </div> */}
        <div className="input-container">
            <span className="input_header">
                {trans.meetingGroup}
            </span>
            <select
                className={`input select ${err.groupIdError ? 'input-error' : ''}`}
                onChange={e => changeMeetingGroup(e)}
                value={current.item.workOrderGroupId && current.item.workOrderGroupId.toString()} >
                <option key={"group" + 0} value={0}>{trans.select}</option>
                {workordergroup.list.map((c, i) => {
                    return (
                        <option key={"group" + i} value={c.id}>{c.name}</option>
                    )
                })}
            </select>
        </div>
        <Input placeholder={trans.subject}
            name={trans.subject}
            autoComplate={"off"}
            header={trans.subject}
            value={current.item.name}
            onChange={e => {
                current.item.name = e.target.value;
                dispatch({ type: itemType, payload: current.item })
            }}
            error={err.nameError}
            maxLength={100}
            minLength={3} />
        <div className="input-container">
            <span className="input_header">{trans.startdate}</span>
            <div className="input">
                <Input
                    type={"datetime-local"}
                    value={current.item.startDate}
                    onChange={e => {
                        current.item.startDate = e.target.value;
                        dispatch({ type: itemType, payload: current.item })
                    }}
                    autoComplate={"off"} />
            </div>
        </div>
        <div className="input-container">
            <span className="input_header">{trans.enddate}</span>
            <div className={`input ${err.endDateError ? 'input-error' : ''}`}>
                <Input
                    type={"datetime-local"}
                    value={current.item.endDate}
                    onChange={e => {
                        current.item.endDate = e.target.value;
                        dispatch({ type: itemType, payload: current.item })
                    }}
                    autoComplate={"off"} />
            </div>
        </div>
        <div className="input-container">
            <span className="input_header">{trans.meetingState}</span>
            <div className="mt-1">
                <Switch
                    classes="mediumSwitch"
                    id="generatedStatus"
                    value={current.item.status === 2 ? true : false}
                    onChange={(e) => {

                        current.item.status = e.target.checked === true ? 2 : 0;
                        dispatch({ type: itemType, payload: current.item })
                    }} />
                <span className={`ml-2 text-muted ${current.item.status ? 'bg-sky-blue' : ''}`}>{current.item.status ? trans.done : trans.notDone}</span>
            </div>
        </div>
        <div className="mb-5">
            <span className="input_header">{trans.participants}</span>
            {(employee.list !== null &&
                employee.list !== undefined &&
                employee.list.length > 0)
                ? <Select
                    mode="multiple"
                    allowClear
                    optionFilterProp="children"
                    maxTagCount={3}
                    maxTagTextLength={4}
                    onChange={selectMultiUserIds}
                    style={(current.item.userIds && current.item.userIds.length > 0) ? { width: '100%' } : { width: '100%', borderColor: "rgba(232, 63, 64, 1)", borderWidth: 1 }}
                    style={{ width: '100%' }}
                    placeholder={trans.participants}
                    filterOption={(input, option) => option.children ? option.children.toLocaleLowerCase('tr').indexOf(input.toLocaleLowerCase('tr')) >= 0 : false}
                    value={current.item.userIds}
                >
                    {employee.list.map((l, i) => {
                        // //
                        return (<Option key={"userd" + i} value={l.id}>{l.name}</Option>)
                    })}

                </Select>
                : null}
        </div>
        <div className="mb-5">
            <span className="input_header">{trans.labels}</span>
            {(label.list !== null &&
                label.list !== undefined &&
                label.list.length > 0)
                ? <Select
                    mode="multiple"
                    allowClear
                    maxTagCount={3}
                    maxTagTextLength={4}
                    optionFilterProp="children"
                    onChange={selectMultiCategories}
                    // style={(current.item.labelIds && current.item.labelIds.length > 0 && err.labelError === "") ? { width: '100%' } : { width: '100%', borderColor: "rgba(232, 63, 64, 1)", borderWidth: 1 }}
                    style={{ width: '100%' }}
                    placeholder={trans.labels}
                    filterOption={(input, option) => option.children ? option.children.toLocaleLowerCase('tr').indexOf(input.toLocaleLowerCase('tr')) >= 0 : false}
                // value={current.item.labelIds}
                >
                    {label.list.map((l, i) => {
                        return (
                            <OptGroup key={l.label} label={l.label}>{
                                l.options.map((m, i) => {
                                    return (
                                        <Option
                                            value={m.idx}
                                            key={"optionLable" + m.id + i}>{m.name}</Option>
                                    )
                                })
                            }
                            </OptGroup>)
                    })}

                </Select> : null}
        </div>
        <div className="input-container">
            <span className="input_header">
                {trans.point}
            </span>
            <select
                className={`input select ${err.customerIdError ? 'input-error' : ''}`}
                onChange={e => changePoint(e)}
                value={current.item.point} >
                <option value="1">1</option>
                <option value="3">3</option>
                <option value="5">5</option>
                <option value="8">8</option>
                <option value="13">13</option>
                <option value="21">21</option>
            </select>
        </div>
        <Input placeholder={trans.decisionsTaken}
            name={trans.decisionsTaken}
            header={trans.decisionsTaken}
            value={current.item.desicion}
            textarea={true}
            onChange={e => {
                current.item.desicion = e.target.value;
                dispatch({ type: itemType, payload: current.item })
            }}
            error={err.desicionError}
            maxLength={200}
            minLength={3} />
        <Input placeholder={trans.notes}
            name={trans.notes}
            header={trans.notes}
            value={current.item.description}
            textarea={true}
            onChange={e => {
                current.item.description = e.target.value;
                dispatch({ type: itemType, payload: current.item })
            }}
            error={err.desicionError}
            maxLength={200}
            minLength={3} />
        {message && <span className="errorspan">{trans.error + ": " + message}</span>}
        <Button classes={"btn btn-primary"}
            onClick={register}>{trans.add}</Button>
    </div>) : (
            <div className="employeeForm">
                <ContentLoader
                    viewBox="0 0 250 600"
                    height={600}
                    width={275}
                    speed={1}
                    backgroundColor="#ddd"
                    foregroundColor="#ecebeb"
                    {...props}>
                    <circle cx="150" cy="30" r="30" />
                    <rect x="25" y="100" rx="3" ry="3" width="275" height="10" />
                    <rect x="25" y="140" rx="3" ry="3" width="275" height="10" />
                    <rect x="25" y="180" rx="3" ry="3" width="275" height="10" />
                    <rect x="25" y="220" rx="3" ry="3" width="275" height="10" />
                </ContentLoader>
            </div>
        )
}
