import React, { useState, useEffect } from "react";
import Layout from "../../components/Container/Layout";
import Modal from "../../components/anBrains/Modal";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomers } from "../../redux/actions/customer_ac";
import { barListType, gridListType, customerListType, addBreadcrumbType, breadcrumbType } from "../../redux/action_types";
import Switch from "../../components/anBrains/Switch";
import Button from "../../components/anBrains/Button";
import Input from "../../components/anBrains/Input";
import { PlusIcon, BarIcon, GridIcon, SortIcon, EditIcon, TrushIcon, Search2Icon } from "../../components/icons";
import firm from "../../images/firm.png";
import ContentLoader from "react-content-loader";
// import Pagination from '../../components/anBrains/Pagination';
import { Pagination } from "antd";
import { success } from "../../redux/actions/loader_ac";
import { Link, useHistory } from "react-router-dom";
import CustomerForm from "../../components/customer/CustomerForm";
import { crud } from "../../config/constants";
import { baseUrl } from "../../config";
import manprofile from "../../images/profiles/man.png";
import womanprofile from "../../images/profiles/woman.png";
export default function CustomerManagement() {
  const dispatch = useDispatch();
  const trans = useSelector((state) => state.trans);
  const theme = useSelector((state) => state.theme);
  const current = useSelector((state) => state.current);
  const result = useSelector((state) => state.result);
  const customer = useSelector((state) => state.customer);
  const [isAddCustomerOpen, setIsAddCustomerOpen] = useState(false);
  const [isDeleteCustomerOpen, setIsDeleteCustomerOpen] = useState(false);
  const [item, setItem] = useState({});
  const [request, setRequest] = useState({ type: -1, rowCount: 14, pageCount: 0, search: "", sort: "id", desc: false, isCustomer: true });
  const loader = useSelector((state) => state.loader);
  const { breadcrumbs } = useSelector((state) => state.current);
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchCustomers(request, crud.list, {}, trans));
    if (current.board === true) {
      dispatch({ type: gridListType });
    }
  }, []);

  const changeStatus = (a, e) => {
    let updateItem = {};
    for (let index = 0; index < customer.list.length; index++) {
      const selectedItem = customer.list[index];
      if (selectedItem.id === e.id) {
        customer.list[index].active = a.target.checked;
        updateItem = customer.list[index];
      }
    }
    dispatch({ type: customerListType, payload: customer.list });
    dispatch(fetchCustomers(request, crud.update, updateItem, trans));
  };
  const showBar = () => {
    dispatch({ type: barListType });
  };
  const showGrid = () => {
    dispatch({ type: gridListType });
  };
  const [selectedPage, setSelectedPage] = useState(1);
  const deleteConfirm = (e) => {
    setIsDeleteCustomerOpen(true);
    setItem(e);
  };

  const deleteItem = () => {
    setIsDeleteCustomerOpen(false);
    dispatch(fetchCustomers(request, crud.delete, item, trans));
    let customers = customer.list.filter((e) => {
      return e.id !== item.id;
    });
    dispatch({ type: customerListType, payload: customers });
  };

  const setSelectedPageFilter = (n) => {
    setSelectedPage(n);
    setRequest({ ...request, pageCount: n - 1 });
    dispatch(fetchCustomers({ ...request, pageCount: n - 1 }, crud.list, {}, trans));
  };
  const [desc, setDesc] = useState(false);
  const sortbyField = (f) => {
    setRequest({ ...request, sort: f, desc });
    dispatch(fetchCustomers({ ...request, sort: f, desc }, crud.list, {}, trans));
    setDesc(!desc);
  };

  const searchChange = (text) => {
    setSearchText(text);
    if (text.length > 2) {
      dispatch(fetchCustomers({ ...request, search: text }, crud.list, {}, trans));
    }
  };

  const searchChangeKeyPress = (text, kcode) => {
    if (kcode === 8) {
      dispatch(fetchCustomers({ ...request, search: text }, crud.list, {}, trans));
    }
  };

  const editItem = (e) => {
    if (breadcrumbs && breadcrumbs.some((b) => b.name === e.name)) {
    }
    if (breadcrumbs.length > 1) {
      var newBreadCrumbs = [];
      newBreadCrumbs.push(breadcrumbs[0]);
      newBreadCrumbs.push({ name: e.name, path: "/customer-management/customer-detail" });
      dispatch({
        type: breadcrumbType,
        payload: newBreadCrumbs,
      });
    } else {
      dispatch({ type: addBreadcrumbType, payload: { name: e.name, path: "/customer-management/customer-detail" } });
    }
    history.push("/customer-management/customer-detail/" + e.id);
  };
  useEffect(() => {
    if (result.success === true) {
      setIsAddCustomerOpen(false);
      dispatch(success(false));
    }
  }, [result.change]);
  const compnayChange = (e) => {
    var type = e.target.value;
    setRequest({ ...request, type, pageCount: 0 });
    dispatch(fetchCustomers({ ...request, type, pageCount: 0 }, crud.list, {}, trans));
    setIsCustomer(type);
    setSelectedPage(1);
  };
  const [isCustomer, setIsCustomer] = useState(-1);
  const [searchText, setSearchText] = useState("");

  return (
    <Layout>
      <Modal isOpen={isAddCustomerOpen} withClose setIsOpen={setIsAddCustomerOpen} classes={"modal_add_employee"}>
        <CustomerForm />
      </Modal>
      <Modal isOpen={isDeleteCustomerOpen} withClose setIsOpen={setIsDeleteCustomerOpen} classes={"modal_delete"}>
        {trans.areyousureyouwanttodelete}
        <Button classes="btn-red" onClick={() => deleteItem()}>
          {trans.confirm}
        </Button>
      </Modal>
      <div className="table_properties">
        <div className="input-container">
          <select className="input select selectfilter" onChange={compnayChange} value={isCustomer}>
            <option value={-1}>{trans.partnerType}</option>
            <option value={0}>{trans.customer}</option>
            <option value={1}>{trans.supplier}</option>
          </select>
        </div>
        <Input
          icon={<Search2Icon color="rgba(96, 168, 221, 1)" />}
          placeholder={trans.search}
          value={searchText}
          onChange={(e) => searchChange(e.target.value)}
          onKeyUp={(e) => searchChangeKeyPress(e.target.value, e.keyCode)}
        />
        <Button icon={<PlusIcon color="rgba(96, 168, 221, 1)" />} classes="btn-basic" onClick={() => setIsAddCustomerOpen(true)}>
          {trans.add}
        </Button>
        <div className="bar_grid">
          <button onClick={showBar}>
            <BarIcon color={current.bar ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#5d5d5d"} />
          </button>
          <button onClick={showGrid}>
            <GridIcon
              color={current.grid ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#5d5d5d"}
            />
          </button>
        </div>
      </div>
      {current.bar &&
        (!loader.loading ? (
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th></th>
                  {/* <th onClick={() => sortbyField("code")}>{trans.code}
                                    <SortIcon color="#5d5d5d" /></th> */}
                  {/* <th onClick={() => sortbyField("representative")}> {trans.representative}
                                    <SortIcon color="#5d5d5d" /></th> */}
                  <th onClick={() => sortbyField("name")}>
                    {trans.company}
                    <SortIcon color="rgba(96, 168, 221, 1)" />
                  </th>
                  <th onClick={() => sortbyField("phone")}>
                    {trans.phone}
                    <SortIcon color="rgba(96, 168, 221, 1)" />
                  </th>
                  <th onClick={() => sortbyField("email")}>
                    {trans.email}
                    <SortIcon color="rgba(96, 168, 221, 1)" />
                  </th>
                  <th onClick={() => sortbyField("taxoffice")}>
                    {trans.taxoffice}
                    <SortIcon color="rgba(96, 168, 221, 1)" />
                  </th>
                  <th onClick={() => sortbyField("taxnumber")}>
                    {trans.taxnumber}
                    <SortIcon color="rgba(96, 168, 221, 1)" />
                  </th>
                  <th>Supervisor</th>
                  <th>{trans.status}</th>
                  <th>{trans.settings}</th>
                </tr>
              </thead>
              <tbody>
                {customer.list &&
                  customer.list.length > 0 &&
                  customer.list.map((e, i) => {
                    return (
                      <tr className="row" key={i}>
                        <td onClick={() => editItem(e)}>
                          {e.photo && e.photo.length > 5 ? (
                            <img src={baseUrl + e.photo} alt="" className="profile_list_img" />
                          ) : (
                            <img src={firm} alt="" className="profile_list_img" />
                          )}
                        </td>
                        {/* <td>{e.code}</td> */}
                        {/* <td>{e.representative}</td> */}
                        <td onClick={() => editItem(e)}>{e.name}</td>
                        <td onClick={() => editItem(e)}>{e.phone}</td>
                        <td onClick={() => editItem(e)}>{e.email}</td>
                        <td onClick={() => editItem(e)}>{e.taxOffice}</td>
                        <td onClick={() => editItem(e)}>{e.taxNumber}</td>
                        <td onClick={() => editItem(e)}>{e.superVisor && e.superVisor.name}</td>
                        <td>
                          <Switch id={"customerStatusSwitch" + e.id} value={e.active} onChange={(a) => changeStatus(a, e)} />
                        </td>
                        <td>
                          <button onClick={() => deleteConfirm(e)}>
                            <TrushIcon color={theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)"} />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        ) : (
          <table>
            <thead>
              <tr>
                <th></th>
                <th>
                  {trans.company}
                  <SortIcon color="rgba(96, 168, 221, 1)" />
                </th>
                <th>
                  {trans.phone}
                  <SortIcon color="rgba(96, 168, 221, 1)" />
                </th>
                <th>
                  {trans.email}
                  <SortIcon color="rgba(96, 168, 221, 1)" />
                </th>
                <th>
                  {trans.taxoffice}
                  <SortIcon color="rgba(96, 168, 221, 1)" />
                </th>
                <th>
                  {trans.taxnumber}
                  <SortIcon color="rgba(96, 168, 221, 1)" />
                </th>
                <th>Supervisor</th>
                <th>{trans.status}</th>
                <th>{trans.settings}</th>
              </tr>
            </thead>
            <tbody>
              <tr className="row">
                <td colSpan={8}>
                  <ContentLoader
                    preserveAspectRatio="none"
                    backgroundColor={theme === "dark" ? "#666666" : "#efefef"}
                    foregroundColor={theme === "dark" ? "#222222" : "#cccccc"}
                    viewBox="0 0 1000 60"
                    height={60}
                    width="100%"
                    style={{ width: "100%" }}
                    speed={2}
                  >
                    <rect x="110" y="21" rx="4" ry="4" width="754" height="6" />
                    <rect x="111" y="41" rx="3" ry="3" width="185" height="7" />
                    <rect x="304" y="-46" rx="3" ry="3" width="350" height="6" />
                    <rect x="371" y="-45" rx="3" ry="3" width="380" height="6" />
                    <rect x="484" y="-45" rx="3" ry="3" width="201" height="6" />
                  </ContentLoader>
                </td>
              </tr>
            </tbody>
          </table>
        ))}
      {current.grid && (
        <div className="grid_container">
          {!loader.loading ? (
            customer.list &&
            customer.list.length > 0 &&
            customer.list.map((e, i) => {
              return (
                <div className="grid_card" key={"grid_card" + i}>
                  <div className="grid_card_profile_img_container" onClick={() => editItem(e)}>
                    {e.photo && e.photo.length > 5 ? (
                      <img src={e.photo ? baseUrl + e.photo : e && e.gender ? manprofile : womanprofile} alt="" className="grid_card_profile_img" />
                    ) : (
                      <img src={firm} alt="" className="grid_card_profile_img" />
                    )}
                  </div>
                  <div className="grid_card_information_container">
                    <div className="grid_card_information" onClick={() => editItem(e)}>
                      <span className="grid_card_name">{e.name}</span>
                      <span className="grid_card_position">
                        <span className="badge">{e.superVisor.name}</span>
                      </span>
                      <span className="grid_card_phone"> {e.phone}</span>
                      <span className="grid_card_email">{e.email}</span>
                    </div>
                    <div className="grid_card_settings">
                      <div>
                        {trans.status}
                        <Switch id={"customerStatusSwitch" + e.id} value={e.active} onChange={(a) => changeStatus(a, e)} />
                      </div>
                      {/* <div>
                                        {trans.document}
                                        <CheckBox value={e.status} />
                                    </div> */}
                    </div>
                  </div>
                  <div className="grid_card_edit_buttons">
                    {/* <Link to={"/customer-management/customer-detail/" + e.id}
                                    >
                                    <EditIcon color={(theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")} />
                                </Link> */}
                    <button onClick={() => deleteConfirm(e)}>
                      <TrushIcon color={theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)"} />
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <ContentLoader
              preserveAspectRatio="none"
              viewBox="0 0 1000 600"
              height={600}
              width={"100%"}
              speed={1}
              backgroundColor={theme === "dark" ? "#666666" : "#efefef"}
              foregroundColor={theme === "dark" ? "#222222" : "#cccccc"}
            >
              <rect x="0" y="56" rx="2" ry="2" width="1000" height="211" />
              <rect x="0" y="300" rx="2" ry="2" width="1000" height="211" />
            </ContentLoader>
          )}
        </div>
      )}
      {current && current.pageCount > 0 && (
        <Pagination
          defaultCurrent={selectedPage}
          onChange={setSelectedPageFilter}
          onShowSizeChange={(pageCount, rowCount) => {
            dispatch(fetchCustomers({ ...request, pageCount, rowCount }, crud.list, {}, trans));
            setRequest({ ...request, pageCount, rowCount });
          }}
          total={current.pageCount * request.rowCount}
        />
      )}
    </Layout>
  );
}
