import axios from 'axios'
import { axiosConfig, baseUrl, openNotification } from '../../config'
import {
    pageCountType, addDepartmentType, updateDepartmentType, updateBudgetType, addBudgetType,
    budgetType, addBudgetDetailType, budgetDetailType, totalCostType, isLoginType
} from '../action_types'
import { success, calculatePageNumber, loading } from './loader_ac'
import { crud } from '../../config/constants'
export const fetchBudgets = (request = { rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}, trans = {}) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/projectcosts/budget`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    // //
    return dispacth => {
        try {
            if (request.loader)
                dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            })
                .then(response => {
                    console.log(response);
                    if (request.loader)
                        dispacth(loading())
                    if (response.data.success) {
                        switch (requestType) {
                            case crud.create:
                                dispacth({
                                    type: addBudgetType,
                                    payload: response.data.data,
                                })
                                dispacth({
                                    type: totalCostType,
                                    payload: response.data.data2,
                                })
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.read:
                                break;
                            case crud.update:
                                dispacth({
                                    type: updateBudgetType,
                                    payload: response.data.data,
                                })
                                dispacth({
                                    type: totalCostType,
                                    payload: response.data.data2,
                                })
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.delete:
                                dispacth({
                                    type: totalCostType,
                                    payload: response.data.data2,
                                })
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.list:
                                // //
                                dispacth({
                                    type: budgetType,
                                    payload: response.data.data,
                                })
                                dispacth({
                                    type: totalCostType,
                                    payload: response.data.data2,
                                })
                                // dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) })
                                break;
                            default:
                                break;
                        }
                        dispacth(success(true))
                    }
                })
                .catch(reason => {
                    
                    console.log();
                    openNotification('error', trans.errorTitle, trans.errorBody, 3)

                })
        } catch (error) {
            console.log(error)
            openNotification('error', trans.errorTitle, trans.errorBody, 3)
            // dispacth(loading())
        }
    }
}

export const fetchBudgetDetails = (request = { rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}, trans = undefined) => {
    let headers = axiosConfig.headers

    let url = `${baseUrl}/api/projectcosts/detail`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }

    return dispacth => {
        try {
            if (request.loader)
                dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            })
                .then(response => {
                    if (request.loader)
                        dispacth(loading())
                    if (response.data.success) {
                        switch (requestType) {
                            case crud.create:
                                dispacth({
                                    type: addBudgetDetailType,
                                    payload: response.data.data,
                                })

                                dispacth({
                                    type: totalCostType,
                                    payload: response.data.data2,
                                })
                                var budget = response.data.data2.budget;
                                budget.selected = true
                                dispacth({
                                    type: updateBudgetType,
                                    payload: budget,
                                })
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.read:
                                break;
                            case crud.update:
                                dispacth({
                                    type: totalCostType,
                                    payload: response.data.data2,
                                })

                                var budget = response.data.data2.budget;
                                budget.selected = true
                                dispacth({
                                    type: updateBudgetType,
                                    payload: budget,
                                })
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.delete:
                                dispacth({
                                    type: totalCostType,
                                    payload: response.data.data2,
                                })

                                var budget = response.data.data2.budget;
                                budget.selected = true
                                dispacth({
                                    type: updateBudgetType,
                                    payload: budget,
                                })
                                break;
                            case crud.list:
                                dispacth({
                                    type: budgetDetailType,
                                    payload: response.data.data,
                                })
                                // dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) })
                                break;
                            default:
                                break;
                        }
                        dispacth(success(true))
                    }
                })
                .catch(reason => {
                    console.log();
                    openNotification('error', trans.errorTitle, trans.errorBody, 3)


                })
        } catch (error) {
            console.log(error)
            openNotification('error', trans.errorTitle, trans.errorBody, 3)
            // dispacth(loading())
        }
    }
}