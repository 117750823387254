import React, { useEffect, useState } from 'react'
import LeftMenu from './LeftMenu';
import Header from './Header';
import Content from './Content';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserType } from '../../redux/action_types';
import bg from '../../images/panobg.png'
import { fetchNotification } from '../../redux/actions/notification_ac'
import { crud } from '../../config/constants'
import GeneralLoader from '../anBrains/GeneralLoader';
import { baseUrl } from '../../config';
export default function Layout(props) {
    // eslint-disable-next-line no-unused-vars
    const history = useHistory();
    const user = useSelector(state => state.user)
    const current = useSelector(state => state.current)
    const { children, className, page, isPano } = props
    const loader = useSelector(state => state.loader)
    const size = useSelector(state => state.size)
    const dispatch = useDispatch()
    const [request, setRequest] = useState({
        loader: true,
        rowCount: 5,
        pageCount: 0,
        search: "",
        sort: "id",
        "desc": true,
        data: {}
    })

    useEffect(() => {
        if (current.unauthorized)
            history.push("/")
        else {
            const user = JSON.parse(localStorage.getItem('user'))
            dispatch({ type: getUserType, payload: user });
        }
    }, [current.unauthorized])
    //    
    return (
        <div className="layout"
            style={isPano && user ? {
                backgroundImage: `url(${(user.background === null || user.background === undefined)
                    ? bg
                    : (baseUrl + user.background)}
                    )`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat'
            } : null}>
            <LeftMenu isPano />
            <div className={size.isOpenLeftMenu ? "headerandcontent headerandcontent_left_width" : "headerandcontent"}>
                <Header isPano={isPano} />
                <Content children={children} />
            </div>
            {loader.loading && <GeneralLoader />}
        </div>
    )
}
