import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Input from '../anBrains/Input'
import Button from '../anBrains/Button'
import ContentLoader from 'react-content-loader'
import { Select } from 'antd';
import 'antd/dist/antd.css';
import { fetchProjectStepSave, fetchWorkorder } from '../../redux/actions/workorder_ac'
import { crud, tableType } from '../../config/constants'
import { ganttItemType, ganttType } from '../../redux/action_types'
import { baseUrl } from '../../config'
import manprofile from '../../images/profiles/man.png';
import womanprofile from '../../images/profiles/woman.png';
const { Option, OptGroup } = Select;
export default function ProjectStepForm(props) {
    const trans = useSelector(state => state.trans)
    const theme = useSelector(state => state.theme)
    const employee = useSelector(state => state.employee)
    const label = useSelector(state => state.label)
    const loader = useSelector(state => state.loader)
    const { item, list } = useSelector(state => state.gantt)
    const dispatch = useDispatch()
    const errors = {
        // descriptionError: "",
        nameError: "",
        userError: "",
        endDateError: "",
        startDateError: "",
        labelError: "",
        workorderuserError: ""
    }
    const [err, setErr] = useState(errors)

    const [message, setMessage] = useState("");
    let validate = () => {
        if (item.userIds.length <= 0) {
            setErr({ ...errors, userError: trans.pleaseEnterValid })
            setMessage(trans.pleaseEnterValid)
            return false
        }

        // if (item.labelIds === null || item.labelIds.length <= 0) {
        //     setErr({ ...errors, labelError: trans.youmustchooseatleastonedepartment })
        //     setMessage(trans.youmustchooseatleastonedepartment)
        //     return false
        // }


        if (item.labelIds !== null && item.labelIds.length > 0) {
            var includeDepartment = false;
            var includeProject = false;
            var includeCustomer = false;
            var selectedTableTypes = []
            for (let i = 0; i < item.labelIds.length; i++)
                selectedTableTypes.push(item.labelIds[i].split("_")[1]);

            for (let index = 0; index < selectedTableTypes.length; index++) {
                var t = parseInt(selectedTableTypes[index])
                if (t === tableType.department)
                    includeDepartment = true;

                if (t === tableType.project)
                    includeProject = true;

                if (t === tableType.customer)
                    includeCustomer = true;
            }

            if (includeDepartment === false ||
                (includeDepartment === true &&
                    includeProject === false &&
                    includeCustomer === false)) {
                setErr({ ...errors, labelError: trans.pleaseEnterValid })
                setMessage(trans.youmustchooseatleastonedepartment)
                return false
            }
        }

        if (item.workOrderUsers && item.workOrderUsers.length > 0 && item.labelIds !== null && item.labelIds.length > 0) {
            for (let i = 0; i < item.workOrderUsers.length; i++) {
                const element = item.workOrderUsers[i];
                if ((element.day == 0 || element.hour == 0)) {
                    setErr({ ...errors, workorderuserError: trans.pleaseEnterValid })
                    setMessage(trans.pleaseEnterValid)
                    return false
                }
            }
        }

        return true
    }

    const register = e => {
        e.preventDefault();
        if (validate()) {
            setErr(errors)
            var options = {};
            if (item.labelIds !== null && item.labelIds.length > 0) {
                options.type = 3;
                options.isGantt = true;
            }
            else {
                options.isWorkOrder = true;
                options.type = 2;
            }
            dispatch(fetchWorkorder({ loader: true }, crud.update, { ...item, ...options }, trans))
            if (list.some(x => parseInt(x.id) === parseInt(item.id))) {
                var itemIndex = list.findIndex(x => parseInt(x.id) === parseInt(item.id));
                list[itemIndex] = item
                list[itemIndex].type = ((item.labelIds !== null && item.labelIds.length > 0) ? 3 : 2);
                dispatch({ type: ganttType, payload: list })
            }
            props.setIsOpen(false)
        }
    }

    const selectMultiUserIds = (userIds) => {
        for (let i = 0; i < userIds.length; i++) {
            const userId = userIds[i];
            if (!item.workOrderUsers.some(x => x.userId === userId)) {
                var user = employee.list.find(x => x.id === userId);
                item.workOrderUsers.push({
                    user,
                    userId: user.id,
                    workOrderId: item.id,
                    hour: 0,
                    day: 0,
                })
                dispatch({ type: ganttItemType, payload: { ...item, userIds } })
            }
        }

        for (let i = 0; i < item.workOrderUsers.length; i++) {
            const wuser = item.workOrderUsers[i];
            if (!userIds.some(x => x === wuser.userId)) {
                item.workOrderUsers = item.workOrderUsers.filter(x => x.userId !== wuser.userId)
                dispatch({ type: ganttItemType, payload: { ...item, userIds } })
            }
        }
    };
    const selectMultiCategories = (labelIds) => {
        setErr({ ...errors, labelError: "" })
        dispatch({ type: ganttItemType, payload: { ...item, labelIds } })
    };


    return (<div className="project_step_form_container">
        <div className="projectStepForm">
            <div className="mb-5">
                <table>
                    <tbody>
                        <tr>
                            <td colSpan={3}>
                                <span className="resourceassignment_header">{trans.resourceassignment}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>{trans.ganttStep}</td>
                            <td>:</td>
                            <td>{item.name}</td>
                        </tr>
                        <tr>
                            <td>{trans.startdate}</td>
                            <td>:</td>
                            <td>{item.startDateString}</td>
                        </tr>
                        <tr>
                            <td>{trans.enddate}</td>
                            <td>:</td>
                            <td>{item.endDateString}</td>
                        </tr>
                        <tr>
                            <td>{trans.day}</td>
                            <td>:</td>
                            <td>{item.day}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="mb-5">
                <span className="input_header">{trans.personals}</span>
                {(employee.list !== null &&
                    employee.list !== undefined &&
                    employee.list.length > 0)
                    ? <Select
                        mode="multiple"
                        allowClear
                        maxTagCount={3}
                        maxTagTextLength={4}
                        optionFilterProp="children"
                        onChange={selectMultiUserIds}
                        style={(item.userIds && item.userIds.length > 0) ? { width: '100%' } : { width: '100%', borderColor: "rgba(232, 63, 64, 1)", borderWidth: 1 }}
                        placeholder={trans.personals}
                        filterOption={(input, option) => option.children ? option.children.toLocaleLowerCase('tr').indexOf(input.toLocaleLowerCase('tr')) >= 0 : false}
                        value={item.userIds}
                    >
                        {employee.list.map((l, i) => {
                            return (<Option key={"userId" + i} value={l.id}>{l.name}</Option>)
                        })}
                    </Select>
                    : null}
            </div>

            <div className="mb-5">
                <span className="input_header">{trans.labels}</span>
                {(label.list !== null &&
                    label.list !== undefined &&
                    label.list.length > 0)
                    ? <Select
                        mode="multiple"
                        allowClear
                        maxTagCount={3}
                        maxTagTextLength={4}
                        optionFilterProp="children"
                        onChange={selectMultiCategories}
                        style={(item.labelIds && item.labelIds.length > 0) && err.labelError === "" ? { width: '100%' } : { width: '100%', borderColor: "rgba(232, 63, 64, 1)", borderWidth: 1 }}
                        placeholder={trans.labels}
                        filterOption={(input, option) => option.children ? option.children.toLocaleLowerCase('tr').indexOf(input.toLocaleLowerCase('tr')) >= 0 : false}
                        value={item.labelIds}
                    >
                        {label.list.map((l, i) => {
                            return (<OptGroup key={l.label} label={l.label}>
                                {
                                    l.options &&
                                    l.options.map((m, i) => {
                                        return (
                                            <Option
                                                value={m.idx}
                                                key={"optionLable" + m.id + i}>{m.name}</Option>
                                        )
                                    })
                                }
                            </OptGroup>)
                        })}
                    </Select>
                    : null}
            </div>

            <Input placeholder={trans.description}
                name={trans.description}
                header={trans.description}
                value={item.description}
                textarea={true}
                onChange={e => dispatch({ type: ganttItemType, payload: { ...item, description: e.target.value } })}
                error={err.descriptionError}
                maxLength={200}
                minLength={3} />

        </div>
        <div className="project_step_form_right_side">
            <table>
                <thead>
                    <tr>
                        {/* <th>{trans.no}</th> */}
                        <th>{trans.personal}</th>
                        <th>{trans.hour}</th>
                        <th></th>
                        <th>{trans.day}</th>
                    </tr>
                </thead>
                {/* <ReactSortable list={list} setList={datalist => setOrderList(datalist)} tag={"tbody"}> */}
                <tbody>

                    {(item.workOrderUsers) ? item.workOrderUsers.map((g, i) => {
                        return (
                            <tr key={"step" + g.userId}>

                                {/* <td>{i + " -"}</td> */}
                                <td>
                                    <img src={g.user && g.user.photo ? baseUrl + g.user.photo : (g && g.user && g.user.gender) ? manprofile : womanprofile} title={g.name} />
                                </td>
                                <td>
                                    <Input
                                        type={"number"}
                                        value={g.hour}
                                        min={0}
                                        error={err.workorderuserError}
                                        onFocus={e => {
                                            if (e.target.value === "0") {
                                                item.workOrderUsers.find(x => x.userId === g.userId).hour = "";
                                                dispatch({ type: ganttItemType, payload: item })
                                            }
                                        }}
                                        onChange={e => {
                                            var hour = e.target.value;
                                            item.workOrderUsers.find(x => x.userId === g.userId).hour = hour;
                                            dispatch({ type: ganttItemType, payload: item })
                                            setErr(errors)
                                            setMessage("")
                                        }}
                                        onBlur={e => {
                                            if (e.target.value === "") {
                                                item.workOrderUsers.find(x => x.userId === g.userId).hour = 0;
                                                dispatch({ type: ganttItemType, payload: item })
                                            }
                                        }}
                                    />
                                </td>
                                <td style={{ textAlign: "center" }}>X</td>
                                <td>

                                    <Input
                                        type={"number"}
                                        value={g.day}
                                        min={0}
                                        max={item.day}
                                        error={err.workorderuserError}
                                        onFocus={e => {
                                            if (e.target.value === "0") {
                                                item.workOrderUsers.find(x => x.userId === g.userId).day = "";
                                                dispatch({ type: ganttItemType, payload: item })
                                            }
                                        }}
                                        onChange={e => {
                                            var day = e.target.value;
                                            if (day <= item.day) {
                                                item.workOrderUsers.find(x => x.userId === g.userId).day = day;
                                                dispatch({ type: ganttItemType, payload: item })
                                                setErr(errors)
                                                setMessage("")
                                            }
                                            else {
                                                item.workOrderUsers.find(x => x.userId === g.userId).day = item.day;
                                                dispatch({ type: ganttItemType, payload: item })
                                                setMessage(trans.overtimestartdateendate)
                                            }
                                        }}
                                        onBlur={e => {
                                            if (e.target.value === "") {

                                                item.workOrderUsers.find(x => x.userId === g.userId).day = 0;
                                                dispatch({ type: ganttItemType, payload: item })
                                            }
                                        }}
                                    />
                                </td>
                            </tr>)
                    }) : null}
                    {/* </ReactSortable> */}
                </tbody>
                {/* <tfoot>
                           
                        </tfoot> */}
            </table>
            {message && <span className="errorspan">{trans.error + ": " + message}</span>}
            <Button classes={"btn btn-primary"}
                onClick={register}>{trans.save}</Button>
        </div>
    </div>)
}
