import React, { useEffect, useState } from "react";
import Layout from "../../components/Container/Layout";
import { useDispatch, useSelector } from "react-redux";
import { Bar, Pie, Polar } from "react-chartjs-2";
import { CheckCircleIcon, EditCircleIcon, EditIcon, LeftIcon, RightIcon } from "../../components/icons";
import { fetchDashboard, fetchReport } from "../../redux/actions/report_ac";
import { addBreadcrumbType, breadcrumbType, getUserType, projectType } from "../../redux/action_types";
import { useHistory } from "react-router-dom";
import { crud, ProjectStatus } from "../../config/constants";
import Switch from "../../components/anBrains/Switch";
import { fetchProject } from "../../redux/actions/project_ac";
import { fetchChangeBackgroundImage, fetchChangeMotto } from "../../redux/actions/authorization_ac";
import { baseUrl } from "../../config";
import manprofile from "../../images/profiles/man.png";
import womanprofile from "../../images/profiles/woman.png";
import Input from "../../components/anBrains/Input";
import BackgroundUploader from "../../components/anBrains/BackgroundUploader";
export default function Pano(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const trans = useSelector((state) => state.trans);
  const user = useSelector((state) => state.user);
  const report = useSelector((state) => state.report);
  const theme = useSelector((state) => state.theme);
  const { breadcrumbs } = useSelector((state) => state.current);
  const project = useSelector((state) => state.project);
  const workorder = useSelector((state) => state.workorder);
  const meetings = useSelector((state) => state.meetings);

  var options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
  var today = new Date();
  useEffect(() => {
    dispatch(fetchDashboard());
  }, []);

  var fields = {
    user: "",
    report: "",
    department: "",
    project: "",
  };

  const selectRow = (e) => {
    if (breadcrumbs && breadcrumbs.some((b) => b.name === e.name)) {
    }
    if (breadcrumbs.length > 1) {
      var newBreadCrumbs = [];
      newBreadCrumbs.push(breadcrumbs[0]);
      newBreadCrumbs.push({
        name: e.name,
        path: "/projects/project-detail" + e.id,
      });
      dispatch({
        type: breadcrumbType,
        payload: newBreadCrumbs,
      });
    } else {
      dispatch({
        type: addBreadcrumbType,
        payload: { name: e.name, path: "/projects/project-detail" + e.id },
      });
    }
    history.push("/projects/project-detail/" + e.id);
  };

  const selectRowWorkorder = (e) => {
    if (breadcrumbs && breadcrumbs.some((b) => b.name === e.name)) {
    }
    if (breadcrumbs.length > 1) {
      var newBreadCrumbs = [];
      newBreadCrumbs.push(breadcrumbs[0]);
      newBreadCrumbs.push({
        name: e.name,
        path: "/workorders/workorder-detail" + e.id,
      });
      dispatch({
        type: breadcrumbType,
        payload: newBreadCrumbs,
      });
    } else {
      dispatch({
        type: addBreadcrumbType,
        payload: { name: e.name, path: "/workorders/workorder-detail" + e.id },
      });
    }
    history.push("/workorders/workorder-detail/" + e.id);
  };

  const renderProjectStatus = (s) => <div className={"statuscircle"} style={{ backgroundColor: ProjectStatus[s].color }}></div>;

  const changeStatus = (a, e) => {
    var updateItem = project.list.find((x) => x.id === e.id);
    project.list.find((x) => x.id === e.id).isViewPano = a.target.checked;
    dispatch({ type: projectType, payload: project.list });
    dispatch(fetchProject({ only: true }, crud.update, updateItem, trans));
  };

  const detailItem = (e) => {
    if (breadcrumbs && breadcrumbs.some((b) => b.name === e.name)) {
    }
    if (breadcrumbs.length > 1) {
      var newBreadCrumbs = [];
      newBreadCrumbs.push(breadcrumbs[0]);
      newBreadCrumbs.push({ name: e.name, path: "/meeting-management/meeting-detail" });
      dispatch({
        type: breadcrumbType,
        payload: newBreadCrumbs,
      });
    } else {
      dispatch({
        type: addBreadcrumbType,
        payload: { name: e.name, path: "/meeting-management/meeting-detail" },
      });
    }
    history.push("/meeting-management/meeting-detail/" + e.id);
  };
  const [mottoVisible, setMottoVisible] = useState(true);
  // const [formdata, setFormdata] = useState(new FormData())
  const loadedFile = (data) => {
    // setFormdata(data)
    dispatch(fetchChangeBackgroundImage(data, user));
  };
  return (
    <Layout isPano>
      <div className="pano_container">
        <BackgroundUploader loadedFile={loadedFile} />
        <div className="pano_header">
          <div>
            <span>{trans.hi + " " + (user != null ? user.name + " !" : "")}</span>
            <span>
              {mottoVisible === true ? (
                user.motto === null ? (
                  '"' + trans.niceword + '"'
                ) : (
                  user.motto
                )
              ) : (
                <Input value={user.motto || trans.niceword} onChange={(e) => dispatch({ type: getUserType, payload: { ...user, motto: e.target.value } })} />
              )}

              {user &&
                user.id > 0 &&
                user.roles.some((x) => x === "Admin") &&
                (mottoVisible === true ? (
                  <button onClick={() => setMottoVisible(!mottoVisible)}>
                    <EditCircleIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setMottoVisible(!mottoVisible);
                      localStorage.setItem("user", JSON.stringify(user));
                      dispatch(fetchChangeMotto(user.motto));
                    }}
                  >
                    <CheckCircleIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />
                  </button>
                ))}
            </span>
          </div>
          <div>
            <span>{new Date().toTimeString().substr(0, 5)}</span>
            <span>{today.toLocaleDateString("tr-TR", options)}</span>
          </div>
        </div>
        <div className="pano_body">
          <div className="pano_body_left">
            <div className="pano_body_left_top">
              <div className="pano_body_left_top_pano_panel">
                <span>{trans.summaryofworkorders}</span>
                <Polar
                  data={report.pie}
                  options={{
                    // title: {
                    //     display: true,
                    //     text: 'Average Rainfall per month',
                    //     fontSize: 20
                    // },
                    legend: {
                      display: window.innerWidth > 1000 ? true : false,
                      position: "bottom",
                    },
                  }}
                />
              </div>
              <div className="pano_body_left_top_pano_panel">
                <div className="number_row">
                  <div>
                    <span>{trans.todo}</span>
                    <number style={{ color: "rgba(96, 168, 221, 1)" }}>{report.numbers && report.numbers.todo}</number>
                  </div>
                  <div>
                    <span>{trans.continueone}</span>
                    <number style={{ color: "rgba(247, 202, 49, 1)" }}>{report.numbers && report.numbers.inProgress}</number>{" "}
                  </div>
                </div>
                <div className="number_row">
                  <div>
                    {" "}
                    <span>{trans.done}</span>
                    <number style={{ color: "#05A85C" }}>{report.numbers && report.numbers.done}</number>{" "}
                  </div>
                  <div>
                    {" "}
                    <span>{trans.canceled}</span>
                    <number style={{ color: "rgba(232, 63, 64, 1)" }}>{report.numbers && report.numbers.canceled}</number>{" "}
                  </div>
                </div>
              </div>
              <div className="pano_body_left_top_pano_panel">
                <span>{trans.lastworkorders}</span>
                <div className="table">
                  <table>
                    <thead>
                      <tr>
                        <th> {trans.workorder}</th>
                        {/* <th>{trans.employees}</th> */}
                        {/* <th>{trans.creator}</th> */}
                        {/* <th>{trans.labels}</th> */}
                        <th>{trans.progress}</th>
                        <th>{trans.point}</th>
                        {/* <th>{trans.startdate}</th> */}
                        {/* <th>{trans.enddate}</th> */}
                        {/* <th>{trans.priority} </th> */}
                        <th>{trans.status}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {workorder.panoList &&
                        workorder.panoList.length > 0 &&
                        workorder.panoList.map((e, i) => {
                          return (
                            <tr className="row" key={i}>
                              <td onClick={() => selectRowWorkorder(e)}>{e.name}</td>

                              <td onClick={() => selectRowWorkorder(e)}>
                                <div className="progress_container">
                                  <div className="progress_bar" style={{ width: (e.progress > 100 ? 100 : e.progress) + "%" }}>
                                    {(e.progress > 100 ? 100 : e.progress) + "%"}
                                  </div>
                                </div>
                              </td>
                              <td onClick={() => selectRowWorkorder(e)} style={{ textAlign: "center" }}>
                                {e.point}
                              </td>
                              {/* <td onClick={() => selectRow(e)}>
                                                        {e.startDateTimeString}
                                                    </td>
                                                    <td onClick={() => selectRow(e)}>
                                                        {e.endDateTimeString}
                                                    </td> */}
                              {/* <td
                                                        title={ProjectStatus[e.status][trans.lang]}>
                                                        {renderPriority(e.priority)}
                                                    </td> */}
                              <td title={ProjectStatus[e.status][trans.lang]} style={{ paddingRight: "1rem" }}>
                                {renderProjectStatus(e.status)}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="pano_body_left_bottom">
              <div className="reports_group">
                <span>{trans.projects}</span>
                <div className="table">
                  <table>
                    <thead>
                      <tr>
                        <th>{trans.status}</th>
                        <th>{trans.code}</th>
                        <th>{trans.projectName}</th>
                        <th>{trans.eventCount}</th>
                        <th>{trans.firm}</th>
                        <th>{trans.startdate}</th>
                        <th>{trans.lastupdateddate}</th>
                        <th>{trans.pano}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {project.list &&
                        project.list.length > 0 &&
                        project.list.map((e, i) => {
                          return (
                            <tr className="row" key={i}>
                              <td title={ProjectStatus[e.status][trans.lang]} onClick={() => selectRow(e)}>
                                {renderProjectStatus(e.status)}
                              </td>
                              <td onClick={() => selectRow(e)}>{e.code}</td>
                              <td onClick={() => selectRow(e)}>{e.name}</td>
                              <td onClick={() => selectRow(e)}> {e.eventCount}</td>
                              <td onClick={() => selectRow(e)}>{e.customerName}</td>
                              <td onClick={() => selectRow(e)}>{e.createDateViewString}</td>
                              <td onClick={() => selectRow(e)}>{e.modifyDateViewString}</td>
                              <td>
                                <Switch id={"projectStatusSwitch" + e.id} value={e.isViewPano} onChange={(a) => changeStatus(a, e)} />
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="pano_body_right">
            <div className="pano_body_right_top"></div>
            <div className="pano_body_right_bottom">
              <span>{trans.meetings}</span>
              <div className="table">
                <table>
                  <thead>
                    <tr>
                      <th>{trans.subject}</th>
                      <th>{trans.participants}</th>
                      <th>{trans.meetingDate}</th>
                      {/* <th>{trans.decisionsTaken}</th> */}
                      {/* <th>{trans.notes}</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {meetings.list &&
                      meetings.list.length > 0 &&
                      meetings.list.map((e, i) => {
                        return (
                          <tr className="row" key={i}>
                            <td onClick={() => detailItem(e)}>{e.name}</td>
                            <td onClick={() => detailItem(e)}>
                              {meetings.list[i].users &&
                                meetings.list[i].users.length > 0 &&
                                meetings.list[i].users.map((e, j) => {
                                  return j < 4 ? (
                                    <img
                                      title={e.name}
                                      key={j}
                                      src={e.photo ? baseUrl + e.photo : e && e.gender ? manprofile : womanprofile}
                                      className="meeting_users_img mr-1"
                                    />
                                  ) : (
                                    ".."
                                  );
                                })}
                            </td>
                            <td onClick={() => detailItem(e)}>{e.startDateString}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
