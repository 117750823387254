import axios from 'axios'
import { axiosConfig, baseUrl, openNotification } from '../../config'
import {
    pageCountType, addProjectType, projectType, updateProjectType, projectBoardType,
    updateProjectBoardListType, itemType, customerListType, departmentType, projectGroupType,
    employeeListType, labelType, lineListType, isLoginType
} from '../action_types'
import { success, calculatePageNumber, loading } from './loader_ac'
import { crud } from '../../config/constants'
export const fetchProject = (request = { rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}, trans = undefined) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/projects`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    //
    //console.log(request)
    //console.log(requestType)
    //console.log(data)
    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            })
                .then(response => {
                    dispacth(loading())
                    if (response.data.success) {
                        switch (requestType) {
                            case crud.create:
                                //
                                dispacth({
                                    type: addProjectType,
                                    payload: response.data.data,
                                })
                                dispacth(success(true))
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.read:
                                break;
                            case crud.update:
                                dispacth(success(true))
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.delete:
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.list:

                                if (request.isKanban || request.isProjectStatus) {
                                    dispacth({
                                        type: updateProjectBoardListType,
                                        payload: { data: response.data.data, projectStatus: request.projectStatus },
                                    })
                                }
                                else {
                                    dispacth({
                                        type: projectType,
                                        payload: response.data.data,
                                    })
                                    dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) })
                                    dispacth(success(true))
                                    dispacth({ type: projectBoardType, payload: response.data.data })
                                }

                                break;
                            default:
                                break;
                        }
                    }
                })
                .catch(reason => {

                    dispacth(loading())
                    openNotification('error', trans.errorTitle, trans.errorBody, 3)
                })
        } catch (error) {
            console.log(error)
            dispacth(loading())
            openNotification('error', trans.errorTitle, trans.errorBody, 3)
        }
    }
}

export const fetchProjectDetail = (request = { rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}, trans = undefined) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/projects`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            })
                .then(response => {
                    dispacth(loading())
                    if (response.data.success) {
                        var project = response.data.data[0];
                        var options = [
                            {
                                label: request.lang === "tr" ? "Projeler" : "Projects",
                                options: response.data.data2.projects
                            },
                            {
                                label: request.lang === "tr" ? "Müşteriler" : "Customers",
                                options: response.data.data2.customersLabels
                            },
                            {
                                label: request.lang === "tr" ? "Departmanlar" : "Departments",
                                options: response.data.data2.departmentLabels
                            },
                        ];
                        dispacth({
                            type: labelType,
                            payload: options
                        })

                        dispacth({
                            type: lineListType,
                            payload: [...response.data.data2.projects,
                            ...response.data.data2.customersLabels,
                            ...response.data.data2.departmentLabels]
                        })


                        dispacth({
                            type: itemType,
                            payload: project,
                        })
                        dispacth({
                            type: customerListType,
                            payload: response.data.data2.customers,
                        })
                        dispacth({
                            type: employeeListType,
                            payload: response.data.data2.users,
                        })

                        dispacth({
                            type: departmentType,
                            payload: response.data.data2.departments,
                        })

                        dispacth({
                            type: projectGroupType,
                            payload: response.data.data2.projectGroups,
                        })
                    }
                })
                .catch(reason => {
                    openNotification('error', trans.errorTitle, trans.errorBody, 3)
                    dispacth(loading())


                })
        } catch (error) {
            openNotification('error', trans.errorTitle, trans.errorBody, 3)
            dispacth(loading())
        }
    }
}
export const fetchShareProject = (projectId, trans) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/projects/`+projectId
    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'GET',
                url,
                headers
            })
                .then(response => {
                    dispacth(loading())
                    if (response.data.success) {
                        var project = response.data.data;
                        dispacth({
                            type: itemType,
                            payload: project,
                        })
                    }
                })
                .catch(reason => {
                    openNotification('error', trans.errorTitle, trans.errorBody, 3)
                    dispacth(loading())


                })
        } catch (error) {
            openNotification('error', trans.errorTitle, trans.errorBody, 3)
            dispacth(loading())
        }
    }
}