import axios from 'axios'
import { axiosConfig, baseUrl } from '../../config'
import {
    pageCountType, performanceScoreType, performanceCriterionType, addPerformanceCriterionType, updatePerformanceCriterionType,
    performanceNumbersType, updatePerformanceScoreType, deletePerformanceCriterionType, isLoginType
} from '../action_types'
import { success, calculatePageNumber, loading } from './loader_ac'
import { crud, dateFormatter } from '../../config/constants'

//++
export const fetchEmployeesScore = (request = { rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}) => {
    request.rangeDate = dateFormatter(request.rangeDate)
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/users`
    if (localStorage.getItem("token"))
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    return dispacth => {
        try {
            if (request.loader)
                dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            }).then(response => {
                if (request.loader)
                    dispacth(loading())
                if (response.data.success) {
                    switch (requestType) {
                        case crud.userAndCriterions:
                            
                            dispacth({
                                type: performanceScoreType,
                                payload: response.data.data
                            })
                            dispacth({
                                type: performanceNumbersType,
                                payload: response.data.data2
                            })
                            dispacth(success(true))
                            dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) })
                            break;
                        default:
                            break;
                    }
                }
            }).catch(reason => {
                
                dispacth(loading())

                
            })
        } catch (error) {
            console.log(error)
            dispacth(loading())
        }
    }
}

//++
export const fetchEmployePerformanceCriterion = (request = { rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}) => {
    
    request.rangeDate = dateFormatter(request.rangeDate)
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/performanceCriterions`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }

    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            }).then(response => {
                dispacth(loading())
                if (response.data.success) {
                    switch (requestType) {
                        case crud.list:
                            dispacth({
                                type: performanceCriterionType,
                                payload: { list: response.data.data, user: response.data.data2 }
                            })
                            console.log(response);
                            dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) })
                            dispacth(success(true))
                            break;
                        case crud.create:
                            dispacth({
                                type: addPerformanceCriterionType,
                                payload: { item: response.data.data, totalQuestionPoint: response.data.data2 }
                            })
                            console.log(response);
                            dispacth(success(true))
                            break;
                        case crud.update:
                            dispacth({
                                type: updatePerformanceCriterionType,
                                payload: { item: response.data.data, totalQuestionPoint: response.data.data2 }
                            })
                            console.log(response);
                            dispacth(success(true))
                            break;
                        case crud.delete:
                            // dispacth({
                            //     type: deletePerformanceCriterionType,
                            //     payload: response.data.data2
                            // })
                            console.log(response);
                        default:
                            break;
                    }
                }
                else
                    dispacth(success(false))

                if (request.loader)
                    dispacth(loading())
            }).catch(reason => {
                
                if (request.loader)
                    dispacth(loading())
                
            })
        } catch (error) {
            console.log(error)
            if (request.loader)
                dispacth(loading())
        }
    }
}

export const fetchEmployePerformanceScore = (request = { rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/performanceScores`
    if (localStorage.getItem("token"))
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }


    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            }).then(response => {
                dispacth(loading())
                if (response.data.success) {
                    switch (requestType) {
                        case crud.update:
                            dispacth({
                                type: updatePerformanceScoreType,
                                payload: { ...response.data.data, ...response.data.data2 }
                            })
                            dispacth(success(true))
                            break;
                        default:
                            break;
                    }
                }
                if (request.loader)
                    dispacth(loading())
            }).catch(reason => {
                
                if (request.loader)
                    dispacth(loading())
                
            })
        } catch (error) {
            console.log(error)
            if (request.loader)
                dispacth(loading())
        }
    }
}