import React from 'react'
import { useSelector } from 'react-redux'
import BreadCrumb from '../advanced/BreadCrumb';

export default function Content(props) {
    const { children } = props
    const size = useSelector(state => state.size)
    const { breadcrumbs } = useSelector(state => state.current)
    return (
        <div className={size.isOpenLeftMenu ? "content content_left_width" : "content"}>
            {breadcrumbs.length > 0 && <BreadCrumb breadcrumbs={breadcrumbs} />}
            {children}
        </div>
    )
}
