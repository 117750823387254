import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { fetchProjectDetail, fetchShareProject } from '../../redux/actions/project_ac';
import { crud, ProjectStatus } from '../../config/constants';
import { baseUrl } from '../../config';
export default function ShareProject(props) {
    const { match: { params } } = props;
    const trans = useSelector(state => state.trans)
    const history = useHistory();
    const current = useSelector(state => state.current)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchShareProject(params.id, trans))
    }, [])
    return (
        <main className="shared_project_container">
            <section className="shared_project_detail_container">
                {current.item.customerPhoto &&
                    <img
                        src={baseUrl + current.item.customerPhoto}
                        id="projectDetailProfile" alt="" />}
                <div className="mb-10 mt-10" style={{ display: 'flex', flexDirection: 'column',alignItems:'center' }}>
                    <h3>{current.item.name} </h3>
                    <span>{current.item.customerName}</span><br/>
                    {current.item &&
                        current.item.status !== undefined &&
                        <span style={{ backgroundColor: ProjectStatus[current.item.status].color,padding:".25rem .5rem", borderRadius:".15rem" }}>
                            {ProjectStatus[current.item.status][trans.lang]}
                        </span>}
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span><b>{trans.code + " : "}</b> {current.item.code}</span>
                    <span><b>{trans.createDate + " : "}</b> {current.item.createDateViewString}</span>
                    <span><b>{trans.lastupdateddate + " : "}</b>
                        {current.item.modifyDateViewString}</span>
                </div>
            </section>
            <section className="shared_project_detail_container"></section>
            <section className="shared_project_detail_container"></section>
            <section className="shared_project_detail_container"></section>
        </main>
    )
}
