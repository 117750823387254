import React, { useEffect, useState } from "react";
import Layout from "../../components/Container/Layout";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/anBrains/Pagination";
import Input from "../../components/anBrains/Input";
import Modal from "../../components/anBrains/Modal";
import Button from "../../components/anBrains/Button";
import { SortIcon, Search2Icon, TrushIcon, EditIcon, PlusIcon } from "../../components/icons";
import { success } from "../../redux/actions/loader_ac";
import { crud } from "../../config/constants";
import {
  employeePerformanceType,
  performanceCriterionType,
  performanceDateType,
  updateQuestionType,
  updateScoreType,
  deletePerformanceCriterionType,
} from "../../redux/action_types";
import InputRange from "react-input-range";
import man from "../../images/profiles/man.png";
import woman from "../../images/profiles/woman.png";
import "react-input-range/lib/css/index.css";
import { fetchEmployePerformanceCriterion, fetchEmployePerformanceScore } from "../../redux/actions/employee_performance_ac";
import { baseUrl } from "../../config";
import manprofile from "../../images/profiles/man.png";
import womanprofile from "../../images/profiles/woman.png";
import { openNotification } from "../../config";

export default function EmployeeScoreDetail(props) {
  const {
    match: { params },
  } = props;
  const dispatch = useDispatch();
  const trans = useSelector((state) => state.trans);
  const theme = useSelector((state) => state.theme);
  const current = useSelector((state) => state.current);
  const result = useSelector((state) => state.result);
  const performancemanagement = useSelector((state) => state.performancemanagement);
  const [searchText, setSearchText] = useState("");
  const [selectedPage, setSelectedPage] = useState(1);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditCriterion, setIsEditCriterion] = useState(false);
  const [isDeleteCriterion, setIsDeleteCriterion] = useState(false);
  const [request, setRequest] = useState({ rowCount: 14, pageCount: 0, search: "", sort: "id", desc: false, data: {} });
  useEffect(() => {
    var month = parseInt(performancemanagement.date.substr(5, 2)) - 1;
    dispatch(
      fetchEmployePerformanceCriterion(
        { ...request, userId: parseInt(params.id), rangeDate: new Date(performancemanagement.date.substr(0, 4), month) },
        crud.list
      )
    );
  }, [performancemanagement.date]);

  useEffect(() => {
    if (result.success === true) {
      setIsEditCriterion(false);
      //dispatch(success(false))
    }
  }, [result.success]);

  var err = {
    errName: "",
    errPoint: "",
  };
  const [error, setError] = useState(err);

  const searchChange = (text) => {
    setSearchText(text);
    if (text.length > 2) {
      dispatch(fetchEmployePerformanceCriterion({ ...request, userId: params.id, search: text }, crud.list, {}));
    }
  };

  const searchChangeKeyPress = (text, kcode) => {
    if (kcode === 8) {
      dispatch(fetchEmployePerformanceCriterion({ ...request, userId: params.id, search: text }, crud.list, {}));
    }
  };

  const [desc, setDesc] = useState(false);
  const sortbyField = (f) => {
    setRequest({ ...request, sort: f, desc });
    dispatch(fetchEmployePerformanceCriterion({ ...request, userId: parseInt(params.id), sort: f, desc }, crud.list, {}));
    setDesc(!desc);
  };

  const setSelectedPageFilter = (n) => {
    setSelectedPage(n);
    setRequest({ ...request, pageCount: n - 1 });
    dispatch(fetchEmployePerformanceCriterion({ ...request, userId: parseInt(params.id), pageCount: n - 1 }, crud.list));
  };

  const editScore = (id, v) => {
    var setScore = getScrumFromPoint(v);
    performancemanagement.list.map((x) => (x.isCompleted = false));
    performancemanagement.list.find((x) => x.id === id).setScore = setScore;
    performancemanagement.list.find((x) => x.id === id).isCompleted = true;
    dispatch({ type: performanceCriterionType, payload: performancemanagement.list });
  };

  const updateScore = (e) => {
    var item = performancemanagement.list.find((x) => x.isCompleted === true);
    performancemanagement.list.map((x) => (x.isCompleted = false));
    dispatch(
      fetchEmployePerformanceScore({}, crud.update, { userId: params.id, criterionId: item.id, rangeDate: performancemanagement.date, score: item.setScore })
    );
  };

  const [message, setMessage] = useState("");
  let validate = () => {
    if (performancemanagement.question.name === undefined || performancemanagement.question.name.length < 3) {
      setError({ ...error, errName: trans.least3 });
      setMessage(trans.least3);
      return false;
    }
    if (!performancemanagement.question.name === undefined && performancemanagement.question.name.length > 30) {
      setError({ ...error, errName: trans.toShort });
      setMessage(trans.toShort);
      return false;
    }
    if (performancemanagement.question.score === undefined || performancemanagement.question.score === 0) {
      setError({ ...error, errPoint: trans.pleaseselectcriteriapoint });
      setMessage(trans.pleaseselectcriteriapoint);
      return false;
    }
    return true;
  };

  const register = (e) => {
    e.preventDefault();
    if (validate()) {
      setError(err);
      if (isEdit) {
        setIsEditCriterion(false);
        dispatch(fetchEmployePerformanceCriterion({ ...request, userId: params.id }, crud.update, performancemanagement.question));
      } else {
        setIsEditCriterion(false);
        dispatch(fetchEmployePerformanceCriterion({ ...request, userId: params.id }, crud.create, performancemanagement.question));
      }

      if (result.success) openNotification("success", trans.successTitle, trans.successBody, 3);
      else openNotification("error", trans.errorTitle, trans.errorBody, 3);
    }
  };

  const editCriterion = (e) => {
    dispatch({ type: updateQuestionType, payload: e });
    setIsEditCriterion(true);
    setIsEdit(true);
  };

  const openForm = () => {
    dispatch({ type: updateQuestionType, payload: {} });
    setIsEditCriterion(true);
    setIsEdit(false);
  };

  const deleteCriterion = (e) => {
    setIsDeleteCriterion(true);
    performancemanagement.list.map((x) => (x.deleted = false));
    performancemanagement.list.find((x) => x.id === e.id).deleted = true;
  };

  const deleteCriterionItem = () => {
    setIsDeleteCriterion(false);
    dispatch(fetchEmployePerformanceCriterion({ ...request, id: performancemanagement.list.find((x) => x.deleted === true).id }, crud.delete, {}));
    var criterionList = performancemanagement.list.filter((e, i) => {
      return e.id !== performancemanagement.list.find((x) => x.deleted === true).id;
    });
    dispatch({ type: deletePerformanceCriterionType, payload: criterionList });
  };

  const getPointFromScrum = (p) => {
    switch (p) {
      case 21:
        return 7;
      case 13:
        return 6;
      case 8:
        return 5;
      case 5:
        return 4;
      case 3:
        return 3;
      case 2:
        return 2;
      case 1:
        return 1;
      case 0:
        return 0;
      default:
        return 0;
    }
  };

  const getScrumFromPoint = (p) => {
    switch (p) {
      case 7:
        return 21;
      case 6:
        return 13;
      case 5:
        return 8;
      case 4:
        return 5;
      case 3:
        return 3;
      case 2:
        return 2;
      case 1:
        return 1;
      case 0:
        return 0;
      default:
        return 0;
    }
  };
  return (
    <Layout>
      <Modal isOpen={isEditCriterion} withClose setIsOpen={setIsEditCriterion} classes={"modal_add_scoring"}>
        <div className="employeePerformanceForm">
          <Input
            placeholder={trans.criteriaQuestion}
            name={trans.criteriaQuestion}
            header={trans.criteriaQuestion}
            value={performancemanagement.question.name}
            onChange={(e) => dispatch({ type: updateQuestionType, payload: { ...performancemanagement.question, name: e.target.value } })}
            error={err.errName}
            maxLength={30}
            minLength={3}
          />
          <div className="input-container">
            <span className="input_header">{trans.point}</span>
            <select
              className={`input select ${err.errPoint ? "input-error" : ""}`}
              onChange={(e) => dispatch({ type: updateQuestionType, payload: { ...performancemanagement.question, score: e.target.value } })}
              value={performancemanagement.question.score}
            >
              <option value="0">{trans.select}</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="5">5</option>
              <option value="8">8</option>
              <option value="13">13</option>
              <option value="21">21</option>
            </select>
          </div>
          {message && <span className="errorspan">{trans.error + ": " + message}</span>}
          <Button classes={"btn btn-primary"} onClick={register}>
            {trans.save}
          </Button>
        </div>
      </Modal>
      <Modal isOpen={isDeleteCriterion} withClose setIsOpen={setIsDeleteCriterion} classes={"modal_delete"}>
        {trans.areyousureyouwanttodelete}
        <Button classes="btn-red" onClick={() => deleteCriterionItem()}>
          {trans.confirm}
        </Button>
      </Modal>
      <div className="table_properties">
        <input type="month" onChange={(e) => dispatch({ type: performanceDateType, payload: e.target.value })} value={performancemanagement.date} />
        {/* <Input
                    icon={<Search2Icon
                        color={(theme === "dark" ? "#3e9fbe" : "#1a9bc4")} />}
                    placeholder={trans.search}
                    value={searchText}
                    onChange={e => searchChange(e.target.value)}
                    onKeyUp={e => searchChangeKeyPress(e.target.value, e.keyCode)} /> */}
        <Button icon={<PlusIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />} classes="btn-basic" onClick={() => openForm()}>
          {trans.add}
        </Button>
      </div>
      <div className="project_container">
        <div className="project_group">
          {performancemanagement && performancemanagement.user && (
            <div className="employe_score_container">
              <div className="employe_info">
                {performancemanagement.user.photo && performancemanagement.user.photo.length > 5 ? (
                  <img
                    src={
                      performancemanagement.user.photo
                        ? baseUrl + performancemanagement.user.photo
                        : performancemanagement.user && performancemanagement.user.gender
                        ? manprofile
                        : womanprofile
                    }
                    alt=""
                    className="personal_score_img"
                  />
                ) : performancemanagement.user.gender ? (
                  <img src={man} alt="" className="personal_score_img" />
                ) : (
                  <img src={woman} alt="" className="personal_score_img" />
                )}
              </div>
              <div className="sub_info">
                <h3>{performancemanagement.user.name}</h3>
                {performancemanagement.user.roleDtos &&
                  performancemanagement.user.roleDtos.length > 0 &&
                  performancemanagement.user.roleDtos.map((r, j) => {
                    return (
                      <span key={j} className="personal_task d-inline-block">
                        {r.name}
                      </span>
                    );
                  })}
                {/* <div className="points">
                                            <span>Toplam Puan</span>
                                            <span className="score">{e.totalScore}</span>
                                        </div> */}
                <div className="points">
                  <span>{trans.totalQuestionPoint}</span>
                  <span className="score">{performancemanagement.user.questionScore}</span>
                </div>
                <div className="points">
                  <span>{trans.youGivedPersonalTotalPoint}</span>
                  <span className="score">{performancemanagement.user.totalPersonalPoint}</span>
                </div>

                <div className="points">
                  <span>{trans.avgPersonalTotalPoint}</span>
                  <span className="score">{performancemanagement.user.totalPersonalAvgPoint}</span>
                </div>
                <div className="points">
                  <span>{trans.jobscore}</span>
                  <span className="score">{performancemanagement.user.totalWorkOrderPoint}</span>
                </div>
                <div className="points">
                  <span>{trans.numberofsuggestion}</span>
                  <span className="score sugges">{performancemanagement.user.totalSuggestionCount}</span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="project_view">
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th onClick={() => sortbyField("name")}>
                    {trans.question} <SortIcon color="#5d5d5d" />
                  </th>
                  <th onClick={() => sortbyField("score")}>
                    {trans.questionseverity} <SortIcon color="#5d5d5d" />
                  </th>
                  <th>{trans.settings}</th>
                  <th style={{ textAlign: "center" }}>{trans.scoring}</th>
                  <th style={{ textAlign: "center" }}>{trans.average}</th>
                </tr>
              </thead>
              <tbody>
                {performancemanagement &&
                  performancemanagement.list &&
                  performancemanagement.list.length > 0 &&
                  performancemanagement.list.map((e, i) => {
                    return (
                      <tr className="row" key={i}>
                        <td className="h-4rem">{e.name}</td>
                        <td className="h-4rem">
                          {e.score > 0 ? <span className={"priority d-inline-block " + "bg-color-" + e.score}>{e.score}</span> : <span>{e.score}</span>}
                        </td>
                        <td>
                          <button onClick={() => editCriterion(e)}>
                            <EditIcon color={theme === "dark" ? "#e5e5e5" : "#000"} />
                          </button>
                          <button onClick={() => deleteCriterion(e)}>
                            <TrushIcon color={theme === "dark" ? "#e5e5e5" : "#000"} />
                          </button>
                        </td>
                        <td className="h-4rem pr-5">
                          <div className="edit-range">
                            <div className="range-container">
                              <InputRange
                                id={"range" + i}
                                maxValue={7}
                                minValue={0}
                                value={getPointFromScrum(e.setScore)}
                                formatLabel={() => ""}
                                onChange={(v) => editScore(e.id, v)}
                                onChangeComplete={updateScore}
                              />
                              <div className="range-footer">
                                <span>0</span>
                                <span>1</span>
                                <span>2</span>
                                <span>3</span>
                                <span>5</span>
                                <span>8</span>
                                <span>13</span>
                                <span>21</span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {e.avgScore > 0 && <span className={"priority d-inline-block " + "bg-color-3"}>{e.avgScore}</span>}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          {current && current.pageCount > 0 && <Pagination setSelectedPage={setSelectedPageFilter} selectedPage={selectedPage} />}
        </div>
      </div>
    </Layout>
  );
}
