import React, { useRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchNotification } from '../../redux/actions/notification_ac'
import { breadcrumbType, addBreadcrumbType, notificationType, notificationCountType } from '../../redux/action_types';
import { crud, getNotifyColorByType, getNotifyNameByType, getNotifyStatus } from '../../config/constants'
import { baseUrl } from '../../config';
import manprofile from '../../images/profiles/man.png';
import womanprofile from '../../images/profiles/woman.png';
export default function NotificationContainer(props) {
    const wrapperRef = useRef(null)
    const { breadcrumbs } = useSelector(state => state.current)
    const dispatch = useDispatch()
    const trans = useSelector(state => state.trans)
    const notifications = useSelector(state => state.notification)
    const handleClickOutside = () => {
        props.setIsOpenNofity(false)
    }
    const history = useHistory();
    const seeAllList = () => {
        if (breadcrumbs && breadcrumbs.some(b => b.name === "Tüm Bildirimler")) { }
        if (breadcrumbs.length > 1) {
            var newBreadCrumbs = [];
            // newBreadCrumbs.push(breadcrumbs[0]);
            newBreadCrumbs.push({ name: "Tüm Bildirimler", path: "/allnotifications" });
            dispatch({
                type: breadcrumbType,
                payload: newBreadCrumbs
            })
        }
        else {
            dispatch({
                type: addBreadcrumbType,
                payload: { name: "Tüm Bildirimler", path: "/allnotifications" }
            })
        }
        history.push("/allnotifications");
    }

    const clickNotify = (notify) => {
        dispatch(fetchNotification({ notificationId: notify.id }, crud.viewed, {}))
        history.push(notify.link);
    }
    const clearNotify = () => {
        dispatch({ type: notificationType, payload: [] })
        dispatch({ type: notificationCountType, payload: "" })
        props.setIsOpenNofity(false)
        dispatch(fetchNotification({ isAll: true }, crud.viewed, {}))
    }

    useOutsideAlerter(wrapperRef, handleClickOutside)
    return (
        <>
            {/* <Modal
                isOpen={isAddSuggestionOpen}
                withClose
                setIsOpen={setIsAddSuggestionOpen}
                classes={"modal_all_notifications"}>
                <div className="table">
                    <table className="tbl-table" style={{ width: "500px" }}>
                        <thead>
                            <tr>
                                <th style={{ textAlign: "center" }} >Bildirim</th>
                                <th style={{ textAlign: "center" }} >Tarihi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {notification && notification.allList && notification.allList.length > 0 && notification.allList.map((e, i) => {
                                return (
                                    <tr className="row" key={i}>
                                        <td>{e.message}</td>
                                        <td>{e.messageDate}</td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div>
            </Modal> */}
            <div className="notification_container" ref={wrapperRef}>
                <h3>{trans.notifications}</h3>
                <span className="notification_clear" onClick={e => clearNotify()}>{trans.clear}</span>
                <ul>
                    {notifications && notifications.list && notifications.list.length > 0 && notifications.list.map((notify, i) => {
                        return (
                            <li key={i} onClick={e => clickNotify(notify)}>
                                <img src={(notify.user && notify.user.photo) ? baseUrl + notify.user.photo : (notify.user && notify.user.gender) ? manprofile : womanprofile} />
                                <div className="notification_information">
                                    <span className="title">{notify.title} <i style={{ color: "#888" }}>
                                        {getNotifyStatus(notify.status, trans.lang)}</i> </span>
                                    <span className="time">{notify.date}</span>
                                </div>
                                <span
                                    className="type"
                                    style={{ backgroundColor: getNotifyColorByType(notify.type) }}>
                                    {getNotifyNameByType(notify.type, trans.lang)}
                                </span>
                            </li>
                        )
                    })}
                </ul>
                <span onClick={seeAllList} className="seeall">{trans.seeallnotifications}</span>
            </div>
        </>
    )
}
function useOutsideAlerter(ref, onClick) {
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            onClick()
        }
    }

    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    })
}