import { meetingType, addMeetingType, updateMeetingType } from '../action_types'
const reducer = (state = { list: [], totalhour: 0 }, action) => {
    switch (action.type) {
        case meetingType:
            return { ...state, list: action.payload };
        case addMeetingType:
            return { ...state, list: [...state.list, action.payload] };
        case updateMeetingType:
            //
            var meetingGroups = [];
            state.list.forEach(p => {
                if (p.id === action.payload.id)
                meetingGroups.push(action.payload);
                else meetingGroups.push(p);
            });
            return { ...state, list: meetingGroups };
        default:
            return state;
    }
};
export default reducer;
