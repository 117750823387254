import { meetingGroupType, addMeetingGroupType, updateMeetingGroupType } from '../action_types'
const reducer = (state = { list: [], currentList: [], totalhour: 0 }, action) => {
    switch (action.type) {
        case meetingGroupType:
            return { ...state, list: action.payload, currentList: action.payload };
        case addMeetingGroupType:
            return { ...state, list: [...state.list, action.payload] };
        case updateMeetingGroupType:
            var meetingGroups = [];
            state.list.forEach(p => {
                if (p.id === action.payload.id)
                    meetingGroups.push(action.payload);
                else meetingGroups.push(p);
            });
            return { ...state, list: meetingGroups };
        default:
            return state;
    }
};
export default reducer;