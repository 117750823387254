import axios from 'axios'
import { axiosConfig, baseUrl } from '../../config'
import { pageCountType, addInOutType, inOutType, avgInOutType, updateInOutType,isLoginType } from '../action_types'
import { success, calculatePageNumber } from './loader_ac'
import { crud } from '../../config/constants'
export const fetchInOuts = (request = { rowCount: 14, pageCount: 0, sort: "id", desc: true },
    requestType = 5, data = {}) => {
    let headers = axiosConfig.headers

    let url = `${baseUrl}/api/inouts`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    return dispacth => {
        try {
            // dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            })
                .then(response => {
                    if (response.data.success) {
                        switch (requestType) {
                            case crud.create:
                                dispacth({
                                    type: addInOutType,
                                    payload: response.data.data,
                                })
                                dispacth(success(true))
                                dispacth({ type: avgInOutType, payload: response.data.data2 })
                                break;
                            case crud.read:
                                break;
                            case crud.update:
                                dispacth({
                                    type: updateInOutType,
                                    payload: response.data.data,
                                })
                                dispacth(success(true))
                                dispacth({ type: avgInOutType, payload: response.data.data2 })
                                break;
                            case crud.delete:
                                dispacth({ type: avgInOutType, payload: response.data.data2 })
                                break;
                            case crud.list:
                                dispacth({
                                    type: inOutType,
                                    payload: response.data.data,
                                })
                                dispacth({ type: avgInOutType, payload: response.data.data2 })
                                dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) })
                                break;
                            default:
                                break;
                        }
                    }
                })
                .catch(reason => {
                    
                  
                })
        } catch (error) {
            console.log(error)
            // dispacth(loading())
        }
    }
}

