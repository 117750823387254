import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { transType } from '../../redux/action_types'
import ImagePicker from './ImagePicker'
export default function BackgroundUploader(props) {
	const { text } = props
	const [err, setErr] = useState(null)
	const trans = useSelector(state => state.trans)
	const loadedFile = data => {
		props.loadedFile(data);
		setErr(null)
	}

	const errMsg = (msg) => {
		setErr(msg)
	}

	useEffect(() => {

	}, [])

	return (
		<>
			<div className="background_image_uploader">
				{/* <img src={(profileImage === null || profileImage === undefined || profileImage.length < 5) ? profile : profileImage} alt="" className="profile_list_img" /> */}
				<ImagePicker
					id="backgroundImage"
					// accept={".jpg,.jpeg,.png,.gif"}
					accept={"image/*"}
					changeCurrentAvatar={loadedFile}
					errMsg={errMsg}
					fileType="file"
					text={text}
					justCamera
				/>

			</div>
			{/* {(err || props.err) &&
				<div className="max_size">{err || props.err}
				</div>} */}
		</>
	)
}
