import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { crud } from '../../config/constants';
import Button from '../anBrains/Button';
import Input from '../anBrains/Input';
import { PlusIcon, SortIcon, TrushIcon, DocumentIcon } from '../icons';
import Modal from '../anBrains/Modal';
import { fileType } from '../../redux/action_types';
import { baseUrl } from '../../config';
import { fetchFiles, fetchFilesForm } from '../../redux/actions/file_ac';
import FileUploader from '../anBrains/FileUploader';
import { Pagination } from 'antd';

export default function File(props) {
    const { projectId, fileFromType, workOrderId, customerId } = props;
    const dispatch = useDispatch();
    const trans = useSelector(state => state.trans)
    const theme = useSelector(state => state.theme)
    const current = useSelector(state => state.current)
    const file = useSelector(state => state.file)
    const [isAddOpen, setIsAddOpen] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [item, setItem] = useState({})
    const [request, setRequest] = useState({ projectId, workOrderId, customerId, fileFromType, rowCount: 14, pageCount: 0, search: "", sort: "id", "desc": true, data: {} })
    const loader = useSelector(state => state.loader)

    useEffect(() => {
         dispatch(fetchFiles(request, crud.list, {}, trans));
    }, [])

    const fields = {
        projectId: parseInt(projectId),
        workOrderId: parseInt(workOrderId),
        fileName: "",
        file: null,
    }
    const [field, setField] = useState(fields)

    const errors = {
        fileNameErr: "",
        fileErr: ""
    }
    const [err, setErr] = useState(errors)

    const [message, setMessage] = useState("");
    let validate = () => {
        if (field.fileName.length < 3 || field.fileName.length > 50) {
            setErr({ ...errors, fileNameErr: trans.toShort })
            setMessage(trans.toShort)
            return false
        }
        if (field.file === null || field.file === undefined) {
            setErr({ ...errors, fileErr: trans.pleaseselectafile })
            setMessage(trans.toShort)
            return false
        }
        return true
    }

    const register = e => {
        e.preventDefault();
        if (validate()) {
            field.path = "";
            formdata.append("data", JSON.stringify(field))
            formdata.append("requestType", crud.create)
            formdata.append("request", "{}")
            setErr(errors)
            dispatch(fetchFilesForm(formdata, fileFromType, trans))
            setIsAddOpen(false);
        }
    }

    const deleteConfirm = (e) => {
        setIsDeleteOpen(true)
        setItem(e);
    }

    const deleteItem = () => {
        setIsDeleteOpen(false)
        dispatch(fetchFiles({ ...request, id: item.id }, crud.delete, {}, trans))
        let files = file.filter((e) => {
            return e.id !== item.id
        });
        dispatch({ type: fileType, payload: files })
    }

    const [selectedPage, setSelectedPage] = useState(1)
    const setSelectedPageFilter = (n) => {
        setSelectedPage(n)
        setRequest({ ...request, pageCount: n - 1 })
        dispatch(fetchFiles({ ...request, pageCount: n - 1 }, crud.list, {}, trans));
    }

    const [desc, setDesc] = useState(false)
    const sortbyField = (f) => {
        setRequest({ ...request, sort: f, desc })
        dispatch(fetchFiles({ ...request, sort: f, desc }, crud.list, {}, trans));
        setDesc(!desc)
    }

    const [formdata, setFormdata] = useState(new FormData())
    const loadedFile = data => {
        setField({ ...field, file: true })
        setFormdata(data);
    }

    const openForm = () => {
        setField(fields);
        setIsAddOpen(true);
    }

    return (
        <div className="event_container">
            <Modal
                isOpen={isAddOpen}
                withClose
                setIsOpen={setIsAddOpen}
                classes={"modal_add_leave"}>
                <div className="leaveForm">
                    <FileUploader text="asdf" loadedFile={loadedFile} err={err.file}  />
                    <Input placeholder={trans.fileName}
                        name={trans.fileName}
                        header={trans.fileName}
                        value={field.fileName}
                        onChange={e => setField({ ...field, fileName: e.target.value })}
                        error={err.fileNameErr}
                        maxLength={50}
                        minLength={3} />
                    <Button classes={"btn-primary"}
                        onClick={register}>{trans.add}</Button>
                    {message && <span className="errorspan">{trans.error + ": " + message}</span>}
                </div>
            </Modal>
            <Modal
                isOpen={isDeleteOpen}
                withClose
                setIsOpen={setIsDeleteOpen}
                classes={"modal_delete"}>
                {trans.areyousureyouwanttodelete}
                <Button classes="btn-red" onClick={() => deleteItem()}>{trans.confirm}</Button>
            </Modal>
            <div className="table_properties">
                <Button
                    icon={<PlusIcon color="rgba(96, 168, 221, 1)" />}
                    classes="btn-basic"
                    onClick={() => openForm(true)}>{trans.add}
                </Button>
            </div>
            <div className="event_list">
                {!loader.loading &&
                    <div className="table">
                        <table>
                            <thead>
                                <tr>
                                    <th onClick={() => sortbyField("fileName")}>
                                        {trans.fileName}
                                        <SortIcon color="rgba(96, 168, 221, 1)" />
                                    </th>
                                    <th>{trans.file}</th>
                                    <th>{trans.settings}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {file.length > 0 && file.map((e, i) => {
                                    return (
                                        <tr className="row" key={i}>
                                            <td>{e.fileName}</td>
                                            <td>
                                                <a target="_blank" href={baseUrl + e.path} key={i}>
                                                    <DocumentIcon color={(theme === "dark" ? "#e5e5e5" : "#000")} />
                                                </a>
                                            </td>
                                            <td>
                                                <button onClick={() => deleteConfirm(e)}>
                                                    <TrushIcon color={(theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)")} />
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>}
            </div>
            {current && current.pageCount > 0 &&
                                <Pagination defaultCurrent={selectedPage}
                                    onChange={setSelectedPageFilter}
                                    onShowSizeChange={(pageCount, rowCount) => {
                                        dispatch(fetchFiles({ ...request, pageCount, rowCount }, crud.list, {}, trans));
                                        setRequest({ ...request, pageCount, rowCount })
                                    }}
                                    total={current.pageCount * request.rowCount} />}
        </div>
    )
}
