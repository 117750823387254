import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isDarkType, isLightType, anyNotification } from '../../redux/action_types';
import manprofile from '../../images/profiles/man.png';
import womanprofile from '../../images/profiles/woman.png';
import { BellIcon, BellNotificationIcon } from '../icons';
import NotificationContainer from '../advanced/NotificationContainer';
import Button from '../anBrains/Button'
import ProfileMenu from '../advanced/ProfileMenu';
import { baseUrl } from '../../config';
import { crud } from '../../config/constants'
import { fetchNotification } from '../../redux/actions/notification_ac';
export default function Header({ isPano }) {
    const trans = useSelector(state => state.trans)
    const current = useSelector(state => state.current)
    const theme = useSelector(state => state.theme)
    const user = useSelector(state => state.user)
    const size = useSelector(state => state.size)
    const notification = useSelector(state => state.notification)
    const [isOpenNofity, setIsOpenNofity] = useState(false)
    const [isOpenProfileMenu, setIsOpenProfileMenu] = useState(false)
    const dispatch = useDispatch();
    const [request, setRequest] = useState({ rowCount: 14, pageCount: 0, search: "", sort: "id", "desc": false, data: {} })

    useEffect(() => {
        dispatch(fetchNotification({}, crud.count, {}))
    }, [])


    const changeTheme = () => {
        if (theme === "light") {
            dispatch({ type: isDarkType })
            animate();
            localStorage.setItem("theme", "dark");
        }
        else {
            dispatch({ type: isLightType })
            animate();
            localStorage.setItem("theme", "light");
        }
    }

    const animate = () => {
        document.documentElement.classList.add("transition")
        setTimeout(() => {
            document.documentElement.classList.remove("transition")
        }, 1000);
    }

    const checkBell = () => {
        dispatch(fetchNotification({ rowCount: 3, isView: false }, crud.list, {}, trans))
        setIsOpenNofity(!isOpenNofity)
    }
    const changeLang = () => {
        var lng = trans.lang;
        dispatch({ type: (lng === "tr" ? "en" : "tr") })
        localStorage.setItem("lang", (lng === "tr" ? "en" : "tr"))
    }

    return (
        <div className={size.isOpenLeftMenu ? "header header_left_margin" : "header"}
            style={isPano ? { backgroundColor: "unset" } : {}}>
            {isOpenNofity && <NotificationContainer setIsOpenNofity={setIsOpenNofity} />}
            <div className="header_left">
                <h2>{current.title}</h2>
            </div>
            <div className="header_right">
                <Button
                    onClick={() => checkBell()}
                >
                    <BellIcon color={notification.count > 0 ? "rgba(232, 63, 64, 1)" : "rgba(96, 168, 221, 1)"} count={notification.count} />
                </Button>
                <div className="profile_right"
                    onClick={() => setIsOpenProfileMenu(!isOpenProfileMenu)}>
                    <div>
                        <h3>{user && user.name}</h3>
                        <span>{user && user.roles && user.roles[0]}</span>
                    </div>
                    <img src={(user && user.photo) ? baseUrl + user.photo : (user && user.gender) ? manprofile : womanprofile} alt="" />
                </div>
            </div>
            {isOpenProfileMenu &&
                <ProfileMenu
                    setIsOpenProfileMenu={setIsOpenProfileMenu}
                    theme={theme}
                    changeTheme={changeTheme}
                    changeLang={changeLang} />}
        </div>
    )
}
