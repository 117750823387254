import {
  task,
  diary,
  project,
  customer,
  meeting,
  suggestion,
  performans,
  report,
  definition,
  settings,
  employeediagram,
  support,
  companyculture,
  employee,
} from "../icons/proskop";
import { HomeIcon } from "../components/icons";

export const LIST_TYPES = {
  BOX: "box",
  LIST: "list",
};

export const CONTRACT_TYPES = {
  terms_of_use: "Terms Of Use",
  membership_agreement: "Membership Agreement",
  privacy_policy: "Privacy Policy",
  conditions: "Conditions",
};

export const tableType = {
  project: 0,
  department: 1,
  customer: 2,
};

export const workOrderStatus = {
  todo: 0,
  inProgress: 1,
  done: 2,
  canceled: 3,
};

export const schedulerType = {
  onlyAgenda: 0,
  workOrder_Agenda: 1,
  onlyProjectStep: 2,
  projectStep_Workorder: 3,
  humanResource: 4,
  meeting: 5,
};

export const notifyType = {
  agenda: 0,
  workOrder: 1,
  project: 2,
  suggestion: 3,
  meeting: 4,
};

export function getNotifyColorByType(type) {
  switch (type) {
    case notifyType.agenda:
      return "rgba(96, 168, 221, 1)";
    case notifyType.workOrder:
      return "rgba(247, 202, 49, 1)";
    case notifyType.project:
      return "#7231F5";
    case notifyType.meeting:
      return "#E62020";
    case notifyType.suggestion:
      return "#E62020";
    default:
      return "#E62020";
  }
}

export function getNotifyNameByType(type, lang) {
  return notifyTypeNames[type][lang];
}

export const notifyTypeNames = [
  { tr: "Ajanda", en: "Agenda" },
  { tr: "Görevler", en: "Tasks" },
  { tr: "Proje", en: "Projects" },
  { tr: "Öneri", en: "Suggestion" },
  { tr: "Toplantı", en: "Meeting" },
];

export function getNotifyStatus(status, lang) {
  return notifyStatusNames[status][lang];
}

export const notifyStatusNames = [
  { tr: "oluşturuldu.", en: "created." },
  { tr: "değiştirildi.", en: "updated." },
  { tr: "silindi.", en: "deleted." },
  { tr: "iptal Edildi.", en: "canceled." },
  { tr: "tamamlandı.", en: "done ." },
];

export const crud = {
  create: 1,
  read: 2,
  update: 3,
  delete: 4,
  list: 5,
  any: 7,
  change: 9,
  with: 10,
  end: 11,
  changeType: 12,
  userAndCriterions: 13,
  kanban: 14,
  changeDate: 15,
  count: 16,
  viewed: 17,
};

export const fileFromType = {
  project: 1,
  workorder: 2,
  customer: 3,
};

export const tableTypeObject = [
  { id: 0, tr: "Projeler", en: "Projects" },
  { id: 1, tr: "Departmanlar", en: "Departments" },
  { id: 2, tr: "Müşteriler", en: "Customers" },
];

export const getTableType = (lang, title) => {
  for (let index = 0; index < tableTypeObject.length; index++) {
    const element = tableTypeObject[index];
    if (element[lang] === title) {
      return element.id;
    }
  }
};

function pad(number) {
  if (number < 10) return "0" + number;
  return number;
}

export const dateFormatter = (date) => {
  if (date)
    return (
      date.getFullYear() +
      "-" +
      pad(date.getMonth() + 1) +
      "-" +
      pad(date.getDate()) +
      "T" +
      pad(date.getHours()) +
      ":" +
      pad(date.getMinutes()) +
      ":" +
      pad(date.getSeconds())
    );
};

export const onlyDateFormatter = (date) => {
  return date.getFullYear() + "-" + pad(date.getMonth() + 1) + "-" + pad(date.getDate());
};

export const ganttMode = ["Quarter Day", "Half Day", "Day", "Week", "Month"];

export const ProjectStatus = [
  { id: 0, en: "To Do", tr: "Yapılacak", color: "#3E9FBE" },
  // { id: 1, en: "Pending", tr: "Bekliyor", color: "#FFCD17" },
  { id: 1, en: "In Progress", tr: "Devam ediyor", color: "#3EBE60" },
  { id: 2, en: "Done", tr: "Tamamlandı", color: "#8B3EBE" },
  { id: 3, en: "Canceled", tr: "İptal edildi", color: "#D64343" },
];

export const Priorities = [
  { id: 0, en: "Low", tr: "Düşük", color: "#17b3a3", selected: false },
  { id: 1, en: "Medium", tr: "Orta", color: "#3e8ef7", selected: true },
  { id: 2, en: "High", tr: "Yüksek", color: "#eb6709", selected: false },
  { id: 3, en: "Very High", tr: "Çok Yüksek", color: "#ff4c52", selected: false },
];

export const left_menu_items = [
  { path: "/pano", tr: "Pano", en: "Dashboard", icon: HomeIcon, active: false, subOpen: false, subs: [] },
  { path: "/workorders", tr: "Görevler", en: "Tasks", icon: task, active: false, subOpen: false, subs: [] },
  { path: "/diary", tr: "Ajanda", en: "Agenda", icon: diary, active: false, subOpen: false, subs: [] },
  { path: "/projects", tr: "Projeler", en: "Projects", icon: project, active: false, subOpen: false, subs: [] },
  { path: "/customer-management", tr: "Paydaş Yönetimi", en: "Stakeholder Management", icon: customer, active: false, subOpen: false, subs: [] },
  { path: "/meeting-management", tr: "Toplantı Yönetimi", en: "Meeting Management", icon: meeting, active: false, subOpen: false, subs: [] },
  { path: "/company-culture", tr: "Takım Kültürü", en: "Team Culture", icon: companyculture, active: false, subOpen: false, subs: [] },
  { path: "/suggestion-box", tr: "Öneri Kutusu", en: "Suggestion Box", icon: suggestion, active: false, subOpen: false, subs: [] },
  // { path: "/personal-performance", tr: "Personel Performansı", en: "Personal Performance", icon: performans, active: false, subOpen: false, subs: [] },
  {
    path: "/personal-performance",
    tr: "Personel Performansı",
    en: "Personal Performance",
    icon: performans,
    active: false,
    subOpen: false,
    subs: [],
  },
  { path: "/personal-management", tr: "Personel Yönetimi", en: "Personal Management", icon: employee, active: false, subOpen: false, subs: [] },
  { path: "/report", tr: "Raporlar", en: "Reports", icon: report, active: false, subOpen: false, subs: [] },
  { path: "/report2", tr: "Raporlar - 2", en: "Reports - 2", icon: report, active: false, subOpen: false, subs: [] },
  {
    pathActive: false,
    path: "/definitions",
    tr: "Tanımlamalar",
    en: "Definitions",
    icon: definition,
    active: false,
    subOpen: false,
    subs: [
      {
        path: "/definitions/project-group",
        tr: "Proje Grup",
        en: "Project Group ",
        active: false,
      },
      // {
      // 	path: "/definitions/holiday",
      // 	en: "Vacation Days",
      // 	tr: "Tatil Günleri",
      // 	active: false
      // },
      {
        path: "/definitions/authorization",
        tr: "Yetkilendirme",
        en: "Authorization",
        active: false,
      },
      {
        path: "/definitions/user-file-type",
        tr: "Belge",
        en: "Document",
        active: false,
      },
      {
        path: "/definitions/department",
        tr: "Departman",
        en: "Department",
        active: false,
      },
    ],
  },
  {
    pathActive: false,
    path: "/settings",
    tr: "Ayarlar",
    en: "Settings",
    icon: settings,
    active: false,
    subOpen: false,
    subs: [
      {
        path: "/settings/company-setting",
        tr: "Firma Ayarları",
        en: "Company Setting",
        active: false,
      },
    ],
  },
  { path: "/personal-task", tr: "Personel İşleri", en: "Employee Tasks", icon: employeediagram, active: false, subOpen: false, subs: [] },
  {
    path: "/support",
    tr: "Destek",
    en: "Support",
    icon: support,
    active: false,
    subOpen: false,
    subs: [
      {
        path: "/support/whatsapp",
        tr: "Whatsapp İletişim",
        en: "Whatsapp Communication",
        active: false,
      },
      {
        path: "/support/guide",
        tr: "Kullanım Kılavuzu",
        en: "User Guide",
        active: false,
      },
      {
        path: "/support/youtube",
        tr: "Açıklama Videoları",
        en: "Exaplanation Videos",
        active: false,
      },
    ],
  },
];
export const customSelect2Styles = {
  option: (provided, state) => ({
    ...provided,
    border: "1px dotted pink",
    //   color: state.isSelected ? 'red' : 'blue',
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    //   width: 200,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};
const colors = [
  "#B09E99",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
  "#FEE9E1",
];
const setBg = () => {};
export const randomColor = () => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);
  document.body.style.backgroundColor = "#" + randomColor;
  return "#" + randomColor;
};

export const language = {
  English: 0,
  Turkish: 1,
};

export const responseTypes = {
  UserNotFound: 0,
  PleaseEntryRequestType: 1,
  PleaseFillInTheBlankFields: 2,
  WrongEmailOrPassword: 3,
  AccountExpired: 4,
  AlreadyInUse: 5,
  PleaseGiveUsertoAurhorization: 6,
  Success: 7,
};
