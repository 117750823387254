import React, { useState, useEffect } from 'react'
import ImagePicker from '../anBrains/ImagePicker'
import manprofile from '../../images/profiles/man.png';
import womanprofile from '../../images/profiles/woman.png';
import { useDispatch, useSelector } from 'react-redux';
import { itemType } from '../../redux/action_types'
import { baseUrl } from '../../config';
import firm from '../../images/firm.png'
export default function Avatar({ isCompany }) {

	const [err, setErr] = useState(null)
	const current = useSelector(state => state.current)
	const dispatch = useDispatch();
	const loadedAvatar = url => {
		current.item.photo = url;
		dispatch({ type: itemType, payload: current.item })
		setErr(null)
	}

	const errMsg = (msg) => setErr(msg)


	return (
		<>
			<div className="avatar">
				{(current.item && current.item.photo && current.item.photo.length > 5)
					? <img src={current.item.photo.includes("base64") ? current.item.photo : baseUrl + current.item.photo} alt="" className="profile_list_img" />
					: <img src={(isCompany === true ? firm : (current.item.gender) ? manprofile : womanprofile)} alt="" className="profile_list_img" />
				}
				<ImagePicker
					accept={".jpg,.jpeg,.png,.gif"}
					justIcon
					isAvatar
					changeCurrentAvatar={loadedAvatar}
					errMsg={errMsg}
					fileType="img"
					capture="camera" />
			</div>
			{err && <span className="avatar_max_size">{err}</span>}</>
	)
}
