import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Input from "../anBrains/Input";
import Button from "../anBrains/Button";
import ContentLoader from "react-content-loader";
import { fetchWorkorder } from "../../redux/actions/workorder_ac";
import { crud, Priorities, getTableType, tableType, schedulerType, language, ProjectStatus } from "../../config/constants";
import Switch from "../anBrains/Switch";
import Modal from "../anBrains/Modal";
import { calendarType, itemType, workorderType } from "../../redux/action_types";
import { Select } from "antd";
import "antd/dist/antd.css";
import { baseUrl } from "../../config";
import manprofile from "../../images/profiles/man.png";
import womanprofile from "../../images/profiles/woman.png";
const { Option, OptGroup } = Select;

export default function SchedulerForm(props) {
  const { isEdit, setIsEdit } = props;
  const trans = useSelector((state) => state.trans);
  const current = useSelector((state) => state.current);
  const employee = useSelector((state) => state.employee);
  const label = useSelector((state) => state.label);
  const loader = useSelector((state) => state.loader);
  const user = useSelector((state) => state.user);
  const { list } = useSelector((state) => state.calendar);
  const theme = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  const errors = {
    // descriptionError: "",
    nameError: "",
    userError: "",
    endDateError: "",
    startDateError: "",
    labelError: "",
  };
  const [err, setErr] = useState(errors);

  const [message, setMessage] = useState("");
  let validate = () => {
    if (current.item.name.length < 3 || current.item.name.length > 100) {
      setErr({ ...errors, nameError: trans.toShort });
      setMessage(trans.toShort);
      return false;
    }

    // if (current.item.userIds.length <= 0) {
    //     setErr({ ...errors, userError: trans.pleaseEnterValid })
    //     setMessage(trans.pleaseEnterValid)
    //     return false
    // }
    if (current.item.type !== schedulerType.meeting) {
      if (!current.item.labelIds || current.item.labelIds.length <= 0) {
        setErr({ ...errors, labelError: trans.youmustchooseatleastonedepartment });
        setMessage(trans.youmustchooseatleastonedepartment);
        return false;
      }
      if (current.item.labelIds !== null && current.item.labelIds.length > 0) {
        var includeDepartment = false;
        var includeProject = false;
        var includeCustomer = false;
        var selectedTableTypes = [];
        for (let i = 0; i < current.item.labelIds.length; i++) selectedTableTypes.push(current.item.labelIds[i].split("_")[1]);

        for (let index = 0; index < selectedTableTypes.length; index++) {
          var t = parseInt(selectedTableTypes[index]);
          if (t === tableType.department) includeDepartment = true;

          if (t === tableType.project) includeProject = true;

          if (t === tableType.customer) includeCustomer = true;
        }

        if (includeDepartment === false || (includeDepartment === true && includeProject === false && includeCustomer === false)) {
          setErr({ ...errors, labelError: trans.pleaseEnterValid });
          setMessage(trans.youmustchooseatleastonedepartment);
          return false;
        }
      }
    }

    var sDate = new Date(current.item.startDate);
    var eDate = new Date(current.item.endDate);
    var tDate = eDate - sDate;
    if (tDate < 5 * 60000) {
      setErr({ ...errors, endDateError: trans.dateRangeError });
      setMessage(trans.dateRangeError);
      return false;
    }

    return true;
  };

  const register = (e) => {
    e.preventDefault();
    if (validate()) {
      setErr(errors);
      dispatch(
        fetchWorkorder(
          { type: current.item.type, loader: true, isAgendaDetail: true },
          current.item.id > 1 ? crud.update : crud.create,
          { ...current.item, language: trans.langCode },
          trans
        )
      );
      // props.setIsOpen(false)
      setIsEdit(false);
    }
  };

  const realized = (e) => {
    e.preventDefault();
    if (validate()) {
      setErr(errors);
      list.find((x) => x.id == current.item.id).isAgendaHappend = true;
      dispatch({ type: calendarType, payload: list });
      dispatch(fetchWorkorder({ type: current.item.type, loader: true, workOrderId: current.item.id }, crud.end, {}, trans));
      props.setIsOpen(false);
    }
  };
  const changePriority = (e) => dispatch({ type: itemType, payload: { ...current.item, priority: parseInt(e.target.value) } });

  const changePoint = (e) => dispatch({ type: itemType, payload: { ...current.item, point: parseInt(e.target.value) } });

  const [isDeleteWorkOrderOpen, setIsDeleteWorkOrderOpen] = useState(false);

  const generateWorkOrder = (e) => {
    dispatch({ type: itemType, payload: { ...current.item, type: current.item.type === 1 ? 0 : 1 } });
    if (current.item.id > 0) {
      dispatch(fetchWorkorder({ type: current.item.type === 1 ? 0 : 1, id: current.item.id }, crud.changeType, {}, trans));
      list.find((x) => x.id === current.item.id).type = current.item.type === 1 ? 0 : 1;
      dispatch({ type: calendarType, payload: list });
    }
  };

  const deleteItem = () => {
    setIsDeleteWorkOrderOpen(false);
    dispatch(fetchWorkorder({}, crud.delete, current.item, trans));
    let workorders = list.filter((e) => {
      return e.id !== current.item.id;
    });
    dispatch({ type: calendarType, payload: workorders });
    props.setIsOpen(false);
  };
  const deleteConfirm = (e) => setIsDeleteWorkOrderOpen(true);

  const selectMultiCategories = (labelIds) => dispatch({ type: itemType, payload: { ...current.item, labelIds } });
  const selectMultiUserIds = (userIds) => dispatch({ type: itemType, payload: { ...current.item, userIds } });

  return !loader.loading ? (
    <div className="diaryForm ">
      <Modal isOpen={isDeleteWorkOrderOpen} withClose setIsOpen={setIsDeleteWorkOrderOpen} classes={"modal_delete"}>
        {trans.areyousureyouwanttodelete}
        <Button classes="btn-red" onClick={() => deleteItem()}>
          {trans.confirm}
        </Button>
      </Modal>
      <div className="diaryForm_Left">
        {isEdit ? (
          <Input
            styles={{ marginBottom: "unset" }}
            placeholder={trans.name}
            name={trans.name}
            header={trans.name}
            value={current.item.name ? current.item.name : ""}
            autoComplate={"off"}
            readOnly={current.item.type === schedulerType.meeting && !user.roles.some((x) => x === "Admin") && current.item.createBy !== user.id}
            onChange={(e) => {
              current.item.name = e.target.value;
              dispatch({ type: itemType, payload: current.item });
            }}
            error={err.nameError}
            maxLength={100}
            minLength={3}
          />
        ) : (
          <h2>{current.item.name}</h2>
        )}

        {isEdit ? (
          <div className="mb-5">
            <span className="input_header">{trans.personals}</span>
            {employee.list !== null && employee.list !== undefined && employee.list.length > 0 ? (
              <Select
                mode="multiple"
                allowClear
                optionFilterProp="children"
                onChange={selectMultiUserIds}
                maxTagCount={3}
                maxTagTextLength={4}
                style={{ width: "100%" }}
                disabled={current.item.type === schedulerType.meeting && !user.roles.some((x) => x === "Admin") && current.item.createBy !== user.id}
                placeholder={trans.personals}
                filterOption={(input, option) =>
                  option.children ? option.children.toLocaleLowerCase("tr").indexOf(input.toLocaleLowerCase("tr")) >= 0 : false
                }
                value={current.item.userIds}
              >
                {employee.list.map((l, i) => {
                  // //
                  return (
                    <Option key={"userd" + i} value={l.id}>
                      {l.name}
                    </Option>
                  );
                })}
              </Select>
            ) : null}
          </div>
        ) : (
          <>
            <span className="input_header">{trans.employees}</span>
            <div className="workorder_detail_personals">
              {current.item &&
                current.item.users &&
                current.item.users.length > 0 &&
                current.item.users.map((u, i) => {
                  return <img key={"user_photo" + i} src={u.photo ? baseUrl + u.photo : u && u.gender ? manprofile : womanprofile} title={u.name} />;
                })}
            </div>
          </>
        )}

        {isEdit ? (
          <div className="mb-5">
            <span className="input_header">{trans.labels}</span>
            {label.list && label.list.length > 0 ? (
              <Select
                mode="multiple"
                allowClear
                maxTagCount={3}
                maxTagTextLength={4}
                optionFilterProp="children"
                onChange={selectMultiCategories}
                style={{ width: "100%" }}
                placeholder={trans.labels}
                disabled={current.item.type === schedulerType.meeting && !user.roles.some((x) => x === "Admin") && current.item.createBy !== user.id}
                filterOption={(input, option) =>
                  option.children ? option.children.toLocaleLowerCase("tr").indexOf(input.toLocaleLowerCase("tr")) >= 0 : false
                }
                value={current.item.labelIds}
              >
                {label.list.map((l, i) => {
                  return (
                    <OptGroup key={l.label} label={l.label}>
                      {l.options.map((m, i) => {
                        return (
                          <Option value={m.idx} key={"optionLable" + m.id + i}>
                            {m.name}
                          </Option>
                        );
                      })}
                    </OptGroup>
                  );
                })}
              </Select>
            ) : null}
          </div>
        ) : (
          <>
            <span className="input_header">{trans.labels}</span>
            <div className="workorder_detail_personals">
              {current.item &&
                current.item.labels &&
                current.item.labels.length > 0 &&
                current.item.labels.map((u, i) => {
                  return (
                    <span className="label_container" key={"label" + i}>
                      {u.name}
                    </span>
                  );
                })}
            </div>
          </>
        )}

        {isEdit ? (
          <Input
            placeholder={trans.startdate}
            name={trans.startdate}
            header={trans.startdate}
            value={current.item.startDate}
            error={err.startDateError}
            readOnly={
              (current.item.type === schedulerType.meeting && !user.roles.some((x) => x === "Admin") && current.item.createBy !== user.id) ||
              current.item.type === schedulerType.projectStep_Workorder
            }
            onChange={(e) => {
              current.item.startDate = e.target.value;
              dispatch({ type: itemType, payload: current.item });
            }}
            type="datetime-local"
          />
        ) : (
          <div className="dateView">
            <span className="input_header">{trans.startdate}</span>
            <span>{current.item.startDateTimeString}</span>
          </div>
        )}

        {isEdit ? (
          <Input
            placeholder={trans.enddate}
            name={trans.enddate}
            header={trans.enddate}
            value={current.item.endDate}
            error={err.endDateError}
            readOnly={
              (current.item.type === schedulerType.meeting && !user.roles.some((x) => x === "Admin") && current.item.createBy !== user.id) ||
              current.item.type === schedulerType.projectStep_Workorder
            }
            onChange={(e) => {
              current.item.endDate = e.target.value;
              dispatch({ type: itemType, payload: current.item });
            }}
            type="datetime-local"
          />
        ) : (
          <div className="dateView">
            <span className="input_header">{trans.enddate}</span>
            <span>{current.item.endDateTimeString}</span>
          </div>
        )}

        {!isEdit && (
          <>
            <span className="input_header">{trans.priority}</span>
            <div className="workorderprioritiy">
              {current.item && current.item.priority > -1 ? (
                <div className={"priority"} style={{ backgroundColor: Priorities[current.item.priority].color }}>
                  {Priorities[current.item.priority][trans.lang]}
                </div>
              ) : null}
            </div>
            <span className="input_header">{trans.point}</span>
            <div className="workorderprioritiy">
              {current.item && current.item.point > -1 ? (
                <div
                  className={"priority"}
                  style={
                    theme === "dark"
                      ? { backgroundColor: "rgba(247, 202, 49, 1)", color: "#000" }
                      : { backgroundColor: "rgba(247, 202, 49, .7)", color: "#000" }
                  }
                >
                  {<b>{current.item.point}</b>}
                </div>
              ) : null}
            </div>
            <span className="input_header">{trans.status}</span>
            <div className="workorderprioritiy">
              {current.item && current.item.status > -1 ? (
                <div className={"priority"} style={{ backgroundColor: ProjectStatus[current.item.status].color }}>
                  {ProjectStatus[current.item.status][trans.lang]}
                </div>
              ) : null}
            </div>
          </>
        )}
        {current.item.type !== schedulerType.meeting && (
          <div className="input-container">
            <span className="input_header">{trans.workorder}</span>
            <Switch classes="mediumSwitch" id="generateWorkOrder" value={current.item.type} onChange={(a) => generateWorkOrder(a)} />
          </div>
        )}
      </div>
      <div className="diaryForm_Right">
        {isEdit && (
          <>
            {" "}
            <div className="input-container">
              <span className="input_header">{trans.priority}</span>
              <select
                disabled={current.item.type === schedulerType.meeting && !user.roles.some((x) => x === "Admin") && current.item.createBy !== user.id}
                className={`input select ${err.customerIdError ? "input-error" : ""}`}
                onChange={(e) => changePriority(e)}
                value={current.item.priority}
              >
                {Priorities.map((c, i) => {
                  return (
                    <option key={"priorities" + i} value={c.id}>
                      {c[trans.lang]}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="input-container">
              <span className="input_header">{trans.point}</span>
              <select
                disabled={!user.roles.some((x) => x === "Admin") && current.item.createBy !== user.id}
                className={`input select ${err.customerIdError ? "input-error" : ""}`}
                onChange={(e) => changePoint(e)}
                value={current.item.point}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="5">5</option>
                <option value="8">8</option>
                <option value="13">13</option>
                <option value="21">21</option>
              </select>
            </div>
          </>
        )}
        {isEdit && (
          <Input
            placeholder={trans.description}
            name={trans.description}
            header={trans.description}
            value={current.item.description}
            textarea={true}
            readOnly={current.item.type === schedulerType.meeting && !user.roles.some((x) => x === "Admin") && current.item.createBy !== user.id}
            onChange={(e) => dispatch({ type: itemType, payload: { ...current.item, description: e.target.value } })}
            error={err.descriptionError}
            maxLength={200}
            minLength={3}
          />
        )}
        {!isEdit && (
          <>
            <span className="description">{trans.description}</span>
            <div className="description_placeholder">{current.item.description}</div>
          </>
        )}
        {message && <span className="errorspan">{trans.error + ": " + message}</span>}

        {(current.item.type !== schedulerType.meeting || user.roles.some((x) => x === "Admin") || current.item.createBy === user.id) && (
          <div className="workorder_settings">
            {isEdit && (
              <Button classes={"btn btn-dark"} onClick={(e) => setIsEdit(false)}>
                {trans.back}
              </Button>
            )}

            {current.item && !current.item.isAgendaHappend && !isEdit ? (
              <Button classes={"btn btn-primary mr-4"} style={{ float: "left !important", backgroundColor: "#28a745" }} onClick={realized}>
                Gerçekleşti
              </Button>
            ) : null}

            {current.item.id > 0 && (
              <Button classes="btn-red mr-4" onClick={() => deleteConfirm(current.item)}>
                {trans.delete}
              </Button>
            )}

            {isEdit && (
              <Button classes={"btn btn-primary"} onClick={register}>
                {trans.save}
              </Button>
            )}

            {!isEdit && (
              <Button classes={"btn btn-primary"} onClick={(e) => setIsEdit(true)}>
                {trans.edit}
              </Button>
            )}
          </div>
        )}

        {/* <Button classes={"btn btn-dark"}
                    onClick={e => setIsEdit(false)}>{trans.close}</Button> */}
      </div>
    </div>
  ) : (
    <div className="employeeForm">
      <ContentLoader viewBox="0 0 250 600" height={600} width={275} speed={1} backgroundColor="#ddd" foregroundColor="#ecebeb" {...props}>
        <rect x="25" y="100" rx="3" ry="3" width="275" height="10" />
        <rect x="25" y="140" rx="3" ry="3" width="275" height="10" />
        <rect x="25" y="180" rx="3" ry="3" width="275" height="10" />
        <rect x="25" y="220" rx="3" ry="3" width="275" height="10" />
      </ContentLoader>
    </div>
  );
}
