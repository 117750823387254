import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Input from "../../components/anBrains/Input";
import { useSelector, useDispatch } from "react-redux";
import { fetchLogin, fetchUser, fetchFinishDemo } from "../../redux/actions/authorization_ac";
import video from "../../images/login.mp4";
import { getUserType } from "../../redux/action_types";
import Switch from "../../components/anBrains/Switch";
import TermOfUseDetail from "../../components/requestForm/TermOfUseDetail";
import Modal from "../../components/anBrains/Modal";
import GeneralLoader from "../../components/anBrains/GeneralLoader";

export default function FinishDemo(props) {
  const {
    match: { params },
    staticContext,
    ...rest
  } = props;
  const trans = useSelector((state) => state.trans);
  const loader = useSelector((state) => state.loader);
  const response = useSelector((state) => state.response);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("params", params);
    dispatch(fetchUser(params.userId));
  }, []);
  const history = useHistory();

  useEffect(() => {
    if (response && response.redirect) {
      history.push("/");
    }
  }, [loader.loading]);

  const [termofuse, settermofuse] = useState(false);

  const openTermofUserDetail = () => {
    settermofuse(!termofuse);
  };
  const errors = {
    passwordError: "",
    passwordConfirmateError: "",
    emailError: "",
    nameError: "",
    companyError: "",
    phoneError: "",
  };
  const [err, setErr] = useState(errors);
  const [message, setMessage] = useState(null);
  let validate = () => {
    emailControl();

    if (user.name.length < 3 || user.name.length > 50) {
      setErr({ ...errors, nameError: trans.toShort });
      setMessage(trans.toShort);
      return false;
    }

    if (user.company.length < 3 || user.company.length > 50) {
      setErr({ ...errors, companyError: trans.toShort });
      setMessage(trans.toShort);
      return false;
    }

    if (user.phone.length < 3 || user.phone.length > 50) {
      setErr({ ...errors, phoneError: trans.toShort });
      setMessage(trans.toShort);
      return false;
    }

    if (user.password.length < 5 || user.password.length > 30) {
      setErr({ ...errors, passwordError: trans.toShort });
      setMessage(trans.toShort);
      return false;
    }

    if (user.password !== user.newPassword) {
      setErr({ ...errors, passwordConfirmateError: trans.passwordsdonotmatch });
      setMessage(trans.passwordsdonotmatch);
      return false;
    }

    if (user.termofuse === false) {
      setErr({ ...errors, termofuseError: trans.termofuseaccept });
      setMessage(trans.termofuseaccept);
      return false;
    }

    return true;
  };

  let emailControl = () => {
    if (!user.email.includes("@") || user.email.length > 50) {
      setErr({ ...errors, emailError: trans.pleaseEnterValidEmailAddress });
      setMessage(trans.pleaseEnterValidEmailAddress);
      return false;
    } else return true;
  };

  const updateProfile = (e) => {
    e.preventDefault();
    if (validate()) {
      //
      setErr(errors);
      setMessage("");
      dispatch(fetchFinishDemo(user));
    }
  };

  const onEnter = (e) => {
    if (e.key === "Enter") updateProfile(e);
  };

  return (
    <>
      {termofuse ? (
        <Modal withClose setIsOpen={settermofuse} isOpen={termofuse} classes={"term_of_use_detail"} btnclass={"btn-close2"}>
          <TermOfUseDetail />
        </Modal>
      ) : null}
      {loader.loading && <GeneralLoader />}
      <div className="login_container">
        <div className="login_image">
          <video src={video} autoPlay muted loop>
            {" "}
          </video>
          <img src="https://timezeta.com/wp-content/uploads/2020/12/1-Seffaf.gif" />
          <div className="login_title">
            <h1>timezeta</h1>
            <p> Timezeta, projelerin düşük maliyetle, zamanında ve istenen kalitede tamamlanmasını sağlar.</p>
          </div>
          <div className="login_form">
            <div className="login_form_inside">
              <h2>Kaydını tamamla !</h2>
              <p>Timezeta, ekiplerin birlikte daha verimli çalışmalarını sağlar.</p>
              <Input
                placeholder={trans.email}
                header={trans.email}
                name={trans.email}
                value={user?.email}
                onChange={(e) => dispatch({ type: getUserType, payload: { ...user, email: e.target.value } })}
                error={err.emailError}
                readOnly={loader.loading}
              />
              <Input
                placeholder={trans.name + " " + trans.surname}
                header={trans.name + " " + trans.surname}
                name={trans.name + " " + trans.surname}
                value={user?.name}
                onChange={(e) => dispatch({ type: getUserType, payload: { ...user, name: e.target.value } })}
                error={err.nameError}
                readOnly={loader.loading}
              />
              <Input
                placeholder={trans.company}
                header={trans.company}
                name={trans.company}
                value={user?.company}
                onChange={(e) => dispatch({ type: getUserType, payload: { ...user, company: e.target.value } })}
                error={err.companyError}
                readOnly={loader.loading}
              />
              <Input
                placeholder={"5xxxxxxx"}
                type={"number"}
                name={trans.mobile}
                header={trans.mobile}
                value={user.phone}
                onChange={(e) => dispatch({ type: getUserType, payload: { ...user, phone: e.target.value } })}
                error={err.mobileError}
              />
              <Input
                placeholder={trans.password}
                type={"password"}
                name={trans.password}
                header={trans.password}
                value={user.password}
                onChange={(e) => dispatch({ type: getUserType, payload: { ...user, password: e.target.value } })}
                error={err.passwordError}
              />
              <Input
                placeholder={trans.password}
                type={"password"}
                name={trans.confirmPassword}
                header={trans.confirmPassword}
                value={user.newPassword}
                onChange={(e) => dispatch({ type: getUserType, payload: { ...user, newPassword: e.target.value } })}
                error={err.passwordConfirmateError}
                onKeyPress={onEnter}
              />
              <div className="termofuse">
                <Switch
                  id={"termsofuseSwitch"}
                  value={user?.termofuse}
                  classes={"termsofuseSwitch"}
                  onChange={(e) => dispatch({ type: getUserType, payload: { ...user, termofuse: e.target.checked } })}
                />
                <span onClick={openTermofUserDetail} className="ml-3 fs-xxsm">
                  {trans.approvalletter}
                  {trans.termofuseinform}
                </span>
              </div>
              <div className="termofuse">
                <Switch
                  id={"advertisementSwitch"}
                  value={user?.advertisement}
                  onChange={(e) => dispatch({ type: getUserType, payload: { ...user, advertisement: e.target.checked } })}
                />
                <span className="ml-3 fs-xxsm">Bilgilendirme Maili almak için onay veriyorum.</span>
              </div>
              {loader.loading === false ? <button onClick={updateProfile}>{trans.completeProfile}</button> : <button>{trans.sending}</button>}
              <div className="login_message">{trans[response.message] || message}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
