import { labelType, lineListType } from '../action_types'
const reducer = (state = { list: [] }, action) => {
    switch (action.type) {
        case labelType:
            return { ...state, list: action.payload, currentList: action.payload };
        case lineListType:
            return { ...state, lineList: action.payload };
        default:
            return state;
    }
};
export default reducer;