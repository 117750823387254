import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../../components/anBrains/Button';
import { PlusIcon, TrushIcon, SortIcon } from '../icons';
import Modal from '../anBrains/Modal';
import { leaveType } from '../../redux/action_types';
import { fetchLeaves } from '../../redux/actions/leave_ac';
import Input from '../anBrains/Input';
import Pagination from '../anBrains/Pagination'
import { crud } from '../../config/constants';
import { success } from '../../redux/actions/loader_ac';
export default function LeaveTab({ employeeid }) {

    const trans = useSelector(state => state.trans)
    const leave = useSelector(state => state.leave)
    const theme = useSelector(state => state.theme)
    const result = useSelector(state => state.result)
    const current = useSelector(state => state.current)
    const dispatch = useDispatch()
    const [item, setItem] = useState({})
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)

    useEffect(() => {
        if (result.success) {
            setIsAddOpen(false);
            setField(fields)
            dispatch(success(false))
        }
        var curr = new Date();
        curr.setDate(curr.getDate());
        var date = curr.toISOString().substr(0, 10);
        setField({ ...field, endDate: date, startDate: date })
    }, [result.change])

    const deleteConfirm = (e) => {
        setIsDeleteOpen(true)
        setItem(e);
    }
    const deleteItem = () => {
        setIsDeleteOpen(false)
        dispatch(fetchLeaves({}, crud.delete, item))
        let leaves = leave.filter((e) => {
            return e.id !== item.id
        });
        dispatch({ type: leaveType, payload: leaves })
    }

    const [isAddOpen, setIsAddOpen] = useState(false)
    const [listParameters, setListParameters] = useState({ rowCount: 14, pageCount: 0, sort: "id", desc: true, userId: employeeid }, crud.list, {})
    const [asc, setAsc] = useState(false)
    const sortbyField = (f) => {
        setListParameters({ ...listParameters, sort: f, desc: asc ? false : true })
        setAsc(!asc)
        dispatch(fetchLeaves({ ...listParameters, sort: f, desc: asc ? false : true }));
    }

    var fields = {
        id: 0,
        userId: employeeid,
        startDate: "",
        endDate: "",
        name: ""
    }
    const [field, setField] = useState(fields)

    const errors = {
        nameErr: "",
        endDateError: "",
        startDateError: "",
    }
    const [err, setErr] = useState(errors)


    useEffect(() => {
        var curr = new Date();
        curr.setDate(curr.getDate());
        var date = curr.toISOString().substr(0, 10);
        setField({ ...field, endDate: date, startDate: date })
    }, [])

    let validate = () => {
        if (field.name.length < 3 || field.name.length > 30) {
            setErr({ ...errors, nameErr: trans.toShort })
            return false
        }

        if (field.startDate === "" || field.startDate === undefined) {
            setErr({ ...errors, startDateError: trans.pleaseEnterValidDate })
            return false
        }

        if (field.endDate === "" || field.endDate === undefined) {
            setErr({ ...errors, endDateError: trans.pleaseEnterValidDate })
            return false
        }
        return true
    }
    const register = e => {
        e.preventDefault();
        if (validate()) {
            setErr(errors)
            dispatch(fetchLeaves({}, crud.create, field, trans))
        }
    }


    const [selectedPage, setSelectedPage] = useState(1)
    const setSelectedPageFilter = (n) => {
        setSelectedPage(n)
        setListParameters({ ...listParameters, pageNumber: n - 1 })
        dispatch(fetchLeaves({ ...listParameters, pageNumber: n - 1 },trans));
    }
    return (
        <>
            <div className="leave">
                <div className="leave_detail_container">
                    <div className="leave_detail_box">
                        <div className="leave_detail_box_number ">
                            {current && (current.item.yearlyLeaveCount)}
                        </div>
                        <div className="leave_detail_box_text">
                            {trans.leavedaycount}
                        </div>
                    </div>

                    <div className="leave_detail_box">
                        <div className="leave_detail_box_number red">
                            {(current && current.item.usedLeaveCount) ? current.item.usedLeaveCount : 0}
                        </div>
                        <div className="leave_detail_box_text">
                            {trans.usedleavecount}
                        </div>
                    </div>
                    <div className="leave_detail_box">
                        <div className={`leave_detail_box_number ${(current.item.yearlyLeaveCount - current.item.usedLeaveCount) <= 0 ? " " : " green"}`}>

                            {current.item.usedLeaveCount !== undefined
                                ? ((current.item.yearlyLeaveCount - current.item.usedLeaveCount)) > 0 ? (current.item.yearlyLeaveCount - current.item.usedLeaveCount) : 0
                                : 0
                            }
                        </div>
                        <div className="leave_detail_box_text">
                            {trans.remaining}
                        </div>
                    </div>
                </div>
                <div className="table_properties">
                    {/* <Input icon={search} placeholder={trans.search} onChange={e => searchChange(e.target.value)} /> */}
                    <Button
                        icon={<PlusIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />}
                        classes="btn btn-basic"
                        onClick={() => setIsAddOpen(true)}>{trans.add}</Button>
                </div>
                <div className="table">
                    <table>
                        <thead>
                            <tr>
                                <th onClick={() => sortbyField("name")}>{trans.description}
                                    <SortIcon color="rgba(96, 168, 221, 1)" /></th>
                                <th onClick={() => sortbyField("endDate")}>{trans.exitdate}

                                    <SortIcon color="rgba(96, 168, 221, 1)" /></th>
                                <th onClick={() => sortbyField("startDate")}>{trans.entrydate}
                                    <SortIcon color="rgba(96, 168, 221, 1)" />
                                </th>
                                <th>{trans.settings}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {leave && leave.length > 0 && leave.map((e, i) => {
                                return (
                                    <tr className="row" key={e.id}>
                                        <td>{e.name}</td>
                                        <td>{e.endDateString}</td>
                                        <td >{e.startDateString}</td>
                                        <td>
                                            {/* <button onClick={() => editItem(e)}>
                                                <EditIcon color={(theme === "dark" ? "rgba(96, 168, 221, 1)" : "#000")} />
                                            </button> */}
                                            <button onClick={() => deleteConfirm(e)}>
                                                <TrushIcon
                                                    color={(theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)")}
                                                /></button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                </div>{current && current.pageCount > 0 &&
                    <Pagination
                        setSelectedPage={setSelectedPageFilter}
                        selectedPage={selectedPage} />}
            </div>
            <Modal
                isOpen={isAddOpen}
                withClose
                setIsOpen={setIsAddOpen}
                classes={"modal_add_leave"}>
                <div className="leaveForm">
                    <Input placeholder={trans.description}
                        name={trans.description}
                        header={trans.description}
                        value={field.name}
                        onChange={e => setField({ ...field, name: e.target.value })}
                        error={err.nameErr} />
                    <Input
                        type={"date"}
                        header={trans.startdate}
                        value={field.startDate}
                        onChange={e => setField({ ...field, startDate: e.target.value })}
                        error={err.startDateError} />
                    <Input
                        type={"date"}
                        header={trans.enddate}
                        value={field.endDate}
                        onChange={e => setField({ ...field, endDate: e.target.value })}
                        error={err.endDateError} />
                    <Button classes={"btn-primary"}
                        onClick={register}>{trans.save}</Button>
                </div>
            </Modal>
            <Modal
                isOpen={isDeleteOpen}
                withClose
                setIsOpen={setIsDeleteOpen}
                classes={"modal_delete"}>
                {trans.areyousureyouwanttodelete}
                <Button classes="btn-red" onClick={() => deleteItem()}>{trans.confirm}</Button>
            </Modal>
        </>
    )
}
