import React, { useEffect, useState } from "react";
import Layout from "../../components/Container/Layout";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/anBrains/Pagination";
import Input from "../../components/anBrains/Input";
import { SortIcon, Search2Icon } from "../../components/icons";
import ContentLoader from "react-content-loader";
import { baseUrl } from "../../config";
import { crud, getNotifyColorByType, getNotifyNameByType } from "../../config/constants";
import { fetchNotification } from "../../redux/actions/notification_ac";
import manprofile from "../../images/profiles/man.png";
import womanprofile from "../../images/profiles/woman.png";
import { useHistory } from "react-router-dom";
export default function Notification(props) {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notification);
  const trans = useSelector((state) => state.trans);
  const theme = useSelector((state) => state.theme);
  const current = useSelector((state) => state.current);
  const [searchText, setSearchText] = useState("");
  const loader = useSelector((state) => state.loader);
  const [selectedPage, setSelectedPage] = useState(1);
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const [request, setRequest] = useState({ rowCount: 14, isAll: true, pageCount: 0, search: "", sort: "", desc: false, data: {} });

  useEffect(() => {
    dispatch(fetchNotification(request, crud.list, {}, trans));
  }, []);

  const searchChange = (text) => {
    setSearchText(text);
    dispatch(fetchNotification({ ...request, search: text }, crud.list, {}, trans));
  };

  const searchChangeKeyPress = (text, kcode) => {
    if (kcode === 8) {
      dispatch(fetchNotification({ ...request, search: text }, crud.list, {}, trans));
    }
  };

  const [desc, setDesc] = useState(false);
  const sortbyField = (f) => {
    setRequest({ ...request, sort: f, desc });
    dispatch(fetchNotification({ ...request, sort: f, desc }, crud.list, {}, trans));
    setDesc(!desc);
  };

  const setSelectedPageFilter = (n) => {
    setSelectedPage(n);
    setRequest({ ...request, pageCount: n - 1 });
    dispatch(fetchNotification({ ...request, pageCount: n - 1 }, crud.list, {}, trans));
  };
  const clickNotify = (notify) => {
    dispatch(fetchNotification({ notificationId: notify.id }, crud.viewed, {}));
    history.push(notify.link);
  };
  return (
    <Layout>
      <div className="table_properties">
        <Input
          icon={<Search2Icon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />}
          placeholder={trans.search}
          value={searchText}
          onChange={(e) => searchChange(e.target.value)}
          onKeyUp={(e) => searchChangeKeyPress(e.target.value, e.keyCode)}
        />
      </div>

      {!loader.loading ? (
        <div className="table">
          <table>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th>{trans.notifications}</th>
                <th>{trans.date}</th>
              </tr>
            </thead>
            <tbody>
              {notifications.allList &&
                notifications.allList.length > 0 &&
                notifications.allList.map((notify, i) => {
                  return (
                    <tr className="row" style={{ cursor: "pointer" }} key={i} onClick={(e) => clickNotify(notify)}>
                      <td>
                        <img
                          src={notify.user && notify.user.photo ? baseUrl + notify.user.photo : notify.user && notify.user.gender ? manprofile : womanprofile}
                          className="meeting_users_img mr-1"
                        />
                      </td>
                      <td>
                        <span className="type" style={{ backgroundColor: getNotifyColorByType(notify.type) }}>
                          {getNotifyNameByType(notify.type, trans.lang)}
                        </span>
                      </td>
                      <td>{notify.title}</td>
                      <td>{notify.date}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : null}
      {current && current.pageCount > 0 && <Pagination setSelectedPage={setSelectedPageFilter} selectedPage={selectedPage} />}
    </Layout>
  );
}
