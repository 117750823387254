import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./styles/app.scss";
import { Helmet } from "react-helmet";
import { isDarkType, isLightType, getUserType, transType } from "./redux/action_types";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import {
  CustomerManagement,
  CustomerDetail,
  ProjectGroup,
  EmployeeDetail,
  EmployeeManagement,
  ProjectManagement,
  MeetingGroupManagement,
  MeetingDetail,
} from "./pages";
import { Authorization, UserFileType, Holiday, Department } from "./pages/definition";
import ProjectDetail from "./pages/project/ProjectDetail";
import WorkOrder from "./pages/workorder/WorkOrder";
import SuggestionBox from "./pages/suggestionBox/SuggestionBox";
import CompanyCulture from "./pages/companyCulture/CompanyCulture";
import EmployeePerformance from "./pages/employeePerformance/EmployeePerformance";
import EmployeeScoreDetail from "./pages/employeePerformance/EmployeeScoreDetail";
import WorkOrderDetail from "./pages/workorder/WorkOrderDetail";
import Login from "./pages/login/Login";
import RequestForm from "./pages/requestForm/RequestForm";
import Pano from "./pages/pano/Pano";
import Profile from "./pages/profile/Profile";
import CompanySetting from "./pages/company/CompanySetting";
import Notification from "./pages/notification/Notification";
import Report from "./pages/report/Reports";
// import { useHistory } from 'react-router-dom';
import ChangePassword from "./pages/login/ChangePassword";
import NotFound from "./pages/error/NotFound";
import Scheduler from "./pages/scheduler/Scheduler";
import Demo from "./pages/login/Demo";
import ThankYou from "./pages/login/ThankYou";
import FinishDemo from "./pages/login/FinishDemo";
import ShareProject from "./pages/common/ShareProject";
import Report2 from "./pages/report2/Reports2";
function App() {
  const theme = useSelector((state) => state.theme);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    let xuser = {};
    if (!user || user.id === 0) {
      if (localStorage.getItem("user") !== null) {
        xuser = JSON.parse(localStorage.getItem("user"));
        dispatch({ type: getUserType, payload: xuser });
      }
    }

    // dispatch(fetchNotification({}, crud.any, {}))
    if (localStorage.getItem("theme") != null) {
      dispatch({
        type: localStorage.getItem("theme") === "dark" ? isDarkType : isLightType,
      });
      //  document.documentElement.classList.add("transition");
    }
    if (localStorage.getItem("lang") != null) {
      dispatch({ type: localStorage.getItem("lang") });
    } else {
      localStorage.setItem("lang", "tr");
    }
  }, []);
  return (
    <>
      <Helmet>
        <html data-theme={theme} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
      </Helmet>
      <Router>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/personal-management" exact component={EmployeeManagement} />
          <Route path="/personal-management/personal-detail/:id" exact component={EmployeeDetail} />
          <Route path="/customer-management" exact component={CustomerManagement} />
          <Route path="/customer-management/customer-detail/:id" exact component={CustomerDetail} />
          <Route path="/definitions/project-group" exact component={ProjectGroup} />
          <Route path="/definitions/user-file-type" exact component={UserFileType} />
          <Route path="/definitions/holiday" exact component={Holiday} />
          <Route path="/definitions/department" exact component={Department} />
          <Route path="/definitions/authorization" exact component={Authorization} />
          <Route path="/projects" exact component={ProjectManagement} />
          <Route path="/projects/project-detail/:id" exact component={ProjectDetail} />
          <Route path="/meeting-management/" exact component={MeetingGroupManagement} />
          <Route path="/meeting-management/meeting-detail/:id" exact component={MeetingDetail} />
          <Route path="/workorders" exact component={WorkOrder} />
          <Route path="/suggestion-box" exact component={SuggestionBox} />
          <Route path="/company-culture" exact component={CompanyCulture} />
          <Route path="/personal-performance/personal-score-detail/:id" exact component={EmployeeScoreDetail} />
          <Route path="/personal-performance" exact component={EmployeePerformance} />
          <Route path="/workorders/workorder-detail/:id" exact component={WorkOrderDetail} />
          <Route path="/diary" exact component={Scheduler} />
          <Route path="/pano" exact component={Pano} />
          <Route path="/profile/:id" exact component={Profile} />
          <Route path="/settings/company-setting" exact component={CompanySetting} />
          <Route path="/allnotifications" exact component={Notification} />
          <Route path="/report" exact component={Report} />
          <Route path="/report2" exact component={Report2} />
          <Route path="/changepassword/:key" exact component={ChangePassword} />
          {/* <Route path="/requestForm" exact component={RequestForm} /> */}
          <Route path="/requestForm" exact component={Demo} />
          <Route path="/thanks" exact component={ThankYou} />
          <Route path="/finishdemo/:userId" exact component={FinishDemo} />
          <Route path="/shared-project/:id" exact component={ShareProject} />

          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
