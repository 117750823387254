import { customerEventType, customerAddEventType, customerUpdateEventType } from '../action_types'
const reducer = (state = [], action) => {
    switch (action.type) {
        case customerEventType:
            console.log(action.payload);
            return action.payload;
        case customerAddEventType:
            return [action.payload, ...state]
        case customerUpdateEventType:
            if (state && state.length > 0) {
                for (let i = 0; i < state.length; i++) {
                    if (state[i].id === action.payload.id ) {
                        state[i] = action.payload;
                    }
                }
                return state
            }
            else return state;
        default:
            return state;
    }
}
export default reducer