import React, { useEffect, useState } from "react";
import Layout from "../../components/Container/Layout";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/anBrains/Button";
import Input from "../../components/anBrains/Input";
import EmployeeDetailInsideMenu from "../../components/employee/EmployeeDetailInsideMenu";
import { insideMenuTypeInWorkOrder, itemType, workorderType } from "../../redux/action_types";
import { TrushIcon, EditIcon } from "../../components/icons";
import ContentLoader from "react-content-loader";
import { crud, Priorities, tableType, getTableType, ProjectStatus, dateFormatter, schedulerType, fileFromType } from "../../config/constants";
import { baseUrl } from "../../config";
import { fetchWorkorder, fetchWorkorderDetail } from "../../redux/actions/workorder_ac";
import Modal from "../../components/anBrains/Modal";
import TimesByUser from "../../components/workorder/TimesByUser";
import CommentsByWorkorder from "../../components/workorder/CommentsByWorkorder";
import { useHistory } from "react-router-dom";
import UserTimes from "../../components/workorder/UserTimes";
import TimerForUser from "../../components/workorder/TimerForUser";
import { Select } from "antd";
import "antd/dist/antd.css";
import manprofile from "../../images/profiles/man.png";
import womanprofile from "../../images/profiles/woman.png";
import File from "../../components/project/File";
// import { SortableTreeWithoutDndContext } from 'react-sortable-tree';
const { Option, OptGroup } = Select;

export default function WorkOrderDetail(props) {
  const {
    match: { params },
    staticContext,
    ...rest
  } = props;
  const dispatch = useDispatch();
  const trans = useSelector((state) => state.trans);
  const user = useSelector((state) => state.user);
  const theme = useSelector((state) => state.theme);
  const { inside_menu, list } = useSelector((state) => state.workorder);
  const current = useSelector((state) => state.current);
  const label = useSelector((state) => state.label);
  const employee = useSelector((state) => state.employee);
  const [request, setRequest] = useState({
    rowCount: 14,
    pageCount: 0,
    search: "",
    sort: "id",
    desc: false,
    loader: true,
    includeDetail: true,
    id: params.id,
    lang: trans.lang,
    isWorkOrderDetail: true,
  });
  const [isTimelineOrFile, setIsTimelineOfFile] = useState(true);
  useEffect(() => {
    dispatch(fetchWorkorderDetail(request, crud.list, {}, trans));
    inside_menu.map((x) => (x.active = false));
    inside_menu.find((x) => x.content === "detail").active = true;
    dispatch({ type: insideMenuTypeInWorkOrder, payload: inside_menu });
  }, []);

  const errors = {
    nameError: "",
    userError: "",
    endDateError: "",
    startDateError: "",
    labelError: "",
  };
  const [err, setErr] = useState(errors);

  const [message, setMessage] = useState("");
  let validate = () => {
    if (current.item.name.length < 3 || current.item.name.length > 100) {
      setErr({ ...errors, nameError: trans.toShort });
      setMessage(trans.toShort);
      return false;
    }

    // //
    var sDate = new Date(current.item.startDate);
    var eDate = new Date(current.item.endDate);
    var tDate = eDate - sDate;
    if (tDate < 5 * 60000) {
      setErr({ ...errors, endDateError: trans.dateRangeError });
      setMessage(trans.dateRangeError);
      return false;
    }

    // if (current && current.item && current.item.users &&current.item.users.length <= 0) {
    //     setErr({ ...errors, userError: trans.pleaseEnterValid })
    //     setMessage(trans.pleaseEnterValid)
    //     return false
    // }

    if (current.item.labelIds === null || current.item.labelIds.length <= 0) {
      setErr({ ...errors, labelError: trans.youmustchooseatleastonedepartment });
      setMessage(trans.youmustchooseatleastonedepartment);
      return false;
    }

    if (current.item.labelIds !== null && current.item.labelIds.length > 0) {
      var includeDepartment = false;
      var includeProject = false;
      var includeCustomer = false;
      var selectedTableTypes = [];
      for (let i = 0; i < current.item.labelIds.length; i++) selectedTableTypes.push(current.item.labelIds[i].split("_")[1]);

      for (let index = 0; index < selectedTableTypes.length; index++) {
        var t = parseInt(selectedTableTypes[index]);
        if (t === tableType.department) includeDepartment = true;

        if (t === tableType.project) includeProject = true;

        if (t === tableType.customer) includeCustomer = true;
      }

      if (includeDepartment === false || (includeDepartment === true && includeProject === false && includeCustomer === false)) {
        setErr({ ...errors, labelError: trans.pleaseEnterValid });
        setMessage(trans.youmustchooseatleastonedepartment);
        return false;
      }
    }

    setErr(errors);
    setMessage("");
    return true;
  };

  // const handleLabel = task => {
  //     current.item.labels = task;
  //     dispatch({ type: itemType, payload: current.item })
  // }

  const changeTab = (content) => {
    inside_menu.map((x) => (x.active = false));
    inside_menu.find((x) => x.content === content).active = true;
    dispatch({ type: insideMenuTypeInWorkOrder, payload: inside_menu });

    switch (content) {
      case "timeline":
        break;
      default:
        break;
    }
  };
  const changePriority = (e) => {
    current.item.priority = parseInt(e.target.value);
    dispatch({ type: itemType, payload: current.item });
  };

  const changePoint = (e) => {
    current.item.point = parseInt(e.target.value);
    dispatch({ type: itemType, payload: current.item });
  };

  const selectMultiCategories = (labelIds) => dispatch({ type: itemType, payload: { ...current.item, labelIds } });
  const selectMultiUserIds = (userIds) => dispatch({ type: itemType, payload: { ...current.item, userIds } });

  const loader = useSelector((state) => state.loader);

  const [item, setItem] = useState({});

  const [isDeleteWorkOrderOpen, setIsDeleteWorkOrderOpen] = useState(false);
  const deleteConfirm = (e) => {
    setIsDeleteWorkOrderOpen(true);
    setItem(e);
  };

  const [openEditDetailPanel, setOpenEditDetailPanel] = useState(false);
  const editItem = (e) => {
    setOpenEditDetailPanel(true);
    setItem(e);
  };
  const history = useHistory();

  const deleteItem = () => {
    setIsDeleteWorkOrderOpen(false);
    dispatch(fetchWorkorder(request, crud.delete, item, trans));
    let workorders = list.filter((e) => {
      return e.id !== item.id;
    });
    dispatch({ type: workorderType, payload: workorders });
    history.push("/workorders");
  };

  const save = (e) => {
    e.preventDefault();
    if (validate()) {
      setErr(errors);
      dispatch(fetchWorkorder(request, crud.update, current.item, trans));
      setOpenEditDetailPanel(false);
    }
  };

  return (
    <Layout>
      <Modal isOpen={isDeleteWorkOrderOpen} withClose setIsOpen={setIsDeleteWorkOrderOpen} classes={"modal_delete"}>
        {trans.areyousureyouwanttodelete}
        <Button classes="btn-red" onClick={() => deleteItem()}>
          {trans.confirm}
        </Button>
      </Modal>
      {current.item && (
        <div className="employee_detail_container">
          {!openEditDetailPanel && (
            <div className="workorder_detail">
              <div className="workorder_detail_header">
                <h3>{current.item && current.item.name}</h3>
              </div>
              <div className="workorder_detail_desc" style={{ height: "170px" }}>
                {current.item && current.item.description}
              </div>
              <div className="workorder_detail_header">
                <h3>{trans.workorderdetail}</h3>
              </div>
              <div className="workorder_detail_desc">
                <h5>{trans.employees}</h5>
                <div className="workorder_detail_personals">
                  {current.item &&
                    current.item.users &&
                    current.item.users.length > 0 &&
                    current.item.users.map((u, i) => {
                      return <img key={"user_photo" + i} src={u.photo ? baseUrl + u.photo : u && u.gender ? manprofile : womanprofile} title={u.name} />;
                    })}
                </div>
                <h5>{trans.labels}</h5>
                <div className="workorder_detail_personals">
                  {current.item &&
                    current.item.labels &&
                    current.item.labels.length > 0 &&
                    current.item.labels.map((u, i) => {
                      return (
                        <span className="label_container" key={"label" + i}>
                          {u.name}
                        </span>
                      );
                    })}
                </div>
                <h5>{trans.priority}</h5>
                <div className="workorderprioritiy">
                  {current.item && current.item.priority > -1 ? (
                    <div className={"priority"} style={{ backgroundColor: Priorities[current.item.priority].color }}>
                      {Priorities[current.item.priority][trans.lang]}
                    </div>
                  ) : null}
                </div>
                <h5>{trans.point}</h5>
                <div className="workorderprioritiy">
                  {current.item && current.item.point > -1 ? (
                    <div
                      className={"priority"}
                      style={
                        theme === "dark"
                          ? { backgroundColor: "rgba(247, 202, 49, 1)", color: "#000" }
                          : { backgroundColor: "rgba(247, 202, 49, .7)", color: "#000" }
                      }
                    >
                      {<b>{current.item.point}</b>}
                    </div>
                  ) : null}
                </div>
                <h5>{trans.status}</h5>
                <div className="workorderprioritiy">
                  {current.item && current.item.status > -1 ? (
                    <div className={"priority"} style={{ backgroundColor: ProjectStatus[current.item.status].color }}>
                      {ProjectStatus[current.item.status][trans.lang]}
                    </div>
                  ) : null}
                </div>
                <h5>{trans.startdate + " - " + trans.enddate}</h5>
                <span className="workorderdate">{current.item && current.item.startDateTimeString + " - " + current.item.endDateTimeString}</span>
              </div>
              <div className="workorder_settings">
                <button onClick={() => editItem(current.item)}>
                  <EditIcon color={theme === "dark" ? "#e5e5e5" : "#000"} />
                </button>
                <button onClick={() => deleteConfirm(current.item)}>
                  <TrushIcon color={theme === "dark" ? "#e5e5e5" : "#000"} />
                </button>
              </div>
            </div>
          )}
          {openEditDetailPanel && (
            <div className="workorder_detail employeeForm workorder_detail_edit pr-5 mt-10">
              <Input
                placeholder={trans.projectName}
                name={trans.workorder}
                header={trans.workorder}
                value={current.item.name}
                onChange={(e) => {
                  current.item.name = e.target.value;
                  dispatch({ type: itemType, payload: current.item });
                }}
                error={err.nameError}
                maxLength={100}
                minLength={3}
              />

              <div className="mb-5">
                <span className="input_header">{trans.personals}</span>
                {employee.list !== null && employee.list !== undefined && employee.list.length > 0 ? (
                  <Select
                    mode="multiple"
                    allowClear
                    maxTagCount={3}
                    maxTagTextLength={4}
                    optionFilterProp="children"
                    onChange={selectMultiUserIds}
                    style={{ width: "100%" }}
                    placeholder={trans.personals}
                    filterOption={(input, option) =>
                      option.children ? option.children.toLocaleLowerCase("tr").indexOf(input.toLocaleLowerCase("tr")) >= 0 : false
                    }
                    value={current.item.userIds}
                  >
                    {employee.list.map((l, i) => {
                      return (
                        <Option key={"userId" + i} value={l.id}>
                          {l.name}
                        </Option>
                      );
                    })}
                  </Select>
                ) : null}
              </div>

              <div className="mb-5">
                <span className="input_header">{trans.labels}</span>
                {label.list !== null && label.list !== undefined && label.list.length > 0 ? (
                  <Select
                    mode="multiple"
                    allowClear
                    maxTagCount={3}
                    maxTagTextLength={4}
                    optionFilterProp="children"
                    onChange={selectMultiCategories}
                    style={{ width: "100%" }}
                    placeholder={trans.labels}
                    filterOption={(input, option) =>
                      option.children ? option.children.toLocaleLowerCase("tr").indexOf(input.toLocaleLowerCase("tr")) >= 0 : false
                    }
                    value={current.item.labelIds}
                  >
                    {label.list.map((l, i) => {
                      return (
                        <OptGroup key={l.label} label={l.label}>
                          {l.options.map((m, i) => {
                            return (
                              <Option value={m.idx} key={"optionLable" + m.id + i}>
                                {m.name}
                              </Option>
                            );
                          })}
                        </OptGroup>
                      );
                    })}
                  </Select>
                ) : null}
              </div>
              <div className="input-container">
                <span className="input_header">{trans.startdate}</span>
                <div className="input">
                  <Input
                    type={"datetime-local"}
                    value={current.item.startDate}
                    readOnly={current.item.type === schedulerType.projectStep_Workorder}
                    onChange={(e) => {
                      current.item.startDate = e.target.value;
                      dispatch({ type: itemType, payload: current.item });
                    }}
                    autoComplate={"off"}
                  />
                </div>
              </div>
              <div className="input-container">
                <span className="input_header">{trans.enddate}</span>
                <div className={`input ${err.endDateError ? "input-error" : ""}`}>
                  <Input
                    type={"datetime-local"}
                    value={current.item.endDate}
                    readOnly={current.item.type === schedulerType.projectStep_Workorder}
                    onChange={(e) => {
                      current.item.endDate = e.target.value;
                      dispatch({ type: itemType, payload: current.item });
                    }}
                    autoComplate={"off"}
                  />
                </div>
              </div>
              <div className="input-container">
                <span className="input_header">{trans.priority}</span>
                <select
                  className={`input select ${err.customerIdError ? "input-error" : ""}`}
                  onChange={(e) => changePriority(e)}
                  value={current.item.priority}
                >
                  {Priorities.map((c, i) => {
                    return (
                      <option key={"priorities" + i} value={c.id}>
                        {c[trans.lang]}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="input-container">
                <span className="input_header">{trans.point}</span>
                <select className={`input select ${err.customerIdError ? "input-error" : ""}`} onChange={(e) => changePoint(e)} value={current.item.point}>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="5">5</option>
                  <option value="8">8</option>
                  <option value="13">13</option>
                  <option value="21">21</option>
                </select>
              </div>
              <Input
                placeholder={trans.description}
                name={trans.description}
                header={trans.description}
                value={current.item.description}
                textarea={true}
                onChange={(e) => {
                  current.item.description = e.target.value;
                  dispatch({ type: itemType, payload: current.item });
                }}
                // error={err.descriptionError}
                maxLength={200}
                minLength={3}
              />

              {message && <span className="errorspan">{trans.error + ": " + message}</span>}
              <div className="workorder_settings">
                <Button classes="btn-dark mr-4" onClick={() => setOpenEditDetailPanel(!openEditDetailPanel)}>
                  {trans.close}
                </Button>
                <Button classes="btn-primary mr-4" onClick={save}>
                  {trans.save}
                </Button>
                <Button classes="btn-red" onClick={() => deleteConfirm(current.item)}>
                  {trans.delete}
                </Button>
              </div>
            </div>
          )}
          <div className="workorder_detail_right">
            <ul className="inside_menu">
              {inside_menu.map((m, i) => {
                return (
                  <EmployeeDetailInsideMenu id={params.id} key={i} changeTab={changeTab} active={m.active} content={m.content} name={m.name[trans.lang]} />
                );
              })}
            </ul>
            <div className="employee_detail_content">
              {inside_menu.find((x) => x.content === "detail").active === true && (
                <div>
                  <TimesByUser />
                  <CommentsByWorkorder current={current} />
                </div>
              )}
              {/* {inside_menu.find(x => x.content === "files").active === true &&
                                <div>
  
                                </div>
                            } */}
            </div>
          </div>
          <div className="workorder_detail_right">
            <ul className="inside_menu">
              <li className={isTimelineOrFile && "active"} onClick={() => setIsTimelineOfFile(true)}>
                {trans.timeline}
              </li>
              <li className={!isTimelineOrFile && "active"} onClick={() => setIsTimelineOfFile(false)}>
                {trans.file}
              </li>
            </ul>
            {isTimelineOrFile === true ? (
              <div className="employee_detail_content">
                {current && current.item && current.item.workOrderUsers && current.item.workOrderUsers.some((x) => x.userId === user.id) ? (
                  <TimerForUser />
                ) : null}
                <UserTimes />
              </div>
            ) : (
              <div className="employee_detail_content">
                <File projectId={0} workOrderId={params.id} customerId={0} fileFromType={fileFromType.workorder} />
              </div>
            )}
          </div>
        </div>
      )}
    </Layout>
  );
}
