import axios from 'axios'
import { axiosConfig, baseUrl,openNotification } from '../../config'
import { pageCountType, addSuggestionType, suggestionType, updateSuggestionType, changeSuggestionStatusType, itemType,isLoginType } from '../action_types'
import { success, calculatePageNumber, loading } from './loader_ac'
import { crud } from '../../config/constants'
export const fetchSuggestion = (request = { rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}, trans = undefined) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/SuggestionBoxs`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            }).then(response => {
                dispacth(loading())
                if (response.data.success) {
                    switch (requestType) {
                        case crud.create:
                            dispacth({
                                type: addSuggestionType,
                                payload: response.data.data,
                            })
                            dispacth(success(true))
                            openNotification('success', trans.successTitle, trans.successBody, 3)
                            break;
                        case crud.read:
                            break;
                        case crud.update:
                            dispacth({
                                type: updateSuggestionType,
                                payload: response.data.data,
                            })

                            var departments = [];
                            var personal = response.data.data;
                            if (personal.departments && personal.departments.length > 0) {

                                for (let index = 0; index < personal.departments.length; index++) {
                                    const element = personal.departments[index];
                                    departments.push({ label: element.name, value: element.id })
                                }
                                personal.departments = departments;
                            }
                            dispacth({
                                type: itemType,
                                payload: personal
                            })
                            dispacth(success(true))
                            openNotification('success', trans.successTitle, trans.successBody, 3)
                            break;
                        case crud.delete:
                            openNotification('success', trans.successTitle, trans.successBody, 3)
                            break;
                        case crud.list:
                            dispacth({
                                type: suggestionType,
                                payload: response.data.data,
                            })
                            
                            dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) })
                            dispacth(success(true))
                            break;
                        case crud.change:
                            dispacth({
                                type: suggestionType,
                                payload: response.data.data
                            })
                            dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) })
                            dispacth(success(true))
                            openNotification('success', trans.successTitle, trans.successBody, 3)
                            break;
                        default:
                            break;
                    }
                }
                else
                    dispacth(success(false))
            }).catch(reason => {
                    console.log();
                    dispacth(loading())
                    dispacth(success(false))
                    openNotification('error', trans.errorTitle, trans.errorBody,3)
                  
                })
        } catch (error) {
            console.log(error)
            dispacth(loading())
            dispacth(success(false))
            openNotification('error', trans.errorTitle, trans.errorBody,3)
        }
    }
}

// export const fetchMeetingDetail = (request = { lang: "tr", rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}) => {
//     let headers = axiosConfig.headers
//     let url = `${baseUrl}/api/Meetings`
//     if (localStorage.getItem("token")) {
//         headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
//     }
//     return dispacth => {
//         try {
//             dispacth(loading())
//             axios({
//                 method: 'POST',
//                 url,
//                 headers,
//                 data: { request, requestType, data }
//             })
//                 .then(response => {
//                     dispacth(loading())
//                     if (response.data.success) {
//                         dispacth({ type: itemType, payload: response.data.data[0] })
//                         var options = [
//                             {
//                                 label: request.lang === "tr" ? "Projeler" : "Projects",
//                                 options: []
//                             },
//                             {
//                                 label: request.lang === "tr" ? "Müşteriler" : "Customers",
//                                 options: []
//                             },
//                             {
//                                 label: request.lang === "tr" ? "Departmanlar" : "Departments",
//                                 options: []
//                             },
//                         ];

//                         var projects = response.data.data2.projects;
//                         var projectLabels = [];
//                         for (let index = 0; index < projects.length; index++) {
//                             const element = projects[index];
//                             projectLabels.push({ label: element.name, value: element.id });
//                         }
//                         options[0].options = projectLabels;

//                         var customers = response.data.data2.customers;
//                         var customerLabels = [];
//                         for (let index = 0; index < customers.length; index++) {
//                             const element = customers[index];
//                             customerLabels.push({ label: element.name, value: element.id });
//                         }
//                         options[1].options = customerLabels;

//                         var departments = response.data.data2.departments;
//                         var departmentLabels = [];
//                         for (let index = 0; index < departments.length; index++) {
//                             const element = departments[index];
//                             departmentLabels.push({ label: element.name, value: element.id });
//                         }
//                         options[2].options = departmentLabels;

//                         dispacth({
//                             type: labelType,
//                             payload: options
//                         })

//                         var users = response.data.data2.users;
//                         var userLabels = [];
//                         for (let u = 0; u < users.length; u++) {
//                             const user = users[u];
//                             userLabels.push({ label: user.name, value: user.id })
//                         }

//                         dispacth({
//                             type: employeeListType,
//                             payload: userLabels
//                         })

//                         var groups = response.data.data2.groups;
//                         var groupLabels = [];
//                         for (let u = 0; u < groups.length; u++) {
//                             const group = groups[u];
//                             groupLabels.push({ name: group.name, id: group.id })
//                         }

//                         dispacth({
//                             type: meetingGroupType,
//                             payload: groupLabels
//                         })
//                     }
//                 })
//                 .catch(reason => {
//                     
//                     dispacth(loading())

//                 })
//         } catch (error) {
//             console.log(error)
//             dispacth(loading())
//         }
//     }
// }
