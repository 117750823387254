import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ContentLoader from 'react-content-loader'
import { crud, dateFormatter, ProjectStatus } from '../../config/constants'
import Input from '../anBrains/Input'
import { addGanttType, assignPersonalsType, ganttHeaderType, ganttItemType, ganttType, ganttUnSavedChanges } from '../../redux/action_types'
import { EditIcon, LeftIcon, LongRightArrowIcon, PlusIcon, RightIcon, SettingsIcon, GanttRightIcon, SettingsIcons, TrushIcon } from '../icons'
import { fetchWorkorder, fetchWorkorderDetail, fetchWorkorderFullUpdate } from '../../redux/actions/workorder_ac'
import Modal from '../anBrains/Modal'
import Button from '../anBrains/Button'
import { Rnd } from 'react-rnd';
import { ReactSortable } from "react-sortablejs";
import ProjectStepForm from './ProjectStepForm'
import ReactTooltip from 'react-tooltip';
import { useRef } from 'react'
import { Checkbox } from 'antd';
const ticksToDate = require('ticks-to-date');
export default function ProjectStepContainer(props) {

    const trans = useSelector(state => state.trans)
    const { list, count, unsavedChanges, headerList } = useSelector(state => state.gantt)
    const theme = useSelector(state => state.theme)
    const loader = useSelector(state => state.loader)
    const dispatch = useDispatch()

    const changeDateByParentId = (plist, day, increase) => {
        for (let pi = 0; pi < plist.length; pi++) {
            for (let i = 0; i < list.length; i++) {
                if (list[i].id === plist[pi].id) {
                    var edate = addDay(list[i].endDateGantt, day, increase)
                    list[i].endDateGantt = edate
                    list[i].endDate = edate
                    list[i].end = edate
                    var sdate = addDay(list[i].startDateGantt, day, increase)
                    list[i].startDateGantt = sdate
                    list[i].startDate = sdate
                    list[i].start = sdate
                    if (list.some(x => x.parentId == list[i].id)) {
                        changeDateByParentId(list.filter(x => x.parentId == list[i].id), day, increase)
                    }
                }
            }
        }
    }

    const getDayDiff = (sdate, edate) => Math.round((new Date(sdate) - new Date(edate)) / (60 * 60 * 1000 * 24))

    const addDay = (date, day, increase) => {
        date = new Date(date);
        date.setDate(increase ? (date.getDate() + day) : (date.getDate() - day))
        date = dateFormatter(date)
        return date
    }

    const getTicks = (date) => {
        return ((date.getTime() * 10000) + 621355968000000000);
    }
    const addSeconds = (date, second, increase) => {
        // 
        date = new Date(date);
        date = getTicks(date);
        // date = date + 108000000000
        date = date * 1
        second = second * 10000000;
        if (increase)
            date = date + second;
        else date = date - second;
        date = ticksToDate(date)
        date = dateFormatter(date)
        return date
    }

    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [item, setItem] = useState({})

    const deleteConfirm = (e) => {
        setItem(e);
        setIsDeleteOpen(true)
    }

    const changeRow = (r) => {
        var bool = r.isHeader;

        list.find(x => x.id == r.id).isHeader = (bool === true ? false : true);

        //cut your parent relation

        if (!bool) {
            list.find(x => x.id == r.id).parentId = "";
            list.find(x => x.id == r.id).parentIndex = "";
            list.find(x => x.id == r.id).dependencies = "";

            for (let i = 0; i < list.length; i++) {
                if (list[i].parentId == r.id) {
                    list.find(x => x.id == list[i].id).parentId = "";
                    list.find(x => x.id == list[i].id).parentIndex = "";
                    list.find(x => x.id == list[i].id).dependencies = "";
                }
            }
            r.custom_class = "frappe_custom_bar";
            dispatch({
                type: ganttHeaderType,
                payload: [...headerList, r],
            })
        }
        else {
            list.find(x => x.id == r.id).custom_class = "";
            var leftHeaderList = headerList.filter(x => x.id != r.id);
            dispatch({
                type: ganttHeaderType,
                payload: [...leftHeaderList],
            })
            var filterList = list.filter(x => x.headerId == r.id)
            for (let i = 0; i < filterList.length; i++) {
                list.find(x => x.id == filterList[i].id).headerId = 0;
            }
        }
        dispatch({ type: ganttType, payload: list })
    }
    const collapseRow = (row) => {
        var bool = (row.collapse === true ? false : true);
        var newRows = []
        for (let i = 0; i < list.length; i++) {
            var newrow = list[i]
            if (newrow.headerId == row.id || newrow.id == row.id) {
                newrow.collapse = bool;
            }
            newRows.push(newrow);
        }
        dispatch({ type: ganttType, payload: newRows })
    }
    const [selectedHeaderId, setSelectedHeaderId] = useState(0)
    const changeHeader = (header) => {
        setSelectedHeaderId(header.id)
    }

    const deleteItem = () => {
        setIsDeleteOpen(false)
        dispatch(fetchWorkorder({}, crud.delete, item, trans))
        var filtered = list.filter(x => x.parentId == item.id)
        for (let i = 0; i < list.length; i++)
            for (let li = 0; li < filtered.length; li++) {
                list.find(x => x.id == filtered[li].id).parentId = "";
                list.find(x => x.id == filtered[li].id).dependencies = "";
                list.find(x => x.id == filtered[li].id).readOnly = false;
            }

        let steps = list.filter((e) => {
            return e.id !== item.id
        });
        dispatch({ type: ganttType, payload: steps })
    }

    const changeStatus = (e, id) => {
        list.find(x => x.id === id).status = e.target.value

        if (e.target.value === "3")
            list.find(x => x.id === id).progress = 100;

        dispatch({ type: ganttType, payload: list })
        dispatch({ type: ganttUnSavedChanges, payload: true })
    }

    const saveGant = () => {
        setSelectedHeaderId(0)
        dispatch(fetchWorkorderFullUpdate(list, trans))
        dispatch({ type: ganttUnSavedChanges, payload: false })
    }

    const [isStepOpen, setIsStepOpen] = useState(false)
    const editStep = (step) => {
        setIsStepOpen(true)
        dispatch(fetchWorkorderDetail({ id: step.id, lang: trans.lang, loader: true, isGantStepDetail: true }, crud.list, {}, trans))
    }
    const setOrderList = (dlist) => {
        for (let i = 0; i < dlist.length; i++)
            dlist[i].orderNumber = i;

        for (let i = 0; i < dlist.length; i++) {
            var element = dlist[i];
            element.parentId = element.parentId * 1;
            if (element
                && element.parentId
                && element.parentId > 0)
                dlist[i].parentIndex = dlist.findIndex(x => x.id == element.parentId) + 1;
        }

        dispatch({ type: ganttType, payload: dlist })
    }

    const arrangeHeaderDate = (headerId) => {
        // var header = list.find(x => x.id === headerId)
        var childs = list.filter(x => x.headerId === headerId)
        var values = []
        if (childs && childs.length > 0) {
            for (let i = 0; i < childs.length; i++) {
                const child = childs[i];
                values.push(getTicks(new Date(child.startDateGantt)))
                values.push(getTicks(new Date(child.endDateGantt)))
            }
            var maxValue = Math.max(...values);
            var endDate = ticksToDate(maxValue)
            list.find(x => x.id == headerId).endDateGantt = endDate;
            list.find(x => x.id == headerId).endDate = endDate;
            list.find(x => x.id == headerId).end = endDate;

            var startDate = ticksToDate(Math.min(...values))
            list.find(x => x.id == headerId).start = startDate;
            list.find(x => x.id == headerId).startDate = startDate;
            list.find(x => x.id == headerId).startDateGantt = startDate;
        }
    }

    const setHeaderId = (g, e) => {
        if (e.target.checked) { list.find(x => x.id === g.id).headerId = selectedHeaderId; }
        else { list.find(x => x.id === g.id).headerId = 0; }

        arrangeHeaderDate(selectedHeaderId)

        dispatch({ type: ganttType, payload: list })
    }
    const onChangeHeader = (g) => {
        var id = g.id * 1
        setSelectedHeaderId(id)
    }

    const fooRef = useRef(null)
    return (
        <>
            <Modal
                isOpen={isDeleteOpen}
                withClose
                setIsOpen={setIsDeleteOpen}
                classes={"modal_delete"}>
                {trans.areyousureyouwanttodelete}
                <Button classes="btn-red" onClick={() => deleteItem()}>{trans.confirm}</Button>
            </Modal>

            <Modal
                isOpen={isStepOpen}
                withClose
                setIsOpen={setIsStepOpen}
                classes={"modal_gantt"}>
                <ProjectStepForm setIsOpen={setIsStepOpen} />
            </Modal>

            {/* <Rnd
                    default={{
                        x: 500,
                        y: 100,
                        width: "50%",
                        height: "auto",
                    }}
                    minWidth={500}
                    minHeight={300}
                    bounds="window"
                > */}

            <div className="project_step_container">

                <table>
                    <thead className="firstThead">
                        {/* <tr>
                                <th colSpan={10}></th>
                                <th style={{ textAlign: "right", padding: ".495rem" }}>
                                </th>
                            </tr> */}
                        <tr style={theme === "dark"
                            ? { borderTop: "1px solid rgba(200, 203, 203, 0.1)", borderBottom: "1px solid rgba(200, 203, 203, 0.1)" }
                            : { borderTop: "1px solid #efefef", borderBottom: "1px solid #efefef" }}>
                            {selectedHeaderId > 0 && <th></th>}
                            <th>{trans.no}</th>
                            <th>{trans.name}</th>
                            <th>{trans.startdate}</th>
                            <th>{trans.enddate}</th>
                            <th>{trans.day}</th>
                            <th>{trans.related}</th>
                            <th>{trans.after}</th>
                            <th>{trans.point}</th>
                            <th>{trans.progress + " (%)"}</th>
                            <th>{trans.settings}</th>
                            <th style={{ padding: "1.31rem" }}>{trans.status}</th>
                        </tr>
                    </thead>


                    {/* {headerList && headerList.length > 0 && headerList.map((h, i) => {
                        return <React.Fragment key={"header_" + h.id} > */}
                    {/* <thead>
                                <tr key={"step" + h.id} className="headerStep">

                                    {selectedHeaderId === 0 ?
                                        <td>
                                            <Checkbox
                                                onChange={e => onChangeHeader(h)}
                                                checked={h.id == selectedHeaderId}></Checkbox>

                                        </td> : <td colSpan={2}></td>}
                                    <td colSpan={8} className="headerRow">

                                        <button onClick={() => collapseRow(h)}
                                            style={h.collapse !== true
                                                ? { transform: "rotate(270deg)" }
                                                : { transform: "rotate(90deg)" }}>
                                            <RightIcon color={(theme === "dark"
                                                ? "rgba(96, 168, 221, .8)"
                                                : "rgba(96, 168, 221, .8)")} /></button>
                                        <Input
                                            key={"nameKey"}
                                            placeholder={trans.workorder}
                                            onFocus={e => {
                                                if (e.target.value === "Yeni Satır" || e.target.value === "New Item") {
                                                    headerList.find(x => x.id === h.id).name = "";
                                                    //  dispatch({ type: ganttType, payload: list })
                                                }
                                            }}
                                            onBlur={e => {
                                                if (e.target.value === "") {
                                                    headerList.find(x => x.id === h.id).name = trans.newItem;
                                                    // dispatch({ type: ganttType, payload: list })
                                                }
                                            }}
                                            value={h.name}
                                            onChange={e => {
                                                headerList.find(x => x.id === h.id).name = e.target.value
                                                list.find(x => x.id === h.id).name = e.target.value
                                                dispatch({ type: ganttType, payload: list })
                                                dispatch({ type: ganttHeaderType, payload: headerList })
                                                dispatch({ type: ganttUnSavedChanges, payload: true })
                                            }}
                                        />
                                    </td>

                                    <td className="td_settings">
                                        <button onClick={() => changeRow(h)}
                                            style={h.isHeader === false ? { transform: "rotate(180deg)" } : null}>
                                            <RightIcon color={(theme === "dark"
                                                ? "rgba(96, 168, 221, .8)"
                                                : "rgba(96, 168, 221, .8)")} /></button>


                                        <button onClick={() => deleteConfirm(h)}>
                                            <TrushIcon color={(theme === "dark"
                                                ? "rgba(232, 63, 64, 1)"
                                                : "rgba(232, 63, 64, 1)")} />
                                        </button>

                                    </td>
                                    <td></td>
                                </tr>
                            </thead> */}


                    {/* <tbody style={{
                        borderBottom: (theme === "dark"
                            ? ".5px solid rgba(96, 168, 221, .8)"
                            : ".5px solid  rgba(96, 168, 221, .8)")
                    }}> */}
                    <ReactSortable
                        list={list}
                        setList={datalist => setOrderList(datalist)}
                        tag={"tbody"}>
                        {(list && list.length > 0) ? list.map((g, i) => {
                            return !g.isHeader
                                ? (
                                    <tr key={"step" + g.id} style={g.collapse === true ? { display: "none" } : {}} >
                                        {selectedHeaderId > 0 &&
                                            <td >
                                                <Checkbox
                                                    onChange={e => setHeaderId(g, e)}
                                                    checked={g.headerId == selectedHeaderId}></Checkbox>
                                            </td>}
                                        <td >{(i + 1) + " -"} </td>
                                        <td>
                                            {/* name*/}
                                            <Input placeholder={trans.workorder}
                                                onFocus={e => {
                                                    if (e.target.value === "Yeni Satır" || e.target.value === "New Item") {
                                                        list.find(x => x.id === g.id).name = "";
                                                        //  dispatch({ type: ganttType, payload: list })
                                                    }
                                                }}
                                                onBlur={e => {
                                                    if (e.target.value === "") {
                                                        list.find(x => x.id === g.id).name = trans.newItem;
                                                        // dispatch({ type: ganttType, payload: list })
                                                    }
                                                }}
                                                value={g.name}
                                                onChange={e => {
                                                    list.find(x => x.id === g.id).name = e.target.value
                                                    dispatch({ type: ganttType, payload: list })
                                                    dispatch({ type: ganttUnSavedChanges, payload: true })

                                                }}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type={"datetime-local"}
                                                readOnly={g.parentId !== null && g.parentId !== "" && g.parentId !== undefined && g.parentId !== 0}
                                                value={g.startDateGantt}
                                                styles={{ maxWidth: "128px" }}
                                                onChange={e => {
                                                    list.find(x => x.id === g.id).startDateGantt = e.target.value
                                                    list.find(x => x.id === g.id).startDate = e.target.value
                                                    list.find(x => x.id === g.id).start = e.target.value
                                                    var day = (new Date(list.find(x => x.id === g.id).endDateGantt) - new Date(list.find(x => x.id === g.id).startDateGantt)) / (60 * 60 * 1000 * 24);
                                                    day = day * 1
                                                    day = Math.round(day);
                                                    list.find(x => x.id === g.id).day = day
                                                    if (list.find(x => x.id === g.id).headerId && list.find(x => x.id === g.id).headerId > 0)
                                                        arrangeHeaderDate(list.find(x => x.id === g.id).headerId)
                                                    dispatch({ type: ganttType, payload: list })
                                                    dispatch({ type: ganttUnSavedChanges, payload: true })
                                                }}
                                            // error={err.birthdateError} 
                                            />
                                        </td>
                                        {/* enddate*/}
                                        <td>

                                            <Input
                                                type={"datetime-local"}
                                                // header={trans.birthdate}
                                                value={g.endDateGantt}
                                                styles={{ maxWidth: "128px" }}
                                                onChange={e => {
                                                    var item = list.find(x => x.id === g.id);
                                                    var xday = (new Date(item.endDateGantt) - new Date(item.startDateGantt)) / (60 * 60 * 1000 * 24);
                                                    xday = xday * 1
                                                    xday = Math.round(xday);
                                                    list.find(x => x.id === g.id).endDate = e.target.value
                                                    list.find(x => x.id === g.id).end = e.target.value
                                                    list.find(x => x.id === g.id).endDateGantt = e.target.value
                                                    var day = (new Date(list.find(x => x.id === g.id).endDateGantt) - new Date(list.find(x => x.id === g.id).startDateGantt)) / (60 * 60 * 1000 * 24);
                                                    day = day * 1
                                                    day = Math.round(day);
                                                    list.find(x => x.id === g.id).day = day
                                                    var increase = true
                                                    if (day > 0) {
                                                        if (day > xday) {
                                                            day = day - xday;
                                                            increase = true
                                                        }
                                                        else {
                                                            day = xday - day;
                                                            increase = false
                                                        }
                                                        changeDateByParentId(list.filter(x => x.parentId == g.id), day, increase)
                                                        if (list.find(x => x.id === g.id).headerId && list.find(x => x.id === g.id).headerId > 0)
                                                            arrangeHeaderDate(list.find(x => x.id === g.id).headerId)

                                                        dispatch({ type: ganttType, payload: list })
                                                        dispatch({ type: ganttUnSavedChanges, payload: true })

                                                    }
                                                }}
                                            /></td>
                                        {/* Day */}
                                        <td>
                                            <Input
                                                type={"number"}
                                                min={0}
                                                styles={{ maxWidth: "50px" }}
                                                value={g.day}
                                                onFocus={e => {
                                                    if (e.target.value === "0") {
                                                        list.find(x => x.id === g.id).day = "";
                                                    }
                                                }}
                                                onBlur={e => {
                                                    if (e.target.value === "") {
                                                        list.find(x => x.id === g.id).day = 0;
                                                    }
                                                }}
                                                onChange={e => {

                                                    var value = e.target.value;
                                                    list.find(x => x.id === g.id).day = value;
                                                    value = value * 1;
                                                    value = Math.round(value);
                                                    if (value > 0) {
                                                        var day = getDayDiff(list.find(x => x.id === g.id).endDateGantt, list.find(x => x.id === g.id).startDateGantt)
                                                        day = day * 1;
                                                        day = Math.round(day);
                                                        var increase = false
                                                        var equal = false;
                                                        if (day > value)
                                                            day = day - value
                                                        else if (value > day) {
                                                            day = value - day
                                                            increase = true
                                                        }
                                                        else equal = true

                                                        if (!equal) {
                                                            var date = addDay(list.find(x => x.id === g.id).endDateGantt, day, increase)
                                                            list.find(x => x.id === g.id).endDateGantt = date;
                                                            list.find(x => x.id === g.id).endDate = date;
                                                            list.find(x => x.id === g.id).end = date;
                                                            changeDateByParentId(list.filter(x => x.parentId == g.id), day, increase)
                                                            if (list.find(x => x.id === g.id).headerId && list.find(x => x.id === g.id).headerId > 0)
                                                                arrangeHeaderDate(list.find(x => x.id === g.id).headerId)
                                                        }
                                                    }
                                                    else list.find(x => x.id == g.id).cannotbindtheprojectsteptoitself = ""
                                                    dispatch({ type: ganttUnSavedChanges, payload: true })
                                                    dispatch({ type: ganttType, payload: list })
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type={"number"}
                                                styles={{ maxWidth: "50px" }}
                                                value={g.parentIndex}
                                                onChange={e => {

                                                    var parentIndex = e.target.value * 1;
                                                    parentIndex = parentIndex - 1;
                                                    var parentId = "";
                                                    if (parentIndex >= 0 && list.length > parentIndex) {
                                                        parentId = list[parentIndex].id;
                                                        if (!list[parentIndex].isHeader) {
                                                            list.find(x => x.id == g.id).parentIndex = parentIndex + 1;
                                                            list.find(x => x.id == g.id).dependencies = parentId?.toString();
                                                            list.find(x => x.id == g.id).parentId = parentId;
                                                        }
                                                    }
                                                    else {
                                                        list.find(x => x.id == g.id).parentIndex = "";
                                                        list.find(x => x.id == g.id).dependencies = "";
                                                        list.find(x => x.id == g.id).parentId = "";
                                                    }

                                                    if (!list.some(x => x.id == parentId) && parentId !== "") {
                                                        list.find(x => x.id == g.id).parentId = parentId.toString()
                                                        list.find(x => x.id == g.id).cannotbindtheprojectsteptoitself = trans.cannotbindtheprojectsteptoitself
                                                        list.find(x => x.id == g.id).readOnly = false
                                                    }
                                                    else if (list.find(x => x.id == g.id).id == parentId && parentId !== "") {
                                                        list.find(x => x.id == g.id).parentId = parentId.toString()
                                                        list.find(x => x.id == g.id).cannotbindtheprojectsteptoitself = trans.cannotbindtheprojectsteptoitself
                                                        list.find(x => x.id == g.id).readOnly = false
                                                    }
                                                    else if (parentId !== "") {
                                                        debugger
                                                        if (!list[parentIndex].isHeader) {
                                                            list.find(x => x.id == g.id).parentId = parentId.toString()
                                                            list.find(x => x.id == g.id).cannotbindtheprojectsteptoitself = ""
                                                            list.find(x => x.id == g.id).readOnly = true
                                                            var startDate = list.find(x => x.id == g.id).startDateGantt;
                                                            var endDate = list.find(x => x.id == g.id).endDateGantt;
                                                            var parentEndDate = list.find(x => x.id == parentId).endDateGantt;
                                                            var day = getDayDiff(endDate, startDate)
                                                            list.find(x => x.id == g.id).day = day;
                                                            g.afterDay = g.afterDay * 1;
                                                            if (g.afterDay > 0)
                                                                day = day + g.afterDay

                                                            startDate = addSeconds(parentEndDate, 1, true)
                                                            list.find(x => x.id == g.id).start = startDate;
                                                            list.find(x => x.id == g.id).startDate = startDate;
                                                            list.find(x => x.id == g.id).startDateGantt = startDate;
                                                            startDate = addSeconds(startDate, 1, false)
                                                            endDate = addDay(startDate, day, true)
                                                            list.find(x => x.id == g.id).endDateGantt = endDate;
                                                            list.find(x => x.id == g.id).endDate = endDate;
                                                            list.find(x => x.id == g.id).end = endDate;
                                                            changeDateByParentId(list.filter(x => x.parentId == g.id), day, true)
                                                        }

                                                    }
                                                    else if (parentId == "") {
                                                        list.find(x => x.id == g.id).parentId = parentId.toString()
                                                        list.find(x => x.id == g.id).cannotbindtheprojectsteptoitselfs = ""
                                                        list.find(x => x.id == g.id).readOnly = false
                                                    }

                                                    dispatch({ type: ganttType, payload: list })

                                                    if (list.find(x => x.id === g.id).headerId && list.find(x => x.id === g.id).headerId > 0)
                                                        arrangeHeaderDate(list.find(x => x.id === g.id).headerId)
                                                    dispatch({ type: ganttUnSavedChanges, payload: true })
                                                }}
                                                error={g.cannotbindtheprojectsteptoitself}
                                            />
                                        </td>
                                        <td>
                                            <Input
                                                type={"number"}
                                                readOnly={!(g.parentId > 0)}
                                                min={0}
                                                styles={{ maxWidth: "50px" }}
                                                value={g.afterDay}
                                                onFocus={e => {
                                                    if (e.target.value === "0") {
                                                        list.find(x => x.id === g.id).afterDay = "";
                                                    }
                                                }}
                                                onBlur={e => {
                                                    if (e.target.value === "") {
                                                        list.find(x => x.id === g.id).afterDay = 0;
                                                    }
                                                }}
                                                onChange={e => {
                                                    var after = list.find(x => x.id === g.id).afterDay;
                                                    list.find(x => x.id === g.id).afterDay = e.target.value;
                                                    if (isNaN(after)) {
                                                        after = 0
                                                    }
                                                    var increase = true
                                                    if (after > e.target.value) {
                                                        after = after - e.target.value
                                                        increase = false
                                                    }
                                                    else {
                                                        after = e.target.value - after
                                                        increase = true
                                                    }
                                                    var edate = addDay(list.find(x => x.id === g.id).endDateGantt, after, increase)
                                                    list.find(x => x.id === g.id).endDateGantt = edate;
                                                    list.find(x => x.id === g.id).endDate = edate;
                                                    list.find(x => x.id === g.id).end = edate;
                                                    var sdate = addDay(list.find(x => x.id === g.id).startDateGantt, after, increase)
                                                    list.find(x => x.id === g.id).startDateGantt = sdate;
                                                    list.find(x => x.id === g.id).startDate = sdate;
                                                    list.find(x => x.id === g.id).start = sdate;
                                                    changeDateByParentId(list.filter(x => x.parentId === g.id), after, increase)

                                                    if (list.find(x => x.id === g.id).headerId && list.find(x => x.id === g.id).headerId > 0)
                                                        arrangeHeaderDate(list.find(x => x.id === g.id).headerId)

                                                    dispatch({ type: ganttType, payload: list })
                                                    dispatch({ type: ganttUnSavedChanges, payload: true })
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <div className="input-container">
                                                <select
                                                    onChange={e => {
                                                        list.find(x => x.id === g.id).point = e.target.value
                                                        dispatch({ type: ganttType, payload: list })
                                                        dispatch({ type: ganttUnSavedChanges, payload: true })

                                                    }}
                                                    value={g.point} >
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="5">5</option>
                                                    <option value="8">8</option>
                                                    <option value="13">13</option>
                                                    <option value="21">21</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td wrap="true">
                                            <Input
                                                styles={{ maxWidth: "50px" }}
                                                onFocus={e => {
                                                    if (e.target.value === "0")
                                                        list.find(x => x.id === g.id).progress = "";
                                                }}
                                                onBlur={e => {
                                                    if (e.target.value === "")
                                                        list.find(x => x.id === g.id).progress = 0;
                                                }}
                                                value={g.progress}
                                                onChange={e => {
                                                    var value = e.target.value;
                                                    if (100 >= value > 0) {
                                                        list.find(x => x.id === g.id).progress = e.target.value
                                                        dispatch({ type: ganttType, payload: list })
                                                        dispatch({ type: ganttUnSavedChanges, payload: true })
                                                    }
                                                }}
                                                type="number"
                                                max={100}
                                                min={0}
                                            /></td>
                                        <td className="td_settings">
                                            <button onClick={() => changeRow(g)}
                                                style={g.isHeader === false ? { transform: "rotate(180deg)" } : null}>
                                                <RightIcon color={(theme === "dark"
                                                    ? "rgba(96, 168, 221, .8)"
                                                    : "rgba(96, 168, 221, .8)")} /></button>
                                            <button onClick={() => deleteConfirm(g)}>
                                                <TrushIcon color={(theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)")} />
                                            </button>
                                            {!g.isHeader && (unsavedChanges === true
                                                ? <button
                                                    title={trans.therearesomeunsavedchanges}
                                                    onClick={() => {
                                                        list.map(x => x.unsaved = false);
                                                        g.unsaved = true
                                                        dispatch({ type: ganttType, payload: list })
                                                        setTimeout(() => {
                                                            list.map(x => x.unsaved = false);
                                                            dispatch({ type: ganttType, payload: list })
                                                        }, 3000);
                                                    }}>
                                                    <SettingsIcon color={(theme === "dark" ? "rgba(96, 168, 221, .8)" : "rgba(96, 168, 221, .2)")}
                                                    />
                                                    {g.unsaved && <>
                                                        <p data-tip='' data-for={"test" + g.id}></p>
                                                        <ReactTooltip id={"test" + g.id}>{trans.therearesomeunsavedchanges}</ReactTooltip>
                                                    </>}
                                                </button>
                                                : <button onClick={() => editStep(g)}>
                                                    <SettingsIcon color={(theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")} />
                                                </button>)}
                                        </td>
                                        <td>
                                            <div className="input-container">
                                                <select
                                                    onChange={e => changeStatus(e, g.id)}
                                                    value={g.status} >
                                                    {ProjectStatus.map((c, i) => {
                                                        return (
                                                            <option key={"status" + i} value={c.id}>{c[trans.lang]}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div></td>
                                    </tr>) :
                                (
                                    <tr key={"step" + g.id} className={"headerStep "+ g.custom_class}>

                                        {selectedHeaderId === 0 ?
                                            <td>
                                                {/* <hr /> */}
                                                <Checkbox
                                                    onChange={e => onChangeHeader(g)}
                                                    checked={g.id == selectedHeaderId}></Checkbox>

                                            </td> : <td colSpan={2}></td>}
                                        <td colSpan={8} className={"headerRow"} >
                                            <button onClick={() => collapseRow(g)}
                                                style={g.collapse !== true
                                                    ? { transform: "rotate(270deg)" }
                                                    : { transform: "rotate(90deg)" }}>
                                                <RightIcon color={(theme === "dark"
                                                    ? "rgba(96, 168, 221, .8)"
                                                    : "rgba(96, 168, 221, .8)")} /></button>
                                            <Input
                                                key={"nameKey"}
                                                placeholder={trans.workorder}
                                                onFocus={e => {
                                                    if (e.target.value === "Yeni Satır" || e.target.value === "New Item") {
                                                        headerList.find(x => x.id === g.id).name = "";
                                                        //  dispatch({ type: ganttType, payload: list })
                                                    }
                                                }}
                                                onBlur={e => {
                                                    if (e.target.value === "") {
                                                        headerList.find(x => x.id === g.id).name = trans.newItem;
                                                        // dispatch({ type: ganttType, payload: list })
                                                    }
                                                }}
                                                value={g.name}
                                                onChange={e => {
                                                    headerList.find(x => x.id === g.id).name = e.target.value
                                                    list.find(x => x.id === g.id).name = e.target.value
                                                    dispatch({ type: ganttType, payload: list })
                                                    dispatch({ type: ganttHeaderType, payload: headerList })
                                                    dispatch({ type: ganttUnSavedChanges, payload: true })
                                                }}
                                            />
                                        </td>

                                        <td className="td_settings">
                                            <button onClick={() => changeRow(g)}
                                                style={g.isHeader === false ? { transform: "rotate(180deg)" } : null}>
                                                <RightIcon color={(theme === "dark"
                                                    ? "rgba(96, 168, 221, .8)"
                                                    : "rgba(96, 168, 221, .8)")} /></button>


                                            <button onClick={() => deleteConfirm(g)}>
                                                <TrushIcon color={(theme === "dark"
                                                    ? "rgba(232, 63, 64, 1)"
                                                    : "rgba(232, 63, 64, 1)")} />
                                            </button>

                                        </td>
                                        <td></td>
                                    </tr>)
                        })
                            : null}
                    </ReactSortable>
                    {/* </tbody> */}

                    {/* </React.Fragment> */}
                    {/* })} */}


                    {/* <ReactSortable
                       // list={list.filter(x => x.headerId === 0 && x.isHeader === false)}
                        // setList={datalist => setOrderList(datalist)}
                        // setList={datalist => {
                        //     dispatch({ type: ganttType, payload: datalist })
                        // }}
                        tag={"tbody"}
                        style={{
                            borderTop: (theme === "dark"
                                ? ".5px solid rgba(96, 168, 221, .8)"
                                : ".5px solid  rgba(96, 168, 221, .8)")
                        }}
                    > */}

                    {/* </ReactSortable> */}

                    <tfoot>
                        <tr>
                            <th colSpan={11} >
                                <Button
                                    icon={<PlusIcon color={(theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")} />}
                                    classes="mr-4"
                                    style={{ float: "left" }}
                                    onClick={() => {
                                        dispatch(fetchWorkorder({ projectId: props.projectId }, crud.create, {
                                            projectId: props.projectId,
                                            name: trans.newItem,
                                            description: "",
                                            point: 1,
                                            progress: 0,
                                            type: 2,
                                            index: list.length
                                        }, trans))
                                    }}>{trans.add}</Button>
                                <Button
                                    //icon={<PlusIcon color={(theme === "dark" ? "#3e9fbe" : "#1a9bc4")} />}
                                    classes=""
                                    style={{ backgroundColor: "rgba(96, 168, 221, 1)", color: "white", float: "left" }}
                                    onClick={() => {
                                        saveGant(list)
                                    }}
                                >{trans.save}
                                </Button>

                                {selectedHeaderId > 0 && <Button
                                    //icon={<PlusIcon color={(theme === "dark" ? "#3e9fbe" : "#1a9bc4")} />}
                                    classes={"btn btn-dark ml-4"}
                                    style={{ float: "left" }}
                                    onClick={() => {
                                        setSelectedHeaderId(0)
                                    }}
                                >{trans.cancel}
                                </Button>}
                                {unsavedChanges === true
                                    ? <span className="ganttMessage">{trans.therearesomeunsavedchanges}</span>
                                    : null}
                            </th>
                        </tr>
                    </tfoot>
                </table>
            </div>
            {/* </Rnd> */}
        </>
    )

}
