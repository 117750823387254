import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Pagination from '../anBrains/Pagination'
import { PlusIcon } from '../icons'
import Button from '../anBrains/Button'
import { fetchDepartment } from '../../redux/actions/department_ac'
import { crud, dateFormatter } from '../../config/constants'
import Modal from '../anBrains/Modal'
import Input from '../anBrains/Input'
import { baseUrl } from '../../config'
import { fetchCustomerEvents } from '../../redux/actions/customer_event_ac'
import manprofile from '../../images/profiles/man.png';
import womanprofile from '../../images/profiles/woman.png';
import { EditIcon, TrushIcon } from '../icons'
import { customerEventType } from '../../redux/action_types'

export default function (props) {
    const { customerId } = props;
    const current = useSelector(state => state.current)
    const { list } = useSelector(state => state.department)
    const trans = useSelector(state => state.trans)
    const dispatch = useDispatch();
    const customerevent = useSelector(state => state.customerevent)
    const [request, setRequest] = useState({customerId:0, departmentId:0, rowCount: 4, pageCount: 0, search: "", sort: "id", "desc": true, data: {} })
    const theme = useSelector(state => state.theme)
    const [isAddOpen, setIsAddOpen] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [item, setItem] = useState({})
    useEffect(() => {
        dispatch(fetchDepartment({}, crud.list,{}, trans))
        dispatch(fetchCustomerEvents({ ...request, customerId  }, crud.list,{}, trans))
    }, [])

    const [selectedDepartmentId, setSelectedDepartmentId] = useState(0)

    const errors = {
        nameErr: "",
        dateError: "",
        departmentIdError: ""
    }

    const [err, setErr] = useState(errors)

    const [selectedPage, setSelectedPage] = useState(1)

    const setSelectedPageFilter = (n) => {
        setSelectedPage(n)
        setRequest({ ...request, pageCount: n - 1 , departmentId:selectedDepartmentId })
        dispatch(fetchCustomerEvents({ ...request, pageCount: n - 1 , departmentId:selectedDepartmentId}, crud.list,{}, trans));
    }

    const fields = {
        name: "",
        description: "",
        customerId,
        departmentId: selectedDepartmentId,
        date: dateFormatter(new Date())
    }
    const [field, setField] = useState(fields)

    const openForm = () => {
        fields.name = "";
        fields.description = "";
        setField(fields)
        setIsAddOpen(true);
    }

    let validate = () => {
        if (field.name.length < 1 || field.name.length > 30) {
            setErr({ ...errors, nameErr: trans.toShort })
            return false
        }
        if (field.date == "" || field.date == undefined) {
            setErr({ ...errors, dateError: trans.pleaseEnterValidDate })
            return false
        }
        if (!field.departmentId || field.departmentId === 0 || field.departmentId === "0") {
            field.departmentId = list[0].id
            // setSelectedDepartmentId(list[0].id )
            // setField({ ...field, departmentId : 111 })
            // setErr({ ...errors, departmentIdError: "pleaseenterDepartmentID" })
            // return false
        }
        return true
    }

    const deleteItem = () => {
        setIsDeleteOpen(false)
        dispatch(fetchCustomerEvents({ ...request }, crud.delete, item, trans))
        let events = customerevent.filter((e) => {
            return e.id !== item.id
        });
        dispatch({ type: customerEventType, payload: events })
    }

    const editItem = (e) => {
        setField(e);
        setIsAddOpen(true);
        setIsEdit(true)
    }

    const [isEdit, setIsEdit] = useState(false)

     const changeDepartmentModal = e => {
         var departmentıd = e.target.value;
         //setSelectedDepartmentId(e.target.value)
         setField({ ...fields, departmentId: e.target.value })
     }

    const changeDepartment = (e) => {
        var departmentId = e.target.value;
        setSelectedPage(1)
        if (departmentId === "0")
            dispatch(fetchCustomerEvents({ ...request, pageCount: 0 , customerId }, crud.list,{}, trans));
        else
            dispatch(fetchCustomerEvents({ ...request, pageCount: 0 , customerId, departmentId }, crud.list,{}, trans));
            
        setSelectedDepartmentId(departmentId)
        
    }

    const register = e => {
        e.preventDefault();
        if (validate()) {
            setErr(errors)
            if (!isEdit) {
                console.log(field);
                dispatch(fetchCustomerEvents({ request, customerId  }, crud.create, field, trans))
                setIsAddOpen(false)
            }
            else {
                dispatch(fetchCustomerEvents({ request, customerId  }, crud.update, field, trans))
                setIsEdit(false)
                setIsAddOpen(false)
            }
        }
    }

    const openDelete = (e) => {
        setIsDeleteOpen(true);
        setItem(e);
    }

    return (
        <div className="event_container">
            <Modal
                isOpen={isAddOpen}
                withClose
                setIsOpen={setIsAddOpen}
                classes={"modal_add_leave"}>
                <div className="leaveForm">
                    <Input
                        placeholder={trans.suggestionTitle}
                        name={trans.suggestionTitle}
                        header={trans.suggestionTitle}
                        value={field.name}
                        onChange={e => setField({ ...field, name: e.target.value })}
                        error={err.nameErr} />
                    <Input
                        type={"datetime-local"}
                        header={trans.date}
                        value={field.date}
                        onChange={e => setField({ ...field, date: e.target.value })} />
                    <div className="input-container">
                        <select
                            className={`input select ${err.projectGroupIdError ? 'input-error' : ''} `}
                            value={field.departmentId}
                            onChange={changeDepartmentModal} >
                            {
                                list && list.length > 0 && list.map((item, i) => {
                                    return (
                                        <option key={i} value={item.id}>{item.name}
                                        </option>
                                    )
                                })
                            }

                        </select>
                    </div>

                    <Input placeholder={trans.description}
                        name={trans.description}
                        header={trans.description}
                        value={field.description}
                        textarea={true}
                        onChange={e => setField({ ...field, description: e.target.value })}
                        // error={err.descriptionError}
                        maxLength={200}
                        minLength={3} />

                    <Button classes={"btn-primary"} onClick={register}>{trans.save}</Button>
                </div>
            </Modal>
            <Modal
                isOpen={isDeleteOpen}
                withClose
                setIsOpen={setIsDeleteOpen}
                classes={"modal_delete"}>
                {trans.areyousureyouwanttodelete}
                <Button classes="btn-red" onClick={() => deleteItem()}>{trans.confirm}</Button>
            </Modal>
            <div className="table_properties">
                <div className="input-container">
                    <select className="input select selectfilter" value={selectedDepartmentId} onChange={changeDepartment} >
                        <option value={0}>{trans.all}</option>
                        {
                            list && list.length > 0 && list.map((item, i) => {
                                return (
                                    <option key={i} value={item.id}>{item.name}
                                    </option>
                                )
                            })
                        }

                    </select>
                </div>
                <Button
                    icon={<PlusIcon color="rgba(96, 168, 221, 1)" />}
                    classes="btn-basic"
                    onClick={() => openForm(true)} > {trans.add}

                </Button>
            </div>

            <div className="event_list">
                {customerevent && customerevent.map((e, i) => {
                    return (
                        <div className="event_row" key={i}>
                            <div className="event_profile">
                                <img src={e.creatorPhoto ? baseUrl + e.creatorPhoto : (e && e.creatorGender) ? manprofile : womanprofile}
                                    title={e.creatorName} />
                            </div>
                            <div className="event_desc">
                                <span>{e.dateString}</span>
                                <span>{e.name}</span>
                                <span>{e.description}</span>
                            </div>
                            <button onClick={() => editItem(e)}>
                                <EditIcon color={(theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")} />
                            </button>
                            <button onClick={() => openDelete(e)}>
                                <TrushIcon color={(theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)")} />
                            </button>
                        </div>
                    )
                })}
            </div>


            {current && current.pageCount > 0 &&
                <Pagination
                 setSelectedPage={setSelectedPageFilter}
                 selectedPage={selectedPage} 
                />}
        </div>
    )
}