import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Layout from "../../components/Container/Layout";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/anBrains/Button";
import Input from "../../components/anBrains/Input";
import {
  barListType,
  gridListType,
  meetingGroupType,
  meetingType,
  breadcrumbType,
  addBreadcrumbType,
  itemType,
  workorderType,
  workOrderGroupType,
} from "../../redux/action_types";
import Modal from "../../components/anBrains/Modal";
import { PlusIcon, GridIcon, BarIcon, SortIcon, Search2Icon, EditIcon, TrushIcon, DocumentIcon } from "../../components/icons";
import ContentLoader from "react-content-loader";
import { baseUrl } from "../../config";
import { crud, dateFormatter } from "../../config/constants";
import { fetchWorkOrderGroup } from "../../redux/actions/workOrder_group_ac";
import { fetchWorkorder } from "../../redux/actions/workorder_ac";
import MeetingForm from "../../components/groupMeeting/MeetingForm";
import manprofile from "../../images/profiles/man.png";
import womanprofile from "../../images/profiles/woman.png";
import { Pagination } from "antd";
const defaultItem = {
  labelIds: [],
  userIds: [],
  date: dateFormatter(new Date()),
  name: "",
  description: "",
  desicion: "",
  workOrderGroupId: 0,
};
export default function MeetingGroupManagement(props) {
  const dispatch = useDispatch();
  const trans = useSelector((state) => state.trans);
  const theme = useSelector((state) => state.theme);
  const current = useSelector((state) => state.current);
  const workordergroup = useSelector((state) => state.workordergroup);
  const workorder = useSelector((state) => state.workorder);
  const [searchText, setSearchText] = useState("");
  const [searchMeetingGroupText, setSearchMeetingGroupText] = useState("");
  const loader = useSelector((state) => state.loader);
  const [selectedPage, setSelectedPage] = useState(1);
  const [editState, setEditState] = useState(0);
  const [addState, setAddState] = useState(false);
  const [isDeleteGroupOpen, setIsDeleteGroupOpen] = useState(false);
  const [isAddMeetingOpen, setIsAddMeetingOpen] = useState(false);
  const [isDeleteMeetingOpen, setIsDeleteMeetingOpen] = useState(false);
  const [itemGroup, setItemGroup] = useState({});
  const [itemMeeting, setItemMeeting] = useState({});
  const result = useSelector((state) => state.result);
  const { breadcrumbs } = useSelector((state) => state.current);
  const [requestMeeting, setRequestMeeting] = useState({ rowCount: 14, pageCount: 0, search: "", type: 5, sort: "id", desc: false, isMeeting: true });
  const [request, setRequest] = useState({ rowCount: 14, pageCount: 0, type: 5, search: "", sort: "id", desc: false, isMeeting: true });

  useEffect(() => {
    dispatch(fetchWorkOrderGroup({ ...request, active: false, loader: true }, crud.with, {}, trans));
  }, []);

  useEffect(() => {
    dispatch({ type: itemType, payload: defaultItem });
  }, []);

  useEffect(() => {
    if (workordergroup.list && workordergroup.list.length > 0) {
      workordergroup.list[0].selected = true;
      current.item.workOrderGroupId = workordergroup.list[0].id;
      setRequest({ ...request, workOrderGroupId: workordergroup.list[0].id });
    }
  }, [workordergroup.list]);

  var fields = {
    id: 0,
    name: "",
  };
  const [field, setField] = useState(fields);

  var err = {
    errName: "",
  };
  const [error, setError] = useState(err);

  let validate = () => {
    if (field.name.length < 3) {
      setError({ ...error, errName: trans.least });
      return false;
    }
    if (field.name.length > 40) {
      setError({ ...error, errName: trans.toShort });
      return false;
    }
    return true;
  };

  const deleteGroupConfirm = (e) => {
    setIsDeleteGroupOpen(true);
    setItemGroup(e);
  };

  const deleteMeetingConfirm = (e) => {
    setIsDeleteMeetingOpen(true);
    setItemMeeting(e);
  };

  const deleteGroupItem = () => {
    setIsDeleteGroupOpen(false);
    dispatch(fetchWorkOrderGroup({ ...request, loader: true }, crud.delete, itemGroup, trans));
    let groups = workordergroup.list.filter((e) => {
      return e.id !== itemGroup.id;
    });
    dispatch({ type: meetingGroupType, payload: groups });
  };

  const deleteMeetingItem = () => {
    setIsDeleteMeetingOpen(false);
    dispatch(fetchWorkorder({ ...request, loader: true }, crud.delete, itemMeeting, trans));
    let workorders = workorder.list.filter((e) => {
      return e.id !== itemMeeting.id;
    });
    dispatch({ type: workorderType, payload: workorders });
  };

  const searchChange = (text) => {
    setSearchText(text);
    if (text.length > 2) {
      dispatch(fetchWorkorder({ ...requestMeeting, search: text }, crud.list, {}, trans));
    }
  };

  const searchChangeKeyPress = (text, kcode) => {
    if (kcode === 8) {
      dispatch(fetchWorkorder({ ...requestMeeting, search: text }, crud.list, {}, trans));
    }
  };

  const selectMeetingGroup = (i) => {
    for (let index = 0; index < workordergroup.list.length; index++) workordergroup.list[index].selected = false;

    workordergroup.list[i].selected = true;
    current.item.workOrderGroupId = workordergroup.list[i].id;
    dispatch({ type: itemType, payload: current.item });
    dispatch({ type: workOrderGroupType, payload: workordergroup.list });
    dispatch(fetchWorkorder({ ...requestMeeting, workOrderGroupId: workordergroup.list[i].id, loader: true }, crud.list, {}, trans));
    setRequestMeeting({ ...requestMeeting, workOrderGroupId: workordergroup.list[i].id });
  };

  const [desc, setDesc] = useState(false);
  const sortbyField = (f) => {
    setRequestMeeting({ ...requestMeeting, sort: f, desc });
    dispatch(fetchWorkorder({ ...requestMeeting, sort: f, desc }, crud.list, {}, trans));
    setDesc(!desc);
  };

  const showBar = () => {
    dispatch({ type: barListType });
  };
  const showGrid = () => {
    dispatch({ type: gridListType });
  };

  const history = useHistory();
  const detailItem = (e) => {
    if (breadcrumbs && breadcrumbs.some((b) => b.name === e.name)) {
    }
    if (breadcrumbs.length > 1) {
      var newBreadCrumbs = [];
      newBreadCrumbs.push(breadcrumbs[0]);
      newBreadCrumbs.push({ name: e.name, path: "/meeting-management/meeting-detail" });
      dispatch({
        type: breadcrumbType,
        payload: newBreadCrumbs,
      });
    } else {
      dispatch({
        type: addBreadcrumbType,
        payload: { name: e.name, path: "/meeting-management/meeting-detail" },
      });
    }
    history.push("/meeting-management/meeting-detail/" + e.id);
  };

  const setSelectedPageFilter = (n) => {
    setSelectedPage(n);
    setRequestMeeting({ ...requestMeeting, pageCount: n - 1 });
    dispatch(fetchWorkorder({ ...requestMeeting, pageCount: n - 1 }, crud.list, {}, trans));
  };

  const setIsEditItem = (e) => {
    setEditState(e.id);
    setAddState(false);
    setField({ ...field, id: e.id, name: e.name });
  };

  const editItem = () => {
    if (validate()) {
      setError(err);
      dispatch(fetchWorkOrderGroup(requestMeeting, crud.update, field, trans));
      setEditState(0);
    }
  };

  const setIsAddItem = () => {
    setField(fields);
    setAddState(true);
    setEditState(0);
  };

  const addItem = () => {
    if (validate()) {
      setError(err);
      dispatch(fetchWorkOrderGroup(requestMeeting, crud.create, field, trans));
      setAddState(false);
      setField(fields);
    }
  };

  const searchMeetingGroupChange = (text) => {
    setSearchMeetingGroupText(text);
    if (text.length > 0) {
      let groups = workordergroup.list.filter((e, i) => {
        return e.name.includes(text);
      });
      workordergroup.list = groups;
    } else workordergroup.list = workordergroup.currentList;
  };

  return (
    <Layout>
      <Modal isOpen={isAddMeetingOpen} withClose setIsOpen={setIsAddMeetingOpen} classes={"modal_add_employee"}>
        <MeetingForm setIsAddMeetingOpen={setIsAddMeetingOpen} />
      </Modal>
      <Modal isOpen={isDeleteGroupOpen} withClose setIsOpen={setIsDeleteGroupOpen} classes={"modal_delete"}>
        {trans.areyousureyouwanttodelete}
        <Button classes="btn-red" onClick={() => deleteGroupItem()}>
          {trans.confirm}
        </Button>
      </Modal>
      <Modal isOpen={isDeleteMeetingOpen} withClose setIsOpen={setIsDeleteMeetingOpen} classes={"modal_delete"}>
        {trans.areyousureyouwanttodelete}
        <Button classes="btn-red" onClick={() => deleteMeetingItem()}>
          {trans.confirm}
        </Button>
      </Modal>
      <div className="table_properties">
        <Input
          icon={<Search2Icon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />}
          placeholder={trans.search}
          value={searchText}
          onChange={(e) => searchChange(e.target.value)}
          onKeyUp={(e) => searchChangeKeyPress(e.target.value, e.keyCode)}
        />
        <Button
          icon={<PlusIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />}
          classes="btn-basic"
          onClick={() => setIsAddMeetingOpen(true)}
        >
          {trans.add}
        </Button>
        <div className="bar_grid">
          <button onClick={showBar}>
            <BarIcon color={current.bar ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#5d5d5d"} />
          </button>
          <button onClick={showGrid}>
            <GridIcon
              color={current.grid ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#5d5d5d"}
            />
          </button>
        </div>
      </div>

      {current.bar &&
        (!loader.loading ? (
          <div className="project_container">
            <div className="project_group">
              <h2>{trans.meetingGroup}</h2>
              <div className="meeting_group_container">
                <Input
                  icon={<Search2Icon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />}
                  placeholder={trans.search}
                  value={searchMeetingGroupText}
                  onChange={(e) => searchMeetingGroupChange(e.target.value)}
                />
                <ul className="meeting_group_list">
                  {workordergroup.list &&
                    workordergroup.list.length > 0 &&
                    workordergroup.list.map((mg, i) => {
                      return editState != mg.id ? (
                        <li className={mg.selected ? "active d-flex" : "d-flex"} key={"meeting_group_" + i}>
                          <div className="field_1" onClick={() => selectMeetingGroup(i)}>
                            <span>{mg.name}</span>
                          </div>
                          <div className="field_2">
                            <a className="edit hide mr-2" onClick={() => setIsEditItem(mg)}>
                              <EditIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"}></EditIcon>
                            </a>
                            <a className="trash hide" onClick={() => deleteGroupConfirm(mg)}>
                              <TrushIcon color={theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)"}></TrushIcon>
                            </a>
                          </div>
                        </li>
                      ) : (
                        <div className="edit_group" key={i}>
                          <div className="d-flex edit_group_line">
                            <Input
                              placeholder={trans.groupName}
                              name={("edit_group_name_", i)}
                              id={("edit_group_name_", i)}
                              className="hide_text"
                              key={i}
                              value={field.name}
                              error={error.errName}
                              onChange={(e) => setField({ ...fields, id: mg.id, name: e.target.value })}
                            />
                            <button className="btn btn-basic" onClick={() => editItem()}>
                              {trans.save}
                            </button>
                          </div>
                          <span className="errorspan p-1">{error.errName}</span>
                        </div>
                      );
                    })}
                </ul>
                <div className="grop_container_buttons">
                  <div className={`add_grop_form ${addState ? "" : "d-none"}`}>
                    <Input
                      name={"add_group_name"}
                      id={"add_group_name"}
                      header={trans.groupName}
                      error={error.errName}
                      value={field.name}
                      onChange={(e) => setField({ ...fields, name: e.target.value })}
                    />
                    <div className="d-flex">
                      <button className="btn btn-basic btn-group" onClick={() => setAddState(false)}>
                        {trans.close}
                      </button>
                      <button className="btn btn-basic btn-group" onClick={() => addItem()}>
                        {trans.save}
                      </button>
                    </div>
                    {/* <span className='errorspan p-1'>{error.errName}</span> */}
                  </div>
                  {addState ? (
                    ""
                  ) : (
                    <Button
                      icon={<PlusIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />}
                      classes="add_group"
                      onClick={() => setIsAddItem()}
                    ></Button>
                  )}
                </div>
              </div>
            </div>
            <div className="project_view">
              <div className="table">
                <table>
                  <thead>
                    <tr>
                      <th onClick={() => sortbyField("name")}>
                        {trans.subject} <SortIcon color="rgba(96, 168, 221, 1)" />
                      </th>
                      <th>{trans.participants}</th>
                      <th>{trans.labels}</th>
                      <th onClick={() => sortbyField("startdate")}>
                        {trans.meetingDate} <SortIcon color="rgba(96, 168, 221, 1)" />
                      </th>
                      {/* <th>{trans.decisionsTaken}</th>
                                            <th>{trans.notes}</th>
                                            <th>{trans.file}</th> */}
                      <th>{trans.settings}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {workorder.list &&
                      workorder.list.length > 0 &&
                      workorder.list.map((e, i) => {
                        return (
                          <tr className="row" key={i}>
                            <td onClick={() => detailItem(e)}>{e.name}</td>
                            <td onClick={() => detailItem(e)}>
                              {workorder.list[i].users &&
                                workorder.list[i].users.length > 0 &&
                                workorder.list[i].users.map((e, j) => {
                                  return (
                                    <span title={e.name} key={j}>
                                      <img src={e.photo ? baseUrl + e.photo : e && e.gender ? manprofile : womanprofile} className="meeting_users_img" />
                                    </span>
                                  );
                                })}
                            </td>
                            <td
                              onClick={() => detailItem(e)}
                              title={
                                e.labels && e.labels.length > 0
                                  ? e.labels.map((e, i) => {
                                      return e.name;
                                    })
                                  : null
                              }
                            >
                              {e.labels && e.labels.length > 0 ? <span className="label_container">{e.labels[0].name + " .."}</span> : null}
                            </td>
                            <td onClick={() => detailItem(e)}>{e.startDateTimeString}</td>
                            {/* <td onClick={() => detailItem(e)}>
                                                        <span title={e.description && e.description.length > 15 ? e.description : ''} key={i}>{e.description && e.description.length > 15 ? e.description.substring(0, 15) + " (!)" : e.description}</span>
                                                    </td>
                                                    <td onClick={() => detailItem(e)}>
                                                        <span title={e.desicion && e.desicion.length > 15 ? e.desicion : ''} key={i}>{e.desicion && e.desicion.length > 15 ? e.desicion.substring(0, 15) + " (!)" : e.desicion}</span>
                                                    </td>
                                                    <td>
                                                        {workorder.list[i].meetingFileDtos && workorder.list[i].meetingFileDtos.length > 0 && workorder.list[i].meetingFileDtos.map((e, h) => {
                                                            if (e.path !== null) {
                                                                return (
                                                                    <a target="_blank" href={baseUrl + e.path} key={h}>
                                                                        <DocumentIcon color={(theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")} />
                                                                    </a>
                                                                )
                                                            }
                                                        })}
                                                    </td> */}
                            <td>
                              <button onClick={() => deleteMeetingConfirm(e)}>
                                <TrushIcon color={theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)"} />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {current && current.pageCount > 0 && (
                <Pagination
                  defaultCurrent={selectedPage}
                  onChange={setSelectedPageFilter}
                  onShowSizeChange={(pageCount, rowCount) => {
                    dispatch(fetchWorkorder({ ...request, pageCount, rowCount }, crud.list, {}, trans));
                    setRequest({ ...request, pageCount, rowCount });
                  }}
                  total={current.pageCount * request.rowCount}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="project_container">
            <div className="project_group">
              <h2>{trans.meetingGroup}</h2>
              <div className="meeting_group_container">
                <Input
                  icon={<Search2Icon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />}
                  placeholder={trans.search}
                  value={searchMeetingGroupText}
                  onChange={(e) => searchMeetingGroupChange(e.target.value)}
                />
                <ul className="meeting_group_list">
                  {workordergroup.list &&
                    workordergroup.list.length > 0 &&
                    workordergroup.list.map((mg, i) => {
                      return editState != mg.id ? (
                        <li className={mg.selected ? "active d-flex" : "d-flex"} key={"meeting_group_" + i}>
                          <div className="field_1">
                            <span onClick={() => selectMeetingGroup(i)}>{mg.name}</span>
                          </div>
                          <div className="field_2">
                            <a className="edit hide mr-2" onClick={() => setIsEditItem(mg)}>
                              <EditIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"}></EditIcon>
                            </a>
                            <a className="trash hide" onClick={() => deleteGroupConfirm(mg)}>
                              <TrushIcon color={theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)"}></TrushIcon>
                            </a>
                          </div>
                        </li>
                      ) : (
                        <div className="d-flex edit_group_line" key={i}>
                          <Input
                            placeholder={trans.groupName}
                            name={("edit_group_name_", i)}
                            id={("edit_group_name_", i)}
                            className="hide_text"
                            key={i}
                            value={field.name}
                            onChange={(e) => setField({ ...fields, id: mg.id, name: e.target.value })}
                          />
                          <button className="btn btn-basic" onClick={() => editItem()}>
                            {trans.save}
                          </button>
                        </div>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div className="project_view">
              <div className="table">
                <table>
                  <thead>
                    <tr>
                      <th>
                        {trans.subject} <SortIcon color="rgba(96, 168, 221, 1)" />
                      </th>
                      <th>
                        {trans.participants} <SortIcon color="rgba(96, 168, 221, 1)" />
                      </th>
                      <th>
                        {trans.meetingDate} <SortIcon color="rgba(96, 168, 221, 1)" />
                      </th>
                      <th>{trans.decisionsTaken}</th>
                      <th>{trans.notes}</th>
                      <th>{trans.file}</th>
                      <th>{trans.settings}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="row">
                      <td colSpan={8}>
                        <ContentLoader
                          preserveAspectRatio="none"
                          backgroundColor={theme === "dark" ? "#666666" : "#efefef"}
                          foregroundColor={theme === "dark" ? "#222222" : "#cccccc"}
                          viewBox="0 0 1000 60"
                          height={60}
                          width="100%"
                          style={{ width: "100%" }}
                          speed={2}
                          {...props}
                        >
                          <rect x="110" y="21" rx="4" ry="4" width="754" height="6" />
                          <rect x="111" y="41" rx="3" ry="3" width="185" height="7" />
                          <rect x="304" y="-46" rx="3" ry="3" width="350" height="6" />
                          <rect x="371" y="-45" rx="3" ry="3" width="380" height="6" />
                          <rect x="484" y="-45" rx="3" ry="3" width="201" height="6" />
                        </ContentLoader>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ))}
      {current.grid && (
        <div className="project_container">
          <div className="project_group">
            <h2>{trans.meetingGroup}</h2>
            <div className="meeting_group_container">
              <Input
                icon={<Search2Icon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />}
                placeholder={trans.search}
                value={searchMeetingGroupText}
                onChange={(e) => searchMeetingGroupChange(e.target.value)}
              />
              <ul className="meeting_group_list">
                {workordergroup.list &&
                  workordergroup.list.length > 0 &&
                  workordergroup.list.map((mg, i) => {
                    return editState != mg.id ? (
                      <li className={mg.selected ? "active d-flex" : "d-flex"} key={"meeting_group_" + i}>
                        <div className="field_1">
                          <span onClick={() => selectMeetingGroup(i)}>{mg.name}</span>
                        </div>
                        <div className="field_2">
                          <a className="edit hide mr-2" onClick={() => setIsEditItem(mg)}>
                            <EditIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"}></EditIcon>
                          </a>
                          <a className="trash hide" onClick={() => deleteGroupConfirm(mg)}>
                            <TrushIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"}></TrushIcon>
                          </a>
                        </div>
                      </li>
                    ) : (
                      <div className="d-flex edit_group_line" key={i}>
                        <Input
                          placeholder={trans.groupName}
                          name={("edit_group_name_", i)}
                          id={("edit_group_name_", i)}
                          className="hide_text"
                          key={i}
                          value={field.name}
                          onChange={(e) => setField({ ...fields, id: mg.id, name: e.target.value })}
                        />
                        <button className="btn btn-basic" onClick={() => editItem()}>
                          {trans.save}
                        </button>
                      </div>
                    );
                  })}
              </ul>
              <div className="grop_container_buttons">
                <div className={`add_grop_form ${addState ? "" : "d-none"}`}>
                  <Input
                    name={"add_group_name"}
                    id={"add_group_name"}
                    header={trans.subject}
                    value={field.name}
                    onChange={(e) => setField({ ...fields, name: e.target.value })}
                  />
                  <div className="d-flex">
                    <button className="btn btn-basic btn-group" onClick={() => setAddState(false)}>
                      {trans.close}
                    </button>
                    <button className="btn btn-basic btn-group" onClick={() => addItem()}>
                      {trans.save}
                    </button>
                  </div>
                </div>
                {addState ? (
                  ""
                ) : (
                  <Button
                    icon={<PlusIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />}
                    classes="add_group"
                    onClick={() => setIsAddItem()}
                  ></Button>
                )}
              </div>
            </div>
          </div>
          <div className="project_view">
            <div className="grid_container">
              {!loader.loading ? (
                workorder &&
                workorder.list.length > 0 &&
                workorder.list.map((e, i) => {
                  return (
                    <div className={`grid_card grid_card_border ${e.status == true ? "bg-sky-border-left" : "bg-gray-border-left"}`} key={"grid_card" + i}>
                      <div className="workorder_card_information_container">
                        <div className="workorder_grid_card_information" onClick={() => detailItem(e)}>
                          <span className="grid_card_name">{e.name}</span>
                          <span className="grid_card_position">
                            <span className="badge">{e.dateViewString}</span>
                          </span>
                          <span className="grid_card_position">
                            {e.status === 2 ? (
                              <span className="grid_greenlabel_container">Yapıldı</span>
                            ) : (
                              <span className="grid_label_container">Yapılmadı</span>
                            )}
                          </span>
                          <span className="grid_card_position">
                            {e.labels && e.labels.length > 0
                              ? e.labels.map((e, i) => {
                                  return (
                                    <span key={"label" + e.id} className="grid_label_container">
                                      {e.name}
                                    </span>
                                  );
                                })
                              : null}
                            {/* {renderPriority(e.priority)} */}
                          </span>
                        </div>
                        <div className="grid_card_participants" onClick={() => detailItem(e)}>
                          <div>
                            {workorder.list[i].users &&
                              workorder.list[i].users.length > 0 &&
                              workorder.list[i].users.map((e, j) => {
                                return (
                                  <span title={e.name} key={j}>
                                    <img src={e.photo ? baseUrl + e.photo : e && e.gender ? manprofile : womanprofile} className="meeting_users_img mr-1" />
                                  </span>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      <div className="grid_card_edit_buttons">
                        <button onClick={() => deleteMeetingConfirm(e)}>
                          <TrushIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "#000"} />
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <ContentLoader
                  preserveAspectRatio="none"
                  viewBox="0 0 1000 600"
                  height={600}
                  width={"100%"}
                  speed={1}
                  backgroundColor={theme === "dark" ? "#666666" : "#efefef"}
                  foregroundColor={theme === "dark" ? "#222222" : "#cccccc"}
                >
                  <rect x="0" y="56" rx="2" ry="2" width="1000" height="211" />
                  <rect x="0" y="300" rx="2" ry="2" width="1000" height="211" />
                </ContentLoader>
              )}
            </div>
            {current && current.pageCount > 0 && (
              <Pagination
                defaultCurrent={selectedPage}
                onChange={setSelectedPageFilter}
                onShowSizeChange={(pageCount, rowCount) => {
                  dispatch(fetchWorkorder({ ...request, pageCount, rowCount }, crud.list, {}, trans));
                  setRequest({ ...request, pageCount, rowCount });
                }}
                total={current.pageCount * request.rowCount}
              />
            )}
          </div>
        </div>
      )}
    </Layout>
  );
}
