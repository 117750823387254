import { fileType, addFileType, updateFileType } from '../action_types'
const reducer = (state = [], action) => {
    switch (action.type) {
        case fileType:
            return action.payload;
        case addFileType:
            return [action.payload, ...state];
        case updateFileType:
            for (let index = 0; index < state.length; index++) {
                state[index].emblezeditem = action.payload.emblezeditem;
                state[index].emblezzlementdate = action.payload.emblezzlementdate;
            }
            return state;
        default:
            return state;
    }
};
export default reducer;