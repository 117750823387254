import { workOrderGroupType, addWorkOrderGroupType, updateWorkOrderGroupType } from '../action_types'
const reducer = (state = { list: [], totalhour: 0 }, action) => {
    switch (action.type) {
        case workOrderGroupType:
            return { ...state, list: action.payload };
        case addWorkOrderGroupType:
            return { ...state, list: [...state.list, action.payload] };
        case updateWorkOrderGroupType:
            var items = [];
            state.list.forEach(p => {
                if (p.id === action.payload.id)
                items.push(action.payload);
                else items.push(p);
            });
            return { ...state, list: items };
        default:
            return state;
    }
};
export default reducer;