import {
    isOpenLeftMenuType,
    leftMenuValueType, isOpenLeftMenuItemType, leftMenuItemValueType, isOpenTextType,
    isOpenTextValueType
} from '../action_types'
const reducer = (state = {
    isOpenLeftMenu: false,
    isOpenLeftMenuItem: false,
    isOpenText: false
}, action) => {
    switch (action.type) {
        case isOpenLeftMenuType:
            return { ...state, isOpenLeftMenu: !state.isOpenLeftMenu }
        case isOpenTextType:
            return { ...state, isOpenText: !state.isOpenText }
        case isOpenLeftMenuItemType:
            return { ...state, isOpenLeftMenuItem: !state.isOpenLeftMenuItem }
        case leftMenuValueType:
            return { ...state, isOpenLeftMenu: action.payload }
        case isOpenTextValueType:
            return { ...state, isOpenText: action.payload }
        case leftMenuItemValueType:
            return { ...state, isOpenLeftMenuItem: action.payload }
        default:
            return state
    }
}
export default reducer