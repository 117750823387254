import React, { useEffect, useState } from "react";
import Layout from "../../components/Container/Layout";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/anBrains/Button";
import Input from "../../components/anBrains/Input";
import EmployeeDetailInsideMenu from "../../components/employee/EmployeeDetailInsideMenu";
import { fileType, insideMenuTypeInProject, isOpenLeftMenuItemType, isOpenLeftMenuType, isOpenTextType, itemType } from "../../redux/action_types";
import { crud, dateFormatter, fileFromType, ProjectStatus } from "../../config/constants";
import { fetchProject, fetchProjectDetail } from "../../redux/actions/project_ac";
import { baseUrl } from "../../config";
import Event from "../../components/project/Event";
import File from "../../components/project/File";
import { fetchEvent } from "../../redux/actions/event_ac";
import { fetchFiles } from "../../redux/actions/file_ac";
// import { fetchdebits } from '../../redux/actions/debit_ac';
import FrappeGanttContainer from "../../components/project/FrappeGanttContainer";
import ProjectCost from "../../components/project/ProjectCost";
import { fetchBudgets } from "../../redux/actions/project_cost_ac";
import { Select } from "antd";
import WorkOrder from "../../components/project/ProjectWorkOrder";
import moment from "moment";
const { Option, OptGroup } = Select;

export default function ProjectDetail(props) {
  const {
    match: { params },
  } = props;
  const dispatch = useDispatch();
  const trans = useSelector((state) => state.trans);
  const theme = useSelector((state) => state.theme);
  const result = useSelector((state) => state.result);
  const { inside_menu } = useSelector((state) => state.project);
  const current = useSelector((state) => state.current);
  const projectgroup = useSelector((state) => state.projectgroup);
  const department = useSelector((state) => state.department);
  const customer = useSelector((state) => state.customer);
  const user = useSelector((state) => state.user);
  const size = useSelector((state) => state.size);
  const employee = useSelector((state) => state.employee);

  const [subs, setSubs] = useState([]);
  useEffect(() => {
    //adas
    dispatch(fetchProjectDetail({ id: params.id, isProjectDetail: true }, crud.list, {}, trans));
    inside_menu.map((x) => (x.active = false));
    if (
      user &&
      user.menus &&
      user.menus.length > 0 &&
      user.menus.some((x) => x.id === 4) &&
      user.menus.some((x) => x.id === 4).subs !== null &&
      user.menus.find((x) => x.id === 4).subs.length > 0
    ) {
      var subs = user.menus.find((x) => x.id === 4).subs;
      setSubs(user.menus.find((x) => x.id === 4).subs);
      for (let index = 0; index < subs.length; index++) {
        const s = subs[index];
        inside_menu.find((x) => x.id === s.id).active = true;
        break;
      }
    }
    dispatch({ type: insideMenuTypeInProject, payload: inside_menu });
  }, [user]);

  const errors = {
    // descriptionError: "",
    nameError: "",
    customerIdError: "",
    projectGroupIdError: "",
  };
  const [err, setErr] = useState(errors);

  const [message, setMessage] = useState("");

  let validate = () => {
    if (current.item.name.length < 3 || current.item.name.length > 30) {
      setErr({ ...errors, nameError: trans.toShort });
      setMessage(trans.toShort);
      return false;
    }

    if (current.item.customerId <= 0) {
      setErr({ ...errors, customerIdError: trans.pleaseEnterValid });
      setMessage(trans.pleaseEnterValid);
      return false;
    }
    if (current.item.projectGroupId <= 0) {
      setErr({ ...errors, projectGroupIdError: trans.pleaseEnterValid });
      setMessage(trans.pleaseEnterValid);
      return false;
    }
    // if (current.item.description.length < 3 || current.item.description.length > 200) {
    //     setErr({ ...errors, descriptionError: trans.toShort })
    //     setMessage(trans.toShort)
    //     return false
    // }

    return true;
  };

  const register = (e) => {
    e.preventDefault();
    if (validate()) {
      setErr(errors);
      dispatch(fetchProject({}, crud.update, current.item, trans));
    }
  };
  const animate = () => {
    document.documentElement.classList.add("transition");
    dispatch({ type: isOpenLeftMenuItemType });
    setTimeout(() => {
      document.documentElement.classList.remove("transition");
      dispatch({ type: isOpenTextType });
      localStorage.setItem("toggleText", JSON.stringify(!size.isOpenText));
    }, 500);
  };
  const changeTab = (content) => {
    inside_menu.map((x) => (x.active = false));
    inside_menu.find((x) => x.content === content).active = true;
    dispatch({ type: insideMenuTypeInProject, payload: inside_menu });
    switch (content) {
      case "detail":
        // dispatch(fetchLeaves({ userId: params.id }, crud.list, {}))
        break;
      case "event":
        dispatch(fetchEvent({ projectId: params.id, departmentId: 0, sort: "id", desc: true, rowCount: 4, pageCount: 0 }, crud.list, {}, trans));
        break;
      case "planning":
        if (size.isOpenLeftMenu) {
          localStorage.setItem("toggle", JSON.stringify(!size.isOpenLeftMenu));
          localStorage.setItem("toggleText", JSON.stringify(!size.isOpenText));
          localStorage.setItem("toggleItem", JSON.stringify(!size.isOpenLeftMenuItem));
          animate();
          dispatch({ type: isOpenLeftMenuType });
        }
        // dispatch(fetchOvertimes({ userId: params.id }, crud.list, {}))
        break;
      case "cost":
        dispatch(fetchBudgets({ projectId: params.id, budgetType: false, loader: true }, crud.list, {}, trans));
        break;
      case "file":
        dispatch(fetchFiles({ projectId: params.id }, crud.list, {}, trans));
        break;
      default:
        break;
    }
  };

  const loader = useSelector((state) => state.loader);
  const changeCustomer = (e) => {
    current.item.customerId = parseInt(e.target.value);
    dispatch({ type: itemType, payload: current.item });
  };

  const changeGroup = (e) => {
    current.item.projectGroupId = parseInt(e.target.value);
    dispatch({ type: itemType, payload: current.item });
  };
  const selectMultiDepartments = (departmentIds) => dispatch({ type: itemType, payload: { ...current.item, departmentIds } });
  const selectMultiUserIds = (userIds) => dispatch({ type: itemType, payload: { ...current.item, userIds } });

  return (
    <Layout>
      {current.item && (
        <div className="employee_detail_container">
          {inside_menu.find((x) => x.content === "planning").active === true ||
          inside_menu.find((x) => x.content === "cost").active === true ||
          inside_menu.find((x) => x.content === "workorder").active === true ? null : (
            <div className="employee_detail_profile projectProfile ">
              {current.item.customerPhoto && <img src={baseUrl + current.item.customerPhoto} id="projectDetailProfile" alt="" />}
              <div className="employee_detail_items mb-10 mt-10" style={{ width: "100%" }}>
                <h3>{current.item.name} </h3>
                <span>{current.item.customerName}</span>
                {current.item && current.item.status !== undefined && (
                  <span className="project-state" style={{ backgroundColor: ProjectStatus[current.item.status].color }}>
                    {ProjectStatus[current.item.status][trans.lang]}
                  </span>
                )}
              </div>
              <div className="employee_detail_items">
                <span>
                  <b>{trans.code + " : "}</b> {current.item.code}
                </span>
                <span>
                  <b>{trans.createDate + " : "}</b> {current.item.createDateViewString}
                </span>
                <span>
                  <b>{trans.lastupdateddate + " : "}</b>
                  {current.item.modifyDateViewString}
                </span>
              </div>
            </div>
          )}
          <div
            className="employee_detail_right"
            style={
              inside_menu.find((x) => x.content === "planning").active === true ||
              inside_menu.find((x) => x.content === "cost").active === true ||
              inside_menu.find((x) => x.content === "workorder").active === true
                ? { width: "100%" }
                : null
            }
          >
            <ul className="inside_menu">
              {subs && subs.length > 0
                ? inside_menu.map((m, i) => {
                    if (user.menus.find((x) => x.id === 4).subs.some((a) => a.id === m.id)) {
                      return (
                        <EmployeeDetailInsideMenu
                          id={params.id}
                          key={i}
                          changeTab={changeTab}
                          active={m.active}
                          content={m.content}
                          name={m.name[trans.lang]}
                        />
                      );
                    }
                  })
                : null}
            </ul>
            <div className="employee_detail_content">
              {inside_menu.find((x) => x.content === "detail").active === true && (
                <div className="personal">
                  <div className="employee_detail_content_left">
                    {user && user.roles && user.roles.length > 0 && user.roles.includes("Admin") && (
                      <Input
                        placeholder={trans.code}
                        name={trans.code}
                        header={trans.code}
                        value={current.item.code}
                        onChange={(e) => {
                          current.item.code = e.target.value;
                          dispatch({ type: itemType, payload: current.item });
                        }}
                      />
                    )}
                    <Input
                      placeholder={trans.projectName}
                      name={trans.projectName}
                      header={trans.projectName}
                      value={current.item.name}
                      onChange={(e) => {
                        current.item.name = e.target.value;
                        dispatch({ type: itemType, payload: current.item });
                      }}
                      error={err.nameError}
                      maxLength={30}
                      minLength={3}
                    />
                    <div className="input-container">
                      <span className="input_header">{trans.partner}</span>
                      <select
                        className={`input select ${err.customerIdError ? "input-error" : ""}`}
                        onChange={(e) => changeCustomer(e)}
                        value={current.item.customerId}
                      >
                        <option value={0}>{trans.select}</option>
                        {customer.list.map((c, i) => {
                          return (
                            <option key={"customer_key" + i} value={c.id}>
                              {c.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="input-container">
                      <span className="input_header">{trans.projectGroup}</span>
                      <select
                        className={`input select ${err.projectGroupIdError ? "input-error" : ""}`}
                        onChange={(e) => changeGroup(e)}
                        value={current.item.projectGroupId}
                      >
                        <option value={0}>{trans.select}</option>
                        {projectgroup.list.map((c, i) => {
                          return (
                            <option key={"projectgroup_key" + i} value={c.id}>
                              {c.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="mb-5">
                      <span className="input_header">{trans.personals}</span>
                      {employee.list !== null && employee.list !== undefined && employee.list.length > 0 ? (
                        <Select
                          mode="multiple"
                          allowClear
                          maxTagCount={3}
                          maxTagTextLength={4}
                          optionFilterProp="children"
                          onChange={selectMultiUserIds}
                          style={{ width: "100%" }}
                          placeholder={trans.personals}
                          filterOption={(input, option) =>
                            option.children ? option.children.toLocaleLowerCase("tr").indexOf(input.toLocaleLowerCase("tr")) >= 0 : false
                          }
                          value={current.item.userIds}
                        >
                          {employee.list.map((l, i) => {
                            return (
                              <Option key={"userId" + i} value={l.id}>
                                {l.name}
                              </Option>
                            );
                          })}
                        </Select>
                      ) : null}
                    </div>
                    <div className="mb-5">
                      <span className="input_header">{trans.departments}</span>
                      {department.list && department.list.length > 0 ? (
                        <Select
                          mode="multiple"
                          allowClear
                          maxTagCount={3}
                          optionFilterProp="children"
                          onChange={selectMultiDepartments}
                          style={{ width: "100%" }}
                          placeholder={trans.departments}
                          filterOption={(input, option) =>
                            option.children ? option.children.toLocaleLowerCase("tr").indexOf(input.toLocaleLowerCase("tr")) >= 0 : false
                          }
                          value={current.item.departmentIds}
                        >
                          {department.list.map((l, i) => {
                            // //
                            return (
                              <Option key={"department" + i} value={l.id}>
                                {l.name}
                              </Option>
                            );
                          })}
                        </Select>
                      ) : null}
                    </div>
                    {/* project start date enddate  */}
                    <Input
                      type={"date"}
                      placeholder={"Start Date"}
                      name={"startDate"}
                      header={"Start Date"}
                      value={moment(current.item.startDate).format("YYYY-MM-DD")}
                      onChange={(e) => {
                        current.item.startDate = e.target.value;
                        dispatch({ type: itemType, payload: current.item });
                      }}
                    />

                    <Input
                      type={"date"}
                      placeholder={"End Date"}
                      name={"endDate"}
                      header={"End Date"}
                      value={moment(current.item.endDate).format("YYYY-MM-DD")}
                      onChange={(e) => {
                        current.item.endDate = e.target.value;
                        dispatch({ type: itemType, payload: current.item });
                      }}
                    />
                  </div>
                  <div className="employee_detail_content_right">
                    <Input
                      placeholder={trans.totalPlannedCost}
                      name={trans.totalPlannedCost}
                      header={trans.totalPlannedCost}
                      value={current.item.totalPlannedCost}
                      onChange={(e) => {
                        current.item.totalPlannedCost = e.target.value;
                        dispatch({ type: itemType, payload: current.item });
                      }}
                    />
                    <Input
                      placeholder={trans.totalPlannedDay}
                      name={trans.totalPlannedDay}
                      header={trans.totalPlannedDay}
                      value={current.item.totalPlannedDay}
                      onChange={(e) => {
                        current.item.totalPlannedDay = e.target.value;
                        dispatch({ type: itemType, payload: current.item });
                      }}
                    />
                    <Input
                      placeholder={trans.description}
                      name={trans.description}
                      header={trans.description}
                      value={current.item.description}
                      textarea={true}
                      onChange={(e) => {
                        current.item.description = e.target.value;
                        dispatch({ type: itemType, payload: current.item });
                      }}
                    />

                    {message && <span className="errorspan">{trans.error + ": " + message}</span>}
                    <Button classes={"btn btn-primary"} onClick={register}>
                      {trans.save}
                    </Button>
                  </div>
                </div>
              )}
              {inside_menu.find((x) => x.content === "event").active === true && current.item.departmentIds && current.item.departmentIds.length > 0 ? (
                <div className="personal">
                  <Event projectId={params.id} departmentId={current.item.departmentIds[0].id} departments={current.item.departments} />
                </div>
              ) : null}

              {inside_menu.find((x) => x.content === "planning").active === true ? <FrappeGanttContainer projectId={params.id} /> : null}
              {inside_menu.find((x) => x.content === "file").active === true ? (
                <File projectId={params.id} workOrderId={0} customerId={0} fileFromType={fileFromType.project} />
              ) : null}
              {inside_menu.find((x) => x.content === "cost").active === true ? <ProjectCost projectId={params.id} /> : null}
              {inside_menu.find((x) => x.content === "workorder").active === true ? <WorkOrder projectId={params.id} /> : null}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
