import axios from 'axios'
import { axiosConfig, baseUrl } from '../../config'
import { positionsType,isLoginType } from '../action_types'
import { loading } from './loader_ac'
export const fetchRoles = () => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/roles`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    return dispacth => {
        try {
            // dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request: {}, requestType: 5 }
            })
                .then(response => {
                    if (response.data.success) {
                        dispacth({
                            type: positionsType,
                            payload: response.data.data,
                        })
                    }
                })
                .catch(reason => {
                    

                  
                })
        } catch (error) {
            console.log(error);
        }
    }
}
