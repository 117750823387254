import React, { useEffect, useState } from "react";
import Layout from "../../components/Container/Layout";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/anBrains/Button";
import Avatar from "../../components/anBrains/Avatar";
import Input from "../../components/anBrains/Input";
import Switch from "../../components/anBrains/Switch";
import Timekeeper from "react-timekeeper";
import ContentLoader from "react-content-loader";
import { crud } from "../../config/constants";
import { baseUrl } from "../../config";
import { fetchCompanySetting, fetchCompanyWokingHours } from "../../redux/actions/company_setting_ac";
import { itemHoursType } from "../../redux/action_types";

export default function CompanySetting() {
  const dispatch = useDispatch();
  const trans = useSelector((state) => state.trans);
  const theme = useSelector((state) => state.theme);
  const current = useSelector((state) => state.current);
  const loader = useSelector((state) => state.loader);
  const user = useSelector((state) => state.user);
  const [showStartTime, setShowStartTime] = useState(null);
  const [showEndTime, setShowEndTime] = useState(null);

  useEffect(() => {
    if (user && user.id && user.id > 0) dispatch(fetchCompanySetting({ id: user.companyId, includeWorkingHours: true }, crud.list, {}, trans));
  }, [user]);

  useEffect(() => {
    setField({
      ...current.item[0],
    });
  }, [current.item]);

  useEffect(() => {
    setHours({
      ...current.itemHours,
    });
  }, [current.itemHours]);

  var fields = {
    photo: "",
    name: "",
    authorizedPerson: "",
    adres: "",
    phone: "",
    gsm: "",
    taxAdministration: "",
    taxNumber: "",
    workingHours: [],
  };
  const [field, setField] = useState(fields);

  var hours = {
    id: 0,
    companyId: "",
    day: "",
    closed: "",
    startTime: new Date(),
    endTime: new Date(),
  };
  const [hour, setHours] = useState(hours);

  const errors = {
    photoErr: "",
    nameErr: "",
    authorizedPersonErr: "",
    adresErr: "",
    phoneErr: "",
    gsmErr: "",
    taxAdministrationErr: "",
    taxNumberErr: "",
  };
  const [err, setErr] = useState(errors);

  const changeUrl = (url) => {
    setField({ ...field, photo: url });
  };

  const [message, setMessage] = useState("");
  let validate = () => {
    if (field.name.length < 3 && field.name.length > 50) {
      setErr({ ...err, nameError: trans.toShort });
      setMessage(trans.toShort);
      return false;
    }
    return true;
  };

  const register = (e) => {
    e.preventDefault();
    if (validate()) {
      setErr(errors);
      dispatch(
        fetchCompanySetting(
          {},
          crud.update,
          { ...field, photo: field.photo && field.photo.includes("http:") ? current.item.photo.replace(baseUrl, "") : field.photo },
          trans
        )
      );
    }
  };

  const changeWorkingStatus = (h, c) => {
    hour.list.find((x) => x.id === h).closed = !hour.list.find((x) => x.id === h).closed;
    if (c) {
      hour.list.find((x) => x.id === h).startTimeString = "08:30";
      hour.list.find((x) => x.id === h).endTimeString = "17:30";
      hour.list.find((x) => x.id === h).startTime = "08:30";
      hour.list.find((x) => x.id === h).endTime = "17:30";
    } else {
      hour.list.find((x) => x.id === h).startTimeString = "00:00";
      hour.list.find((x) => x.id === h).endTimeString = "00:00";
      hour.list.find((x) => x.id === h).startTime = "00:00";
      hour.list.find((x) => x.id === h).endTime = "00:00";
    }

    dispatch({ type: itemHoursType, payload: hour.list });
  };

  const changeWorkingHour = (time, h, state) => {
    if (state === 1) {
      hour.list.find((x) => x.id === h).startTimeString = time;
      hour.list.find((x) => x.id === h).startTime = time;
      setShowStartTime(null);
    } else {
      hour.list.find((x) => x.id === h).endTimeString = time;
      hour.list.find((x) => x.id === h).endTime = time;
      setShowEndTime(null);
    }
    dispatch({ type: itemHoursType, payload: hour.list });
  };

  const showTime = (i, s) => {
    setShowStartTime(null);
    setShowEndTime(null);
    s === 1 ? setShowStartTime(i) : setShowEndTime(i);
  };

  const registerWorkingHours = () => {
    dispatch(fetchCompanyWokingHours({}, crud.update, hour.list, trans));
  };

  return (
    <Layout>
      {field && !loader.loading ? (
        <div className="employee_detail_container">
          <div className="profile_detail">
            <Avatar changeUrl={changeUrl} defaultImg={field.photo} isCompany={true} />
            <div className="profile_detail_items" style={{ width: "100%" }}>
              <h3>{field.name} </h3>
              <span>{field.authorizedPerson}</span>
            </div>
            <div className="profile_detail_items">
              <span>{field.tel}</span>
              <span>{field.gsm}</span>
            </div>
            <div className="profile_detail_items working_hours_view">
              <span className="label">{trans.workingHours}</span>
              <table className="conpany_working_hours_table">
                <tbody>
                  {hour &&
                    hour.list &&
                    hour.list.length > 0 &&
                    hour.list.map((h, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            <Switch
                              classes="mediumSwitch"
                              id={`${"generetedStatus" + i}`}
                              key={i}
                              value={!h.closed}
                              onChange={(c) => changeWorkingStatus(h.id, c.target.checked)}
                            />
                            <span className="week_name">
                              {h.day === 0
                                ? trans.monday
                                : "" || h.day === 1
                                ? trans.tuesday
                                : "" || h.day === 2
                                ? trans.wednesday
                                : "" || h.day === 3
                                ? trans.thursday
                                : "" || h.day === 4
                                ? trans.friday
                                : "" || h.day === 5
                                ? trans.saturnday
                                : "" || h.day === 6
                                ? trans.sunday
                                : ""}
                            </span>
                          </td>
                          <td>
                            <span
                              className={`${h.closed ? "disabled working_hour_time priority bg-secondary" : "working_hour_time priority bg-secondary"}`}
                              onClick={() => showTime(i, 1)}
                            >
                              {h.startTimeString}
                            </span>
                            {showStartTime === i ? (
                              <Timekeeper
                                id={`${"startTime" + i}`}
                                time={h.startTimeString}
                                hour24Mode={true}
                                doneButton={(e) => (
                                  <span className="css-ue2et3-DoneButton" onClick={() => changeWorkingHour(e.formatted24, h.id, 1)}>
                                    {trans.select}
                                  </span>
                                )}
                              />
                            ) : null}
                          </td>
                          <td>
                            <span
                              className={`${h.closed ? "disabled working_hour_time priority bg-secondary" : "working_hour_time priority bg-secondary"}`}
                              onClick={() => showTime(i, 2)}
                            >
                              {h.endTimeString}
                            </span>
                            {showEndTime === i ? (
                              <Timekeeper
                                id={`${"endTime" + i}`}
                                time={h.endTimeString}
                                hour24Mode={true}
                                switchToMinuteOnHourSelect
                                doneButton={(e) => (
                                  <span className="css-ue2et3-DoneButton" onClick={() => changeWorkingHour(e.formatted24, h.id, 2)}>
                                    {trans.select}
                                  </span>
                                )}
                              />
                            ) : null}
                          </td>
                        </tr>
                      );
                    })}
                  <tr>
                    <td>
                      <Button classes="btn-basic" onClick={() => registerWorkingHours()}>
                        {trans.update}
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="employee_detail_right">
            <div className="employee_detail_content">
              <div className="personal">
                <div className="employee_detail_content_left">
                  <Input
                    placeholder={trans.name}
                    name={trans.name}
                    header={trans.name}
                    value={field.name}
                    onChange={(e) => setField({ ...field, name: e.target.value })}
                    error={err.nameError}
                  />
                  <Input
                    name={trans.representative}
                    header={trans.representative}
                    value={field.authorizedPerson}
                    onChange={(e) => setField({ ...field, authorizedPerson: e.target.value })}
                    error={err.authorizedPersonErr}
                  />
                  <Input
                    placeholder={"5xxxxxxx"}
                    type={"text"}
                    name={trans.phone}
                    header={trans.phone}
                    value={field.phone}
                    onChange={(e) => setField({ ...field, phone: e.target.value })}
                    error={err.phoneErr}
                  />
                  <Input
                    textarea
                    placeholder={trans.adres}
                    name={trans.adres}
                    header={trans.adres}
                    value={field.adres}
                    onChange={(e) => setField({ ...field, adres: e.target.value })}
                    error={err.telErr}
                  />
                </div>
                <div className="employee_detail_content_right">
                  <Input
                    placeholder={trans.gsm}
                    type={"text"}
                    name={trans.gsm}
                    header={trans.gsm}
                    value={field.gsm}
                    onChange={(e) => setField({ ...field, gsm: e.target.value })}
                    error={err.gsmErr}
                  />

                  <Input
                    placeholder={trans.taxAdministration}
                    name={trans.taxAdministration}
                    header={trans.taxAdministration}
                    value={field.taxAdministration}
                    onChange={(e) => setField({ ...field, taxAdministration: e.target.value })}
                    error={err.taxAdministrationErr}
                  />
                  <Input
                    name={trans.taxNumber}
                    header={trans.taxNumber}
                    value={field.taxNumber}
                    onChange={(e) => setField({ ...field, taxNumber: e.target.value })}
                    error={err.taxNumberErr}
                  />
                  <Button classes={"btn-primary"} onClick={register}>
                    {trans.update}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <ContentLoader
          preserveAspectRatio="none"
          viewBox="0 0 1000 600"
          height={600}
          width={"100%"}
          speed={1}
          backgroundColor={theme === "dark" ? "#666666" : "#efefef"}
          foregroundColor={theme === "dark" ? "#222222" : "#cccccc"}
        >
          <rect x="0" y="56" rx="2" ry="2" width="1000" height="211" />
          <rect x="0" y="300" rx="2" ry="2" width="1000" height="211" />
        </ContentLoader>
      )}
    </Layout>
  );
}
