import React from 'react'

export default function Button({
	children,
	icon,
	type,
	theme,
	onClick,
	classes,
	iconPosition,
	isDisable,
	style,
	id,
}) {
	return (
		<button
			id={id || null}
			style={style}
			disabled={isDisable}
			className={`btn ${classes ? classes : ''} ${
				theme ? 'btn-' + theme : ''
				} ${iconPosition === 'right' ? 'flex-reverse' : ''}`}
			type={type}
			onClick={
				onClick
			}
		>
			{icon && (
				<div
					className={`flex al-center ${
						iconPosition === 'right' ? 'ml-2' : 'mr-2'
						}`}
				>
					{icon}
				</div>
			)}
			{children}
		</button>
	)
}
