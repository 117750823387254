import axios from 'axios'
import { axiosConfig, baseUrl, openNotification } from '../../config'
import { pageCountType, addFileType, updateFileType, fileType, isLoginType } from '../action_types'
import { success, calculatePageNumber, loading } from './loader_ac'
import { crud, fileFromType } from '../../config/constants'
export const fetchFiles = (request = { rowCount: 14, fileFromType: 1, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}, trans = undefined) => {
    let headers = axiosConfig.headers
    
    let url = "";
    switch (request.fileFromType) {
        case fileFromType.project:
            url = `${baseUrl}/api/projectfiles`
            break;
        case fileFromType.workorder:
            url = `${baseUrl}/api/workorderfiles`
            break;
        case fileFromType.customer:
            url = `${baseUrl}/api/customerfiles`
            break;
        default:
            url = `${baseUrl}/api/projectfiles`
            break;
    }
    if (localStorage.getItem("token"))
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }

    return dispacth => {
        dispacth(loading())
        try {
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            }).then(response => {
                dispacth(loading())
                if (response.data.success) {
                    dispacth(success(true))
                    switch (requestType) {
                        case crud.create:
                            dispacth({
                                type: addFileType,
                                payload: response.data.data,
                            })
                            openNotification('success', trans.successTitle, trans.successBody, 3)
                            break;
                        case crud.read:
                            break;
                        case crud.update:
                            dispacth({
                                type: updateFileType,
                                payload: response.data.data,
                            })
                            openNotification('success', trans.successTitle, trans.successBody, 3)
                            break;
                        case crud.delete:
                            openNotification('success', trans.successTitle, trans.successBody, 3)
                            break;
                        case crud.list:
                            dispacth({
                                type: fileType,
                                payload: response.data.data,
                            })
                            dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) })
                            
                            break;
                        default:
                            break;
                    }
                }
            })
                .catch(reason => {
                    dispacth(loading())
                    openNotification('error', trans.errorTitle, trans.errorBody, 3)

                })
        } catch (error) {
            dispacth(loading())
            openNotification('error', trans.errorTitle, trans.errorBody, 3)
        }
    }
}

export const fetchFilesForm = (data, type, trans = undefined) => {
    let headers = axiosConfig.headers
    let url = "";
    
    switch (type) {
        case fileFromType.project:
            url = `${baseUrl}/api/projectfiles/form`
            break;
        case fileFromType.workorder:
            url = `${baseUrl}/api/workorderfiles/form`
            break;
        case fileFromType.customer:
            url = `${baseUrl}/api/customerfiles/form`
            break;
        default:
            url = `${baseUrl}/api/projectfiles/form`
            break;
    }
    if (localStorage.getItem("token"))
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }

    return dispacth => {
        dispacth(loading())
        try {
            axios({
                method: 'POST',
                url,
                headers,
                data
            }).then(response => {
                dispacth(loading())
                if (response.data.success) {
                    dispacth({
                        type: addFileType,
                        payload: response.data.data,
                    })
                    openNotification('success', trans.successTitle, trans.successBody, 3)
                    dispacth(success(true))
                    console.log(response.data);
                }
            }).catch(reason => {
                console.log();
                openNotification('error', trans.errorTitle, trans.errorBody, 3)


            })
        } catch (error) {
            console.log(error)
            openNotification('error', trans.errorTitle, trans.errorBody, 3)
        }
    }
}