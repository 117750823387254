import React from 'react'
import { Link } from 'react-router-dom'
import { RightIcon } from '../icons'
import { useSelector } from 'react-redux'
export default function BreadCrumb({ breadcrumbs }) {
    const theme = useSelector(state => state.theme)
    return (
        <div className="breadcrumb">
            <ul>
                {breadcrumbs.map((b, i) => {
                    return (
                        <Link to={b.pathActive ? b.path : "#"} key={i + b}>
                            <li>
                                {b.name} {breadcrumbs.length !== i + 1 &&
                                    <RightIcon color={theme === "dark" ? "#e5e5e5" : "#222222"} />}
                            </li>
                        </Link>
                    )
                })}
            </ul>
        </div>
    )
}
