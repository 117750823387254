import axios from "axios";
import { axiosConfig, baseUrl, openNotification } from "../../config";
import {
  dashboardType,
  meetingType,
  notificationType,
  projectType,
  reportType,
  usersType,
  workorderPanoType,
  workorderType,
  isLoginType,
  getUserType,
} from "../action_types";
import { success, loading } from "./loader_ac";
import { dateFormatter, randomColor } from "../../config/constants";
import { useHistory } from "react-router-dom";
import { Redirect } from "react-router";
import React, { Component } from "react";
import TermOfUseDetail from "../../components/requestForm/TermOfUseDetail";
import Modal from "../../components/anBrains/Modal";
import Login from "../../pages/login/Login";
import { Link } from "react-router-dom";
export const fetchDashboard = () => {
  let headers = axiosConfig.headers;
  let url = `${baseUrl}/api/DashboardReports`;
  if (localStorage.getItem("token")) {
    headers = { ...headers, authorization: `Bearer ${localStorage.getItem("token")}` };
  }
  return (dispacth) => {
    try {
      dispacth(loading());
      axios({
        method: "GET",
        url,
        headers,
      })
        .then((response) => {
          console.log(response);
          dispacth(loading());
          if (response.data.success) {
            var data = response.data.data;
            var projects = data.projects;
            dispacth({ type: projectType, payload: projects });
            var lastworks = data.lastworks;
            dispacth({ type: workorderPanoType, payload: lastworks });
            var notifications = data.notifications;
            dispacth({ type: notificationType, payload: notifications });
            var meetings = data.meetings;
            dispacth({ type: meetingType, payload: meetings });
            var labels = data.pie.labels;
            if (localStorage.getItem("lang") != null) var lang = localStorage.getItem("lang");

            if (labels.includes("OTHER") && lang !== "en") var dataindex = labels.findIndex((x) => x === "OTHER");
            labels[dataindex] = "DİĞERLERİ";

            var pie = {
              labels,
              datasets: [
                {
                  // label: 'asdf',
                  borderWidth: 1,
                  borderColor: "rgba(250,250,250,0)",
                  backgroundColor: [
                    "rgba(96, 168, 221, 0.9)",
                    "rgba(247, 202, 49, 0.9)",
                    "rgba(232, 63, 64, 0.9)",
                    "rgba(148, 99, 247, 0.9)",
                    "rgba(5, 168, 92, 0.9)",
                    "rgba(11, 178, 212, 0.9)",
                    "rgba(235, 103, 9, 0.9)",
                    "rgba(0, 133, 119, 0.9)",
                  ],
                  hoverBackgroundColor: [
                    "rgba(96, 168, 221, 1)",
                    "rgba(247, 202, 49, 1)",
                    "rgba(232, 63, 64, 1)",
                    "rgba(148, 99, 247, 1)",
                    "rgba(5, 168, 92, 1)",
                    "rgba(11, 178, 212, 1)",
                    "rgba(235, 103, 9, 1)",
                    "rgba(0, 133, 119, 0.9)",
                  ],
                  data: data.pie.data,
                },
              ],
            };
            dispacth({ type: dashboardType, payload: { pie, numbers: response.data.data.numbers } });
          }
        })
        .catch((reason) => {
          dispacth(loading());
        });
    } catch (error) {
      console.log(error);
      //dispacth(loading())
    }
  };
};

export const fetchReport = (request, trans = undefined) => {
  request.rangeDate = dateFormatter(request.rangeDate);
  let headers = axiosConfig.headers;
  let url = `${baseUrl}/api/Reports`;
  if (localStorage.getItem("token")) {
    headers = { ...headers, authorization: `Bearer ${localStorage.getItem("token")}` };
  }
  return (dispacth) => {
    try {
      dispacth(loading());
      axios({
        method: "POST",
        url,
        headers,
        data: request,
      })
        .then((response) => {
          console.log(response);
          dispacth(loading());
          if (response.data.success) {
            var reports = response.data.data.reports;
            var dataset = { labels: [], datasets: [] };
            var dataset2 = { labels: [], datasets: [] };
            request.rangeDate = new Date(request.rangeDate);
            if (reports.length > 0 && request.reportType === "0") {
              var days = reports[0].user.days.map((d) => d.dayNumber + "/" + (request.rangeDate.getMonth() + 1));
              dataset.labels = days;
              dataset2.labels = days;
              for (let i = 0; i < reports.length; i++) {
                const report = reports[i];
                dataset.datasets.push({
                  id: report.user.id,
                  label: report.user.name,
                  data: report.user.days.map((d) => d.totalMinutePlanned),
                  backgroundColor: randomColor(),
                  hitRadius: 50,
                });

                dataset2.datasets.push({
                  id: report.user.id,
                  label: report.user.name,
                  data: report.user.days.map((d) => d.totalMinuteCompleted),
                  backgroundColor: randomColor(),
                  hitRadius: 50,
                });
              }
            }

            if (reports.length > 0 && request.reportType === "1") {
              var days = reports[0].user.days.map((d) => d.dayNumber + "/" + (request.rangeDate.getMonth() + 1));
              dataset.labels = days;
              for (let i = 0; i < reports.length; i++) {
                const report = reports[i];
                dataset.datasets.push({
                  id: report.user.id,
                  label: report.user.name,
                  data: report.user.days.map((d) => d.totalPoint),
                  backgroundColor: randomColor(),
                  hitRadius: 50,
                });
              }
            }
            dispacth({ type: reportType, payload: { list: response.data.data.reports, dataset, dataset2 } });
            dispacth({ type: usersType, payload: response.data.data.users });
          }
        })
        .catch((reason) => {
          //
          console.log();
          dispacth(loading());
          openNotification("error", trans.errorTitle, trans.errorBody, 3);
        });
    } catch (error) {
      console.log(error);
      dispacth(loading());
    }
  };
};

// export const getThy = () => {
//     let headers = axiosConfig.headers
//     let url = `https://api.turkishairlines.com/test/getPortList`
//     headers = {
//         // ...headers,
//         "apisecret": "fdfd003e13f34fbf81c1ce8848748d08",
//         "apikey": "l7xxa004a4254bbb426e98911068da636968",
//         // "Access-Control-Allow-Origin":"*"
//     }
//     return dispacth => {
//         try {
//             dispacth(loading())
//             axios({
//                 method: 'POST',
//                 url,
//                 headers,
//                 data:
//                 {
//                     requestHeader: {
//                         "clientUsername": "OPENAPI",
//                         "clientTransactionId": "CLIENT_TEST_1",
//                         "channel": "WEB",
//                         "languageCode": "TR",
//                         "airlineCode": "TK"
//                     }
//                 }
//             }).then(response => {
//                 console.log(response);
//                 dispacth(loading())
//                 //

//             }).catch(reason => {
//
//                 dispacth(loading())
//             })
//         } catch (error) {
//             console.log(error)
//             dispacth(loading())
//         }
//     }
// }
