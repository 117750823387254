import { dashboardType, reportType, isPlannedType } from '../action_types'
const reducer = (state = { reports: [], dataset: {}, dataset2: {}, isPlanned: true }, action) => {
    switch (action.type) {
        case dashboardType:
            return { ...state, ...action.payload };
        case isPlannedType:
            return { ...state, isPlanned: !state.isPlanned };
        case reportType:
            return { ...state, reports: action.payload.list, dataset: action.payload.dataset, dataset2: action.payload.dataset2 };
        default:
            return state;
    }
};
export default reducer;