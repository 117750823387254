import React, { useState } from 'react'
import { CameraIcon, PlusIcon, UploadIcon } from '../icons'
import { useSelector } from 'react-redux'
export default function ImagePicker({
	// upload,
	// isScreenShot,
	// isAvatar,
	classes,
	justIcon,
	id,
	text,
	changeCurrentAvatar,
	errMsg,
	accept,
	fileType,
	justImage,
	justCamera
}) {
	const trans = useSelector(state => state.trans)
	const theme = useSelector(state => state.theme)
	const [selectedFile, setSelectedFile] = useState(null)
	const handleImageChoosen = e => {
		
		e.preventDefault()
		let file = e.target.files[0]
		setSelectedFile(file)
		if (file && file.size < 1000000 && fileType === "img") {
					
			let reader = new FileReader()
			reader.readAsDataURL(file)
			reader.onloadend = () => {
				changeCurrentAvatar(reader.result)
			}
		}
		else if (file && file.size < 20000000 && fileType === "file") {
			const data = new FormData()
			data.append("postedFile", file);
			changeCurrentAvatar(data);
		}
		// else if (1000000 < file.size < 3000000) {
		// 	errMsg(trans.tooLongSize2)
		// }
		else if (file.size > 1000000 && fileType === "img") {
			errMsg(trans.tooLongSize3)
		}
		else if (file.size > 2000000 && fileType === "file") {
			errMsg(trans.tooLongSize2)
		}
	}

	return (
		<React.Fragment>
			<input
				id={id || 'upload'}
				name="image"
				type="file"
				accept={accept}
				// capture="camera"
				onChange={e => handleImageChoosen(e)}
			/>
			<label htmlFor={id || 'upload'}
				className={`${classes}`}>
				{justIcon && (
					<span className="btn btn-primary">
						<PlusIcon color={"#fff"} /></span>
				)}
				{justCamera && (
					<span className="btn">
						<CameraIcon color={"rgba(96, 168, 221, 1)"} />
						<span className="changeBackgroundImagetext"> {trans.changeBackgroundImage}</span>
					</span>
				)}
				{justImage && (
					<div className="file_upload_area">
						{!selectedFile &&
							<UploadIcon color={(theme === "dark" ? "#e5e5e5" : "#cccccc")} />}
						{selectedFile && selectedFile.name}
						{selectedFile && <span className="size">{selectedFile.size + " B"}</span>}
					</div>
				)}
			</label>
		</React.Fragment>
	)
}
