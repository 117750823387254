import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { baseUrl } from '../../config';
import firm from '../../images/firm.png'
import Switch from '../anBrains/Switch';
import { projectType } from '../../redux/action_types';
import { fetchProject } from '../../redux/actions/project_ac';
import { crud } from '../../config/constants';
import ContentLoader from 'react-content-loader'

export default function ProjectBoardCard(props) {
    const { photo, name, customerName, modifyDateViewString, isViewPano, id, code } = props;
    const dispatch = useDispatch();
    const trans = useSelector(state => state.trans)
    const project = useSelector(state => state.project)
    const loader = useSelector(state => state.loader)
    const theme = useSelector(state => state.theme)
    const [request, setRequest] = useState({ rowCount: 14, pageCount: 0, search: "", sort: "id", "desc": false, data: {} })
    //console.log(props);

    const changeStatus = (a, e) => {
        let updateItem = {};
        for (let index = 0; index < project.list.length; index++) {
            const selectedItem = project.list[index];
            if (selectedItem.id === e.id) {
                project.list[index].isViewPano = a.target.checked;
                updateItem = project.list[index];
            }
        }
        dispatch({ type: projectType, payload: project.list })
        dispatch(fetchProject(request, crud.update, updateItem, trans))
    }

    return (
        // !loader.loading ?
            <div className="board_card" >
                <div className="grid_card_profile_img_container" onClick={props.onClick}>
                    {(photo && photo.length > 5)
                        ? <img src={baseUrl + photo} alt="" className="grid_card_profile_img" />
                        : <img src={firm} alt="" className="grid_card_profile_img" />
                    }
                </div>
                <div className="grid_card_information_container">
                    <div className="grid_card_information" >
                        <span className="grid_card_name">{name}</span>
                        <span className="grid_card_position">
                            <span className="badge">{customerName}</span>
                        </span>
                        <span className="grid_card_phone"> {modifyDateViewString}</span>
                        <span className="grid_card_code">
                            {code}</span>
                    </div>
                    <div className="grid_card_settings">
                        <div>
                            {trans.pano}
                            <Switch
                                id={"projectStatusSwitch" + id}
                                value={isViewPano}
                                onChange={(a) => changeStatus(a, props)} />
                        </div>
                    </div>
                </div>
                {/* <div className="grid_card_edit_buttons">
            <Link to={"/project-management/project-detail/" + e.id}
                onClick={() => editItem(e)}>
                <EditIcon color={(theme === "dark" ? "#e5e5e5" : "#000")} />
            </Link>
            <button onClick={() => deleteConfirm(e)}>
                <TrushIcon
                    color={(theme === "dark" ? "#e5e5e5" : "#000")}
                /></button>
        </div> */}
            </div> 
            // : <ContentLoader
            //     preserveAspectRatio="none"
            //     viewBox="0 0 400 200"
            //     height={200}
            //     width={"100%"}
            //     speed={1}
            //     backgroundColor={theme === "dark" ? "#666666" : "#efefef"}
            //     foregroundColor={theme === "dark" ? "#222222" : "#cccccc"}>
            //     <rect x="0" y="56" rx="2" ry="2" width="100%" height="200" />
            //     {/* <rect x="0" y="300" rx="2" ry="2" width="1000" height="211" /> */}
            // </ContentLoader>
    )
}