import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Input from '../anBrains/Input'
import Button from '../anBrains/Button'
import Avatar from '../anBrains/Avatar'
import ContentLoader from 'react-content-loader'
import { fetchCustomers } from '../../redux/actions/customer_ac'
import firm from '../../images/firm.png'
import { crud } from '../../config/constants'

export default function CustomerForm(props) {
    const trans = useSelector(state => state.trans)
    const loader = useSelector(state => state.loader)
    const current = useSelector(state => state.current)
    const dispatch = useDispatch()
    const { success, change } = useSelector(state => state.result)
    useEffect(() => {
        if (success === true) {
            setField(fields)
        }
    }, [change])

    var fields = {
        type: 0,
        name: "",
        superVisorId: 0,
        email: "",
        phone: "",
        address: "",
        fax: "",
        photo: "",
        taxOffice: "",
        taxNumber: ""
    }
    const [field, setField] = useState(fields)

    const errors = {
        nameError: "",
        typeError: "",
        emailError: "",
        phoneError: "",
        superVisorIdError: "",
        addressError: "",
        photoError: "",
        taxOfficeError: "",
        taxNumberError: "",
    }
    const [err, setErr] = useState(errors)


    let validate = () => {
        // if (field.code.length < 3 || field.code.length > 30) {
        //     setErr({ ...errors, codeError: trans.toShort })
        //     return false
        // }


        if (field.name.length < 3 || field.name.length > 30) {
            setErr({ ...errors, nameError: trans.toShort })
            setMessage(trans.toShort)
            return false
        }

        // if (field.phone.length < 10 || field.phone.length > 30) {
        //     setErr({ ...errors, phoneError: trans.pleaseEnterValidPhoneAddress })
        //     setMessage(trans.pleaseEnterValidPhoneAddress)
        //     return false
        // }

        // if (field.fax.length < 10 || field.fax.length > 30) {
        //     setErr({ ...errors, faxError: trans.pleaseEnterValidFaxAddress })
        //     setMessage(trans.pleaseEnterValidFaxAddress)
        //     return false
        // }

        // if (!field.email.includes('@') || field.email.length > 30) {
        //     setErr({ ...errors, mailError: trans.pleaseEnterValidEmailAddress })
        //     setMessage(trans.pleaseEnterValidEmailAddress)
        //     return false
        // }

        // if (field.address.length < 5 || field.address.length > 50) {
        //     setErr({ ...errors, addressError: trans.toShort })
        //     setMessage(trans.toShort)
        //     return false
        // }

        // if (field.taxOffice.length < 5 || field.taxOffice.length > 25) {
        //     setErr({ ...errors, taxOfficeError: trans.toShort })
        //     setMessage(trans.toShort)
        //     return false
        // }
        // if (field.taxNumber.length < 5 || field.taxNumber.length > 25) {
        //     setErr({ ...errors, taxNumberError: trans.toShort })
        //     setMessage(trans.toShort)
        //     return false
        // }
        // if (field.superVisorId <= 0) {
        //     setErr({ ...errors, superVisorIdError: trans.pleaseEnterValid })
        //     setMessage(trans.pleaseEnterValid)

        //     return false
        // }
        if (field.type < 0) {
            setErr({ ...errors, typeError: trans.pleaseEnterValid })
            setMessage(trans.pleaseEnterValid)
            return false
        }
        return true
    }

    const register = e => {

        e.preventDefault();
        if (validate()) {
            setErr(errors)
            dispatch(fetchCustomers({}, crud.create, field, trans))
        }
    }
    const changeUrl = url => {
        setField({ ...field, photo: url })
    }
    const supervisorChange = (e) => {
        setField({ ...field, superVisorId: e.target.value })
    }
    const [message, setMessage] = useState("");
    const compnayChange = (e) => {
        setField({ ...field, type: e.target.value })
    }
    return !loader.loading ? (<div className="employeeForm">
        <Avatar changeUrl={changeUrl} defaultImg={firm} />
        {/* <Input placeholder={trans.code}
            name={trans.code}
            header={trans.code}
            value={field.code}
            onChange={e => setField({ ...field, code: e.target.value })}
            error={err.codeError}
            maxLength={30}
            minLength={3} /> */}
        {/* <Input placeholder={trans.representative}
            name={trans.representative}
            header={trans.representative}
            value={field.representative}
            onChange={e => setField({ ...field, representative: e.target.value })}
            error={err.representativeError}
            maxLength={30}
            minLength={3} /> */}
        <Input placeholder={trans.name}
            name={trans.name}
            header={trans.name}
            value={field.name}
            onChange={e => setField({ ...field, name: e.target.value })}
            error={err.nameError}
            maxLength={30}
            minLength={3} />
        <Input
            placeholder={"5xxxxxxx"}
            type={"number"}
            name={trans.phone}
            header={trans.phone}
            value={field.phone}
            maxLength={30}
            minLength={10}
            onChange={e => setField({ ...field, phone: e.target.value })}
            error={err.phoneError} />
        <Input
            placeholder={"xxxxxxxx"}
            type={"number"}
            name={trans.fax}
            header={trans.fax}
            value={field.fax}
            maxLength={30}
            minLength={10}
            onChange={e => setField({ ...field, fax: e.target.value })}
            error={err.faxError} />
        <Input
            placeholder={trans.email}
            name={trans.email}
            header={trans.email}
            value={field.email}
            maxLength={30}
            minLength={5}
            onChange={e => setField({ ...field, email: e.target.value })}
            error={err.mailError} />
        <Input
            placeholder={trans.address}
            name={trans.address}
            header={trans.address}
            value={field.address}
            maxLength={50}
            minLength={5}
            onChange={e => setField({ ...field, address: e.target.value })}
            error={err.addressError} />
        <Input
            placeholder={trans.taxoffice}
            name={trans.taxoffice}
            header={trans.taxoffice}
            value={field.taxOffice}
            maxLength={30}
            minLength={5}
            onChange={e => setField({ ...field, taxOffice: e.target.value })}
            error={err.taxOfficeError} />

        <Input placeholder={trans.taxnumber}
            type={"number"}
            name={trans.taxnumber}
            header={trans.taxnumber}
            value={field.taxNumber}
            min={0}
            max={1000}
            // maxLength={15}
            minLength={5}
            onChange={e => setField({ ...field, taxNumber: e.target.value })}
            error={err.taxNumberError}
        />
        <div className="input-container">
            <span className="input_header">
                Supervisor
                {err.superVisorIdError && <p className="input_danger_message">{err.superVisorIdError}</p>}
            </span>
            <select className={`input select ${err.superVisorIdError ? 'input-error' : ''}`} onChange={supervisorChange} value={field.superVisorId} >
                <option value={0}>{trans.select}</option>
                {(current.users && current.users.length) ?
                    current.users.map((u, i) => {
                        return (<option key={"supervisor" + i} value={u.id}>{u.name}</option>)
                    }) : null}
            </select>
        </div>
        <div className="input-container">
            <span className="input_header">
                {trans.partnerType}
                {err.superVisorIdError && <p className="input_danger_message">{err.superVisorIdError}</p>}
            </span>
            <select
                className="input select"
                onChange={e => compnayChange(e)}
                value={field.type} >
                <option value={0}>{trans.customer}</option>
                <option value={1}>{trans.supplier}</option>
            </select>
        </div>
        {message && <span className="errorspan">{trans.error + ": " + message}</span>}

        <Button classes={"btn btn-primary"}
            onClick={register}>{trans.add}</Button>
    </div>) : (
            <div className="employeeForm">
                <ContentLoader
                    viewBox="0 0 250 600"
                    height={600}
                    width={275}
                    speed={1}
                    backgroundColor="#ddd"
                    foregroundColor="#ecebeb"
                    {...props}
                >
                    <circle cx="150" cy="30" r="30" />
                    <rect x="25" y="100" rx="3" ry="3" width="275" height="10" />
                    <rect x="25" y="140" rx="3" ry="3" width="275" height="10" />
                    <rect x="25" y="180" rx="3" ry="3" width="275" height="10" />
                    <rect x="25" y="220" rx="3" ry="3" width="275" height="10" />
                </ContentLoader>
            </div>
        )
}
