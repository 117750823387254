import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { baseUrl } from '../../config';
import { crud, workOrderStatus } from '../../config/constants';
import manprofile from '../../images/profiles/man.png';
import womanprofile from '../../images/profiles/woman.png';
export default function TimesByUser() {

    const trans = useSelector(state => state.trans)
    const current = useSelector(state => state.current)
    // const dispatch = useDispatch()

    return (
        <>
            <div className="timesbyuser_container">
                <div className="table tablewithoutshdow">
                    <table>
                        <thead>
                            <tr>
                                <th>{trans.personals}</th>
                                <th>{trans.scheduledtime}</th>
                                <th>{trans.timespent}</th>
                                <th>{trans.progress}</th>
                                <th>{trans.status}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {current.item && current.item.timesByUsers && current.item.timesByUsers.length > 0 && current.item.timesByUsers.map((e, i) => {
                                return (
                                    <tr className="row" key={e.userId}>
                                        <td style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                            {e.user && <img src={e.user.photo ? baseUrl + e.user.photo : (e && e.gender === true) ? manprofile : womanprofile} />}
                                            {e.user && e.user.name}
                                        </td>
                                        <td >{e.workOrderTotalTime}</td>
                                        <td>{e.totalTime}</td>
                                        <td>
                                            <div className="progress_container">
                                                <div className="progress_bar"
                                                    style={{ width: (e.progress >= 100 ? 100 : (e.status === workOrderStatus.done ? 100 : e.progress)) + "%" }}>
                                                    {(e.progress >= 100 ? 100 : (e.status === workOrderStatus.done ? 100 : e.progress)) + "%"}
                                                </div>
                                            </div>
                                        </td>
                                        <td> {e.totalTime === "00:00:00" && !e.status === workOrderStatus.done
                                            ? <span className="priority"
                                                style={{ backgroundColor: "rgba(96, 168, 221, 1)" }}>
                                                {trans.notstartedyet}
                                            </span>
                                            : (e.status === workOrderStatus.done
                                                ? <span className="priority" style={{ backgroundColor: "rgba(232, 63, 64, 1)" }}>{trans.completed}</span>
                                                : <span className="priority" style={{ backgroundColor: "rgba(247, 202, 49, 1)", color: "#222" }}>{trans.doing}</span>)}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
