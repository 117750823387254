import axios from 'axios'
import { axiosConfig, baseUrl,openNotification } from '../../config'
import { itemHoursType, itemType, updateSettingType, updateWorkingHoursType,isLoginType } from '../action_types'
import { success, loading } from './loader_ac'
import { crud } from '../../config/constants'
export const fetchCompanySetting = (request = { rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}, trans=undefined) => {
    let headers = axiosConfig.headers

    let url = `${baseUrl}/api/companys`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            })
                .then(response => {
                    dispacth(loading())
                    if (response.data.success) {
                        switch (requestType) {
                            case crud.create:
                                break;
                            case crud.read:
                                break;
                            case crud.update:
                                dispacth({
                                    type: updateSettingType,
                                    payload: response.data.data,
                                })
                                dispacth(success(true))
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.delete:
                                break;
                            case crud.list:
                                dispacth({
                                    type: itemType,
                                    payload: response.data.data,
                                })
                                dispacth({
                                    type: itemHoursType,
                                    payload: response.data.data[0].hours
                                })
                                console.log(response.data.data);
                                break;
                            default:
                                break;
                        }
                    }
                    else{
                        dispacth(success(false))
                        openNotification('error', trans.errorTitle, trans.errorBody,3)
                    }
                })
                .catch(reason => {
                    console.log();
                    openNotification('error', trans.errorTitle, trans.errorBody,3)
                  
                    //dispacth(success(false))
                })
        } catch (error) {
            console.log(error)
            dispacth(success(false))
            openNotification('error', trans.errorTitle, trans.errorBody,3)
        }
    }
}

export const fetchCompanyWokingHours = ({}, requestType = 5, data = {}, trans=undefined) => {
    let headers = axiosConfig.headers

    let url = `${baseUrl}/api/hours`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data
            })
                .then(response => {
                    dispacth(loading())
                    if (response.data.success) {
                        switch (requestType) {
                            case crud.update:
                                dispacth({
                                    type: updateWorkingHoursType,
                                    payload: response.data.data,
                                })
                                console.log(response);
                                dispacth(success(true))
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            default:
                                break;
                        }
                    }
                })
                .catch(reason => {
                    console.log();
                    openNotification('error', trans.errorTitle, trans.errorBody,3)
                })
        } catch (error) {
            console.log(error)
            openNotification('error', trans.errorTitle, trans.errorBody,3)
        }
    }
}