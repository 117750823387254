import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Input from '../anBrains/Input';
import { crud } from '../../config/constants';
import { baseUrl } from '../../config';
import { fetchWorkordercomments } from '../../redux/actions/workorder_ac';
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

export default function CommentsByWorkorder({ current }) {

    const trans = useSelector(state => state.trans)
    const result = useSelector(state => state.result)
    const dispatch = useDispatch()
    var fields = {
        name: ""
    }
    const myRef = useRef(null)


    useEffect(() => {
        setSended(false);
        myRef.current.scrollTop = myRef.current.scrollHeight;
        setField(fields)
    }, [result.change])

    const [field, setField] = useState(fields)
    const [sended, setSended] = useState(false)
    const add = () => {

        dispatch(fetchWorkordercomments({},
            crud.create,
            {
                workOrderId: current.item.id,
                name: field.name
            }, trans))

    }

    const onEnter = (e) => {
        if (e.key === "Enter" && !sended && field.name != "") {
            setSended(true);
            add()
        }

    }
    return (
        <>
            <div className="commentsbyworkorder">
                <ul ref={myRef}>
                    {
                        (current.item &&
                            current.item.comments &&
                            current.item.comments.length > 0) ?
                            current.item.comments.map((c, i) => {
                                return (
                                    <li key={"comment" + i} >
                                        <img src={baseUrl + (c.user && c.user.photo)} title={c.user && c.user.name} />
                                        <div className="info">
                                            <span className="date"> {c.createDateString}</span>
                                            <div className={c.active ? "comment active" : "comment"} title={c.name}>
                                                {c.name.length > 100 ? c.name.substring(0, 100) + " ..." : c.name}
                                            </div>
                                        </div>
                                    </li>
                                )
                            }) : null
                    }
                </ul>
                <div className="comment_area">
                    <Input placeholder={trans.description}
                        name={trans.description}
                        header={trans.description}
                        value={field.name}
                        textarea={true}
                        onKeyPress={onEnter}
                        readOnly={sended}
                        onChange={e => setField({ ...field, name: e.target.value })}
                    />
                    {/* <button className="btn-primary"
                        onClick={() => add()}>{trans.add} </button> */}
                </div>
            </div>
        </>
    )
}
