import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { crud, dateFormatter } from '../../config/constants'
import { fetchBudgetDetails, fetchBudgets } from '../../redux/actions/project_cost_ac'
import { budgetDetailType, budgetItemType, budgetType, costTypeType, requestBudgetType } from '../../redux/action_types'
import Button from '../anBrains/Button'
import Input from '../anBrains/Input'
import Modal from '../anBrains/Modal'
import Switch from '../anBrains/Switch'
import NumberFormat from 'react-number-format';
import { BarIcon, EditIcon, GridIcon, PlusIcon, TrushIcon } from '../icons'
import BudgetForm from './BudgetForm'
import BudgetDetailForm from './BudgetDetailForm'
var toDate = new Date();
var year = toDate.getFullYear();
var month = toDate.getMonth() + 1;
var toDateString = year + (month.toString().length > 1 ? "-" : "-0") + month;
var defaultItem = {
    name: "",
    price: 0,
    realizedPrice: 0,
    date: toDateString,
    type: 1,
    id: 0
}

export default function ProjectCost(props) {
    const dispatch = useDispatch()
    const trans = useSelector(state => state.trans)
    const project_cost = useSelector(state => state.project_cost)
    const current = useSelector(state => state.current)
    const theme = useSelector(state => state.theme)
    const loader = useSelector(state => state.loader)
    // useEffect(() => {
    //     project_cost.request.projectId = props.projectId
    //     dispatch({ type: requestBudgetType, payload: project_cost.request })
    // }, [])

    const changeCostType = () => {
        // project_cost.type = !project_cost.type
        dispatch(fetchBudgets({ ...project_cost.request, budgetType: !project_cost.type, projectId: props.projectId, loader: true }, crud.list, {}, trans))
        dispatch({ type: costTypeType })
    }
    const editItem = e => {
        var date = e.date.substr(0, 7);
        dispatch({ type: budgetItemType, payload: { ...e, date } })
        setIsAddOpen(true);
    }
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [isAddOpen, setIsAddOpen] = useState(false)

    const deleteItem = () => {
        
        if (!project_cost.item.incomeId) {
            dispatch(fetchBudgets({ ...project_cost.request, loader: true }, crud.delete, project_cost.item, trans))
            let budgets = project_cost.budgets.filter((e) => {
                return e.id !== project_cost.item.id
            });
            dispatch({ type: budgetType, payload: budgets })
            if (project_cost.item.selected)
                dispatch({ type: budgetDetailType, payload: [] })
        }
        else {
            dispatch(fetchBudgetDetails({ ...project_cost.request, loader: true, id: project_cost.item.id }, crud.delete, {}, trans))
            let details = project_cost.details.filter((e) => {
                return e.id !== project_cost.item.id
            });
            dispatch({ type: budgetDetailType, payload: details })
        }
        setIsDeleteOpen(false)
    }
    const deleteConfirm = (e) => {
        console.log(e)
        setIsDeleteOpen(true)
        dispatch({ type: budgetItemType, payload: e })
    }


    const getDetails = (income) => {
        project_cost.request.budgetId = income.id;
        for (let i = 0; i < project_cost.budgets.length; i++)
            project_cost.budgets[i].selected = false;

        project_cost.budgets.find(x => x.id === income.id).selected = true;
        dispatch(fetchBudgetDetails(project_cost.request, crud.list, {}, trans))
    }

    const editDetailItem = e => {
        dispatch({ type: budgetItemType, payload: e })
        setIsAddDetailModalOpen(true);
    }
    // const [isDeleteDetailModalOpen, setIsDeleteDetailModalOpen] = useState(false)
    const [isAddDetailModalOpen, setIsAddDetailModalOpen] = useState(false)
    return !loader.loading
        ? <>
            <Modal
                isOpen={isAddOpen}
                withClose
                setIsOpen={setIsAddOpen}
                classes={"modal_add_leave"}>
                <BudgetForm setIsAddOpen={setIsAddOpen} projectId={props.projectId} />
            </Modal>
            <Modal
                isOpen={isDeleteOpen}
                withClose
                setIsOpen={setIsDeleteOpen}
                classes={"modal_delete"}>
                {trans.areyousureyouwanttodelete}
                <Button classes="btn-red" onClick={() => deleteItem(true)}>{trans.confirm}</Button>
            </Modal>
            <Modal
                isOpen={isAddDetailModalOpen}
                withClose
                setIsOpen={setIsAddDetailModalOpen}
                classes={"modal_add_leave"}>
                <BudgetDetailForm
                    setIsAddOpen={setIsAddDetailModalOpen}
                    projectId={props.projectId} />
            </Modal>
            <div className="project_cost_container">
                <section>
                    <div className="project_cost_header_container">
                        <span className="headerspan">
                            <h2 style={{ maxWidth: "30%" }}>{project_cost.type ? trans.income : trans.expense}</h2>
                        </span>
                        <Button
                            icon={<PlusIcon color={(theme === "dark" ? "rgba(96, 168, 221, 1)" : "#1a9bc4")} />}
                            classes="btn-basic mr-4"
                            onClick={() => {
                                setIsAddOpen(true)
                                defaultItem.type = project_cost.type;
                                defaultItem.projectId = props.projectId;
                                dispatch({ type: budgetItemType, payload: { ...defaultItem } })

                            }}>
                            {trans.addAccount}
                        </Button>
                        {project_cost.budgets.length > 0 &&
                            project_cost.budgets.some(x => x.selected === true) &&
                            <Button
                                icon={<PlusIcon color={(theme === "dark" ? "rgba(96, 168, 221, 1)" : "#1a9bc4")} />}
                                classes="btn-basic mr-4"
                                onClick={() => {
                                    setIsAddDetailModalOpen(true)
                                    defaultItem.type = project_cost.type;
                                    defaultItem.projectId = props.projectId;
                                    dispatch({ type: budgetItemType, payload: { ...defaultItem, date: undefined } })
                                }}>
                                {project_cost.type ? trans.addAnticipatedIncome : trans.enterPurchase}
                            </Button>}
                        <span className="switch_container">
                            <span>{project_cost.type ? trans.projectRevenues : trans.projectExpenses}</span>
                            <Switch
                                id={"costTypeSwitch"}
                                value={project_cost.type}
                                onChange={changeCostType} />
                        </span>
                    </div>
                </section>
                <section>
                    <div className="project_cost_container_left_side">
                        <div className="table" >
                            <table>
                                <thead>
                                    <tr>
                                        <th>{trans.account2} </th>
                                        <th style={{ textAlign: "right", paddingRight: 0 }}>{trans.predictedPrice}</th>
                                        <th style={{ textAlign: "right", paddingRight: 0 }}>{trans.realizedPrice}</th>
                                        <th style={{ textAlign: "right", paddingRight: 0 }}>{trans.remainingPrice}</th>
                                        <th style={{ textAlign: "right", paddingRight: 0 }}>{trans.progress}</th>
                                        <th style={{ textAlign: "right", paddingRight: "5px" }}>{trans.settings}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {project_cost.budgets
                                        && project_cost.budgets.length > 0
                                        && project_cost.budgets.map((b, i) => {
                                            return (
                                                <tr className="row" key={i} style={b.selected ? { backgroundColor: "rgba(247, 202, 49, 0.2)" } : {}}>
                                                    <td onClick={e => getDetails(b)}>{b.name}</td>
                                                    <td onClick={e => getDetails(b)} style={{ textAlign: "right" }}>
                                                        <NumberFormat
                                                            value={b.price}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'₺ '}
                                                        />
                                                    </td>
                                                    <td onClick={e => getDetails(b)} style={{ textAlign: "right" }}>
                                                        <NumberFormat
                                                            value={b.realizedPrice}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'₺ '}
                                                        />
                                                    </td>
                                                    <td onClick={e => getDetails(b)} style={{ textAlign: "right" }}>
                                                        <NumberFormat
                                                            value={b.finalPrice}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'₺ '}
                                                        />
                                                    </td>
                                                    <td onClick={e => getDetails(b)}>
                                                        <div className="progress_container">
                                                            <div className="progress_bar"
                                                                style={{ width: (b.progress > 100 ? 100 : b.progress) + "%" }}>
                                                                {(b.progress > 100 ? 100 : b.progress) + "%"}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td style={{ textAlign: "right" }}>
                                                        <button onClick={() => editItem(b)}>
                                                            <EditIcon color={(theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")} />
                                                        </button>
                                                        <button onClick={() => deleteConfirm(b)}>
                                                            <TrushIcon
                                                                color={(theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)")}
                                                            />
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th></th>
                                        <th style={{ textAlign: "right", paddingRight: 0 }}>
                                            <NumberFormat
                                                value={project_cost.costDetail && project_cost.costDetail.totalPredicted}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'₺ '}
                                            />
                                        </th>
                                        <th style={{ textAlign: "right", paddingRight: 0 }}>
                                            <NumberFormat
                                                value={project_cost.costDetail && project_cost.costDetail.totalRealized}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'₺ '}
                                            />
                                        </th>
                                        <th style={{ textAlign: "right", paddingRight: 0 }}>
                                            <NumberFormat
                                                value={project_cost.costDetail && project_cost.costDetail.totalFinal}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'₺ '}
                                            />
                                        </th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    <div className="project_cost_container_right_side">
                        <div className="table" >
                            <table>
                                <thead>
                                    <tr>
                                        <th>{project_cost.type ? trans.income : trans.expense}</th>
                                        <th style={{ textAlign: "right", paddingRight: 0 }}>{trans.predictedPrice}</th>
                                        <th style={{ textAlign: "right", paddingRight: 0 }}>{trans.realizedPrice}</th>
                                        <th style={{ textAlign: "right", paddingRight: 0 }}>{trans.date}</th>
                                        <th style={{ textAlign: "right", paddingRight: "5px" }}>{trans.settings}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {project_cost.details
                                        && project_cost.details.length > 0
                                        && project_cost.details.map((b, i) => {
                                            return (
                                                <tr className="row" key={i}>
                                                    <td>{b.name}</td>
                                                    <td style={{ textAlign: "right" }}>
                                                        <NumberFormat
                                                            value={b.price}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'₺ '}
                                                        />
                                                    </td>
                                                    <td style={{ textAlign: "right" }}>
                                                        <NumberFormat
                                                            value={b.realizedPrice}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            prefix={'₺ '}
                                                        />
                                                    </td>
                                                    <td style={{ textAlign: "right" }}>
                                                        {dateFormatter(new Date(b.date)).replace('T', ' ')}
                                                    </td>
                                                    <td style={{ textAlign: "right" }}>
                                                        <button onClick={() => editDetailItem(b)}>
                                                            <EditIcon color={(theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")} />
                                                        </button>
                                                        <button onClick={() => deleteConfirm(b)}>
                                                            <TrushIcon
                                                                color={(theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)")}
                                                            />
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                </tbody>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th style={{ textAlign: "right", paddingRight: 0 }}>

                                        </th>
                                        <th style={{ textAlign: "right", paddingRight: 0 }}></th>
                                        <th style={{ textAlign: "right", paddingRight: 0 }}></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </section>
            </div>
        </>
        : <div>

        </div>
}
