import axios from 'axios'
import { axiosConfig, baseUrl, openNotification } from '../../config'
import { pageCountType, autorizationType, addAutorizationType, updateAutorizationType, autorizationMenuType, getUserType, loginErrorType, responseType, hasExpiredType, isLoginType } from '../action_types'
import { success, calculatePageNumber, loading } from './loader_ac'
import { crud, responseTypes } from '../../config/constants'
import { getToken, onMessageListener } from '../../firebase';
import firebase from "firebase"
export const fetchAuthorization = (request = { rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}, trans = undefined) => {
    let headers = axiosConfig.headers

    let url = `${baseUrl}/api/roles`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    return dispacth => {
        try {
            // dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            })
                .then(response => {
                    console.log(response);
                    if (response.data.success) {
                        switch (requestType) {
                            case crud.create:
                                dispacth({
                                    type: addAutorizationType,
                                    payload: response.data.data,
                                })
                                dispacth(success(true))
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.read:
                                break;
                            case crud.update:
                                dispacth({
                                    type: updateAutorizationType,
                                    payload: response.data.data,
                                })
                                dispacth(success(true))
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.delete:
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.list:
                                dispacth({
                                    type: autorizationType,
                                    payload: response.data.data,
                                })
                                dispacth({
                                    type: autorizationMenuType,
                                    payload: response.data.data2,
                                })
                                dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) })
                                break;
                            default:
                                break;
                        }
                    }
                })
                .catch(reason => {
                    console.log();
                    openNotification('error', trans.errorTitle, trans.errorBody, 3)


                })
        } catch (error) {
            console.log(error)
            openNotification('error', trans.errorTitle, trans.errorBody, 3)
            // dispacth(loading())
        }
    }
}
export const fetchChangeAuthorization = (data, trans = undefined) => {
    let headers = axiosConfig.headers

    let url = `${baseUrl}/api/roles/changeauth`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data
            })
                .then(response => {
                    console.log(response);
                    if (response.data.success)
                        dispacth(success(true))
                    openNotification('success', trans.successTitle, trans.successBody, 3)
                    dispacth(loading())

                })
                .catch(reason => {
                    console.log();
                    dispacth(loading())
                    openNotification('error', trans.errorTitle, trans.errorBody, 3)

                })
        } catch (error) {
            console.log(error)
            dispacth(loading())
            openNotification('error', trans.errorTitle, trans.errorBody, 3)
        }
    }
}
export const fetchLogin = (data, trans = undefined) => {
    let headers = axiosConfig.headers

    let url = `${baseUrl}/token`
    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data
            })
                .then(response => {

                    if (response.data.success) {
                        dispacth(success(true))
                        localStorage.setItem("token", response.data.data.token)
                        var user = response.data.data.user
                        localStorage.setItem("user", JSON.stringify(user))
                        dispacth({ type: getUserType, payload: user })
                        dispacth({ type: isLoginType, payload: true })

                        //firebase 
                        if (firebase.messaging.isSupported()) {
                            const deviceId = navigator.userAgent
                            if (user && (user.userDevices === null || user.userDevice === undefined)) {
                                // var token = getToken(user)

                                const firebasePromise = new Promise((resolve, reject) => { resolve(getToken()) });
                                firebasePromise.then(token => {

                                    const updateTokenPromise = new Promise((resolve, reject) => { resolve(updateToken({ deviceId, token })) });
                                    updateTokenPromise.then(res => {
                                        console.log(user);

                                    }, err => {

                                    })
                                    console.log(token);
                                }, err => {

                                    console.log(err);
                                })

                            }
                            else {
                                const firebasePromise = new Promise((resolve, reject) => { resolve(getToken(user)) });
                                firebasePromise.then(token => {

                                    if (user && !user.userDevices.some(x => x.deviceId === deviceId && x.token === token)) {
                                        const updateTokenPromise = new Promise((resolve, reject) => { resolve(updateToken({ deviceId, token })) });
                                        updateTokenPromise.then(res => {
                                            console.log(user);

                                        }, err => {

                                        })
                                        console.log(token);
                                    }
                                }, err => {

                                    console.log(err);
                                })
                            }
                        }

                    }
                    else if (response.data.message === "AccountExpired") {
                        dispacth({ type: hasExpiredType, payload: true })
                        dispacth(success(false))
                    }
                    else {
                        dispacth(success(false))
                    }
                    dispacth({ type: responseType, payload: response.data })
                    dispacth(loading())
                })
                .catch(reason => {

                    dispacth(loading())
                    openNotification('error', trans.errorTitle, trans.errorBody, 3)

                })
        } catch (error) {
            dispacth(loading())
            openNotification('error', trans.errorTitle, trans.errorBody, 3)
        }
    }
}
export const fetchEmail = (email) => {
    let headers = axiosConfig.headers

    let url = `${baseUrl}/generatelinksendemail/${email}`
    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'GET',
                url,
                headers
            })
                .then(response => {
                    dispacth(loading())
                    dispacth({ type: responseType, payload: response.data })
                })
                .catch(reason => {

                    dispacth(loading())

                })
        } catch (error) {
            dispacth(loading())
        }
    }
}
export const fetchChangePassword = (data) => {
    let headers = axiosConfig.headers

    let url = `${baseUrl}/changePassword`
    if (localStorage.getItem("token"))
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }

    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data
            })
                .then(response => {
                    dispacth(loading())
                    dispacth({ type: responseType, payload: response.data })
                })
                .catch(reason => {

                    dispacth(loading())
                })
        } catch (error) {
            dispacth(loading())
        }
    }
}

export const updateToken = (userDevice) => {

    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/UserDevices`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    let data = {
        request: {},
        requestType: 3,
        data: userDevice
    }
    return axios({
        method: 'POST',
        url,
        headers,
        data
    }).then(res => res);

}
export const fetchUser = (userId) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/getUser/${userId}`
    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'GET',
                url,
                // headers
            })
                .then(response => {
                    dispacth(loading())
                    if (response.data.success)
                        dispacth({ type: getUserType, payload: { ...response.data.data, name: "", password: "", company: "", phone: "", password: "", newPassword: "", termofuse: false, advertisement: true } })
                    else dispacth({ type: responseType, payload: response.data })
                })
                .catch(response => {
                    dispacth(loading())
                    if (response.data)
                        dispacth({ type: responseType, payload: response.data })
                })
        } catch (error) {
            dispacth(loading())
            if (error.data)
                dispacth({ type: responseType, payload: error.data })
        }
    }
}
export const fetchFinishDemo = (data) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/finishDemo`
    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                data
            })
                .then(response => {
                    dispacth({ type: responseType, payload: { ...response.data, redirect: true } })
                    dispacth(loading())
                })
                .catch(response => {
                    dispacth(loading())
                    if (response.data)
                        dispacth({ type: responseType, payload: response.data })
                })
        } catch (error) {
            dispacth(loading())
            if (error.data)
                dispacth({ type: responseType, payload: error.data })
        }
    }
}

export const fetchChangeMotto = (data) => {
    let headers = axiosConfig.headers
    if (localStorage.getItem("token"))
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }

    let url = `${baseUrl}/api/changeMotto/${data}`
    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers
            })
                .then(response => {
                    dispacth({ type: responseType, payload: response.data })
                    dispacth(loading())
                })
                .catch(response => {
                    dispacth(loading())
                    if (response.data)
                        dispacth({ type: responseType, payload: response.data })
                })
        } catch (error) {
            dispacth(loading())
            if (error.data)
                dispacth({ type: responseType, payload: error.data })
        }
    }
}

export const fetchChangeBackgroundImage = (data, user) => {
    let headers = axiosConfig.headers
    if (localStorage.getItem("token"))
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }

    let url = `${baseUrl}/api/user/changebackground`
    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data
            })
                .then(response => {
                    
                    if (response.data.success) {
                        dispacth({ type: getUserType, payload: { ...user, background: response.data.data2 } })
                        localStorage.setItem("user", JSON.stringify({ ...user, background: response.data.data2 }))
                    }
                    dispacth({ type: responseType, payload: response.data })
                    dispacth(loading())
                })
                .catch(response => {
                    dispacth(loading())
                    if (response.data)
                        dispacth({ type: responseType, payload: response.data })
                })
        } catch (error) {
            dispacth(loading())
            if (error.data)
                dispacth({ type: responseType, payload: error.data })
        }
    }
}