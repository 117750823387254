import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchWorkorder, fetchWorkorderFullUpdate } from '../../redux/actions/workorder_ac'
import { crud, ganttMode } from '../../config/constants'
import { FrappeGantt } from 'frappe-gantt-react'
// import SortableTree from 'react-sortable-tree/dist/index.cjs.js';
// import SortableTree, { addNodeUnderParent, changeNodeAtPath, removeNodeAtPath, getTreeFromFlatData } from 'react-sortable-tree';
import moment from 'moment'

//import 'react-sortable-tree/style.css'
// import { PlayListPlusIcon, PlusIcon, TrushIcon } from '../icons'
import { ganttCountType, ganttType } from '../../redux/action_types'
// import SortTreeContainer from './SortTreeContainer'
import ProjectStepContainer from './ProjectStepContainer'
import Button from '../anBrains/Button'
// import { ganttType, treeType } from '../../redux/action_types'
// import { task } from '../../icons/proskop'
// import { Rnd } from 'react-rnd';

export default function FrappeGanttContainer(props) {
    const { projectId } = props
    const trans = useSelector(state => state.trans)
    // const current = useSelector(state => state.current)
    const { list, count } = useSelector(state => state.gantt)
    // const theme = useSelector(state => state.theme)
    const loader = useSelector(state => state.loader)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchWorkorder({ projectId, onlyList: true, IsGantStep: true, loader: true }, crud.list, {}, trans))
    }, [])
    const [mode, setMode] = useState("Day")
    const frappe = useRef(null)



    return (<div className="ganttContainer">
        <ProjectStepContainer projectId={projectId} />
        {/* <Rnd
                    default={{
                        x: 0,
                        y: 100,
                        width: "50%",
                        height: "auto",
                    }}
                    minWidth={500}
                    minHeight={300}
                    bounds="window"
                >  */}
        <div className="ganttBox">
            <ul className="ganttMode">
                {ganttMode.map((g, i) => {
                    return (<li onClick={() => setMode(g)} key={"gli" + i}>{g}</li>)
                })}
            </ul>
            {(list && list.length > 0)
                ?
                <div className="frappe_disable_container">
                    <FrappeGantt
                        ref={frappe}
                        tasks={list}
                        viewMode={mode}
                        onDateChange={(task, start, end) => {

                            if (count === 0) {
                                list.find(x => x.id === task.id).start = start;
                                list.find(x => x.id === task.id).startDate = start;
                                list.find(x => x.id === task.id).startDateGantt = start;
                                list.find(x => x.id === task.id).end = end;
                                list.find(x => x.id === task.id).endDate = end;
                                list.find(x => x.id === task.id).endDateGantt = end;
                                dispatch({ type: ganttType, payload: list })
                            }

                            if (count > 0) {
                                dispatch({ type: ganttCountType, payload: count - 1 })
                            }
                        }}
                        onProgressChange={(task, progress) => {
                            list.find(t => t.id === task.id).progress = progress;
                            dispatch({ type: ganttType, payload: list })
                        }} /> </div> : null}
        </div>
        {/* </Rnd>  */}
    </div>
    )

}
