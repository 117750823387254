import axios from 'axios'
import { axiosConfig, baseUrl } from '../../config'
import { labelType,isLoginType } from '../action_types'

export const fetchLabels = (lang) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/labels`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    return dispacth => {
        try {
            // dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers
            })
                .then(response => {
                    if (response.data.success) {
                        var options = [
                            {
                                label: lang === "tr" ? "Projeler" : "Projects",
                                options: []
                            },
                            {
                                label: lang === "tr" ? "Müşteriler" : "Customers",
                                options: []
                            },
                            {
                                label: lang === "tr" ? "Departmanlar" : "Departments",
                                options: []
                            },
                        ];

                        var projects = response.data.data.projects;
                        var projectLabels = [];
                        for (let index = 0; index < projects.length; index++) {
                            const element = projects[index];
                            projectLabels.push({ label: element.name, value: element.id });
                        }
                        options[0].options = projectLabels;
                        
                        var customers = response.data.data.customers;
                        var customerLabels = [];
                        for (let index = 0; index < customers.length; index++) {
                            const element = customers[index];
                            customerLabels.push({ label: element.name, value: element.id });
                        }
                        options[1].options = customerLabels;
                        
                        var departments = response.data.data.departments;
                        var departmentLabels = [];
                        for (let index = 0; index < departments.length; index++) {
                            const element = departments[index];
                            departmentLabels.push({ label: element.name, value: element.id });
                        }
                        options[2].options = departmentLabels;
                        dispacth({
                            type: labelType,
                            payload: options
                        })
                    }
                })
                .catch(reason => {
                    
                  
                })
        } catch (error) {
            console.log(error);
        }
    }
}


export const fetchDepartments = () => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/labels`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    return dispacth => {
        try {
            axios({
                method: 'POST',
                url,
                headers
            })
                .then(response => {
                    if (response.data.success) {
                        var departments = response.data.data.departments;
                        var departmentLabels = [];
                        for (let index = 0; index < departments.length; index++) {
                            const element = departments[index];
                            departmentLabels.push({ label: element.name, value: element.id });
                        }
                        dispacth({
                            type: labelType,
                            payload: departmentLabels
                        })
                    }
                })
                .catch(reason => {
                    
                })
        } catch (error) {
            console.log(error);
        }
    }
}

export const fetchDepartmentList = () => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/labels`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    return dispacth => {
        try {
            axios({
                method: 'POST',
                url,
                headers
            }).then(response => {
                    if (response.data.success) {
                        var departments = response.data.data.departments;
                        var departmentLabels = [];
                        for (let index = 0; index < departments.length; index++) {
                            const element = departments[index];
                            departmentLabels.push({ name: element.name, id: element.id, selected: false });
                        }
                        dispacth({
                            type: labelType,
                            payload: departmentLabels
                        })
                    }
                })
                .catch(reason => {
                    
                })
        } catch (error) {
            console.log(error);
        }
    }
}