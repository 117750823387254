import { notificationType, notificationAllType, notificationCountType } from '../action_types'
const reducer = (state = { list: [], count: "" }, action) => {
    switch (action.type) {
        case notificationType:
            return { ...state, list: action.payload };
        case notificationAllType:
            return { ...state, allList: action.payload };
        case notificationCountType:
            return { ...state, count: action.payload }

        default:
            return state;
    }
};
export default reducer;
