import { ChevronBottomIcon, CheckCircleIcon, CircleIcon } from '../icons'
import React, { useState, useRef, useEffect } from 'react'
import Button from './Button'
import { useSelector } from 'react-redux'

export default function SelectBox({
    options,
    value,
    setValue,
    defaultValue,
    optionsPosition,
}) {
    const [isOpen, setIsOpen] = useState(false)

    const handleClickOutside = () => {
        setIsOpen(false)
    }

    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef, handleClickOutside)
    const theme = useSelector(state => state.theme)
    return (
        <div className="select-box" ref={wrapperRef}>
            <Button
                classes={`select-box__default ${
                    isOpen ? 'select-box__default__open' : ''
                    }`}
                icon={<ChevronBottomIcon color={(theme === "dark" ? "#e5e5e5" : "#000")} />}
                iconPosition="right"
                onClick={() => setIsOpen(!isOpen)}
            >
                {defaultValue || value || 'null'}
            </Button>
            {isOpen && (
                <div
                    className={`select-box__options select-box__options__${optionsPosition}`}
                >
                    {options.map((option, index) => {

                        const isSelected = value === option.name

                        return (
                            <Button
                                key={index}
                                onClick={() => setValue(index)}
                                classes={`select-box__option ${
                                    isSelected ? 'select-box__option__selected' : ''
                                    }`}
                            >
                                {isSelected ? <CheckCircleIcon /> : <CircleIcon />}
                                {option.name + " "}
                            </Button>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

function useOutsideAlerter(ref, onClick) {
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            onClick()
        }
    }

    useEffect(() => {
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    })
}
