import { employeeListType, addEmployeeType, insideMenuType } from '../action_types'
const reducer = (state = {
    list: [], form: {}, inside_menu: [
        { id: 13, content: "personal", name: { tr: "Personel", en: "Personal" }, active: true },
        { id: 14, content: "leave", name: { tr: "İzin", en: "Leave" }, active: false },
        { id: 15, content: "inOut", name: { tr: "Giriş - Çıkış", en: "In - Out" }, active: false },
        { id: 16, content: "overtime", name: { tr: "Mesai", en: "Over Time" }, active: false },
        { id: 17, content: "payroll", name: { tr: "Bordro", en: "Payroll" }, active: false },
        { id: 18, content: "debit", name: { tr: "Zimmet", en: "debit" }, active: false },
        { id: 19, content: "employeefile", name: { tr: "Dosyalar", en: "Files" }, active: false },
        { id: 56, content: "agenda", name: { tr: "Ajanda", en: "Agenda" }, active: false },
    ]
}, action) => {
    switch (action.type) {
        case employeeListType:
            return { ...state, list: action.payload, filterList: action.payload };
        case addEmployeeType:
            return { ...state, list: [action.payload, ...state.list] };
        case insideMenuType:
            return { ...state, inside_menu: action.payload };
        default:
            return state;
    }
};
export default reducer;