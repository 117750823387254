import React, { useEffect, useState } from "react";
import Layout from "../../components/Container/Layout";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/anBrains/Pagination";
import Button from "../../components/anBrains/Button";
import Input from "../../components/anBrains/Input";
import Modal from "../../components/anBrains/Modal";
import {
  PlusIcon,
  SortIcon,
  Search2Icon,
  EditIcon,
  TrushIcon,
  InfoIcon,
  EvaluateIcon,
  AcceptIcon,
  RejectIcon,
  ChevronBottomIcon,
} from "../../components/icons";
import ContentLoader from "react-content-loader";
import { success } from "../../redux/actions/loader_ac";
import { baseUrl } from "../../config";
import { crud } from "../../config/constants";
import { fetchSuggestion } from "../../redux/actions/suggestion_box_ac";
import { fetchDepartmentList } from "../../redux/actions/label_ac";
import { suggestionType } from "../../redux/action_types";
import FilterByDepartments from "../../components/filter/FilterByDepartments";
import manprofile from "../../images/profiles/man.png";
import womanprofile from "../../images/profiles/woman.png";
import { openNotification } from "../../config";
export default function SuggestionBox(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const trans = useSelector((state) => state.trans);
  const theme = useSelector((state) => state.theme);
  const current = useSelector((state) => state.current);
  const suggestionBox = useSelector((state) => state.suggestionBox);
  const [searchText, setSearchText] = useState("");
  const loader = useSelector((state) => state.loader);
  const label = useSelector((state) => state.label);
  const [selectedPage, setSelectedPage] = useState(1);
  const [isAddSuggestionOpen, setIsAddSuggestionOpen] = useState(false);
  const [isDeleteSuggestionOpen, setIsDeleteSuggestionOpen] = useState(false);
  const [itemSuggestion, setItemSuggestion] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isStatus, setIsStatus] = useState(false);
  const [statusTypeId, setStatusTypeId] = useState(0);
  const [itemId, setItemId] = useState(0);
  const result = useSelector((state) => state.result);
  const [request, setRequest] = useState({ rowCount: 14, pageCount: 0, search: "", sort: "id", desc: false, data: {} });

  useEffect(() => {
    dispatch(fetchSuggestion(request, crud.list, {}, trans));
    dispatch(fetchDepartmentList());
  }, []);

  useEffect(() => {
    if (result.success === true) {
      setIsAddSuggestionOpen(false);
      dispatch(success(false));
    }
  }, [result.success]);

  var fields = {
    departmentId: 0,
    name: "",
    description: "",
  };
  const [field, setField] = useState(fields);

  var err = {
    errDepartmentId: "",
    errName: "",
    errDescription: "",
  };
  const [error, setError] = useState(err);

  const [message, setMessage] = useState("");
  let validate = () => {
    // if (field.name.length < 5) {
    //     setError({ ...error, errName: trans.least })
    //     setMessage(trans.least)
    //     return false
    // }
    if (field.name.length < 3 || field.name.length > 50) {
      setError({ ...error, errName: trans.toShort });
      setMessage(trans.toShort);
      return false;
    }
    // if (field.description < 5 || field.description > 200) {
    //     setError({ ...error, errDescription: trans.toShort })
    //     setMessage(trans.toShort)
    //     return false
    // }
    if (field.departmentId === 0 || field.departmentId.length === 0) {
      setError({ ...err, errDepartmentId: trans.chooseanydepartment });
      setMessage(trans.chooseanydepartment);
      return false;
    }
    return true;
  };

  const register = (e) => {
    e.preventDefault();
    if (validate()) {
      setError(err);
      if (!isEdit) {
        dispatch(fetchSuggestion({}, crud.create, field, trans));
      } else {
        dispatch(fetchSuggestion({}, crud.update, field, trans));
        setIsEdit(false);
      }
    }
  };

  const deleteSuggestionComfirm = (e) => {
    setIsDeleteSuggestionOpen(true);
    setItemSuggestion(e);
  };

  const deleteSuggestionItem = () => {
    setIsDeleteSuggestionOpen(false);
    dispatch(fetchSuggestion(request, crud.delete, itemSuggestion, trans));
    let suggestions = suggestionBox.list.filter((e, i) => {
      return e.id !== itemSuggestion.id;
    });
    dispatch({ type: suggestionType, payload: suggestions });
  };

  const searchChange = (text) => {
    setSearchText(text);
    if (text.length > 2) {
      dispatch(fetchSuggestion({ ...request, search: text }, crud.list, {}, trans));
    }
  };

  const searchChangeKeyPress = (text, kcode) => {
    if (kcode === 8) {
      dispatch(fetchSuggestion({ ...request, search: text }, crud.list, {}, trans));
    }
  };

  const [desc, setDesc] = useState(false);
  const sortbyField = (f) => {
    setRequest({ ...request, sort: f, desc });
    dispatch(fetchSuggestion({ ...request, sort: f, desc }, crud.list, {}, trans));
    setDesc(!desc);
  };

  const setSelectedPageFilter = (n) => {
    setSelectedPage(n);
    setRequest({ ...request, pageCount: n - 1 });
    dispatch(fetchSuggestion({ ...request, pageCount: n - 1 }, crud.list, {}, trans));
  };

  const openForm = () => {
    setField(fields);
    setIsAddSuggestionOpen(true);
  };

  const editItem = (e) => {
    setIsEdit(true);
    setField(e);
    setIsAddSuggestionOpen(true);
  };

  const changeDepartment = (e) => {
    setField({ ...field, departmentId: parseInt(e.target.value) });
  };

  const editStatus = (e, statusType) => {
    setIsStatus(true);
    setItemId(e.id);
    setStatusTypeId(statusType);
  };

  const editSuggestionStatus = () => {
    dispatch(fetchSuggestion({ ...request, suggestionStatus: statusTypeId, id: itemId }, crud.change, {}, trans));
    setIsStatus(false);
  };

  const [isDepartmentOpen, setIsDepartmentOpen] = useState(false);
  const filterByDepartments = (list) => {
    dispatch(fetchSuggestion({ ...request, departmentIds: list.map((x) => x.id) }, crud.list, {}, trans));
  };

  return (
    <Layout>
      <Modal isOpen={isDepartmentOpen} withClose setIsOpen={setIsDepartmentOpen} classes={"modal_filter"}>
        <FilterByDepartments filterByDepartments={filterByDepartments} />
      </Modal>
      <Modal isOpen={isAddSuggestionOpen} withClose setIsOpen={setIsAddSuggestionOpen} classes={"modal_add_employee"}>
        <div className="employeeForm">
          <div className="input-container">
            <span className="input_header">{trans.department}</span>
            <select className={`input select ${err.errDepartmentId ? "input-error" : ""}`} onChange={(e) => changeDepartment(e)} value={field.departmentId}>
              <option key={"group" + 0} value={0}>
                {trans.select}
              </option>
              {label &&
                label.list &&
                label.list.length > 0 &&
                label.list.map((c, i) => {
                  return (
                    <option key={"department" + i} value={c.id}>
                      {c.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <Input
            placeholder={trans.suggestionTitle}
            name={trans.suggestionTitle}
            header={trans.suggestionTitle}
            value={field.name}
            onChange={(e) => setField({ ...field, name: e.target.value })}
            error={err.errName}
            /*maxLength={30}
                        minLength={3}*/
          />
          <Input
            placeholder={trans.description}
            name={trans.description}
            header={trans.description}
            value={field.description}
            textarea={true}
            onChange={(e) => setField({ ...field, description: e.target.value })}
            error={err.errDescription}
            /*maxLength={200}
                        minLength={3}*/
          />
          {message && <span className="errorspan">{trans.error + ": " + message}</span>}
          <Button classes={"btn btn-primary"} onClick={register}>
            {trans.save}
          </Button>
        </div>
      </Modal>
      <Modal isOpen={isDeleteSuggestionOpen} withClose setIsOpen={setIsDeleteSuggestionOpen} classes={"modal_delete"}>
        {trans.areyousureyouwanttodelete}
        <Button classes="btn-red" onClick={() => deleteSuggestionItem()}>
          {trans.confirm}
        </Button>
      </Modal>
      <Modal isOpen={isStatus} withClose setIsOpen={setIsStatus} classes={"modal_delete"}>
        {trans.doyouwanttochangethesituation}
        <Button classes="btn-red" onClick={() => editSuggestionStatus()}>
          {trans.confirm}
        </Button>
      </Modal>
      <div className="table_properties">
        <Input
          icon={<Search2Icon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />}
          placeholder={trans.search}
          value={searchText}
          onChange={(e) => searchChange(e.target.value)}
          onKeyUp={(e) => searchChangeKeyPress(e.target.value, e.keyCode)}
        />
        <Button icon={<PlusIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />} classes="btn-basic" onClick={() => openForm()}>
          {trans.add}
        </Button>
      </div>

      {current.bar &&
        (!loader.loading ? (
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th onClick={() => sortbyField("name")}>
                    {trans.suggestionTitle} <SortIcon color="rgba(96, 168, 221, 1)" />
                  </th>
                  <th>{trans.description}</th>
                  <th onClick={() => sortbyField("department")}>
                    {trans.department}
                    <SortIcon color="rgba(96, 168, 221, 1)" />
                    <span className="icon_container" onClick={setIsDepartmentOpen}>
                      <ChevronBottomIcon color="rgba(96, 168, 221, 1)" />
                    </span>
                  </th>
                  <th>{trans.creator}</th>
                  <th>{trans.status}</th>
                  <th>{trans.settings}</th>
                  {user && user.roles && user.roles.some((x) => x === "Admin") && <th>{trans.status}</th>}
                </tr>
              </thead>
              <tbody>
                {suggestionBox.list &&
                  suggestionBox.list.length > 0 &&
                  suggestionBox.list.map((e, i) => {
                    return (
                      <tr className="row" key={i}>
                        <td>{e.name}</td>
                        <td className="info-icon" title={e.description && e.description.length > 15 ? e.description : ""}>
                          {e.description && e.description.length > 15 ? e.description.substring(0, 15) + "" : e.description}
                          {e.description && e.description.length > 15 ? (
                            <InfoIcon color={theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)"} />
                          ) : null}
                        </td>
                        <td>{e.departmentName}</td>
                        <td>
                          <span title={e.user && e.user.name}>
                            <img
                              src={e.user && e.user.photo ? baseUrl + e.user.photo : e && e.user && e.user.gender ? manprofile : womanprofile}
                              className="meeting_users_img"
                            />
                          </span>
                        </td>
                        <td>
                          {e.status == 1 ? <span className="priority bg-orange d-inline-block">{trans.notevaluated}</span> : ""}
                          {e.status == 2 ? <span className="priority bg-yellow d-inline-block">{trans.takenintoconsideration}</span> : ""}
                          {e.status == 3 ? <span className="priority bg-success d-inline-block">{trans.accepted}</span> : ""}
                          {e.status == 4 ? <span className="priority bg-danger d-inline-block">{trans.denied}</span> : ""}
                        </td>
                        <td>
                          {e.status !== 1 && user && user.roles && !user.roles.some((x) => x === "Admin") ? (
                            <div>
                              <button
                                //cursor={e.status == 2 ? true : false}
                                style={{ cursor: "no-drop" }}
                                title={trans.youcannotchangetheevaluatedsuggestion}
                              >
                                <EditIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />
                              </button>
                              <button
                                //cursor={e.status == 2 ? true : false}
                                style={{ cursor: "no-drop" }}
                                title={trans.youcannotchangetheevaluatedsuggestion}
                                onClick={() => deleteSuggestionComfirm(e)}
                                disabled={true}
                              >
                                <TrushIcon color={theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)"} />
                              </button>
                            </div>
                          ) : (
                            <div>
                              <button onClick={() => editItem(e)} cursor={e.status == 2 ? true : false}>
                                <EditIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />
                              </button>
                              <button onClick={() => deleteSuggestionComfirm(e)} disabled={e.status == 2 ? true : false}>
                                <TrushIcon color={theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)"} />
                              </button>
                            </div>
                          )}
                        </td>
                        {user && user.roles && user.roles.some((x) => x === "Admin") && (
                          <td>
                            <button onClick={() => editStatus(e, 2)} title="Değerlendirmeye Al">
                              <EvaluateIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />
                            </button>
                            <button onClick={() => editStatus(e, 3)} title="Kabul Et">
                              <AcceptIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />
                            </button>
                            <button onClick={() => editStatus(e, 4)} title="Reddet">
                              <RejectIcon color={theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)"} />
                            </button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>
                    {trans.title} <SortIcon color="rgba(96, 168, 221, 1)" />
                  </th>
                  <th>
                    {trans.description} <SortIcon color="rgba(96, 168, 221, 1)" />
                  </th>
                  <th>
                    {trans.department} <SortIcon color="rgba(96, 168, 221, 1)" />
                  </th>
                  <th>{trans.creator}</th>
                  <th>{trans.status}</th>
                  <th>{trans.settings}</th>
                </tr>
              </thead>
              <tbody>
                <tr className="row">
                  <td colSpan={8}>
                    <ContentLoader
                      preserveAspectRatio="none"
                      backgroundColor={theme === "dark" ? "#666666" : "#efefef"}
                      foregroundColor={theme === "dark" ? "#222222" : "#cccccc"}
                      viewBox="0 0 1000 60"
                      height={60}
                      width="100%"
                      style={{ width: "100%" }}
                      speed={2}
                      {...props}
                    >
                      <rect x="110" y="21" rx="4" ry="4" width="754" height="6" />
                      <rect x="111" y="41" rx="3" ry="3" width="185" height="7" />
                      <rect x="304" y="-46" rx="3" ry="3" width="350" height="6" />
                      <rect x="371" y="-45" rx="3" ry="3" width="380" height="6" />
                      <rect x="484" y="-45" rx="3" ry="3" width="201" height="6" />
                    </ContentLoader>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      {current && current.pageCount > 0 && <Pagination setSelectedPage={setSelectedPageFilter} selectedPage={selectedPage} />}
    </Layout>
  );
}
