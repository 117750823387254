import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/Container/Layout";
import BigCalendar from "../../components/scheduler/BigCalendar";
export default function Scheduler(props) {
  return (
    <Layout>
      <BigCalendar isMenuPage={true} />
    </Layout>
  );
}
