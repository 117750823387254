import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { crud, Priorities, ProjectStatus } from '../../config/constants'
import Pagination from '../../components/anBrains/Pagination'
import { fetchWorkorder } from '../../redux/actions/workorder_ac'
import Input from '../anBrains/Input'
import manprofile from '../../images/profiles/man.png';
import womanprofile from '../../images/profiles/woman.png';
import { baseUrl } from '../../config'
import { Search2Icon, BarIcon, GridIcon, BoardViewIcon, PlusIcon, SortIcon, DownIcon } from '../icons'
import { barListType, boardListType, gridListType, breadcrumbType, addBreadcrumbType } from '../../redux/action_types'
//import WorkorderBoardView from '../workorder/WorkorderBoardView'
import { useHistory } from 'react-router-dom'
import Modal from '../../components/anBrains/Modal';
import Button from '../../components/anBrains/Button';
import FilterByUsers from '../../components/filter/FilterByUsers'
import FilterByLabels from '../../components/filter/FilterByLabels'

import { WorkorderBoardView, WorkorderForm } from '../../components/workorder'

export default function ProjectWorkOrder(props) {
    const { projectId } = props;
    const trans = useSelector(state => state.trans)
    const dispatch = useDispatch()
    const [selectedPage, setSelectedPage] = useState(1)
    const theme = useSelector(state => state.theme)
    const current = useSelector(state => state.current)
    const { breadcrumbs } = useSelector(state => state.current)

    const history = useHistory();

    useEffect(() => {
        dispatch(fetchWorkorder(request, crud.list, {}, trans));
        //current.item.id = 1;
        //dispatch({ type: itemType, payload: current.item })
    }, [])

    const setSelectedPageFilter = (n) => {
        setSelectedPage(n)
        setRequest({ ...request, pageCount: n - 1 })
        dispatch(fetchWorkorder({ ...request, pageCount: n - 1, onlyList: true }, crud.list, {}, trans));
    }

    const findProject = (id) => selectRow(workorder.list.find(x => x.id === id));

    const selectRow = (e) => {

        if (breadcrumbs && breadcrumbs.some(b => b.name === e.name)) { }
        if (breadcrumbs.length > 1) {
            var newBreadCrumbs = [];
            newBreadCrumbs.push(breadcrumbs[0]);
            newBreadCrumbs.push({
                name: e.name,
                path: "/workorders/workorder-detail" + e.id
            });
            dispatch({
                type: breadcrumbType,
                payload: newBreadCrumbs
            })
        }
        else {
            dispatch({
                type: addBreadcrumbType,
                payload: { name: e.name, path: "/workorders/workorder-detail" + e.id }
            })
        }
        history.push("/workorders/workorder-detail/" + e.id)

    }

    const [request, setRequest] = useState({
        loader: true,
        rowCount: 14,
        pageCount: 0,
        isWorkOrder: true,
        search: "",
        sort: "id",
        labels: [{ tableId: projectId, tableType: 0 }],
        "desc": true,
        data: {},
        onlyList: false
    })



    const renderPriority = (value) => {
        return (<span className="priority" style={{ backgroundColor: Priorities[value].color }}>
            {Priorities[value][trans.lang]}
        </span>)
    }

    const renderStatus = (value) => {
        return (<span className="priority" style={{ backgroundColor: ProjectStatus[value].color }}>
            {ProjectStatus[value][trans.lang]}
        </span>)
    }

    const workorder = useSelector(state => state.workorder)

    const searchChange = (text) => {
        setSearchText(text)
        if (text.length > 2)
            dispatch(fetchWorkorder({ ...request, search: text, onlyList: true }, crud.list, {}, trans))
    }

    const searchChangeKeyPress = (text, kcode) => {
        if (kcode === 8) { dispatch(fetchWorkorder({ ...request, search: text, onlyList: true }, crud.list, {}, trans)) }
    }

    const filterByLabel = (labels) => {
        labels.push({ tableId: projectId, tableType: 0 });
        setRequest({ ...request, labels, pageCount: 0 })
        setSelectedPage(1)
        dispatch(fetchWorkorder({ ...request, labels, pageCount: 0, onlyList: true }, crud.list, {}, trans))
    }

    const [searchText, setSearchText] = useState("")

    const showBar = () => {
        dispatch({ type: barListType })
    }

    const showGrid = () => {
        dispatch({ type: gridListType })
    }

    const showBoard = () => {
        dispatch({ type: boardListType })
    }

    const [desc, setDesc] = useState(false)
    const sortbyField = (f) => {
        setRequest({ ...request, sort: f, desc })
        dispatch(fetchWorkorder({ ...request, sort: f, desc, onlyList: true }, crud.list, {}, trans));
        setDesc(!desc)
    }

    const [isAddOpen, setIsAddOpen] = useState(false)
    const [isUsersOpen, setIsUsersOpen] = useState(false)
    const [isLabelsOpen, setIsLabelsOpen] = useState(false)
    const [openProgress, setOpenProgress] = useState(false)
    const [openPrioritySelect, setOpenPrioritySelect] = useState(false)
    const [openStatusSelect, setOpenStatusSelect] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState(-1)
    const [selectedPriority, setSelectedPriority] = useState(-1)

    const filterByUser = (list) => {
        setRequest({ ...request, userIds: list.map(x => x.id), pageCount: 0 })
        setSelectedPage(1)
        dispatch(fetchWorkorder({ ...request, userIds: list.map(x => x.id), onlyList: true }, crud.list, {}, trans))
    }
    const filterStatus = (p) => {
        setSelectedStatus(p.target.value)
        dispatch(fetchWorkorder({
            ...request,
            pageCount: 0,
            status: (p.target.value),
            onlyList: true
        }, crud.list, {}, trans))
        setSelectedPage(1)
    }
    const filterPriorities = (p) => {
        setSelectedPriority(p.target.value)
        dispatch(fetchWorkorder({
            ...request,
            priority: (p.target.value)
            , onlyList: true,
            pageCount: 0
        }, crud.list, {}, trans))
        setSelectedPage(1)
    }

    return (
        <div>
            <Modal
                isOpen={isUsersOpen}
                withClose
                setIsOpen={setIsUsersOpen}
                classes={"modal_filter"}>
                <FilterByUsers filterByUser={filterByUser} />
            </Modal>
            <Modal
                isOpen={isLabelsOpen}
                withClose
                setIsOpen={setIsLabelsOpen}
                classes={"modal_filter"}>
                <FilterByLabels filterByLabel={filterByLabel} />
            </Modal>
            <Modal
                isOpen={isAddOpen}
                withClose
                setIsOpen={setIsAddOpen}
                classes={"modal_add_employee"}>
                <WorkorderForm setIsAddOpen={setIsAddOpen} projectWorkorder="true" />
            </Modal>
            <div className="table_properties project_workorder_table_properties">
                <Button
                    icon={<PlusIcon color={(theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")} />}
                    classes="btn-basic project_workorder_button"
                    onClick={() => setIsAddOpen(true)}>{trans.add}</Button>
                <Input
                    icon={<Search2Icon
                        color={(theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")} />}
                    placeholder={trans.search}
                    value={searchText}
                    onChange={e => searchChange(e.target.value)}
                    onKeyUp={e => searchChangeKeyPress(e.target.value, e.keyCode)} />
                <div className="bar_grid">
                    <button onClick={showBar}>
                        <BarIcon color={current.bar
                            ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")
                            : (theme === "dark" ? "#e5e5e5" : "#5d5d5d")}
                        />
                    </button>
                    <button onClick={showGrid}>
                        <GridIcon color={current.grid
                            ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")
                            : (theme === "dark" ? "#e5e5e5" : "#5d5d5d")}
                        />
                    </button>
                    <button onClick={showBoard}>
                        <BoardViewIcon color={current.board
                            ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")
                            : (theme === "dark" ? "#e5e5e5" : "#5d5d5d")}
                        />
                    </button>
                </div>
            </div>

            {
                current.bar && (<div className="project_container">
                    <div className="workorder_view">
                        <div className="table project_workorder_table">
                            <table>
                                <thead>
                                    <tr>
                                        <td onClick={() => sortbyField("name")}>
                                            {trans.workorder}
                                            <SortIcon color="rgba(96, 168, 221, 1)" />
                                        </td>
                                        <td onClick={() => setIsUsersOpen(true)}>
                                            {trans.employees}
                                            <span className={`${isUsersOpen === true ? 'icon_container transform-180' : 'icon_container'}`}>
                                                <DownIcon color="rgba(247, 202, 49, 1)" /></span>
                                        </td>
                                        <td>
                                            {trans.creator}
                                        </td>
                                        <td onClick={() => setIsLabelsOpen(true)}>
                                            {trans.labels}
                                            <span className={`${isLabelsOpen === true ? 'icon_container transform-180' : 'icon_container'}`}>
                                                <DownIcon color="rgba(247, 202, 49, 1)" /></span>
                                        </td>
                                        <td>
                                            <span
                                                onClick={() => sortbyField("progress")}>{trans.progress}<SortIcon color="rgba(96, 168, 221, 1)" />
                                            </span>
                                            <span
                                                id="chevronIcon"
                                                className={`${openProgress === true ? 'icon_container transform-180' : 'icon_container'}`}
                                                onClick={() => setOpenProgress(!openProgress)}>
                                                {/* <DownIcon color="rgba(247, 202, 49, 1)" /> */}
                                            </span>
                                        </td>
                                        <td onClick={() => sortbyField("point")}>
                                            {trans.point}
                                            <SortIcon color="rgba(96, 168, 221, 1)" />
                                        </td>
                                        <td onClick={() => sortbyField("startDate")}>
                                            {trans.startdate}
                                            <SortIcon color="rgba(96, 168, 221, 1)" />
                                        </td>
                                        <td onClick={() => sortbyField("endDate")}>
                                            {trans.enddate}
                                            <SortIcon color="rgba(96, 168, 221, 1)" />
                                        </td>
                                        <td>
                                            {!openPrioritySelect ? <span
                                                onClick={() => sortbyField("priority")}>{trans.priority}<SortIcon color="rgba(96, 168, 221, 1)" />
                                            </span> :
                                                <div className="input-container" style={{ float: "left" }}>
                                                    <select className="input select selectfilter" onChange={filterPriorities} value={selectedPriority} >
                                                        <option value={-1}>{trans.all}</option>
                                                        {Priorities.map((p, i) => {
                                                            return (
                                                                <option key={"priority" + i} value={p.id}>
                                                                    {p[trans.lang]}
                                                                </option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            }
                                            <span
                                                id="chevronIcon"
                                                className={`${openPrioritySelect === true ? 'icon_container transform-180' : 'icon_container'}`}
                                                onClick={() => setOpenPrioritySelect(!openPrioritySelect)}>
                                                <DownIcon color="rgba(247, 202, 49, 1)" /></span>
                                        </td>
                                        <td>
                                            {!openStatusSelect ? <span
                                                onClick={() => sortbyField("status")}>{trans.status}<SortIcon color="rgba(96, 168, 221, 1)" />
                                            </span> :
                                                <div className="input-container" style={{ float: "left" }}>
                                                    <select className="input select selectfilter" onChange={filterStatus} value={selectedStatus} >
                                                        <option value={-1}>{trans.all}</option>
                                                        {ProjectStatus.map((p, i) => {
                                                            return (
                                                                <option value={p.id}>
                                                                    {p[trans.lang]}
                                                                </option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            }
                                            <span
                                                id="chevronIcon"
                                                className={`${openStatusSelect === true ? 'icon_container transform-180' : 'icon_container'}`}
                                                onClick={() => setOpenStatusSelect(!openStatusSelect)}>
                                                <DownIcon color="rgba(247, 202, 49, 1)" /></span>
                                        </td>
                                    </tr>
                                </thead>
                                <tbody style={{ width: "90%" }}>
                                    {console.log(workorder.list)}
                                    {workorder && workorder.list && workorder.list.length > 0 && workorder.list.map((order, i) => {
                                        return (
                                            <tr className="row" key={i}>
                                                <td onClick={() => selectRow(order)} >{order.name}</td>
                                                <td onClick={() => selectRow(order)} >{
                                                    (order.users && order.users.length > 0) ? order.users.map((u, i) => {
                                                        return (
                                                            <img key={"workorder_row_profile" + i} className="workorder_row_profile" src={u.photo ? baseUrl + u.photo : (u && u.gender) ? manprofile : womanprofile} alt={u.name}
                                                                title={u.name} />
                                                        )
                                                    }) : null
                                                }</td>
                                                <td onClick={() => selectRow(order)} >{order.creator &&
                                                    <img className="workorder_row_profile" src={order.creator.photo ? baseUrl + order.creator.photo : (order && order.creator.gender) ? manprofile : womanprofile}
                                                        alt={order.creator.name}
                                                        title={order.creator.name} />}</td>
                                                <td onClick={() => selectRow(order)} title={(order.labels && order.labels.length > 0)
                                                    ?
                                                    order.labels.map((e, i) => {
                                                        return (
                                                            e.name)
                                                    })
                                                    : null}>
                                                    {(order.labels && order.labels.length > 0)
                                                        ?
                                                        <span className="label_container">{order.labels[0].name + " .."}</span>
                                                        : null}</td>
                                                <td onClick={() => selectRow(order)} >
                                                    <div className="progress_container">
                                                        <div className="progress_bar" style={{ width: (order.progress > 100 ? 100 : order.progress) + "%" }}>
                                                            {(order.progress > 100 ? 100 : order.progress) + "%"}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td onClick={() => selectRow(order)} >{order.point}</td>
                                                <td onClick={() => selectRow(order)} >{order.startDateTimeString}</td>
                                                <td onClick={() => selectRow(order)} >{order.endDateTimeString}</td>
                                                <td onClick={() => selectRow(order)} title={Priorities[order.priority][trans.lang]}>{renderPriority(order.priority)}</td>
                                                <td onClick={() => selectRow(order)} title={ProjectStatus[order.status][trans.lang]} style={{ paddingRight: "1rem" }}>{renderStatus(order.status)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            setSelectedPage={setSelectedPageFilter}
                            selectedPage={selectedPage} />
                    </div>
                </div>)
            }

            {
                current.grid && (
                    <div className="project_container">
                        <div className="workorder_view">
                            <div className="grid_container">
                                {workorder && workorder.list.length > 0 && workorder.list.map((order, i) => {
                                    return (
                                        <div className="grid_card grid_card_border"
                                            style={{ borderLeftColor: ProjectStatus[order.status].color }}
                                            key={"grid_card" + i}>
                                            <div className="workorder_card_information_container">
                                                <div className="workorder_grid_card_information">
                                                    <span>{order.name}</span>
                                                    <span className="grid_card_position">
                                                        {(order.labels && order.labels.length > 0)
                                                            ?
                                                            order.labels.map((e, i) => {
                                                                return (<span
                                                                    key={"label" + e.id}
                                                                    className="grid_label_container">{e.name}</span>)
                                                            })
                                                            : null}
                                                        {renderPriority(order.priority)}
                                                        <div
                                                            className={"priority"}
                                                            style={theme === "dark"
                                                                ? { backgroundColor: "rgba(247, 202, 49, 1)", color: "#000" }
                                                                : { backgroundColor: "rgba(247, 202, 49, .7)", color: "#000" }}
                                                        >
                                                            {trans.lang === "en" ? "Point: " : "Puan: " + order.point}
                                                        </div>
                                                    </span>
                                                    <span className="grid_card_phone">
                                                        <div className="progress_container">
                                                            <div className="progress_bar"
                                                                style={{ width: (order.progress > 100 ? 100 : order.progress) + "%" }}>
                                                                {(order.progress > 100 ? 100 : order.progress) + "%"}
                                                            </div>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div className="workorder_card_settings">
                                                    {(order.users && order.users.length > 0) ? order.users.map((u, i) => {
                                                        return (
                                                            <img key={"workorder_row_profile" + i} className="workorder_row_profile" src={u.photo ? baseUrl + u.photo : (u && u.gender) ? manprofile : womanprofile} alt={u.name}
                                                                title={u.name} />
                                                        )
                                                    }) : null}
                                                </div>
                                            </div>
                                        </div>)
                                })}
                            </div>
                            {current && current.pageCount > 0 &&
                                <Pagination
                                    setSelectedPage={setSelectedPageFilter}
                                    selectedPage={selectedPage} />}
                        </div>
                    </div>
                )
            }

            {
                current.board && (
                    <div className="project_container">
                        <div className="workorder_view">
                            {workorder.boardlist && workorder.boardlist.lanes && workorder.boardlist.lanes.length > 0 &&
                                <WorkorderBoardView
                                    selectRow={findProject}
                                    trello={workorder.boardlist} />}

                        </div>
                        {current && current.pageCount > 0 &&
                            <Pagination
                                setSelectedPage={setSelectedPageFilter}
                                selectedPage={selectedPage} />}
                    </div>
                )
            }

        </div>)
}