import React, { useState, useEffect } from "react";
import { axiosConfig, baseUrl } from "../../config";
import { Avatar, Button, ConfigProvider, DatePicker, Input, Modal, Progress, Select, Spin, Table, notification } from "antd";
import { barListType, gridListType, isPlannedType, itemType } from "../../redux/action_types";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { fetchReport } from "../../redux/actions/report_ac";
import { fetchProject } from "../../redux/actions/project_ac";
import { crud } from "../../config/constants";
import "./style.css";

const { confirm } = Modal;
var toDate = new Date();
var year = toDate.getFullYear();
var month = toDate.getMonth() + 1;
var toDateString = year + (month.toString().length > 1 ? "-" : "-0") + month;

export default function ReportList2(props) {
  const dispatch = useDispatch();
  const project = useSelector((state) => state.project);
  const theme = useSelector((state) => state.theme);
  const loader = useSelector((state) => state.loader);
  const trans = useSelector((state) => state.trans);
  const selectMultiProjectIds = (projectIds) => dispatch({ type: itemType, payload: { ...current.item, projectIds } });
  const searchProject = (search) => dispatch(fetchProject({ search, isSmallDto: true, spinner: true }, crud.list, {}, trans));

  const [date, setDate] = useState(toDateString);

  useEffect(() => {
    dispatch({ type: itemType, payload: { reportType: "0" } });
  }, []);

  const filter = () => {
    var month = parseInt(date.substr(5, 2)) - 1;
    dispatch(
      fetchReport(
        {
          rangeDate: new Date(date.substr(0, 4), month),
          projectIds: current.item.projectIds,
        },
        trans
      )
    );
  };

  const [projectData, setProjectData] = useState([]);
  const current = useSelector((state) => state.current);
  const employee = useSelector((state) => state.employee);
  const [tab, setTab] = useState(1);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [personelSelect, setPersonelSelect] = useState("");
  const [personelName, setPersonelName] = useState("");
  const [projectSelect, setProjectSelect] = useState("");
  const [daySelect, setDaySelect] = useState("");
  const [dateSelect, setDateSelect] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);

  const [selectedKey, setSelectedKey] = useState(0);

  const openNotification = (message) => {
    notification.open({
      description: message,
      style: {
        backgroundColor: "red",
        color: "white",
      },
    });
  };

  const openNotificationSuccess = (message) => {
    notification.open({
      description: "Başarılı bir şekilde işlem gerçekleştirildi",
      style: {
        backgroundColor: "green",
        color: "white",
      },
    });
  };

  const addReport = (personelSelect, projectSelect, daySelect, dateSelect) => {
    let headers = axiosConfig.headers;
    let url = `${baseUrl}/api/ProjectUserEfors`;
    if (localStorage.getItem("token")) {
      headers = { ...headers, authorization: `Bearer ${localStorage.getItem("token")}` };
    }
    axios({
      method: "POST",
      url,
      headers,
      data: {
        request: {},
        data: {
          projectId: projectSelect,
          userId: personelSelect,
          efors: daySelect,
          eforDate: dateSelect,
        },
        requestType: 1,
      },
    }).then((res) => {
      fetchReports();
      console.log("res", res);
      if (res.data.message) {
        openNotification(res.data.message);
      } else {
        openNotificationSuccess();
      }
    });
  };

  useEffect(() => {
    fetchReports();
  }, []);
  useEffect(() => {
    console.log("projectData", projectData);
  }, [projectData]);

  useEffect(() => {
    fetchReports();
  }, [current]);

  const fetchReports = async () => {
    let headers = axiosConfig.headers;
    let url = `${baseUrl}/api/ProjectUserEfors`;
    if (localStorage.getItem("token")) {
      headers = { ...headers, authorization: `Bearer ${localStorage.getItem("token")}` };
    }
    await axios({
      method: "POST",
      url,
      headers,
      data: {
        request: {
          isEforReport: true,
          projectIds: current.item.projectIds,
          eforDate: props.date,
          userIds: current.item.userIds,
          IsForeman: true,
        },
        data: {},
        requestType: 5,
      },
    }).then((res) => {
      setProjectData(res.data);
    });
  };

  const columns = [
    {
      title: "Personel",
      dataIndex: "name",
      key: "name",

      width: 150,
    },
    ...Array.from(
      {
        length: props.date ? new Date(props.date.substr(0, 4), parseInt(props.date.substr(5, 2)), 0).getDate() : 0,
      },
      (_, index) => ({
        title: `${index + 1}`,
        dataIndex: `eforDays[${index}].efor`,
        key: `day_${index}`,
        width: 100,
        render: (efor, record) => (
          <div
            style={{
              margin: 0,
              tableLayout: "fixed",
              color: record.eforDays[index] ? (record.eforDays[index].efor > 0 ? "white" : "black") : "black",
              backgroundColor: selectedKey === record.eforDays[index] ? (theme === "dark" ? "#191920" : "lightblue") : theme === "dark" ? "" : "",
            }}
          >
            {record.key !== 0 ? (
              <div
                style={{
                  cursor: "pointer",
                  margin: 0,
                }}
                onClick={() => {
                  if (current.item.projectIds && current.item.projectIds.length === 1) {
                    let dates = new Date();
                    dates.setDate(index + 1);
                    dates.setMonth(props.date.substr(5, 2) - 1);
                    dates.setFullYear(props.date.substr(0, 4));

                    setPersonelSelect(record.key);
                    setProjectSelect(current.item.projectIds[0]);
                    setDaySelect(efor);
                    setDateSelect(dates);
                    setPersonelName(record.name);
                    setIsUpdated(record.eforDays[index].efor === 0 ? false : true);
                    setIsModalOpen(true);
                    setSelectedKey(record.eforDays[index]);
                  }
                }}
                onDoubleClick={() => {
                  if (current.item.projectIds && current.item.projectIds.length === 1) {
                    let dates = new Date();
                    dates.setDate(index + 1);
                    dates.setMonth(props.date.substr(5, 2) - 1);
                    dates.setFullYear(props.date.substr(0, 4));

                    addReport(record.key, current.item.projectIds[0], "2", dates);
                  }
                }}
              >
                <b
                  style={{
                    margin: 0,
                    color: record.eforDays[index]
                      ? record.eforDays[index].efor > 0
                        ? theme === "dark"
                          ? "white"
                          : "black"
                        : theme === "dark"
                        ? "gray"
                        : "lightgray"
                      : "black",
                  }}
                >
                  {record.eforDays[index] ? record.eforDays[index].efor : "-"}
                </b>
              </div>
            ) : (
              <div
                style={{
                  cursor: "pointer",
                  margin: 0,
                  backgroundColor: theme === "dark" ? "#262626" : "",
                }}
              >
                <b
                  style={{
                    margin: 0,
                    color: record.eforDays[index] ? (record.eforDays[index].efor > 0 ? (theme === "dark" ? "white" : "black") : "gray") : "black",
                  }}
                >
                  {record.eforDays[index] ? record.eforDays[index].efor : "-"}
                </b>
              </div>
            )}
          </div>
        ),
      })
    ),
    {
      title: "Toplam",
      dataIndex: "total",
      key: "total",
      width: 150,
      fixed: "right",
    },
  ];

  const data =
    projectData && projectData.data
      ? projectData.data.users.map((user) => ({
          key: user.id,
          name: user.name,
          eforDays: user.eforDays,
          total: user.totalEfors + " - " + user.totalCost + " ₺",
        }))
      : [];

  return (
    <>
      <div
        style={{
          shadowColor: "black",
          shadowOpacity: 0.6,
          shadowOffset: { width: 0, height: 2 },
          shadowRadius: 8,
          elevation: 12,

          alignItems: "center",
          display: "flex",

          gap: 10,
        }}
      >
        <Select
          mode="multiple"
          allowClear
          maxTagCount={1}
          onSearch={searchProject}
          maxTagTextLength={4}
          optionFilterProp="children"
          onChange={selectMultiProjectIds}
          style={{ width: "200px", margin: 10 }}
          notFoundContent={loader.spinning ? <Spin size="small" /> : null}
          placeholder={trans.project}
          filterOption={(input, option) => (option.children ? option.children.toLocaleLowerCase("tr").indexOf(input.toLocaleLowerCase("tr")) >= 0 : false)}
          // filterSort={(input, option) =>
          //     input.children.toLowerCase().localeCompare(option.children.toLowerCase())
          //   }
          value={current.item.projectIds}
        >
          {project.list.map((l, i) => {
            return (
              <Select.Option key={"projectId" + i} value={l.id}>
                {l.name}
              </Select.Option>
            );
          })}
        </Select>
        {isModalOpen == true && current.item.projectIds && current.item.projectIds.length === 1 && (
          <>
            {/* <div> */}
            {/* <h2> {personelName} için Efor Ekliyorsunuz.</h2> */}
            {/* <Select
            defaultValue="Personel Seçiniz"
            value={personelSelect}
            onChange={(value) => setPersonelSelect(value)}
            style={{
              width: "100%",
            }}
          >
            <Select.Option value={personelSelect}>{personelSelect}</Select.Option>
          </Select> */}
            {/* </div> */}
            <div style={{ display: "flex", flexDirection: "row", gap: 10, width: "100%" }}>
              {/* <Select
              defaultValue="Proje Seçiniz"
              value={projectSelect}
              onChange={(value) => setProjectSelect(value)}
              style={{
                width: "50%",
              }}
            >
              {projectData.data.projects.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select> */}

              <Select
                defaultValue="Gün Seçiniz"
                value={daySelect}
                onChange={(value) => setDaySelect(value)}
                style={{
                  width: "50%",
                }}
              >
                <Select.Option key="0" value="0">
                  0
                </Select.Option>
                <Select.Option key="1" value="1">
                  0,5
                </Select.Option>
                <Select.Option key="2" value="2">
                  1
                </Select.Option>
                <Select.Option key="3" value="3">
                  1,5
                </Select.Option>
              </Select>

              {/* <DatePicker
              style={{
                width: "100%",
              }}
              value={dateSelect && moment(dateSelect, "YYYY-MM-DD")}
              onChange={(date, dateString) => setDateSelect(dateString)}
            /> */}

              <Button
                onClick={() => {
                  addReport(personelSelect, projectSelect, daySelect, dateSelect);
                  setIsModalOpen(false);
                }}
                type="primary"
                style={{
                  width: "100%",
                }}
              >
                Efor Ekle
              </Button>

              <Button
                onClick={() => setIsModalOpen(false)}
                style={{
                  marginLeft: "auto",
                  backgroundColor: "red",
                  color: "white",
                }}
              >
                x
              </Button>
            </div>
          </>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: 24 }}>
        <Button
          type="primary"
          style={{
            width: "50%",

            borderRadius: 10,
          }}
          onClick={() => {
            setTab(1);
            setIsModalOpen(false);
          }}
        >
          Tablo
        </Button>
        <Button
          type="primary"
          style={{
            width: "50%",
            marginLeft: 20,
            borderRadius: 10,
          }}
          onClick={() => {
            setTab(2);
            setIsModalOpen(false);
          }}
        >
          Proje Bazlı Kart
        </Button>
      </div>
      {tab === 1 && data && projectData.data && (
        <ConfigProvider prefixCls="ant-table">
          <Table
            columns={columns}
            className={theme === "dark" ? "ant-table-dark" : "ant-table-light"}
            dataSource={data}
            bordered
            pagination={false}
            scroll={{ x: 1200 }}
          />
        </ConfigProvider>
      )}
      {tab === 2 && data && projectData.data && (
        <div //grid view
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr" /* İki sütun */,
            gap: "10px" /* Sütunlar arası boşluk */,
          }}
        >
          {projectData &&
            projectData.data.projects.map((project) => (
              <div
                style={{
                  backgroundColor: theme === "dark" ? "#262626" : "white",
                  borderColor: "lightgray",
                  borderWidth: 1,
                  width: "100%",
                  padding: 20,
                  borderRadius: 10,
                }}
                key={project.id}
              >
                <div key={project.id}>
                  <h1>{project.name}</h1>
                  <h5>
                    Adam / Gün : {project.totalEforCount} / {project.totalPlannedDay ? project.totalPlannedDay : 0}{" "}
                  </h5>
                  <h5>
                    Bütçe:{" "}
                    {
                      //format to currency end of number

                      project.eforUsers
                        .map((user) => user.totalCost)
                        .reduce((a, b) => a + b, 0)
                        .toFixed(2) + " ₺ "
                    }
                    /
                    {
                      //format to currency end of number

                      new Intl.NumberFormat("tr-TR", {
                        style: "currency",
                        currency: "TRY",
                      })
                        .format(project.totalPlannedCost ? project.totalPlannedCost : 0)
                        .replace(/^(\D+)/, "$1 ")
                        .replace("₺", "")
                    }{" "}
                    ₺
                  </h5>

                  <Progress
                    showInfo={true}
                    style={{
                      width: "100%",
                    }}
                    className={theme === "dark" ? "ant-progress-dark" : "ant-progress-light"}
                    percent={project.eforProgress}
                    status="active"
                  />
                </div>
                <br />

                {project.eforUsers.map(
                  (user) =>
                    //hide last user

                    user.id !== 0 && (
                      <div key={user.id}>
                        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                          <Avatar size={55} src={baseUrl + user.photo} />

                          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                            <h5>
                              {user.name} - {user.totalCost} ₺
                            </h5>
                            <h5>Projedeki Eforu: {user.efors} Gün</h5>
                            <Progress
                              percent={((user.efors / project.totalPlannedDay) * 100).toFixed(2)}
                              status="active"
                              className={theme === "dark" ? "ant-progress-dark" : "ant-progress-light"}
                            />
                          </div>
                        </div>
                      </div>
                    )
                )}
              </div>
            ))}
        </div>
      )}
    </>
  );
}
