import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../anBrains/Button';
import { PlusIcon, TrushIcon, SortIcon, DocumentIcon } from '../icons';
import Modal from '../anBrains/Modal';
import { employeeDocumentType } from '../../redux/action_types';
import Input from '../anBrains/Input';
import Pagination from '../anBrains/Pagination'
import FileUploader from '../anBrains/FileUploader';
import { crud } from '../../config/constants';
import { baseUrl } from '../../config';
import { fetchEmployeeDocuments, fetchEmployeeDocument } from '../../redux/actions/employee_document_ac';
import SelectBox from '../anBrains/SelectBox'
export default function EmployeeFileTab({ employeeid, isEmploye }) {

    const trans = useSelector(state => state.trans)
    const employeedocument = useSelector(state => state.employeedocument)
    const theme = useSelector(state => state.theme)
    const result = useSelector(state => state.result)
    const current = useSelector(state => state.current)
    const dispatch = useDispatch()
    const [item, setItem] = useState({})
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)

    useEffect(() => {
        if (result.success) {
            setIsAddOpen(false);
            setField(fields)
            dispatch(fetchEmployeeDocuments({ ...listParameters }));
        }
        console.log(result.change);

    }, [result.change])

    const deleteConfirm = (e) => {
        setIsDeleteOpen(true)
        setItem(e);
    }
    const deleteItem = () => {
        setIsDeleteOpen(false)
        dispatch(fetchEmployeeDocuments({}, crud.delete, item))
        let employeedocuments = employeedocument.list.filter((e) => {
            return e.id !== item.id
        });
        dispatch({ type: employeeDocumentType, payload: employeedocuments })
    }
    // const editItem = (e) => {
    //     setField(e);
    //     setIsAddOpen(true);
    // }
    const [isAddOpen, setIsAddOpen] = useState(false)
    const [listParameters, setListParameters] = useState({ rowCount: 14, pageCount: 0, sort: "id", desc: true, userId: employeeid }, crud.list, {})
    const [asc, setAsc] = useState(false)
    const sortbyField = (f) => {
        setListParameters({ ...listParameters, sort: f, desc: asc ? false : true })
        setAsc(!asc)
        dispatch(fetchEmployeeDocuments({ ...listParameters, sort: f, desc: asc ? false : true }));
    }

    var fields = {
        userId: employeeid,
        userFileTypeId: 0,
        name: "",
        path: null
    }
    const [field, setField] = useState(fields)

    const errors = {
        nameErr: "",
        pathError: "",
        userFileTypeIdError: "",
    }
    const [err, setErr] = useState(errors)

    // useEffect(() => {
    //     var curr = new Date();
    //     curr.setDate(curr.getDate());
    //     var date = curr.toISOString().substr(0, 10);
    //     setField({ ...field, period: date })
    // }, [])

    let validate = () => {
        if (field.path === null || field.path === undefined) {
            setErr({ ...errors, pathError: trans.pleaseEnterValid })
            return false
        }

        if (field.name.length < 3 || field.name.length > 30) {
            setErr({ ...errors, nameErr: trans.toShort })
            return false
        }

        return true
    }
    const register = e => {
        e.preventDefault();
        if (validate()) {
            
            field.path = "";
            if (field.userFileTypeId === 0)
                field.userFileTypeId = employeedocument.groups[0].id;
            console.log(field, "register");
            formdata.append("data", JSON.stringify(field))
            formdata.append("requestType", crud.create)
            formdata.append("request", "{}")
            setErr(errors)
            for (var value of formdata.values()) {
                console.log(value);
            }
            dispatch(fetchEmployeeDocument(formdata, trans))
        }
    }

    const [selectedPage, setSelectedPage] = useState(1)
    const setSelectedPageFilter = (n) => {
        setSelectedPage(n)
        setListParameters({ ...listParameters, pageNumber: n - 1 })
        dispatch(fetchEmployeeDocuments({ ...listParameters, pageNumber: n - 1 }));
    }
    const [formdata, setFormdata] = useState(null)
    const loadedFile = data => {
        
        setField({ ...field, path: true })
        setFormdata(data);
    }
    const setSelectedGroup = (c) => {
        setSelectedGroupIndex(c)
        setField({ ...field, userFileTypeId: employeedocument.groups[c].id })
    }

    const [selectedGroupIndex, setSelectedGroupIndex] = useState(0)
    const RequiredDocumenstField = () => {
        return (
            <div className="missing_document">
                {trans.missingDocument + ": "}
                {employeedocument.requiredDocuments.map((d, i) => {
                    return (<span key={d} className="missing_document_item">{employeedocument.requiredDocuments[i]}{i + 1 === employeedocument.requiredDocuments.length ? "" : ","}</span>)
                })}
            </div>
        )
    }
    return (
        <>
            <div className="leave">
                <div className="leave_detail_container">
                    {(employeedocument &&
                        employeedocument.requiredDocuments.length > 0) ?
                        <RequiredDocumenstField />
                        : null}
                </div>

                <div className="table_properties">
                    <Button
                        icon={<PlusIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />}
                        classes="btn btn-basic"
                        onClick={() => setIsAddOpen(true)}>{trans.add}</Button>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>{trans.document}</th>
                            <th onClick={() => sortbyField("name")}>{trans.description} <SortIcon color="rgba(96, 168, 221, 1)" /></th>
                            <th onClick={() => sortbyField("createDate")}>{trans.date}
                                <SortIcon color="rgba(96, 168, 221, 1)" /></th>
                            <th>{trans.creator}</th>
                            {isEmploye ?
                                <th>{trans.settings}</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {employeedocument && employeedocument.list.length > 0 && employeedocument.list.map((e, i) => {
                            return (
                                <tr className="row" key={e.id}>
                                    <td> <a target="_blank" href={baseUrl + e.path}>
                                        <DocumentIcon color={(theme === "dark" ? "#e5e5e5" : "#000")} /></a>
                                    </td>
                                    <td>{e.name}</td>
                                    <td >{e.createDateString}</td>
                                    <td>{e.setUserName}</td>
                                    {isEmploye ?
                                        <td>
                                            {/* <button onClick={() => editItem(e)}>
                                            <EditIcon color={(theme === "dark" ? "#e5e5e5" : "#000")} />
                                        </button> */}
                                            <button onClick={() => deleteConfirm(e)}>
                                                <TrushIcon
                                                    color={(theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)")}
                                                /></button>
                                        </td> : null}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                {current && current.pageCount > 0 &&
                    <Pagination
                        setSelectedPage={setSelectedPageFilter}
                        selectedPage={selectedPage} />}
            </div>
            <Modal
                isOpen={isAddOpen}
                withClose
                setIsOpen={setIsAddOpen}
                classes={"modal_add_leave"}>
                <div className="leaveForm">
                    <FileUploader text="asdf" loadedFile={loadedFile} err={err.pathError} />
                    {employeedocument && employeedocument.groups &&
                        employeedocument.groups.length > 0 &&
                        <div className="input-container">
                            <span className="input_header">{trans.fileType} </span>
                            <div className="input flex al-center">
                                <SelectBox
                                    defaultValue={employeedocument.groups[selectedGroupIndex].name}
                                    options={employeedocument.groups}
                                    value={employeedocument.groups[selectedGroupIndex].name}
                                    setValue={c => setSelectedGroup(c)}
                                />
                            </div>
                        </div>}
                    <Input placeholder={trans.description}
                        name={trans.description}
                        header={trans.description}
                        value={field.name}
                        onChange={e => setField({ ...field, name: e.target.value })}
                        error={err.nameErr} />
                    {/* <Input
                        type={"date"}
                        header={trans.date}
                        value={field.period}
                        onChange={e => setField({ ...field, period: e.target.value })}
                        error={err.periodError} /> */}
                    {/* <Input
                        type={"date"}
                        header={trans.enddate}
                        value={field.end}
                        onChange={e => setField({ ...field, end: e.target.value })}
                        error={err.endError} /> */}
                    <Button classes={"btn-primary"}
                        onClick={register}>{trans.save}</Button>
                </div>
            </Modal>
            <Modal
                isOpen={isDeleteOpen}
                withClose
                setIsOpen={setIsDeleteOpen}
                classes={"modal_delete"}>
                {trans.areyousureyouwanttodelete}
                <Button classes="btn-red" onClick={() => deleteItem()}>{trans.confirm}</Button>
            </Modal>
        </>
    )
}