import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/Container/Layout";
import Button from "../../components/anBrains/Button";
import Input from "../../components/anBrains/Input";
import Switch from "../../components/anBrains/Switch";
import { fetchDeleteMeetingFiles, fetchMeeting, fetchMeetingDetail, fetchMeetingFiles } from "../../redux/actions/meeting_ac";
import { crud, fileFromType, getTableType, tableType } from "../../config/constants";
import FileUploader from "../../components/anBrains/FileUploader";
import { TrushIcon, DocumentIcon } from "../../components/icons";
import { baseUrl } from "../../config";
import Modal from "../../components/anBrains/Modal";
import ContentLoader from "react-content-loader";
import moment from "moment-timezone";
import "moment-timezone";
import manprofile from "../../images/profiles/man.png";
import womanprofile from "../../images/profiles/woman.png";
import { Select } from "antd";
import "antd/dist/antd.css";
import { itemType } from "../../redux/action_types";
import { fetchWorkorder, fetchWorkorderDetail } from "../../redux/actions/workorder_ac";
import File from "../../components/project/File";
const { Option, OptGroup } = Select;
export default function MeetingDetail(props) {
  const dispatch = useDispatch();
  const {
    match: { params },
  } = props;
  const trans = useSelector((state) => state.trans);
  const theme = useSelector((state) => state.theme);
  const current = useSelector((state) => state.current);
  const employee = useSelector((state) => state.employee);
  const label = useSelector((state) => state.label);
  const loader = useSelector((state) => state.loader);
  const meetingGroup = useSelector((state) => state.meetingGroup);
  const meetings = useSelector((state) => state.meetings);
  const [isDeleteFileOpen, setIsDeleteFileOpen] = useState(false);
  const [item, setItem] = useState({});
  const [request, setRequest] = useState({ rowCount: 14, pageCount: 0, search: "", sort: "id", desc: false, data: {} });
  const workordergroup = useSelector((state) => state.workordergroup);
  const [isDetailOrFile, setIsDetailOrFile] = useState(true);
  useEffect(() => {
    dispatch(fetchWorkorderDetail({ id: params.id, lang: trans.lang, isMeetingDetail: true, includeDetail: true }, crud.list, {}, trans));
  }, []);

  const errors = {
    groupIdError: "",
    nameError: "",
    dateError: "",
    usersError: "",
    labelError: "",
    descriptionError: "",
    desicionError: "",
  };
  const [err, setErr] = useState(errors);

  const [message, setMessage] = useState("");
  let validate = () => {
    if (current.item.name !== undefined && current.item.name !== null) {
      if (current.item.name.length < 3 || current.item.name.length > 30) {
        setErr({ ...err, nameError: trans.toShort });
        setMessage(trans.toShort);
        return false;
      }
    }
    // if (current.item.description !== undefined && current.item.description !== null) {
    //     if (current.item.description.length < 3 || current.item.description.length > 300) {
    //         setErr({ ...err, descriptionError: trans.toShort })
    //         setMessage(trans.toShort)
    //         return false
    //     }
    // }
    if (current.item.workOrderGroupId === 0 || current.item.workOrderGroupId === undefined) {
      setErr({ ...err, groupIdError: trans.pleaseSelectGroup });
      setMessage(trans.pleaseSelectGroup);
      return false;
    }

    // if (!current.item.labelIds || current.item.labelIds.length === 0) {
    //     setErr({ ...err, meetingLabelsError: trans.blankPass })
    //     setMessage(trans.blankPass)
    //     return false
    // }

    if (current.item.labelIds !== null && current.item.labelIds.length > 0) {
      var includeDepartment = false;
      var includeProject = false;
      var includeCustomer = false;
      var selectedTableTypes = [];
      for (let i = 0; i < current.item.labelIds.length; i++) selectedTableTypes.push(current.item.labelIds[i].split("_")[1]);

      for (let index = 0; index < selectedTableTypes.length; index++) {
        var t = parseInt(selectedTableTypes[index]);
        if (t === tableType.department) includeDepartment = true;

        if (t === tableType.project) includeProject = true;

        if (t === tableType.customer) includeCustomer = true;
      }

      if (includeDepartment === false || (includeDepartment === true && includeProject === false && includeDepartment === false)) {
        setErr({ ...errors, labelError: trans.pleaseEnterValid });
        setMessage(trans.youmustchooseatleastonedepartment);
        return false;
      }
    }

    return true;
  };

  const openDeleteFrom = (e) => {
    setIsDeleteFileOpen(true);
    setItem(e);
  };

  const deleteMeetingFile = () => {
    dispatch(fetchDeleteMeetingFiles({ ...request, Id: item.id }, crud.delete));
  };

  const register = (e) => {
    e.preventDefault();
    if (validate()) {
      setErr(errors);
      current.item.workOrderLabels = label.lineList.filter((x) => current.item.labelIds.includes(x.idx));
      current.item.isAgendaHappend = false;
      dispatch(fetchWorkorder({}, crud.update, current.item, trans));
    }
  };

  const loadedFile = (data) => {
    current.item.file = true;
    dispatch({ type: itemType, payload: current.item });
  };
  const selectMultiCategories = (labelIds) => {
    current.item.labelIds = labelIds;
    dispatch({ type: itemType, payload: current.item });
  };
  const selectMultiUserIds = (userIds) => {
    current.item.userIds = userIds;
    dispatch({ type: itemType, payload: current.item });
  };
  const changeMeetingGroup = (e) => {
    current.item.workOrderGroupId = parseInt(e.target.value);
    dispatch({ type: itemType, payload: current.item });
  };
  const changePoint = (e) => {
    current.item.point = parseInt(e.target.value);
    dispatch({ type: itemType, payload: current.item });
  };
  return (
    <Layout>
      <Modal isOpen={isDeleteFileOpen} withClose setIsOpen={setIsDeleteFileOpen} classes={"modal_delete"}>
        {trans.areyousureyouwanttodelete}
        <Button classes="btn-red" onClick={() => deleteMeetingFile()}>
          {trans.confirm}
        </Button>
      </Modal>
      {current.item && (
        <div className="employee_detail_container">
          <div className="meeting_detail_profile">
            <div className="meeting_detail_items" style={{ width: "100%" }}>
              <h2>{current.item.name}</h2>
            </div>

            <div className="workorder_detail_header">
              <h3>{trans.meetingDetail}</h3>
            </div>
            <div className="workorder_detail_desc">
              <h5>{trans.employees}</h5>
              <div className="workorder_detail_personals">
                {current.item.users &&
                  current.item.users.length > 0 &&
                  current.item.users.map((u, i) => {
                    return <img key={"user_photo" + i} src={u.photo ? baseUrl + u.photo : u && u.gender ? manprofile : womanprofile} title={u.name} />;
                  })}
              </div>
              <h5>{trans.labels}</h5>
              <div className="workorder_detail_personals">
                {current.item.labels &&
                  current.item.labels.length > 0 &&
                  current.item.labels.map((u, i) => {
                    return (
                      <span className="label_container" key={"label" + i}>
                        {u.name}
                      </span>
                    );
                  })}
              </div>
              <h5>{trans.status}</h5>
              <div className="workorderprioritiy">
                <span className="grid_card_position">
                  {current.item.status == 2 ? (
                    <span className="grid_greenlabel_container">Yapıldı</span>
                  ) : (
                    <span className="grid_label_container">Yapılmadı</span>
                  )}
                </span>
              </div>
              <h5>{trans.meetingDate}</h5>
              <span className="text-bold">{current.item.startDateTimeString}</span>
            </div>
          </div>
          <div className="employee_detail_right">
            <ul className="inside_menu">
              <li className={isDetailOrFile && "active"} onClick={() => setIsDetailOrFile(true)}>
                {trans.meetingDetail}
              </li>
              <li className={!isDetailOrFile && "active"} onClick={() => setIsDetailOrFile(false)}>
                {trans.meetingFiles}
              </li>
            </ul>
            <div className="employee_detail_content">
              {isDetailOrFile === true && (
                <div className="meeting_detail_info">
                  <div className="meeting_info">
                    <div className="employee_detail_content_left">
                      <div className="input-container">
                        <span className="input_header">{trans.meetingGroup}</span>
                        <select
                          className={`input select ${err.groupIdError ? "input-error" : ""}`}
                          onChange={(e) => changeMeetingGroup(e)}
                          value={current.item.workOrderGroupId}
                        >
                          <option key={"group" + 0} value={0}>
                            {trans.select}
                          </option>
                          {workordergroup &&
                            workordergroup.list &&
                            workordergroup.list.length > 0 &&
                            workordergroup.list.map((c, i) => {
                              return (
                                <option key={"group" + i} selected={c.selected} value={c.id}>
                                  {c.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <Input
                        header={trans.subject}
                        name={trans.subject}
                        id={trans.subject}
                        value={current.item.name}
                        error={err.nameError}
                        onChange={(e) => {
                          current.item.name = e.target.value;
                          dispatch({ type: itemType, payload: current.item });
                        }}
                      />
                      <div className="input-container">
                        <span className="input_header">{trans.meetingState}</span>
                        <div className="mt-1">
                          <Switch
                            classes="mediumSwitch"
                            id="generatedStatus"
                            value={current.item.status === 2 ? true : false}
                            onChange={(e) => {
                              current.item.status = e.target.checked === true ? 2 : 0;
                              dispatch({ type: itemType, payload: current.item });
                            }}
                          />
                          <span className={`ml-2 text-muted ${current.item.status ? "bg-sky-blue" : ""}`}>
                            {current.item.status ? trans.done : trans.notDone}
                          </span>
                        </div>
                      </div>
                      <div className="input-container">
                        <span className="input_header">{trans.point}</span>
                        <select
                          className={`input select ${err.customerIdError ? "input-error" : ""}`}
                          onChange={(e) => changePoint(e)}
                          value={current.item.point}
                        >
                          <option value="1">1</option>
                          <option value="3">3</option>
                          <option value="5">5</option>
                          <option value="8">8</option>
                          <option value="13">13</option>
                          <option value="21">21</option>
                        </select>
                      </div>
                      <div className="input-container">
                        <span className="input_header">{trans.startdate}</span>
                        <div className="input">
                          <Input
                            type={"datetime-local"}
                            value={current.item.startDate}
                            onChange={(e) => {
                              current.item.startDate = e.target.value;
                              dispatch({ type: itemType, payload: current.item });
                            }}
                            autoComplate={"off"}
                          />
                        </div>
                      </div>
                      <div className="input-container">
                        <span className="input_header">{trans.enddate}</span>
                        <div className={`input ${err.endDateError ? "input-error" : ""}`}>
                          <Input
                            type={"datetime-local"}
                            value={current.item.endDate}
                            onChange={(e) => {
                              current.item.endDate = e.target.value;
                              dispatch({ type: itemType, payload: current.item });
                            }}
                            autoComplate={"off"}
                          />
                        </div>
                      </div>

                      <div className="input-container mb-5">
                        <span className="input_header">{trans.participants}</span>
                        {employee.list !== null && employee.list !== undefined && employee.list.length > 0 ? (
                          <Select
                            mode="multiple"
                            allowClear
                            maxTagCount={3}
                            maxTagTextLength={4}
                            optionFilterProp="children"
                            onChange={selectMultiUserIds}
                            style={
                              current.item.userIds && current.item.userIds.length > 0
                                ? { width: "100%" }
                                : { width: "100%", borderColor: "rgba(232, 63, 64, 1)", borderWidth: 1 }
                            }
                            placeholder={trans.personals}
                            filterOption={(input, option) =>
                              option.children ? option.children.toLocaleLowerCase("tr").indexOf(input.toLocaleLowerCase("tr")) >= 0 : false
                            }
                            value={current.item.userIds}
                          >
                            {employee.list.map((l, i) => {
                              // //
                              return (
                                <Option key={"userd" + i} value={l.id}>
                                  {l.name}
                                </Option>
                              );
                            })}
                          </Select>
                        ) : null}
                      </div>
                      <div className="input-container mb-5">
                        <span className="input_header">{trans.labels}</span>
                        {label.list !== null && label.list !== undefined && label.list.length > 0 ? (
                          <Select
                            mode="multiple"
                            allowClear
                            maxTagCount={3}
                            maxTagTextLength={4}
                            optionFilterProp="children"
                            onChange={selectMultiCategories}
                            style={
                              current.item.labelIds && current.item.labelIds.length > 0 && err.labelError === ""
                                ? { width: "100%" }
                                : { width: "100%", borderColor: "rgba(232, 63, 64, 1)", borderWidth: 1 }
                            }
                            placeholder={trans.labels}
                            filterOption={(input, option) =>
                              option.children ? option.children.toLocaleLowerCase("tr").indexOf(input.toLocaleLowerCase("tr")) >= 0 : false
                            }
                            value={current.item.labelIds}
                          >
                            {label.list.map((l, i) => {
                              return (
                                <OptGroup key={l.label} label={l.label}>
                                  {l.options.map((m, i) => {
                                    return (
                                      <Option value={m.idx} key={"optionLable" + m.id + i}>
                                        {m.name}
                                      </Option>
                                    );
                                  })}
                                </OptGroup>
                              );
                            })}
                          </Select>
                        ) : null}
                      </div>
                    </div>
                    <div className="meeting_detail_content_right">
                      {/* <div className="form-group-file">
                                            <span className="input_header">
                                                {trans.file}
                                            </span>
                                            <FileUploader text="asdf" loadedFile={loadedFile} err={err.file} />
                                        </div> */}
                      <Input
                        placeholder={trans.decisionsTaken}
                        name={trans.decisionsTaken}
                        header={trans.decisionsTaken}
                        value={current.item.desicion}
                        textarea={true}
                        rows={10}
                        onChange={(e) => {
                          current.item.desicion = e.target.value;
                          dispatch({ type: itemType, payload: current.item });
                        }}
                        error={err.descriptionError}
                      />
                      <Input
                        placeholder={trans.notes}
                        name={trans.notes}
                        header={trans.notes}
                        value={current.item.description}
                        textarea={true}
                        rows={10}
                        onChange={(e) => {
                          current.item.description = e.target.value;
                          dispatch({ type: itemType, payload: current.item });
                        }}
                        error={err.desicionError}
                      />
                      <Button classes={"btn-primary"} onClick={register}>
                        {trans.update}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              {isDetailOrFile === false && (
                <div className="meeting_detail_info">
                  <File projectId={0} workOrderId={params.id} fileFromType={fileFromType.workorder} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
