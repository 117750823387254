import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Input from '../anBrains/Input'
import Button from '../anBrains/Button'
import ContentLoader from 'react-content-loader'
import { fetchBudgets, fetchBudgetDetails } from '../../redux/actions/project_cost_ac'
import { crud, Priorities, tableType, dateFormatter } from '../../config/constants'
import { Select } from 'antd';
import 'antd/dist/antd.css';
import { budgetItemType, budgetType } from '../../redux/action_types'
import { InputNumber } from 'antd';
import NumberFormat from 'react-number-format';
var toDate = new Date();
var year = toDate.getFullYear();
var month = toDate.getMonth() + 1;
var toDateString = year + (month.toString().length > 1 ? "-" : "-0") + month;
var defaultItem = {
    name: "",
    price: "",
    date: toDateString,
    type: 1,
    id: 0
}
export default function BudgetForm(props) {
    const trans = useSelector(state => state.trans)
    const loader = useSelector(state => state.loader)
    const project_cost = useSelector(state => state.project_cost)

    const dispatch = useDispatch()

    const errors = {
        nameError: "",
        priceError: "",
        dateError: ""
    }
    const [err, setErr] = useState(errors)

    const [message, setMessage] = useState("");
    let validate = () => {
        if (project_cost.item.name.length < 3 || project_cost.item.name.length > 100) {
            setErr({ ...errors, nameError: trans.toShort })
            setMessage(trans.toShort)
            return false
        }

        if (project_cost.item.price <= 0) {
            setErr({ ...errors, priceError: trans.pleaseEnterValid })
            setMessage(trans.pleaseEnterValid)
            return false
        }

        return true
    }


    const register = e => {
        e.preventDefault();
        if (validate()) {
            setErr(errors)
            var budget = { ...project_cost.item, date: dateFormatter(new Date(project_cost.item.date)) }
            dispatch(fetchBudgets({ loader: true }, project_cost.item.id > 0 ? crud.update : crud.create, budget, trans))
            // //
            // if (crud.update) {
            //     var i = project_cost.budgets.findIndex(x => x.id === project_cost.item.id)
            //     project_cost.budgets[i] = project_cost.budgets;
            //     dispatch({ type: budgetType, payload: project_cost.budgets })
            // }
            props.setIsAddOpen(false)
            defaultItem.type = project_cost.type;
            defaultItem.projectId = props.projectId;
            dispatch({ type: budgetItemType, payload: defaultItem })
        }
    }
    return !loader.loading ? (<div className="employeeForm">
        <Input placeholder={trans.account2}
            name={trans.account2}
            header={trans.account2}
            value={project_cost.item.name}
            onChange={e => {
                project_cost.item.name = e.target.value;
                dispatch({ type: budgetItemType, payload: project_cost.item })
            }}
            error={err.nameError}
        />
        <div className="input-container">
            <span className="input_header">{trans.date}
                {err.dateError && <p class="input_danger_message">{err.dateError}</p>}</span>
            <div className="input">
                <Input
                    type={"month"}
                    value={project_cost.item.date ?? defaultItem.date}
                    onChange={e => {
                        project_cost.item.date = e.target.value;
                        dispatch({ type: budgetItemType, payload: project_cost.item })
                    }}
                    autoComplate={"off"} />
            </div>
        </div>

        <div className="input-container">
            <span className="input_header">{trans.price}
                {err.priceError && <p class="input_danger_message">{err.priceError}</p>}</span>
            <div className={"flex input al-center" + (err.priceError ? " input-error" : "")}>
                {/* <InputNumber
                
                    defaultValue={project_cost.item.price}
                    formatter={value => `${value}₺`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\₺\s?|(,*)/g, '')}
                    min={0}
                    onChange={e=>{
                        project_cost.item.price = e;
                        dispatch({ type: budgetItemType, payload: project_cost.item })
                    }}
                /> */}
                <NumberFormat
                    onFocus={e => {
                        if (e.target.value === "₺0" || e.target.value === 0) {
                            project_cost.item.price = "";
                            dispatch({ type: budgetItemType, payload: project_cost.item })
                        }
                    }}
                    onBlur={e => {
                        if (e.target.value === "") {
                            project_cost.item.price = 0;
                            dispatch({ type: budgetItemType, payload: project_cost.item })
                        }
                    }}
                    value={project_cost.item.price}
                    onValueChange={e => {
                        project_cost.item.price = e.floatValue;
                        dispatch({ type: budgetItemType, payload: project_cost.item })
                    }}
                    // displayType={'text'} 
                    thousandSeparator={true}
                    prefix={'₺'}
                />
            </div>
        </div>

        {message && <span className="errorspan">{trans.error + ": " + message}</span>}
        <Button classes={"btn btn-primary"}
            onClick={register}>{trans.save}</Button>
    </div>) : (
        <div className="employeeForm">
            <ContentLoader
                viewBox="0 0 250 600"
                height={600}
                width={275}
                speed={1}
                backgroundColor="#ddd"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="25" y="100" rx="3" ry="3" width="275" height="10" />
                <rect x="25" y="140" rx="3" ry="3" width="275" height="10" />
                <rect x="25" y="180" rx="3" ry="3" width="275" height="10" />
                <rect x="25" y="220" rx="3" ry="3" width="275" height="10" />
            </ContentLoader>
        </div>
    )
}
