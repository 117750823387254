import React, { Component } from 'react'
import Input from '../../components/anBrains/Input'
import video from '../../images/login.mp4';
import Recaptcha from 'react-google-invisible-recaptcha';
import { connect } from 'react-redux';
import { fetchRequestDemo } from '../../redux/actions/request_form_ac';
import GeneralLoader from '../../components/anBrains/GeneralLoader';
import { CheckCircleIcon, RejectIcon } from '../../components/icons';
import { Redirect, withRouter } from 'react-router';
const mapStateToProps = state => ({
    loader: state.loader,
    trans: state.trans,
    response: state.response
});
const mapDispatchToProps = dispatch => ({
    fetchDemo: (payload) => dispatch(fetchRequestDemo(payload))
});


class Demo extends Component {
    state = {
        emailError: "",
        email: "",
        resolved: false,
        message: ""
    }

    onResolved = () => this.setState({ ...this.state, resolved: true })

    validate = () => {
        if (!this.state.email.includes('@') || this.state.email.length > 50) {
            this.setState({
                ...this.state,
                emailError: this.props.trans.pleaseEnterValidEmailAddress,
                message: this.props.trans.pleaseEnterValidEmailAddress
            })
            return false
        } else return true;
    }

    onEnter = e => {
        if (e.key === "Enter")
            this.sendLink()
    }

    sendLink = () => {
        this.recapca.execute();
        if (this.validate() && this.state.resolved)
            this.props.fetchDemo(this.state)
    }
    setField = v => e => {
        this.setState({ ...this.state, [v]: e.target.value })
        console.log(this.state);
    }
    render() {
        if (this.props.response.success) {
            this.props.history.push('/thanks');
        }
        return (
            <>
                {this.props.loader.loading && <GeneralLoader />}
                <div className="login_container">
                    <div className="login_image">
                        <video src={video} autoPlay muted loop> </video>
                        <img src="https://timezeta.com/wp-content/uploads/2020/12/1-Seffaf.gif" />
                        <div className="login_title">
                            <h1>timezeta</h1>
                            <p> Timezeta, projelerin düşük maliyetle, zamanında ve  istenen kalitede tamamlanmasını sağlar.</p>
                        </div>
                        {this.props.trans && <div className="login_form">
                            <div className="login_form_inside"><h2>{this.props.trans.requestademo}</h2>
                                <p>Timezeta, ekiplerin birlikte daha verimli çalışmalarını sağlar.</p>
                                {this.props.response.success === false && <Input
                                    placeholder={this.props.trans.email}
                                    name={this.state.email}
                                    value={this.state.email}
                                    onChange={this.setField("email")}
                                    error={this.state.emailError}
                                    readOnly={this.props.loader.loading}
                                    onKeyPress={this.onEnter} />}

                                {this.props.response.success === false && (this.props.loader.loading === false ?
                                    <button onClick={this.sendLink}>
                                        {this.props.trans.sendlink}
                                    </button> :
                                    <button>
                                        {this.props.trans.sending}
                                    </button>)}
                                <Recaptcha
                                    ref={ref => this.recapca = ref}
                                    //sitekey={"6LfbGaAaAAAAAL7jJ3lKuH499R7gvTk7Hnbg_ayU"}// localhost
                                    sitekey={"6LdT5p8aAAAAANKdwX7c3-u4d9Vvdrbkjb20vGqg"}
                                    onResolved={this.onResolved}
                                />
                                <div className="login_message" style={this.props.response.success ? { color: "#28a745" } : {}}>
                                    {this.props.response.success === true && <CheckCircleIcon color={"#28a745"} />}
                                    {this.props.trans[this.props.response.message] || this.state.message}
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </>
        )
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Demo));