import React, { useEffect, useRef, useState } from "react";
import Layout from "../Container/Layout";
import { useDispatch, useSelector } from "react-redux";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { calendarType, itemType } from "../../redux/action_types";
import { fetchWorkorder, fetchWorkorderDetail } from "../../redux/actions/workorder_ac";
import { crud, dateFormatter, ProjectStatus, schedulerType } from "../../config/constants";
import "moment/locale/tr";
import "moment/locale/en-au";
import Modal from "../anBrains/Modal";
import SchedulerForm from "../scheduler/SchedulerForm";
const localizer = momentLocalizer(moment);
var defaultItem = {
  name: "",
  description: "",
  progress: 0,
  priority: 1,
  point: 1,
  projectGroupId: 0,
  workOrderLabels: [],
  userIds: [],
  startDate: dateFormatter(new Date()),
  endDate: dateFormatter(new Date()),
  type: 0,
  id: 0,
};
export default function BigCalendar(props) {
  const dispatch = useDispatch();
  const DragAndDropCalendar = withDragAndDrop(Calendar);
  const trans = useSelector((state) => state.trans);
  const theme = useSelector((state) => state.theme);
  const calendar = useSelector((state) => state.calendar);
  const loader = useSelector((state) => state.loader);
  const user = useSelector((state) => state.user);
  const [request, setRequest] = useState({ loader: true, isAgenda: true, includeDetail: false });
  useEffect(() => {
    dispatch(
      fetchWorkorder(
        { rangeDate: new Date().toISOString().split("T")[0], currentUserId: props.employeeid, ...request, includeDetail: true },
        crud.list,
        {},
        trans
      )
    );
    if (props.isMenuPage) {
      dispatch({ type: itemType, payload: defaultItem });
    }
  }, []);
  const handleSelect = (selectProps) => {
    if (props.isMenuPage) {
      const { start, end } = selectProps;
      defaultItem.startDate = dateFormatter(start);
      defaultItem.endDate = dateFormatter(end);
      dispatch({ type: itemType, payload: defaultItem });
      setIsEdit(true);
      setIsAddOpen(true);
    }
  };
  const resize = (e) => {
    if (props.isMenuPage) {
      if (
        (calendar.list.find((x) => x.id === e.event.id).type !== schedulerType.meeting ||
          user.roles.some((x) => x === "Admin") ||
          calendar.list.find((x) => x.id === e.event.id).createBy === user.id) &&
        calendar.list.find((x) => x.id === e.event.id).type !== schedulerType.projectStep_Workorder
      ) {
        const modifiedEvents = calendar.list.map((x) => {
          return x.id == e.event.id ? { ...x, start: e.start, end: e.end, allDay: e.allDay } : x;
        });
        dispatch({ type: calendarType, payload: modifiedEvents });
        console.log(e);
        dispatch(
          fetchWorkorder(
            request,
            crud.changeDate,
            {
              ...e.event,
              startDate: moment(e.start).utcOffset("GMT+03:00")._d,
              endDate: moment(e.end).utcOffset("GMT+03:00")._d,
            },
            trans
          )
        );
      }
    }
  };
  const changeView = (e) => setCalendarView(e);

  const [calendarView, setCalendarView] = useState("week");
  const calendarDrag = (e) => {
    if (props.isMenuPage) {
      if (
        (calendar.list.find((x) => x.id === e.event.id).type !== schedulerType.meeting ||
          user.roles.some((x) => x === "Admin") ||
          calendar.list.find((x) => x.id === e.event.id).createBy === user.id) &&
        calendar.list.find((x) => x.id === e.event.id).type !== schedulerType.projectStep_Workorder
      ) {
        const modifiedEvents = calendar.list.map((x) => {
          return x.id == e.event.id ? { ...x, start: e.start, end: e.end, allDay: e.allDay } : x;
        });
        dispatch({ type: calendarType, payload: modifiedEvents });
        dispatch(
          fetchWorkorder(
            request,
            crud.changeDate,
            {
              ...e.event,
              startDate: moment(e.start).utcOffset("GMT+03:00")._d,
              endDate: moment(e.end).utcOffset("GMT+03:00")._d,
            },
            trans
          )
        );
      }
    }
  };
  const [defaultDate, setDefaultDate] = useState(new Date());
  const eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = "#000";
    var color = "#000";
    if (event.isAgendaHappend) {
      backgroundColor = "#28a745";
      color = "#fff";
    } else {
      switch (event.type) {
        case schedulerType.onlyAgenda:
          backgroundColor = "rgba(96, 168, 221, 1)";
          color = "#fff";
          break;
        case schedulerType.workOrder_Agenda:
          backgroundColor = "rgba(247, 202, 49, 1)";
          color = "#222222";
          break;
        case schedulerType.projectStep_Workorder:
          backgroundColor = "#7231F5";
          color = "#fff";
          break;
        case schedulerType.humanResource:
          backgroundColor = "#E62020";
          color = "#fff";
          break;
        case schedulerType.meeting:
          backgroundColor = "#E62020";
          color = "#fff";
          break;
        default:
          break;
      }
    }
    var style = {
      backgroundColor,
      borderRadius: "5px",
      opacity: 0.9,
      color,
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const onSelectEvent = (e) => {
    if (props.isMenuPage) {
      e.startDate = e.start;
      e.endDate = e.end;
      dispatch(fetchWorkorderDetail({ ...request, id: e.id, isAgendaDetail: true, isAgenda: false, lang: trans.lang }, crud.list, {}, trans));
      setIsEdit(false);
      setIsAddOpen(true);
    }
  };
  const navigateDate = (date) => {
    if (props.isMenuPage) {
      dispatch(fetchWorkorder({ rangeDate: date.toISOString().split("T")[0], ...request }, crud.list, {}, trans));
    }
  };
  const messages = {
    tr: {
      allDay: "Tüm Gün",
      previous: "Önceki",
      next: "Sonraki",
      today: "Bugün",
      month: "Ay",
      week: "Hafta",
      day: "Gün",
      work_week: "Çalışma Günleri",
      agenda: "Agenda",
      date: "Tarih",
      time: "Saat",
      event: "Olay",
      showMore: (total) => `+ Toplam Olay  (${total})`,
    },
    en: {
      allDay: "All Day",
      previous: "Previous",
      next: "Next",
      today: "Today",
      month: "Month",
      week: "Week",
      work_week: "Work Week",
      day: "Day",
      agenda: "Agenda",
      date: "Date",
      time: "Time",
      event: "Event",
      showMore: (total) => `+ Total Event  (${total})`,
    },
  };
  return (
    <>
      <Modal isOpen={isAddOpen} withClose setIsOpen={setIsAddOpen} classes={"modal_scheduler"}>
        <SchedulerForm isEdit={isEdit} setIsEdit={setIsEdit} setIsOpen={setIsAddOpen} />
      </Modal>
      <div className="scheduler_container" style={props.isMenuPage ? {} : { padding: 10 }}>
        <DragAndDropCalendar
          selectable
          resizable
          localizer={localizer}
          events={calendar.list}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 820 }}
          // defaultDate={new Date()}
          date={defaultDate}
          onNavigate={(date) => {
            setDefaultDate(date);
            navigateDate(date);
          }}
          // onRangeChange={range}
          onSelectSlot={handleSelect}
          views={["month", "week", "day" /*,'agenda'*/]}
          view={calendarView}
          onView={changeView}
          // resources={resourceMap}
          // resourceIdAccessor="resourceId"
          // resourceTitleAccessor="resourceTitle"
          culture={"tr-TR"}
          // onEventResize={resizeEvent}
          onEventResize={resize}
          onEventDrop={calendarDrag}
          eventPropGetter={eventStyleGetter}
          onSelectEvent={onSelectEvent}
          messages={messages[trans.lang]}
          // ref={calendarRef}
        />
      </div>
    </>
  );
}
