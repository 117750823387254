import { leaveType, addleaveType } from '../action_types'
const reducer = (state = [], action) => {
    switch (action.type) {
        case leaveType:
            return action.payload;
        case addleaveType:
            return [action.payload, ...state];
        default:
            return state;
    }
};
export default reducer;