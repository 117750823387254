import React, { useEffect, useState } from "react";
import Layout from "../../components/Container/Layout";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/anBrains/Pagination";
import Button from "../../components/anBrains/Button";
import Input from "../../components/anBrains/Input";
import Modal from "../../components/anBrains/Modal";
import { PlusIcon, SortIcon, Search2Icon, EditIcon, TrushIcon, InfoIcon } from "../../components/icons";
import ContentLoader from "react-content-loader";
import { success } from "../../redux/actions/loader_ac";
import { crud } from "../../config/constants";
import { fetchCompanyCulture } from "../../redux/actions/company_culture_ac";
import { companyCultureType, departmentType, itemType } from "../../redux/action_types";
import { openNotification } from "../../config";
const defaultItem = {
  departmentId: "0",
  currentDepartmentId: 0,
  name: "",
  description: "",
};
export default function CompanyCulture(props) {
  const dispatch = useDispatch();
  const trans = useSelector((state) => state.trans);
  const theme = useSelector((state) => state.theme);
  const current = useSelector((state) => state.current);
  const companyCulture = useSelector((state) => state.companyCulture);
  const [searchText, setSearchText] = useState("");
  const [searchDepartmentText, setSearchDepartmentText] = useState("");
  const loader = useSelector((state) => state.loader);
  const department = useSelector((state) => state.department);
  const [selectedPage, setSelectedPage] = useState(1);
  const [isAddCultureOpen, setIsAddCultureOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [itemCulture, setItemCulture] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const result = useSelector((state) => state.result);
  const [request, setRequest] = useState({ rowCount: 14, pageCount: 0, search: "", sort: "id", desc: false, data: {} });
  const [cultureRequest, setCultureRequest] = useState({ rowCount: 14, pageCount: 0, search: "", sort: "id", desc: false, data: {} });

  useEffect(() => {
    dispatch(fetchCompanyCulture(request, crud.with, {}, trans));
  }, []);

  useEffect(() => {
    if (department.list.length > 0) {
      setRequest({ ...request, projectGroupId: department.list[0].id });
      dispatch({ type: itemType, payload: { ...defaultItem, departmentId: department.list[0].id } });
    }
  }, [department.list]);

  var errors = {
    errDepartmentId: "",
    errName: "",
    errDescription: "",
  };
  const [err, setErr] = useState(errors);

  const [message, setMessage] = useState("");
  let validate = () => {
    if (current.item.name.length < 3 || current.item.name.length > 50) {
      setErr({ ...errors, errName: trans.least3 });
      setMessage(trans.least3);
      return false;
    }
    // if (current.item.description < 5 || current.item.description > 200) {
    //     setErr({ ...errors, errDescription: trans.toShort })
    //     setMessage(trans.toShort)
    //     return false
    // }
    if (current.item.departmentId.length === 0) {
      setErr({ ...errors, errDepartmentId: trans.toShort });
      setMessage(trans.toShort);
      return false;
    }
    return true;
  };

  const register = (e) => {
    e.preventDefault();
    if (validate()) {
      setErr(errors);
      if (!isEdit) {
        dispatch(fetchCompanyCulture({}, crud.create, current.item, trans));
      } else {
        dispatch(fetchCompanyCulture({}, crud.update, current.item, trans));
        setIsEdit(false);
      }

      // if (result.success)
      //     openNotification('success', trans.successTitle, trans.successBody, 3)
      // else
      //     openNotification('error', trans.errorTitle, trans.errorBody, 3)

      setIsAddCultureOpen(false);
    }
  };

  const selectDepartment = (i) => {
    department.list.map((x) => (x.selected = false));
    department.list[i].selected = true;
    dispatch({ type: departmentType, payload: department.list });
    var departmentId = department.list[i].id;
    current.item.departmentId = departmentId;
    dispatch({ type: itemType, payload: { ...current.item, departmentId } });
    dispatch(fetchCompanyCulture({ ...cultureRequest, departmentId }, crud.list, {}, trans));
    setCultureRequest({ ...cultureRequest, departmentId });
  };

  const deleteCultureComfirm = (e) => {
    setIsDeleteOpen(true);
    setItemCulture(e);
  };

  const deleteItem = () => {
    setIsDeleteOpen(false);
    dispatch(fetchCompanyCulture(cultureRequest, crud.delete, itemCulture, trans));
    let cultures = companyCulture.list.filter((e, i) => {
      return e.id !== itemCulture.id;
    });
    dispatch({ type: companyCultureType, payload: cultures });
  };

  const searchChange = (text) => {
    setSearchText(text);
    if (text.length > 2) {
      dispatch(fetchCompanyCulture({ ...cultureRequest, search: text, departmentId: 0 }, crud.list, {}, trans));
      //department.list.map(x => x.selected = false);
      dispatch({ type: departmentType, payload: department.list });
    }
  };

  const searchChangeKeyPress = (text, kcode) => {
    if (kcode === 8) {
      dispatch(fetchCompanyCulture({ ...cultureRequest, search: text }, crud.list, {}, trans));
    }
  };

  // const searchDepartmentChange = (text) => {
  //     setSearchDepartmentText(text);
  //     if (text.length > 0) {
  //         let labelList = department.currentList.filter((e, i) => {
  //             return e.name.includes(text);
  //         })
  //         department.list = labelList;
  //     }
  //     else {
  //         department.list = label.currentList;
  //     }
  // }

  const [desc, setDesc] = useState(false);
  const sortbyField = (f) => {
    setCultureRequest({ ...cultureRequest, sort: f, desc });
    dispatch(fetchCompanyCulture({ ...cultureRequest, sort: f, desc }, crud.list, {}, trans));
    setDesc(!desc);
  };

  const setSelectedPageFilter = (n) => {
    setSelectedPage(n);
    setCultureRequest({ ...cultureRequest, pageCount: n - 1 });
    dispatch(fetchCompanyCulture({ ...cultureRequest, pageCount: n - 1 }, crud.list, {}, trans));
  };

  const openForm = () => {
    dispatch({ type: itemType, payload: { ...defaultItem, departmentId: department.list.find((x) => x.selected).id } });
    setIsAddCultureOpen(true);
  };

  const editItem = (e) => {
    setIsEdit(true);
    dispatch({ type: itemType, payload: e });
    setIsAddCultureOpen(true);
  };

  const changeDepartment = (e) => dispatch({ type: itemType, payload: { ...current.item, departmentId: parseInt(e.target.value) } });

  return (
    <Layout>
      <Modal isOpen={isAddCultureOpen} withClose setIsOpen={setIsAddCultureOpen} classes={"modal_add_employee"}>
        <div className="compnayCultureForm">
          <div className="input-container">
            <span className="input_header">{trans.department}</span>
            <select
              className={`input select ${err.errDepartmentId ? "input-error" : ""}`}
              onChange={(e) => changeDepartment(e)}
              value={current.item.departmentId}
            >
              <option key={"group" + 0} value={0}>
                {trans.select}
              </option>
              {department &&
                department.list.length > 0 &&
                department.list.map((c, i) => {
                  return (
                    <option key={"department" + i} value={c.id}>
                      {c.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <Input
            placeholder={trans.name}
            name={trans.name}
            header={trans.name}
            value={current.item.name}
            onChange={(e) => dispatch({ type: itemType, payload: { ...current.item, name: e.target.value } })}
            error={err.errName}
            maxLength={50}
          />
          <Input
            placeholder={trans.description}
            name={trans.description}
            header={trans.description}
            value={current.item.description}
            textarea={true}
            onChange={(e) => dispatch({ type: itemType, payload: { ...current.item, description: e.target.value } })}
            error={err.errDescription}
          />
          {message && <span className="errorspan">{trans.error + ": " + message}</span>}
          <Button classes={"btn btn-primary"} onClick={register}>
            {trans.save}
          </Button>
        </div>
      </Modal>
      <Modal isOpen={isDeleteOpen} withClose setIsOpen={setIsDeleteOpen} classes={"modal_delete"}>
        {trans.areyousureyouwanttodelete}
        <Button classes="btn-red" onClick={() => deleteItem()}>
          {trans.confirm}
        </Button>
      </Modal>
      <div className="table_properties">
        <Input
          icon={<Search2Icon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />}
          placeholder={trans.search}
          value={searchText}
          onChange={(e) => searchChange(e.target.value)}
          onKeyUp={(e) => searchChangeKeyPress(e.target.value, e.keyCode)}
        />
        <Button icon={<PlusIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />} classes="btn-basic" onClick={() => openForm()}>
          {trans.add}
        </Button>
      </div>

      {!loader.loading ? (
        <div className="project_container">
          <div className="project_group">
            <h2>{trans.department}</h2>
            <div className="department_container">
              {/* <Input
                                icon={<Search2Icon
                                    color={(theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")} />}
                                placeholder={trans.search}
                                value={searchDepartmentText}
                                onChange={e => searchDepartmentChange(e.target.value)} /> */}
              <ul className="meeting_group_list">
                {department &&
                  department.list &&
                  department.list.length > 0 &&
                  department.list.map((d, i) => {
                    return (
                      <li onClick={() => selectDepartment(i)} className={d.selected ? "active d-flex" : "d-flex"} key={"department" + i}>
                        {d.name}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className="project_view">
            <div className="table">
              <table>
                <thead>
                  <tr>
                    <th onClick={() => sortbyField("name")}>
                      {trans.name} <SortIcon color="rgba(96, 168, 221, 1)" />
                    </th>
                    <th>{trans.description}</th>
                    <th>{trans.settings}</th>
                  </tr>
                </thead>
                <tbody>
                  {companyCulture &&
                    companyCulture.list &&
                    companyCulture.list.length > 0 &&
                    companyCulture.list.map((e, i) => {
                      return (
                        <tr className="row" key={i}>
                          <td onClick={() => editItem(e)} disabled={e.status == 2 ? true : false}>
                            {e.name}
                          </td>
                          <td
                            onClick={() => editItem(e)}
                            disabled={e.status == 2 ? true : false}
                            className="info-icon"
                            title={e.description && e.description.length > 15 ? e.description : ""}
                          >
                            {e.description && e.description.length > 15 ? e.description.substring(0, 15) + "" : e.description}
                            {e.description && e.description.length > 15 ? (
                              <InfoIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />
                            ) : null}
                          </td>
                          <td>
                            {/* <button>
                                                        <EditIcon color={(theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")} />
                                                    </button> */}
                            <button onClick={() => deleteCultureComfirm(e)} disabled={e.status == 2 ? true : false}>
                              <TrushIcon color={theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)"} />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {current && current.pageCount > 0 && <Pagination setSelectedPage={setSelectedPageFilter} selectedPage={selectedPage} />}
          </div>
        </div>
      ) : (
        <div className="project_container">
          <div className="project_group">
            <h2>{trans.department}</h2>
            <div className="meeting_group_container">
              {/* <Input
                                icon={<Search2Icon
                                    color={(theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")} />}
                                placeholder={trans.search}
                                value={searchDepartmentText}
                                onChange={e => searchDepartmentChange(e.target.value)} /> */}
              <ul className="meeting_group_list">
                {department.list.length > 0 &&
                  department.list.map((d, i) => {
                    return (
                      <li onClick={() => selectDepartment(i)} className={d.selected ? "active d-flex" : "d-flex"} key={"department" + i}>
                        {d.name}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className="project_view">
            <div className="table">
              <table>
                <thead>
                  <tr>
                    <th>{trans.name}</th>
                    <th>{trans.description}</th>
                    <th>{trans.settings}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="row">
                    <td colSpan={8}>
                      <ContentLoader
                        preserveAspectRatio="none"
                        backgroundColor={theme === "dark" ? "#666666" : "#efefef"}
                        foregroundColor={theme === "dark" ? "#222222" : "#cccccc"}
                        viewBox="0 0 1000 60"
                        height={60}
                        width="100%"
                        style={{ width: "100%" }}
                        speed={2}
                        {...props}
                      >
                        <rect x="110" y="21" rx="4" ry="4" width="754" height="6" />
                        <rect x="111" y="41" rx="3" ry="3" width="185" height="7" />
                        <rect x="304" y="-46" rx="3" ry="3" width="350" height="6" />
                        <rect x="371" y="-45" rx="3" ry="3" width="380" height="6" />
                        <rect x="484" y="-45" rx="3" ry="3" width="201" height="6" />
                      </ContentLoader>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}
