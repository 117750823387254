import { Button, notification, Space } from "antd";
export const baseUrl = "https://api.timezeta.com";
// export const baseUrl = "http://localhost:5001";
export const axiosConfig = {
  headers: {
    "Content-Type": "application/json",
    // 'X-Requested-With': 'XMLHttpRequest',
    // 'Access-Control-Allow-Origin': '*'
  },
  //  mode: 'no-cors'
};

export const openNotification = (type, title, description, duration = 20) => {
  notification[type]({
    message: title,
    description,
    duration,
  });
};
