import React from 'react'

export default function Switch({ value, onChange, id, text, classes }) {
	return (
		<>
			<input type="checkbox" checked={value} id={id} name="theme" onChange={onChange} />
			<label htmlFor={id} title="check" className={classes ? classes : ""}>{text} </label>
		</>
	)
}
