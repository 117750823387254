import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Input from "../../components/anBrains/Input";
import { useSelector, useDispatch } from "react-redux";
import { fetchLogin, fetchEmail } from "../../redux/actions/authorization_ac";
import video from "../../images/login.mp4";
import { treeType, isLoginType, hasExpiredType } from "../../redux/action_types";
import Modal from "../../components/anBrains/Modal";
import Expired from "../../pages/login/Expired";

export default function Login(props) {
  const trans = useSelector((state) => state.trans);
  const result = useSelector((state) => state.result);
  const user = useSelector((state) => state.user);
  const loader = useSelector((state) => state.loader);
  const response = useSelector((state) => state.response);
  const current = useSelector((state) => state.current);
  const history = useHistory();
  const authorization = useSelector((state) => state.authorization);
  const [renderCount, setRenderCount] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authorization.isLogin) history.push("/pano");
  }, [authorization.isLogin]);

  const [isForget, setIsForget] = useState(false);
  var fields = {
    email: "",
    password: "",
  };
  const [field, setField] = useState(fields);

  const errors = {
    passwordError: "",
    emailError: "",
  };
  const [err, setErr] = useState(errors);
  const [message, setMessage] = useState(null);
  let validate = () => {
    if (field.password.length < 5 || field.password.length > 50) {
      setErr({ ...errors, passwordError: trans.pleaseEnterValid });
      setMessage(trans.pleaseEnterValid);
      return false;
    }
    emailControl();
    return true;
  };

  let emailControl = () => {
    if (!field.email.includes("@") || field.email.length > 50) {
      setErr({ ...errors, emailError: trans.pleaseEnterValidEmailAddress });
      setMessage(trans.pleaseEnterValidEmailAddress);
      return false;
    } else return true;
  };

  const login = (e) => {
    setRenderCount(renderCount + 1);
    e.preventDefault();

    if (isForget) sendLink();
    else {
      if (validate()) {
        //
        setErr(errors);
        setMessage("");
        dispatch(fetchLogin(field, trans));
        dispatch({ type: isLoginType, payload: false });
      }
    }
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {
      if (isForget) sendLink();
      else login(e);
    }
  };
  const forgotPasswordRedirect = () => setIsForget(!isForget);

  const sendLink = () => {
    dispatch(fetchEmail(field.email));
  };

  const setIsOpen = () => {
    dispatch({ type: hasExpiredType, payload: false });
  };

  return (
    <>
      <Modal isOpen={authorization.hasexpired} withClose setIsOpen={setIsOpen} classes={"modal_delete"} style={{ maxWidth: "50%" }}>
        <Expired />
      </Modal>
      <div className="login_container">
        <div className="login_image">
          <video src={video} autoPlay muted loop>
            {" "}
          </video>
          {/* <div className="overlay"></div> */}
          <img src="https://timezeta.com/wp-content/uploads/2020/12/6-seffaf-3.gif" />
          <div className="login_title">
            <h1>timezeta</h1>
            <p> Timezeta, projelerin düşük maliyetle, zamanında ve istenen kalitede tamamlanmasını sağlar.</p>
          </div>
          <div className="login_form">
            <div className="login_form_inside">
              <h2>{!isForget ? trans.pleaselogin : trans.forgotPassword}</h2>
              <p>Timezeta, ekiplerin birlikte daha verimli çalışmalarını sağlar.</p>
              <Input
                placeholder={trans.email}
                name={trans.email}
                value={field.email}
                onChange={(e) => setField({ ...field, email: e.target.value })}
                error={err.emailError}
                readOnly={loader.loading}
                onKeyPress={onEnter}
              />

              {!isForget && (
                <Input
                  placeholder={trans.password}
                  type={"password"}
                  name={trans.password}
                  value={field.password}
                  onChange={(e) => setField({ ...field, password: e.target.value })}
                  error={err.passwordError}
                  readOnly={loader.loading}
                  onKeyPress={onEnter}
                />
              )}

              <span onClick={forgotPasswordRedirect}>{isForget ? trans.pleaselogin : trans.forgotPassword}</span>
              {loader.loading === false ? (
                <button onClick={login}>{!isForget ? trans.pleaselogin : trans.sendlink}</button>
              ) : (
                <button>{!isForget ? trans.signingin : trans.sending}</button>
              )}
              <div className="login_message">{trans[response.message]}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
