import React, { useState, useEffect } from 'react'
import { fetchProject } from '../../redux/actions/project_ac';
import { crud, ProjectStatus } from '../../config/constants';
import ProjectBoardCard from './ProjectBoardCard';
import Board from "react-trello";
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd';
import { fetchProjectGroup } from '../../redux/actions/project_group_ac'
import { labelType, projectWorkOrderType } from '../../redux/action_types';

export default function ProjectBoardView(props) {
    const dispatch = useDispatch();
    const trans = useSelector(state => state.trans)
    const project = useSelector(state => state.project)
    const [request, setRequest] = useState({ rowCount: 14, pageCount: 0, search: "", sort: "id", "desc": false, data: {}, projectStatus: 0 })
    const handleDragEnd = (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
        console.log(cardId, position);
        cardDetails.status = targetLaneId;
        dispatch(fetchProject({}, crud.update, cardDetails, trans))
    }
    //
    const components = {
        Card: ProjectBoardCard
        //LaneFooter: BoardLineFooter,
    };
    const cardClick = (cardId, metadata, laneId) => {
        console.log(cardId, metadata, laneId);
        props.selectRow(cardId)
    }
    const [isControl, setIsControl] = useState(false)
    function paginate(requestedPage, laneId) {

        var lane = project.boardlist.lanes.find(x => x.id === laneId);
        if (parseInt(lane.label) % 9 === 0 && !isControl) {
            setIsControl(true)
            setTimeout(() => {
                setIsControl(false)
            }, 2000);
            const promise = new Promise((resolve, reject) => {
                resolve(dispatch(fetchProject({ ...request, projectStatus: parseInt(laneId), pageCount: lane.currentPage, isProjectStatus: true, projectGroupId: lane.cards[0].projectGroupId }, crud.list, {}, trans)))
            });

            return (promise);
        }
        else {
            const promise = new Promise((resolve, reject) => {
                resolve(null)
            });
            return (promise);
        }

    }

    return (
        <div>
            <Board
                data={project.boardlist}
                style={{
                    // backgroundColor: theme === "dark" ? "#232323" : "#fff"
                }}
                onCardClick={(cardId, metadata, laneId) => cardClick(cardId, metadata, laneId)
                }
                //onLaneScroll={(requestedPage, laneId) => myData(requestedPage, laneId)}
                onLaneScroll={paginate}
                components={components}
                //laneFooter
                //collapsibleLanes
                //LaneFooter
                //draggable
                //showfooter
                handleDragEnd={(cardId,
                    sourceLaneId,
                    targetLaneId,
                    position,
                    cardDetails) => handleDragEnd(cardId, sourceLaneId, targetLaneId, position, cardDetails)
                }
            />
        </div>
    )
}