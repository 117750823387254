import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWorkorder } from '../../redux/actions/workorder_ac'
import { crud, ProjectStatus } from '../../config/constants';
import ProjectBoardCard from './WorkorderBoardCard';
import Board from "react-trello";

export default function WorkorderBoardView(props) {

    const trans = useSelector(state => state.trans)
    const dispatch = useDispatch();
    const handleDragEnd = (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
        
        console.log(cardId, position);
        cardDetails.status = targetLaneId;
        cardDetails.IsAgendaHappend = false;
        // 
        // if(targetLaneId === 2)
        //     cardDetails.ishappened = true;
        dispatch(fetchWorkorder({ id: cardDetails.id, status: targetLaneId, loader: true }, crud.change, {}, trans))
    }
    const components = {
        Card: ProjectBoardCard
    };
    const asdf = (cardId, metadata, laneId) => {
        console.log(cardId, metadata, laneId);
        props.selectRow(cardId)
    }


    const [isControl, setIsControl] = useState(false)
    function paginate(requestedPage, laneId) {
        
        console.log(requestedPage, laneId)
        var lane = props.trello.lanes.find(x => x.id === laneId);
        if (parseInt(lane.label) % 10 === 0
            && !isControl
        ) {
            setIsControl(true)
            setTimeout(() => {
                setIsControl(false)
            }, 2000);
            const promise = new Promise((resolve, reject) => {
                resolve(dispatch(fetchWorkorder({ isWorkOrder: true, status: laneId, isKanban: true, addKanban: true, rowCount: 10, pageCount: lane.currentPage, loader: true }, crud.kanban, {}, trans)));
            });

            return (promise);
        }
        else {
            const promise = new Promise((resolve, reject) => {
                resolve(null)
            });
            return (promise);
        }
    }

    return (
        <Board
            data={props.trello}
            style={{
                // overflowX: "hidden",
                height: "auto"
            }}
            onCardClick={(cardId, metadata, laneId) => asdf(cardId, metadata, laneId)
            }
            // onLaneClick={(cardId, metadata, laneId) => asdf(cardId, metadata, laneId)
            // }
            onLaneScroll={paginate}
            components={components}
            draggable
            handleDragEnd={(cardId,
                sourceLaneId,
                targetLaneId,
                position,
                cardDetails) => handleDragEnd(cardId, sourceLaneId, targetLaneId, position, cardDetails)
            }
        />
    )
}