import {
    breadcrumbType,
    addBreadcrumbType,
    pageTitleType,
    barListType,
    gridListType,
    pageCountType,
    pageCountType2,
    tcType,
    itemType,
    usedLeaveCountType,
    usersType,
    addResponsibleType,
    responsibleType,
    boardListType,
    addWorkorderCommentType,
    updateWorkOrderUserTimeType,
    itemHoursType,
    requestFormType,
    addWorkOrderUserTimeType,
    isLoginType,
    deleteWorkOrderUserTimeType
} from '../action_types'
const reducer = (state = { breadcrumbs: [], bar: true, grid: false, pageCount: 0, numbers: [], tcconfirm: false, item: { userTimes: [], responsiblePersons: [] }, itemHours: { list: [] }, requestMessage: "" }, action) => {
    switch (action.type) {
        case updateWorkOrderUserTimeType:
            return { ...state, item: { ...state.item, ...action.payload } }
        case deleteWorkOrderUserTimeType:
            return { ...state, item: { ...state.item, userTimes : action.payload } }
        case addWorkOrderUserTimeType:
            var status = action.payload.status;
            var usertime = action.payload.workorderusertime;
            var userTimes = [];
            if (state.item.userTimes != null && state.item.userTimes.length > 0)
                userTimes = state.item.userTimes;
            userTimes.push(usertime);
            return { ...state, item: { ...state.item, ...action.payload, userTimes, status } };
        case addWorkorderCommentType:
            if (state.item.comments === null) {
                state.item.comments = [];
            }
            state.item.comments.push({ ...action.payload, active: true });
            return state
        case breadcrumbType:
            return { ...state, breadcrumbs: action.payload }

        case pageTitleType:
            return { ...state, title: action.payload }
        case barListType:
            return { ...state, bar: true, grid: false, board: false }
        case gridListType:
            return { ...state, bar: false, grid: true, board: false }
        case boardListType:
            return { ...state, bar: false, grid: false, board: true }
        case usersType:
            return { ...state, users: action.payload }
        case addBreadcrumbType:
            return { ...state, breadcrumbs: [...state.breadcrumbs, action.payload] }
        case pageCountType:
            let numbers = []
            for (let index = 0; index < action.payload; index++) 
                numbers.push(index + 1)
            return { ...state, pageCount: action.payload, numbers }
        case tcType:
            return { ...state, tcconfirm: action.payload }
        case itemType:
            return { ...state, item: action.payload }
        case itemHoursType:
            return { ...state, itemHours: { list: action.payload } }
        case usedLeaveCountType:
            return { ...state, item: { ...state.item, usedLeaveCount: action.payload } }
        case addResponsibleType:
            return { ...state, item: { ...state.item, responsiblePersons: [...state.item.responsiblePersons, action.payload] } }
        case responsibleType:
            return { ...state, item: { ...state.item, responsiblePersons: action.payload } }
        case requestFormType:
            return { ...state, requestMessage: action.payload }
        default:
            return state
    }
}
export default reducer