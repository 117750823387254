import React, { useEffect, useState } from "react";
import Layout from "../../components/Container/Layout";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/anBrains/Button";
import { ManIcon, WomanIcon } from "../../components/icons";
import Avatar from "../../components/anBrains/Avatar";
import Input from "../../components/anBrains/Input";
import ContentLoader from "react-content-loader";
import { crud } from "../../config/constants";
import { baseUrl } from "../../config";
import { fetchRoles } from "../../redux/actions/position_ac";
import { tcType, insideMenuType, itemType } from "../../redux/action_types";
import { fetchEmployeeDetail, fetchTc, fetchEmployees } from "../../redux/actions/employee_ac";
import EmployeeDetailInsideMenu from "../../components/employee/EmployeeDetailInsideMenu";
import LeaveTab from "../../components/employee/LeaveTab";
import OvertimeTab from "../../components/employee/OvertimeTab";
import DebitTab from "../../components/employee/DebitTab";
import PayrollTab from "../../components/employee/PayrollTab";
import InOutTab from "../../components/employee/InOutTab";
import EmployeeFileTab from "../../components/employee/EmployeeFileTab";
import { fetchLeaves } from "../../redux/actions/leave_ac";
import { fetchOvertimes } from "../../redux/actions/overtime_ac";
import { fetchInOuts } from "../../redux/actions/inOut_ac";
import { fetchdebits } from "../../redux/actions/debit_ac";
import { fetchpayrolls } from "../../redux/actions/payroll_ac";
import { fetchEmployeeDocuments } from "../../redux/actions/employee_document_ac";
import { Select } from "antd";
const { Option, OptGroup } = Select;
export default function Profile(props) {
  const {
    match: { params },
  } = props;
  const dispatch = useDispatch();
  const trans = useSelector((state) => state.trans);
  const user = useSelector((state) => state.user);
  const theme = useSelector((state) => state.theme);
  const current = useSelector((state) => state.current);
  const loader = useSelector((state) => state.loader);
  const positions = useSelector((state) => state.position);
  const { inside_menu } = useSelector((state) => state.employee);

  useEffect(() => {
    dispatch(fetchEmployeeDetail(params.id));
  }, []);

  useEffect(() => {
    setField({
      ...current.item,
      newPassword: current.item.password,
      birthDate: current.item.birthDateString,
      yearlyLeaveDate: current.item.yearlyLeaveDateString,
      photo: baseUrl + current.item.photo,
      password: "&&&&&&",
      newPassword: "&&&&&&",
    });

    if (!positions.length > 0) {
      dispatch(fetchRoles());
    }
  }, [current.item]);

  var fields = {
    id: params.id,
    name: "",
    tc: "",
    birthDate: "",
    yearlyLeaveDate: "",
    photo: "",
    password: "",
    newPassword: "",
    email: "",
    phone: "",
    gender: true,
    hourlyCost: 0,
    yearlyLeaveCount: 0,
    roleIds: [],
  };
  const [field, setField] = useState(fields);

  const errors = {
    tcErr: "",
    nameError: "",
    emailError: "",
    mobileError: "",
    passwordError: "",
    passwordConfirmateError: "",
    birthDateError: "",
    yearlyLeaveDateError: "",
    hourlyCostError: "",
    yearlyLeaveCountError: "",
  };
  const [err, setErr] = useState(errors);

  let validate = () => {
    if (field.name.length < 3 || field.name.length > 50) {
      setErr({ ...errors, nameError: trans.toShort });
      return false;
    }

    // if (field.tc.length !== 11) {
    //     setErr({ ...errors, tcErr: trans.pleaseEnterValidCitizenNo })
    //     return false
    // }

    // if (field.birthDate === "" || field.birthDate === undefined) {
    //     setErr({ ...errors, birthDateError: trans.pleaseEnterValidDate })
    //     return false
    // }
    // if (field.yearlyLeaveDate === "" || field.yearlyLeaveDate === undefined) {
    //     setErr({ ...errors, yearlyLeaveDateError: trans.pleaseEnterValidDate })
    //     return false
    // }

    if (!field.email.includes("@") || field.email.length > 50) {
      setErr({ ...errors, mailError: trans.pleaseEnterValidEmailAddress });
      return false;
    }

    // if (field.phone.length < 10 || field.phone.length > 40) {
    //     setErr({ ...errors, mobileError: trans.pleaseEnterValidPhoneAddress })
    //     return false
    // }

    // if (field.hourlyCost === 0) {
    //     setErr({ ...errors, hourlyCostError: trans.pleaseEnterValid })
    //     return false
    // }

    // if (field.yearlyLeaveCount === 0) {
    //     setErr({ ...errors, yearlyLeaveCountError: trans.pleaseEnterValid })
    //     return false
    // }

    if (field.password !== null && field.password !== undefined && field.password !== "&&&&&&") {
      if (field.password.length < 5 || field.name.length > 40) {
        setErr({ ...errors, passwordError: trans.toShort });
        return false;
      }
    }
    if (field.password !== field.newPassword) {
      setErr({ ...errors, passwordConfirmateError: trans.passwordsdonotmatch });
      return false;
    }

    return true;
  };

  const handleTask = (task) => {
    setField({ ...field, roles: task });
  };

  const register = (e) => {
    e.preventDefault();
    if (validate()) {
      setErr(errors);
      var roleIds = current.item.roleIds;
      field.roles = null;
      field.roleDtos = null;
      dispatch(
        fetchEmployees(
          {},
          crud.update,
          {
            ...field,
            roleIds,
            photo:
              current.item.photo && (current.item.photo.includes("http:") || current.item.photo.includes("https:"))
                ? current.item.photo.replace(baseUrl, "")
                : current.item.photo,
          },
          trans
        )
      );
    }
  };

  const changeTab = (content) => {
    inside_menu.map((x) => (x.active = false));
    inside_menu.find((x) => x.content === content).active = true;
    dispatch({ type: insideMenuType, payload: inside_menu });

    switch (content) {
      case "leave":
        dispatch(fetchLeaves({ userId: params.id }, crud.list, {}, trans));
        break;
      case "inOut":
        dispatch(fetchInOuts({ userId: params.id }, crud.list, {}));
        break;
      case "overtime":
        dispatch(fetchOvertimes({ userId: params.id }, crud.list, {}));
        break;
      case "debit":
        dispatch(fetchdebits({ userId: params.id }, crud.list, {}, trans));
        break;
      case "payroll":
        dispatch(fetchpayrolls({ userId: params.id }, crud.list, {}));
        break;
      case "employeefile":
        dispatch(fetchEmployeeDocuments({ userId: params.id }, crud.list, {}));
        break;
      default:
        break;
    }
  };
  const selectMultiUserIds = (roleIds) => dispatch({ type: itemType, payload: { ...current.item, roleIds } });

  return (
    <Layout>
      {field && !loader.loading ? (
        <div className="employee_detail_container">
          <div className="profile_detail">
            {current && current.item && <Avatar />}
            <div className="profile_detail_items" style={{ width: "100%" }}>
              <h3>{field.name} </h3>
              <span>{field.roleDtos && field.roleDtos.length > 0 && field.roleDtos[0].name}</span>
            </div>
            <div className="profile_detail_items">
              <span>{field.email}</span>
              <span>{field.phone}</span>
            </div>
          </div>
          <div className="employee_detail_right">
            <ul className="inside_menu">
              {inside_menu.map((m, i) => {
                return (
                  <EmployeeDetailInsideMenu id={params.id} key={i} changeTab={changeTab} active={m.active} content={m.content} name={m.name[trans.lang]} />
                );
              })}
            </ul>
            <div className="employee_detail_content">
              {inside_menu.find((x) => x.content === "personal").active === true ? (
                <div className="personal">
                  <div className="employee_detail_content_left">
                    <Input
                      placeholder={trans.name + ", " + trans.surname}
                      name={trans.name}
                      header={trans.name + ", " + trans.surname}
                      value={field.name}
                      onChange={(e) => setField({ ...field, name: e.target.value })}
                      error={err.nameError}
                    />
                    <Input
                      placeholder={trans.citizenNo}
                      type={"number"}
                      name={trans.citizenNo}
                      header={trans.citizenNo}
                      value={field.tc}
                      onChange={(e) => {
                        setField({ ...field, tc: e.target.value });
                        if (e.target.value.length === 11) {
                          dispatch(fetchTc(e.target.value));
                        } else {
                          dispatch({ type: tcType, payload: false });
                          setErr({ ...errors, tcErr: trans.pleaseEnterValidCitizenNo });
                        }
                      }}
                      success={current.tcconfirm}
                      error={!current.tcconfirm ? err.tcErr : ""}
                    />

                    <div className="input-container ">
                      <span className="input_header">{trans.task}</span>
                      {/* <Select
                                                defaultValue={(field.roles !== null && field.roles !== undefined && field.roles.length > 0) ? field.roles : null}
                                                options={positions}
                                                onChange={handleTask}
                                                isMulti
                                                placeholder={trans.select} /> */}

                      {positions && positions.length > 0 ? (
                        <Select
                          mode="multiple"
                          allowClear
                          maxTagCount={3}
                          maxTagTextLength={4}
                          className={`${err.roleError ? "input-error" : ""}`}
                          optionFilterProp="children"
                          onChange={selectMultiUserIds}
                          style={{ width: "100%" }}
                          placeholder={trans.personals}
                          filterOption={(input, option) =>
                            option.children ? option.children.toLocaleLowerCase("tr").indexOf(input.toLocaleLowerCase("tr")) >= 0 : false
                          }
                          value={current.item.roleIds}
                        >
                          {positions.map((l, i) => {
                            // //
                            return (
                              <Option key={"role" + i} value={l.id}>
                                {l.name}
                              </Option>
                            );
                          })}
                        </Select>
                      ) : null}
                    </div>
                    <div className="gender">
                      <Button
                        icon={<ManIcon color={field.gender ? "#fff" : null} />}
                        classes={field.gender ? "button dark" : "button"}
                        onClick={() => setField({ ...field, gender: true })}
                      >
                        {trans.man}
                      </Button>
                      <Button
                        icon={<WomanIcon color={!field.gender ? "#fff" : null} />}
                        classes={!field.gender ? "button dark" : "button"}
                        onClick={() => setField({ ...field, gender: false })}
                      >
                        {trans.woman}
                      </Button>
                    </div>
                    <Input
                      type={"date"}
                      header={trans.birthdate}
                      value={field.birthDate}
                      onChange={(e) => setField({ ...field, birthDate: e.target.value })}
                      error={err.birthDateError}
                    />
                    <Input
                      placeholder={trans.email}
                      name={trans.email}
                      header={trans.email}
                      value={field.email}
                      onChange={(e) => setField({ ...field, email: e.target.value })}
                      error={err.mailError}
                    />
                  </div>
                  <div className="employee_detail_content_right">
                    <Input
                      placeholder={"5xxxxxxx"}
                      type={"text"}
                      name={trans.mobile}
                      header={trans.mobile}
                      value={field.phone}
                      onChange={(e) => setField({ ...field, phone: e.target.value })}
                      error={err.mobileError}
                    />
                    <Input
                      placeholder={trans.hourlycost}
                      readOnly
                      type={"number"}
                      name={trans.hourlycost}
                      header={trans.hourlycost}
                      value={field.hourlyCost}
                      onChange={(e) => setField({ ...field, hourlyCost: parseFloat(e.target.value) })}
                      error={err.hourlyCostError}
                    />

                    {/* <Input placeholder={trans.leavedaycount}
                                        readOnly
                                        type={"number"}
                                        name={trans.leavedaycount}
                                        header={trans.leavedaycount}
                                        value={field.yearlyLeaveCount}
                                        onChange={e => setField({ ...field, yearlyLeaveCount: e.target.value })}
                                        error={err.yearlyLeaveCountError} />
                                    <Input
                                        readOnly
                                        type={"date"}
                                        header={trans.yearlyHolidayStartDate}
                                        value={field.yearlyLeaveDate}
                                        onChange={e => setField({ ...field, yearlyLeaveDate: e.target.value })}
                                        error={err.yearlyLeaveDateError} /> */}

                    <Input
                      placeholder={trans.password}
                      type={"password"}
                      name={trans.password}
                      header={trans.password}
                      value={field.password}
                      onChange={(e) => setField({ ...field, password: e.target.value })}
                      error={err.passwordError}
                    />
                    <Input
                      placeholder={trans.confirmPassword}
                      type={"password"}
                      name={trans.confirmPassword}
                      header={trans.confirmPassword}
                      value={field.newPassword}
                      onChange={(e) => setField({ ...field, newPassword: e.target.value })}
                      error={err.passwordConfirmateError}
                    />
                    <Button classes={"btn-primary"} onClick={register}>
                      {trans.update}
                    </Button>
                  </div>
                </div>
              ) : null}
              {inside_menu.find((x) => x.content === "leave").active === true ? <LeaveTab employeeid={parseFloat(params.id)} isProfile /> : null}
              {inside_menu.find((x) => x.content === "inOut").active === true ? <InOutTab employeeid={parseFloat(params.id)} isProfile /> : null}
              {inside_menu.find((x) => x.content === "overtime").active === true ? <OvertimeTab employeeid={parseFloat(params.id)} isProfile /> : null}
              {inside_menu.find((x) => x.content === "debit").active === true ? <DebitTab employeeid={parseFloat(params.id)} /> : null}
              {inside_menu.find((x) => x.content === "payroll").active === true ? <PayrollTab employeeid={parseFloat(params.id)} /> : null}
              {inside_menu.find((x) => x.content === "employeefile").active === true ? <EmployeeFileTab employeeid={parseFloat(params.id)} /> : null}
            </div>
          </div>
        </div>
      ) : (
        <ContentLoader
          preserveAspectRatio="none"
          viewBox="0 0 1000 600"
          height={600}
          width={"100%"}
          speed={1}
          backgroundColor={theme === "dark" ? "#666666" : "#efefef"}
          foregroundColor={theme === "dark" ? "#222222" : "#cccccc"}
        >
          <rect x="0" y="56" rx="2" ry="2" width="1000" height="211" />
          <rect x="0" y="300" rx="2" ry="2" width="1000" height="211" />
        </ContentLoader>
      )}
    </Layout>
  );
}
