import { eventType, addeventType, updateeventType } from '../action_types'
const reducer = (state = [], action) => {
    switch (action.type) {
        case eventType:
            return action.payload;
        case addeventType:
            return [action.payload, ...state];
        case updateeventType:
            if (state && state.length > 0) {
                for (let i = 0; i < state.length; i++) {
                    if (state[i].id === action.payload.id) {
                        state[i] = action.payload
                    }
                }
                return state
            } else return state;
        default:
            return state;
    }
};
export default reducer;