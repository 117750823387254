import { customerListType, addCustomerType, newResponsibleType, customerMenus } from '../action_types'
const reducer = (state = {
    list: [],
    responsiblePersons: [],
    menus: [
        { id: 1, content: "detail", name: { tr: "Detay", en: "Detail" }, active: true },
        { id: 2, content: "event", name: { tr: "Olay", en: "Event" }, active: false },
        { id: 3, content: "file", name: { tr: "Dosyalar", en: "Files" }, active: false }
    ]
}, action) => {
    switch (action.type) {
        case customerListType:
            return { ...state, list: action.payload };
        case addCustomerType:
            return { ...state, list: [action.payload, ...state.list] };
        case newResponsibleType:
            return { ...state, responsiblePersons: action.payload.responsiblePersons }
        case customerMenus:
            return { ...state, menus: action.payload }
        default:
            return state;
    }
};
export default reducer;