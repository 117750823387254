import React, { useEffect, useState } from "react";
import Layout from "../../components/Container/Layout";
import firm from "../../images/firm.png";
import { useDispatch, useSelector } from "react-redux";
import Switch from "../../components/anBrains/Switch";
// import Pagination from '../../components/anBrains/Pagination';
import Button from "../../components/anBrains/Button";
import Input from "../../components/anBrains/Input";
import { barListType, gridListType, projectType, projectGroupType, boardListType, breadcrumbType, addBreadcrumbType } from "../../redux/action_types";
import Modal from "../../components/anBrains/Modal";
import { PlusIcon, GridIcon, BarIcon, SortIcon, Search2Icon, BoardViewIcon, TrushIcon } from "../../components/icons";
import ContentLoader from "react-content-loader";
import { success } from "../../redux/actions/loader_ac";
import { baseUrl } from "../../config";
import { crud, ProjectStatus } from "../../config/constants";
import { fetchProject } from "../../redux/actions/project_ac";
import { fetchProjectGroup } from "../../redux/actions/project_group_ac";
import ProjectForm from "../../components/project/ProjectForm";
import ProjectBoardView from "../../components/project/ProjectBoardView";
import { useHistory } from "react-router-dom";
import manprofile from "../../images/profiles/man.png";
import womanprofile from "../../images/profiles/woman.png";
import { Pagination } from "antd";
export default function ProjectManagement(props) {
  //  const { staticcontext } = props
  const dispatch = useDispatch();
  const trans = useSelector((state) => state.trans);
  const theme = useSelector((state) => state.theme);
  const current = useSelector((state) => state.current);
  const project = useSelector((state) => state.project);
  const projectgroup = useSelector((state) => state.projectgroup);
  const [isAddEmployeeOpen, setIsAddEmployeeOpen] = useState(false);
  const [isDeleteEmployeeOpen, setIsDeleteEmployeeOpen] = useState(false);
  const [item, setItem] = useState({});
  const [request, setRequest] = useState({ rowCount: 14, pageCount: 0, search: "", sort: "id", desc: true, isProject: true });
  const loader = useSelector((state) => state.loader);
  const { breadcrumbs } = useSelector((state) => state.current);
  const result = useSelector((state) => state.result);

  useEffect(() => {
    dispatch(fetchProjectGroup({ rowCount: 20, pageCount: 0, search: "", sort: "id", desc: true, isProject: true }, crud.with, {}, trans));
  }, []);

  useEffect(() => {
    if (projectgroup.list.length > 0) {
      projectgroup.list[0].selected = true;
      setRequest({ ...request, projectGroupId: projectgroup.list[0].id });
    }
  }, [projectgroup.list]);

  const changeStatus = (a, e) => {
    var updateItem = project.list.find((x) => x.id === e.id);
    project.list.find((x) => x.id === e.id).isViewPano = a.target.checked;
    dispatch({ type: projectType, payload: project.list });
    dispatch(fetchProject(request, crud.update, updateItem, trans));
  };
  const showBar = () => {
    dispatch({ type: barListType });
  };
  const showGrid = () => {
    dispatch({ type: gridListType });
  };

  const showBoard = () => {
    dispatch({ type: boardListType });
    //console.log("more board projects");
    //
    dispatch(fetchProject({ ...request, isKanban: true }, crud.list, {}, trans));
  };

  const [selectedPage, setSelectedPage] = useState(1);
  const deleteConfirm = (e) => {
    setIsDeleteEmployeeOpen(true);
    setItem(e);
  };

  const deleteItem = () => {
    setIsDeleteEmployeeOpen(false);
    dispatch(fetchProject(request, crud.delete, item, trans));
    let projects = project.list.filter((e) => {
      return e.id !== item.id;
    });
    dispatch({ type: projectType, payload: projects });
  };

  const setSelectedPageFilter = (n) => {
    setSelectedPage(n);
    setRequest({ ...request, pageCount: n - 1 });
    dispatch(fetchProject({ ...request, pageCount: n - 1 }, crud.list, {}, trans));
  };
  const [desc, setDesc] = useState(false);
  const sortbyField = (f) => {
    setRequest({ ...request, sort: f, desc });
    dispatch(fetchProject({ ...request, sort: f, desc }, crud.list, {}, trans));
    setDesc(!desc);
  };

  const searchChange = (text) => {
    setSearchText(text);
    if (text.length > 2) {
      setSelectedPage(1);
      dispatch(fetchProject({ ...request, search: text, pageCount: 0, projectGroupId: 0 }, crud.list, {}, trans));
      //projectgroup.list.map(x => x.selected = false);
      dispatch({ type: projectGroupType, payload: projectgroup.list });
    }
  };

  const searchChangeKeyPress = (text, kcode) => {
    if (kcode === 8) {
      dispatch(fetchProject({ ...request, search: text }, crud.list, {}, trans));
    }
  };

  const history = useHistory();
  const findProject = (id) => {
    //
    //If projects doesnt exist on projects list because of Kanban listing we will look on boardlist on porject
    var selectedItem = project.list.find((x) => x.id === id);

    if (selectedItem) {
      selectRow(project.list.find((x) => x.id === id));
    } else {
      project.boardlist.lanes.map((e, i) => {
        //
        //selectedItem = e.cards.find(x => x.id === id);
        if (e.cards.find((x) => x.id === id)) {
          selectedItem = e.cards.find((x) => x.id === id);
        }
      });
      selectRow(selectedItem);
    }
  };
  const selectRow = (e) => {
    //
    if (breadcrumbs && breadcrumbs.some((b) => b.name === e.name)) {
    }
    if (breadcrumbs.length > 1) {
      var newBreadCrumbs = [];
      newBreadCrumbs.push(breadcrumbs[0]);
      newBreadCrumbs.push({
        name: e.name,
        path: "/projects/project-detail" + e.id,
      });
      dispatch({
        type: breadcrumbType,
        payload: newBreadCrumbs,
      });
    } else {
      dispatch({
        type: addBreadcrumbType,
        payload: { name: e.name, path: "/projects/project-detail" + e.id },
      });
    }
    history.push("/projects/project-detail/" + e.id);
  };
  useEffect(() => {
    if (result.success === true) {
      setIsAddEmployeeOpen(false);
      dispatch(success(false));
    }
  }, [result.success]);
  const [searchText, setSearchText] = useState("");

  const selectProjectGroup = (i) => {
    for (let index = 0; index < projectgroup.list.length; index++) projectgroup.list[index].selected = false;

    projectgroup.list[i].selected = true;
    dispatch({ type: projectGroupType, payload: projectgroup.list });
    setRequest({ ...request, projectGroupId: projectgroup.list[i].id, pageCount: 0 });
    setSelectedPage(1);
    dispatch(fetchProject({ ...request, projectGroupId: projectgroup.list[i].id, pageCount: 0 }, crud.list, {}, trans));
  };

  useEffect(() => {
    if (current.board === true) {
      //showBoard()
    }
  }, [result.change]);

  const renderProjectStatus = (s) => {
    return <div className={"statuscircle"} style={{ backgroundColor: ProjectStatus[s].color }}></div>;
  };

  return (
    <Layout>
      <Modal isOpen={isAddEmployeeOpen} withClose setIsOpen={setIsAddEmployeeOpen} classes={"modal_add_employee"}>
        <ProjectForm projectGroupId={request.projectGroupId} />
      </Modal>
      <Modal isOpen={isDeleteEmployeeOpen} withClose setIsOpen={setIsDeleteEmployeeOpen} classes={"modal_delete"}>
        {trans.areyousureyouwanttodelete}
        <Button classes="btn-red" onClick={() => deleteItem()}>
          {trans.confirm}
        </Button>
      </Modal>
      <div className="table_properties">
        <Input
          icon={<Search2Icon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "#1a9bc4"} />}
          placeholder={trans.search}
          value={searchText}
          onChange={(e) => searchChange(e.target.value)}
          onKeyUp={(e) => searchChangeKeyPress(e.target.value, e.keyCode)}
        />
        <Button
          icon={<PlusIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "#1a9bc4"} />}
          classes="btn-basic"
          onClick={() => setIsAddEmployeeOpen(true)}
        >
          {trans.add}
        </Button>
        <div className="bar_grid">
          <button onClick={showBar}>
            <BarIcon color={current.bar ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)") : theme === "dark" ? "#e5e5e5" : "#5d5d5d"} />
          </button>
          <button onClick={showGrid}>
            <GridIcon color={current.grid ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "#1a9bc4") : theme === "dark" ? "#e5e5e5" : "#5d5d5d"} />
          </button>
          <button onClick={showBoard}>
            <BoardViewIcon color={current.board ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "#1a9bc4") : theme === "dark" ? "#e5e5e5" : "#5d5d5d"} />
          </button>
        </div>
      </div>

      {current.bar &&
        (!loader.loading ? (
          <div className="project_container">
            <div className="project_group">
              <h2>{trans.projectGroup}</h2>
              <div className="project_group_container">
                <ul>
                  {projectgroup &&
                    projectgroup.list &&
                    projectgroup.list.length > 0 &&
                    projectgroup.list.map((pg, i) => {
                      return (
                        <li onClick={() => selectProjectGroup(i)} className={pg.selected ? "active" : ""} key={"project_group_" + i}>
                          {" "}
                          {pg.name}{" "}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div className="project_view">
              <div className="table">
                <table>
                  <thead>
                    <tr>
                      <th>{trans.status}</th>
                      <th onClick={() => sortbyField("code")}>
                        {trans.code}
                        <SortIcon color="rgba(96, 168, 221, 1)" />
                      </th>
                      <th onClick={() => sortbyField("name")}>
                        {trans.projectName}
                        <SortIcon color="rgba(96, 168, 221, 1)" />
                      </th>
                      <th>{trans.eventCount}</th>
                      <th>{trans.firm}</th>
                      <th onClick={() => sortbyField("createDate")}>
                        {trans.startdate}
                        <SortIcon color="rgba(96, 168, 221, 1)" />
                      </th>
                      <th onClick={() => sortbyField("modifyDate")}>
                        {trans.lastupdateddate}
                        <SortIcon color="rgba(96, 168, 221, 1)" />
                      </th>
                      <th>{trans.pano}</th>
                      <th>{trans.settings}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {project.list &&
                      project.list.length > 0 &&
                      project.list.map((e, i) => {
                        return (
                          <tr className="row" key={i}>
                            <td title={e && e.status && ProjectStatus && ProjectStatus[e.status][trans.lang]} onClick={() => selectRow(e)}>
                              {renderProjectStatus(e.status)}
                            </td>
                            <td onClick={() => selectRow(e)}>{e.code}</td>
                            <td onClick={() => selectRow(e)}>{e.name}</td>
                            <td onClick={() => selectRow(e)}> {e.eventCount}</td>
                            <td onClick={() => selectRow(e)}>{e.customerName}</td>
                            <td onClick={() => selectRow(e)}>{e.createDateViewString}</td>
                            <td onClick={() => selectRow(e)}>{e.modifyDateViewString}</td>
                            <td>
                              <Switch id={"projectStatusSwitch" + e.id} value={e.isViewPano} onChange={(a) => changeStatus(a, e)} />
                            </td>
                            {/* <td><CheckBox value={e.documentCompleted} /></td> */}
                            <td>
                              {/* <Link to={"/project-management/project-detail/" + e.id}
                                                            onClick={() => editItem(e)}>
                                                            <EditIcon color={(theme === "dark" ? "#e5e5e5" : "#000")} />
                                                        </Link> */}
                              <button onClick={() => deleteConfirm(e)}>
                                <TrushIcon color={theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)"} />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {/* {current && current.pageCount > 0 &&
                                <Pagination
                                    setSelectedPage={setSelectedPageFilter}
                                    selectedPage={selectedPage} />} */}

              {current && current.pageCount > 0 && (
                <Pagination
                  defaultCurrent={selectedPage}
                  onChange={setSelectedPageFilter}
                  onShowSizeChange={(pageCount, rowCount) => {
                    dispatch(fetchProject({ ...request, pageCount, rowCount }, crud.list, {}, trans));
                    setRequest({ ...request, pageCount, rowCount });
                  }}
                  total={current.pageCount * request.rowCount}
                />
              )}
            </div>
          </div>
        ) : (
          <div className="project_container">
            <div className="project_group">
              <h2>{trans.projectGroup}</h2>
              <div className="project_group_container">
                <ul>
                  {projectgroup &&
                    projectgroup.list &&
                    projectgroup.list.length > 0 &&
                    projectgroup.list.map((pg, i) => {
                      return (
                        <li onClick={() => selectProjectGroup(i)} className={pg.selected ? "active" : ""} key={"project_group_" + i}>
                          {" "}
                          {pg.name}{" "}
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div className="project_view">
              <div className="table">
                <table>
                  <thead>
                    <tr>
                      <th>{trans.status}</th>
                      <th onClick={() => sortbyField("code")}>
                        {trans.code}
                        <SortIcon color="rgba(96, 168, 221, 1)" />
                      </th>
                      <th onClick={() => sortbyField("name")}>
                        {trans.projectName}
                        <SortIcon color="rgba(96, 168, 221, 1)" />
                      </th>
                      <th>{trans.eventCount}</th>
                      <th>{trans.firm}</th>
                      <th>{trans.startdate}</th>
                      <th>{trans.lastupdateddate}</th>
                      <th>{trans.pano}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="row">
                      <td colSpan={8}>
                        <ContentLoader
                          preserveAspectRatio="none"
                          backgroundColor={theme === "dark" ? "#666666" : "#efefef"}
                          foregroundColor={theme === "dark" ? "#222222" : "#cccccc"}
                          viewBox="0 0 1000 60"
                          height={60}
                          width="100%"
                          style={{ width: "100%" }}
                          speed={2}
                          {...props}
                        >
                          <rect x="110" y="21" rx="4" ry="4" width="754" height="6" />
                          <rect x="111" y="41" rx="3" ry="3" width="185" height="7" />
                          <rect x="304" y="-46" rx="3" ry="3" width="350" height="6" />
                          <rect x="371" y="-45" rx="3" ry="3" width="380" height="6" />
                          <rect x="484" y="-45" rx="3" ry="3" width="201" height="6" />
                        </ContentLoader>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ))}
      {current.grid && (
        <div className="project_container">
          <div className="project_group">
            <h2>{trans.projectGroup}</h2>
            <div className="project_group_container">
              <ul>
                {projectgroup &&
                  projectgroup.list &&
                  projectgroup.list.length > 0 &&
                  projectgroup.list.map((pg, i) => {
                    return (
                      <li onClick={() => selectProjectGroup(i)} className={pg.selected ? "active" : ""} key={"project_group_" + i}>
                        {" "}
                        {pg.name}{" "}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className="project_view">
            <div className="grid_container">
              {!loader.loading ? (
                project &&
                project.list.length > 0 &&
                project.list.map((e, i) => {
                  return (
                    <div className="grid_card grid_card_border" style={{ borderLeftColor: ProjectStatus[e.status].color }} key={"grid_card" + i}>
                      <div className="grid_card_profile_img_container">
                        {e.photo && e.photo.length > 5 ? (
                          <img src={e.photo ? baseUrl + e.photo : e && e.gender ? manprofile : womanprofile} alt="" className="grid_card_profile_img" />
                        ) : (
                          <img src={firm} alt="" className="grid_card_profile_img" />
                        )}
                      </div>
                      <div className="grid_card_information_container">
                        <div className="grid_card_information" onClick={() => selectRow(e)}>
                          <span className="grid_card_name">{e.name}</span>
                          <span className="grid_card_position">
                            <span className="badge">{e.customerName}</span>
                          </span>
                          <span className="grid_card_phone"> {e.modifyDateViewString}</span>
                          <span className="grid_card_code">{e.code}</span>
                        </div>
                        <div className="grid_card_settings">
                          <div>
                            {trans.pano}
                            <Switch id={"projectStatusSwitch" + e.id} value={e.isViewPano} onChange={(a) => changeStatus(a, e)} />
                          </div>
                        </div>
                      </div>
                      {/* <div className="grid_card_edit_buttons">
                                            <Link to={"/project-management/project-detail/" + e.id}
                                                onClick={() => editItem(e)}>
                                                <EditIcon color={(theme === "dark" ? "#e5e5e5" : "#000")} />
                                            </Link>
                                            <button onClick={() => deleteConfirm(e)}>
                                                <TrushIcon
                                                    color={(theme === "dark" ? "#e5e5e5" : "#000")}
                                                /></button>
                                        </div> */}
                    </div>
                  );
                })
              ) : (
                <ContentLoader
                  preserveAspectRatio="none"
                  viewBox="0 0 1000 600"
                  height={600}
                  width={"100%"}
                  speed={1}
                  backgroundColor={theme === "dark" ? "#666666" : "#efefef"}
                  foregroundColor={theme === "dark" ? "#222222" : "#cccccc"}
                >
                  <rect x="0" y="56" rx="2" ry="2" width="1000" height="211" />
                  <rect x="0" y="300" rx="2" ry="2" width="1000" height="211" />
                </ContentLoader>
              )}
            </div>
            {current && current.pageCount > 0 && (
              <Pagination
                defaultCurrent={selectedPage}
                onChange={setSelectedPageFilter}
                onShowSizeChange={(pageCount, rowCount) => {
                  dispatch(fetchProject({ ...request, pageCount, rowCount }, crud.list, {}, trans));
                  setRequest({ ...request, pageCount, rowCount });
                }}
                total={current.pageCount * request.rowCount}
              />
            )}
          </div>
        </div>
      )}

      {current.board && (
        <div className="project_container">
          <div className="project_group">
            <h2>{trans.projectGroup}</h2>
            <div className="project_group_container">
              <ul>
                {projectgroup &&
                  projectgroup.list &&
                  projectgroup.list.length > 0 &&
                  projectgroup.list.map((pg, i) => {
                    return (
                      <li onClick={() => selectProjectGroup(i)} className={pg.selected ? "active" : ""} key={"project_group_" + i}>
                        {" "}
                        {pg.name}{" "}
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className="project_view">
            {
              // !loader.loading ?
              project.boardlist.lanes.length > 0 && <ProjectBoardView selectRow={findProject} trello={project.boardlist} />
              // : <ContentLoader
              //         preserveAspectRatio="none"
              //         viewBox="0 0 1000 600"
              //         height={600}
              //         width={"100%"}
              //         speed={1}
              //         backgroundColor={theme === "dark" ? "#666666" : "#efefef"}
              //         foregroundColor={theme === "dark" ? "#222222" : "#cccccc"}>
              //         <rect x="0" y="56" rx="2" ry="2" width="1000" height="211" />
              //         <rect x="0" y="300" rx="2" ry="2" width="1000" height="211" />
              //     </ContentLoader>
            }
          </div>
        </div>
      )}
    </Layout>
  );
}
