import React from 'react'
import video from '../../images/login.mp4';
import { useSelector } from 'react-redux'

export default function Expired() {
    const trans = useSelector(state => state.trans)
    return (
        <div className="login_expired_modal">
            {/* <video style={{ maxWidth: "50%;" }} src={video} autoPlay muted loop> </video> */}
            <div>
                <h2>{trans && trans.expiredUser}</h2>
                <h1>{trans && trans.expiredTime}</h1>
                <h1>{trans && trans.expiredContact}</h1>
                <h1><a href="https://timezeta.com/bize-ulasin/" target="_blank">
                    {trans && trans.expiredContactlink}</a></h1>
            </div>
        </div>
    )
}
