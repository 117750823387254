import { overtimeType, totalhourType, addOvertimeType } from '../action_types'
const reducer = (state = { list: [], totalhour: 0 }, action) => {
    switch (action.type) {
        case overtimeType:
            return { ...state, list: action.payload };
        case addOvertimeType:
            return { ...state, list: [...state.list, action.payload] };
        case totalhourType:
            return { ...state, totalhour: action.payload };
        default:
            return state;
    }
};
export default reducer;