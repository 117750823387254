import React, { useEffect, useState } from "react";
import Layout from "../../components/Container/Layout";
import { useDispatch, useSelector } from "react-redux";
import { crud } from "../../config/constants";
import { autorizationType } from "../../redux/action_types";
import Button from "../../components/anBrains/Button";
import Input from "../../components/anBrains/Input";
import { Search2Icon, PlusIcon, SortIcon, EditIcon, TrushIcon, SettingsIcon } from "../../components/icons";
import { success } from "../../redux/actions/loader_ac";
import Modal from "../../components/anBrains/Modal";
import Switch from "../../components/anBrains/Switch";
import ContentLoader from "react-content-loader";
import { fetchAuthorization, fetchChangeAuthorization } from "../../redux/actions/authorization_ac";
import { Pagination } from "antd";

export default function Authorization(props) {
  const dispatch = useDispatch();
  const trans = useSelector((state) => state.trans);
  const theme = useSelector((state) => state.theme);
  const current = useSelector((state) => state.current);
  const result = useSelector((state) => state.result);
  const authorization = useSelector((state) => state.authorization);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isChangeOpen, setIsChangeOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [item, setItem] = useState({});
  const [request, setRequest] = useState({ rowCount: 14, pageCount: 0, search: "", sort: "id", desc: false, data: {} });
  const loader = useSelector((state) => state.loader);

  useEffect(() => {
    dispatch(fetchAuthorization(request, crud.list, {}, trans));
    if (result.success === true) {
      setIsChangeOpen(false);
      dispatch(success(false));
    }
  }, [result.change]);

  const changeStatus = (a, e) => {
    console.log(a.target.value, e);
    let updateItem = {};
    for (let index = 0; index < authorization.list.length; index++) {
      const selectedItem = authorization.list[index];
      if (selectedItem.id === e.id) {
        authorization.list[index].active = a.target.checked;
        updateItem = authorization.list[index];
      }
    }
    dispatch({ type: autorizationType, payload: authorization.list });
    dispatch(fetchAuthorization(request, crud.update, updateItem, trans));
  };

  const [selectedPage, setSelectedPage] = useState(1);
  const deleteConfirm = (e) => {
    setIsDeleteOpen(true);
    setItem(e);
  };

  const deleteItem = () => {
    setIsDeleteOpen(false);
    dispatch(fetchAuthorization(request, crud.delete, item, trans));
    let authorizations = authorization.list.filter((e) => {
      return e.id !== item.id;
    });
    dispatch({ type: autorizationType, payload: authorizations });
  };

  const setSelectedPageFilter = (n) => {
    setSelectedPage(n);
    setRequest({ ...request, pageCount: n - 1 });
    dispatch(fetchAuthorization({ ...request, pageCount: n - 1 }, crud.list, {}, trans));
  };
  const [desc, setDesc] = useState(false);
  const sortbyField = (f) => {
    setRequest({ ...request, sort: f, desc });
    dispatch(fetchAuthorization({ ...request, sort: f, desc }, crud.list, {}, trans));
    setDesc(!desc);
  };

  const searchChange = (text) => {
    setSearchText(text);
    if (text.length > 2) {
      dispatch(fetchAuthorization({ ...request, search: text }, crud.list, {}, trans));
    }
  };

  const searchChangeKeyPress = (text, kcode) => {
    if (kcode === 8) {
      dispatch(fetchAuthorization({ ...request, search: text }, crud.list, {}, trans));
    }
  };

  useEffect(() => {
    if (result.success === true) {
      setIsAddOpen(false);
      dispatch(success(false));
    }
  }, [result.change]);

  const [searchText, setSearchText] = useState("");

  var fields = {
    type: 0,
    name: "",
  };
  const [field, setField] = useState(fields);

  const errors = {
    nameErr: "",
  };
  const [err, setErr] = useState(errors);

  let validate = () => {
    if (field.name.length < 3 || field.name.length > 30) {
      setErr({ ...errors, nameErr: trans.toShort });
      return false;
    }
    return true;
  };

  const register = (e) => {
    e.preventDefault();
    if (validate()) {
      setErr(errors);
      if (!isEdit) dispatch(fetchAuthorization({}, crud.create, field, trans));
      else {
        dispatch(fetchAuthorization({}, crud.update, field, trans));
        setIsEdit(false);
      }
    }
  };

  const editItem = (e) => {
    console.log(e);
    setField(e);
    setIsAddOpen(true);
    setIsEdit(true);
  };
  const editAuth = (e) => {
    setRoleId(e.id);
    var menus = authorization.menus;
    console.log(authorization.menus);
    console.log(e);
    for (let index = 0; index < menus.length; index++) {
      const m = menus[index];
      if (e.menus.some((x) => x.id === m.id)) m.active = true;
      else m.active = false;

      if (m.subs != null) {
        for (let si = 0; si < e.menus.length; si++) {
          if (menus[index].subs.some((x) => x.id === e.menus[si].id)) {
            menus[index].subs.find((x) => x.id === e.menus[si].id).active = true;
          }
        }
      }

      // if (m.subs != null && !(m.subs.some(x => x.active === false))) {
      //     menus[index].active = true;
      // }
    }
    setMenusByRole(menus);
    setIsChangeOpen(true);
  };

  const [isEdit, setIsEdit] = useState(false);

  const changeAuth = (e) => {
    var addList = [];

    var removeList = [];

    for (let index = 0; index < menusByRole.length; index++) {
      const parent = menusByRole[index];

      if (parent.active) addList.push({ roleId: roleId, menuId: parent.id });
      else removeList.push({ roleId: roleId, menuId: parent.id });

      if (parent.subs != null && parent.subs.length > 0) {
        for (let i = 0; i < parent.subs.length; i++) {
          const sub = parent.subs[i];
          if (sub.active) addList.push({ roleId: roleId, menuId: sub.id });
          else removeList.push({ roleId: roleId, menuId: sub.id });
        }
      }
    }

    var data = {
      addList,
      removeList,
    };
    dispatch(fetchChangeAuthorization(data, trans));
  };
  const changeAuthPerRow = (e, id) => {
    for (let index = 0; index < menusByRole.length; index++) {
      const menuitem = menusByRole[index];

      if (menuitem.id === id) {
        menusByRole[index].active = !menusByRole[index].active;
        if (menusByRole[index].subs !== null) {
          for (let si = 0; si < menusByRole[index].subs.length; si++) {
            menusByRole[index].subs[si].active = menusByRole[index].active === true ? true : false;
          }
        }
      }

      if (menuitem.subs != null && menuitem.subs.length > 0 && menuitem.subs.some((x) => x.id === id)) {
        for (let i = 0; i < menuitem.subs.length; i++) {
          const subitem = menuitem.subs[i];
          if (subitem.id === id) {
            menusByRole[index].subs[i].active = !menusByRole[index].subs[i].active;
          }
        }

        if (!menuitem.subs.some((x) => x.active === true)) {
          menusByRole[index].active = false;
        }

        if (!menuitem.subs.some((x) => x.active === false)) {
          menusByRole[index].active = true;
        }

        if (menusByRole[index].active === false && menuitem.subs.some((x) => x.active === true)) {
          menusByRole[index].active = true;
        }
      }
    }
    setMenusByRole([...menusByRole]);
  };

  const [menusByRole, setMenusByRole] = useState([]);
  const [roleId, setRoleId] = useState(0);
  const openForm = () => {
    setField(fields);
    setIsAddOpen(true);
  };
  return (
    <Layout>
      <Modal isOpen={isChangeOpen} withClose setIsOpen={setIsChangeOpen} classes={"authModalContainer"}>
        <div className="authModal">
          {!loader.loading ? (
            menusByRole &&
            menusByRole.map((m, i) => {
              return (
                <div className="authModalRow" key={"authSwitch" + m.id}>
                  <div className="input-container">
                    <span className="input_header">{m[trans.lang]}</span>
                    <Switch classes="mediumSwitch" id={"authSwitch" + m.id} value={m.active} onChange={(e) => changeAuthPerRow(e, m.id)} />
                  </div>
                  {m.subs &&
                    m.subs.map((s, si) => {
                      return (
                        <div className="authModalSubRow" key={"authSwitch" + s.id}>
                          <div className="input-container">
                            <span className="input_header">{s[trans.lang]}</span>
                            <Switch classes="mediumSwitch" id={"authSwitch" + s.id} value={s.active} onChange={(e) => changeAuthPerRow(e, s.id)} />
                          </div>
                        </div>
                      );
                    })}
                </div>
              );
            })
          ) : (
            <ContentLoader
              preserveAspectRatio="none"
              backgroundColor={theme === "dark" ? "#666666" : "#efefef"}
              foregroundColor={theme === "dark" ? "#222222" : "#cccccc"}
              viewBox="0 0 1000 60"
              height={60}
              width="100%"
              style={{ width: "100%" }}
              speed={2}
            >
              <rect x="110" y="21" rx="4" ry="4" width="754" height="6" />
              <rect x="111" y="41" rx="3" ry="3" width="185" height="7" />
              <rect x="304" y="-46" rx="3" ry="3" width="350" height="6" />
              <rect x="371" y="-45" rx="3" ry="3" width="380" height="6" />
              <rect x="484" y="-45" rx="3" ry="3" width="201" height="6" />
            </ContentLoader>
          )}
          {!loader.loading && (
            <Button classes={"btn-primary"} onClick={changeAuth}>
              {trans.save}
            </Button>
          )}
        </div>
      </Modal>
      <Modal isOpen={isAddOpen} withClose setIsOpen={setIsAddOpen} classes={"modal_add_leave"}>
        <div className="leaveForm">
          <Input
            placeholder={trans.definition}
            name={trans.definition}
            header={trans.definition}
            value={field.name}
            minLength={5}
            maxLength={30}
            onChange={(e) => setField({ ...field, name: e.target.value })}
            error={err.nameErr}
          />

          <Button classes={"btn-primary"} onClick={register}>
            {trans.save}
          </Button>
        </div>
      </Modal>
      <Modal isOpen={isDeleteOpen} withClose setIsOpen={setIsDeleteOpen} classes={"modal_delete"}>
        {trans.areyousureyouwanttodelete}
        <Button classes="btn-red" onClick={() => deleteItem()}>
          {trans.confirm}
        </Button>
      </Modal>
      <div className="table_properties">
        <Input
          icon={<Search2Icon color="rgba(96, 168, 221, 1)" />}
          placeholder={trans.search}
          value={searchText}
          onChange={(e) => searchChange(e.target.value)}
          onKeyUp={(e) => searchChangeKeyPress(e.target.value, e.keyCode)}
        />
        <Button icon={<PlusIcon color="rgba(96, 168, 221, 1)" />} classes="btn-basic" onClick={() => openForm(true)}>
          {trans.add}
        </Button>
      </div>
      {!loader.loading ? (
        <div className="table">
          <table>
            <thead>
              <tr>
                <th onClick={() => sortbyField("name")}>
                  {trans.definition}
                  <SortIcon color="rgba(96, 168, 221, 1)" />
                </th>
                <th>{trans.status}</th>
                <th>{trans.settings}</th>
              </tr>
            </thead>
            <tbody>
              {authorization.list &&
                authorization.list.length > 0 &&
                authorization.list.map((e, i) => {
                  return (
                    <tr className="row" key={i}>
                      <td style={e.name === "Admin" ? { fontWeight: "bold" } : {}}>{e.name}</td>
                      <td>{e.name !== "Admin" ? <Switch id={"customerStatusSwitch" + e.id} value={e.active} onChange={(a) => changeStatus(a, e)} /> : null}</td>

                      <td>
                        {e.name !== "Admin" ? (
                          <>
                            <button onClick={() => editAuth(e)}>
                              <SettingsIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />
                            </button>
                            <button onClick={() => editItem(e)}>
                              <EditIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />
                            </button>
                            <button onClick={() => deleteConfirm(e)}>
                              <TrushIcon color={theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)"} />
                            </button>
                          </>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>{trans.definition}</th>
              <th>{trans.status}</th>
              <th>{trans.settings}</th>
            </tr>
          </thead>
          <tbody>
            <tr className="row">
              <td colSpan={4}>
                <ContentLoader
                  preserveAspectRatio="none"
                  backgroundColor={theme === "dark" ? "#666666" : "#efefef"}
                  foregroundColor={theme === "dark" ? "#222222" : "#cccccc"}
                  viewBox="0 0 1000 60"
                  height={60}
                  width="100%"
                  style={{ width: "100%" }}
                  speed={2}
                >
                  <rect x="110" y="21" rx="4" ry="4" width="754" height="6" />
                  <rect x="111" y="41" rx="3" ry="3" width="185" height="7" />
                  <rect x="304" y="-46" rx="3" ry="3" width="350" height="6" />
                  <rect x="371" y="-45" rx="3" ry="3" width="380" height="6" />
                  <rect x="484" y="-45" rx="3" ry="3" width="201" height="6" />
                </ContentLoader>
              </td>
            </tr>
          </tbody>
        </table>
      )}

      {current && current.pageCount > 0 && (
        <Pagination
          defaultCurrent={selectedPage}
          onChange={setSelectedPageFilter}
          onShowSizeChange={(pageCount, rowCount) => {
            dispatch(fetchAuthorization({ ...request, pageCount, rowCount }, crud.list, {}, trans));
            setRequest({ ...request, pageCount, rowCount });
          }}
          total={current.pageCount * request.rowCount}
        />
      )}
    </Layout>
  );
}
