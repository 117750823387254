import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../../components/anBrains/Button';
import { PlusIcon, TrushIcon, SortIcon } from '../icons';
import Modal from '../anBrains/Modal';
import { debitType } from '../../redux/action_types';
import { fetchdebits } from '../../redux/actions/debit_ac';
import Input from '../anBrains/Input';
import Pagination from '../anBrains/Pagination'
import { crud } from '../../config/constants';
import { success } from '../../redux/actions/loader_ac';
export default function DebitTab({ employeeid, isEmploye, isProfile }) {

    const trans = useSelector(state => state.trans)
    const debit = useSelector(state => state.debit)
    const theme = useSelector(state => state.theme)
    const result = useSelector(state => state.result)
    const current = useSelector(state => state.current)
    const dispatch = useDispatch()
    const [item, setItem] = useState({})
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)

    useEffect(() => {
        if (result.success) {
            setIsAddOpen(false);
            setField(fields)
            dispatch(success(false))
            var curr = new Date();
            curr.setDate(curr.getDate());
            var date = curr.toISOString().substr(0, 10);
            setField({ ...field, endDate: date, startDate: date })

        }
    }, [result.change])

    const deleteConfirm = (e) => {
        setIsDeleteOpen(true)
        setItem(e);
    }
    const deleteItem = () => {
        setIsDeleteOpen(false)
        dispatch(fetchdebits({}, crud.delete, item, trans))
        let debits = debit.filter((e) => {
            return e.id !== item.id
        });
        dispatch({ type: debitType, payload: debits })
    }

    const [isAddOpen, setIsAddOpen] = useState(false)
    const [listParameters, setListParameters] = useState({ rowCount: 14, pageCount: 0, sort: "id", desc: true, userId: employeeid }, crud.list, {})
    const [asc, setAsc] = useState(false)
    const sortbyField = (f) => {
        setListParameters({ ...listParameters, sort: f, desc: asc ? false : true })
        setAsc(!asc)
        dispatch(fetchdebits({ ...listParameters, sort: f, desc: asc ? false : true }));
    }

    var fields = {
        id: 0,
        userId: employeeid,
        name: ""
    }
    const [field, setField] = useState(fields)

    const errors = {
        nameErr: ""
    }
    const [err, setErr] = useState(errors)

    let validate = () => {
        if (field.name.length < 3 || field.name.length > 30) {
            setErr({ ...errors, nameErr: trans.toShort })
            return false
        }
        return true
    }
    const register = e => {
        e.preventDefault();
        if (validate()) {
            setErr(errors)
            dispatch(fetchdebits({}, crud.create, field, trans))
        }
    }


    const [selectedPage, setSelectedPage] = useState(1)
    const setSelectedPageFilter = (n) => {
        setSelectedPage(n)
        setListParameters({ ...listParameters, pageNumber: n - 1 })
        dispatch(fetchdebits({ ...listParameters, pageNumber: n - 1 }));
    }
    return (
        <>
            <div className="debit">
                <div className="table_properties">
                    {/* <Input icon={search} placeholder={trans.search} onChange={e => searchChange(e.target.value)} /> */}
                    <Button
                        icon={<PlusIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />}
                        classes="btn btn-basic"
                        onClick={() => setIsAddOpen(true)}>{trans.add}</Button>
                </div>
                <div className="table">
                    <table>
                        <thead>
                            <tr>
                                <th onClick={() => sortbyField("name")}>{trans.description}
                                    <SortIcon color="rgba(96, 168, 221, 1)" /></th>
                                <th>{trans.embezzler}</th>
                                <th onClick={() => sortbyField("createDate")}>{trans.date}
                                    <SortIcon color="rgba(96, 168, 221, 1)" /></th>
                                <th>{trans.settings}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {debit && debit.length > 0 && debit.map((e, i) => {
                                return (
                                    <tr className="row" key={e.id}>
                                        <td>{e.name}</td>
                                        <td>{e.setUserName}</td>
                                        <td >{e.createDateString}</td>
                                        <td>
                                            {/* <button onClick={() => editItem(e)}>
                                                <EditIcon color={(theme === "dark" ? "rgba(96, 168, 221, 1)" : "#000")} />
                                            </button> */}
                                            <button onClick={() => deleteConfirm(e)}>
                                                <TrushIcon
                                                    color={(theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)")}
                                                /></button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                </div>{current && current.pageCount > 0 &&
                    <Pagination
                        setSelectedPage={setSelectedPageFilter}
                        selectedPage={selectedPage} />}
            </div>
            <Modal
                isOpen={isAddOpen}
                withClose
                setIsOpen={setIsAddOpen}
                classes={"modal_add_leave"}>
                <div className="leaveForm">
                    <Input placeholder={trans.description}
                        name={trans.description}
                        header={trans.description}
                        value={field.name}
                        onChange={e => setField({ ...field, name: e.target.value })}
                        error={err.nameErr} />
                    <Button classes={"btn-primary"}
                        onClick={register}>{trans.save}</Button>
                </div>
            </Modal>
            <Modal
                isOpen={isDeleteOpen}
                withClose
                setIsOpen={setIsDeleteOpen}
                classes={"modal_delete"}>
                {trans.areyousureyouwanttodelete}
                <Button classes="btn-red" onClick={() => deleteItem()}>{trans.confirm}</Button>
            </Modal>
        </>
    )
}