import React from 'react'

export default function Input({
	icon,
	placeholder,
	name,
	onChange,
	type,
	classes,
	textarea,
	value,
	readOnly,
	error,
	autoFocus,
	autoComplate,
	maxLength,
	minLength,
	max,
	min,
	onFocus,
	onBlur,
	onKeyUp,
	header,
	defaultValue,
	success,
	onKeyPress,
	styles,
	width,
	rows,
	inputStyles
}) {
	// const [isFocused, setIsFocused] = useState(false)
	// const isErrorOccured = error ? error.type === name : false
	// //

	return (
		//${isFocused ? 'brd-blue' : ''}

		<div className={`input-container ${classes || ''}`}
			style={styles}>

			{/* {success && <p className="mb-2 green">{success}</p>} */}
			{header && <span className="input_header">{header}
				{/* {console.log(error)} */}
				{error && <p className="input_danger_message">{error}</p>}</span>}

			<div
				className={`flex ${textarea ? 'al-start textarea' : 'input al-center'} 
				${error ? 'input-error' : success ? 'input-success' : ''} 
				 ${readOnly ? 'read-only' : ''}`}>
				{icon}
				{textarea ? (
					<textarea
						readOnly={readOnly}
						placeholder={placeholder}
						onFocus={onFocus}
						onKeyPress={onKeyPress}
						onBlur={onBlur}
						name={name}
						onChange={onChange}
						value={value}
						autoFocus={autoFocus}
						rows={rows ? rows : 3}
					/>
				) : (
						<input
							readOnly={readOnly}
							type={type}
							placeholder={placeholder}
							onFocus={onFocus}
							onBlur={onBlur}
							name={name}
							onChange={onChange}
							value={value ?? ""}
							autoFocus={autoFocus}
							autoComplete={autoComplate}
							onKeyPress={onKeyPress}
							defaultValue={defaultValue}
							maxLength={maxLength}
							minLength={minLength}
							max={max}
							min={min}
							onKeyUp={onKeyUp}
							// style={width ? { width } : null}
							style={inputStyles}
						/>
					)}
			</div>
			{
				maxLength && value !== undefined && value !== null && (
					<span className="fs-xsm char-counter">
						{value.length}/{maxLength}
					</span>
				)
			}
		</div >
	)
}
