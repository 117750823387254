import {
    isLoaderType,
    isSpinType
} from '../action_types'
const reducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case isLoaderType:
            return { ...state, loading: !state.loading }
        case isSpinType:
            return { ...state, spinning: !state.spinning }
        default:
            return state
    }
}
export default reducer