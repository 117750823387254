import axios from 'axios'
import { axiosConfig, baseUrl } from '../../config'
import { pageCountType, addMeetingType, meetingType, updateMeetingType, itemType, meetingGroupType, labelType, employeeListType, lineListType,isLoginType } from '../action_types'
import { success, calculatePageNumber, loading } from './loader_ac'
import { crud } from '../../config/constants'
export const fetchMeeting = (request = { rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/Meetings`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            }).then(response => {
                dispacth(loading())
                if (response.data.success) {
                    switch (requestType) {
                        case crud.create:
                            dispacth({
                                type: addMeetingType,
                                payload: response.data.data,
                            })
                            dispacth(success(true))
                            break;
                        case crud.read:
                            break;
                        case crud.update:
                            dispacth({
                                type: itemType,
                                payload: response.data.data
                            })
                            dispacth(success(true))

                            break;
                        case crud.delete:
                            break;
                        case crud.list:
                            dispacth({
                                type: meetingType,
                                payload: response.data.data,
                            })
                            dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) })
                            dispacth(success(true))
                            break;
                        default:
                            break;
                    }
                }
            })
                .catch(reason => {
                    console.log();
                    dispacth(loading())
                  
                })
        } catch (error) {
            console.log(error)
            dispacth(loading())
        }
    }
}

export const fetchMeetingFiles = (data) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/Meetings/form`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data
            }).then(response => {
                dispacth(loading())
                if (response.data.success) {
                    //
                    dispacth({
                        type: addMeetingType,
                        payload: response.data.data,
                    })
                    dispacth({
                        type: itemType,
                        payload: response.data.data
                    })
                    dispacth(success(true))
                }
            }).catch(reason => {
                console.log();
                dispacth(loading())
            })
        } catch (error) {
            console.log(error)
            dispacth(loading())
        }
    }
}

export const fetchMeetingDetail = (request = { lang: "tr", rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/Meetings`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            })
                .then(response => {
                    dispacth(loading())
                    if (response.data.success) {
                        dispacth({ type: itemType, payload: response.data.data})
                        var options = [
                            {
                                label: request.lang === "tr" ? "Projeler" : "Projects",
                                options: []
                            },
                            {
                                label: request.lang === "tr" ? "Müşteriler" : "Customers",
                                options: []
                            },
                            {
                                label: request.lang === "tr" ? "Departmanlar" : "Departments",
                                options: []
                            },
                        ];

                        options[0].options = response.data.data2.projects;
                        options[1].options = response.data.data2.customers;
                        options[2].options = response.data.data2.departments;
                        dispacth({
                            type: lineListType,
                            payload: [...response.data.data2.projects, ...response.data.data2.customers, ...response.data.data2.departments]
                        })

                        dispacth({
                            type: labelType,
                            payload: options
                        })
                        dispacth({
                            type: employeeListType,
                            payload: response.data.data2.users
                        })
                        dispacth({
                            type: meetingGroupType,
                            payload: response.data.data2.groups
                        })
                    }
                })
                .catch(reason => {
                    
                    dispacth(loading())
                  
                    
                })
        } catch (error) {
            console.log(error)
            dispacth(loading())
        }
    }
}

export const fetchDeleteMeetingFiles = (request = { rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}) => {
    let headers = axiosConfig.headers
    let url = `${baseUrl}/api/MeetingFiles`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            }).then(response => {
                dispacth(loading())
                if (response.data.success) {
                    //dispacth({ type: itemType, payload: response.data.data })
                }
            }).catch(reason => {
                console.log();
                dispacth(loading())

               
            })
        } catch (error) {
            console.log(error)
            dispacth(loading())
        }
    }
}