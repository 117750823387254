import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import Input from '../../components/anBrains/Input'
import { useSelector, useDispatch } from 'react-redux'
import { fetchLogin, fetchEmail, fetchChangePassword } from '../../redux/actions/authorization_ac'
import video from '../../images/login.mp4';
import { LogoIcon } from '../../components/icons';

export default function ChangePassword(props) {
    const trans = useSelector(state => state.trans)
    const result = useSelector(state => state.result)
    const loader = useSelector(state => state.loader)
    const response = useSelector(state => state.response)
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        localStorage.setItem("token", props.match.params.key)
    }, [])

    useEffect(() => {
        if (response.success) {
            history.push("/")
        }
    }, [response.success])
    const fields = {
        password: "",
        newPassword: ""
    }
    const [field, setField] = useState(fields)

    const errors = {
        passwordError: "",
        newPasswordError: "",
    }
    const [err, setErr] = useState(errors)
    const [message, setMessage] = useState(null)
    let validate = () => {
        if (field.password.length < 5 || field.password.length > 30) {
            setErr({ ...errors, passwordError: trans.toShort })
            setMessage(trans.toShort)
            return false
        }

        if (field.password !== field.newPassword) {
            setErr({ ...errors, newPasswordError: trans.passwordsdonotmatch })
            setMessage(trans.passwordsdonotmatch)
            return false
        }
        return true
    }

    const onEnter = e => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (validate()) {
                setErr(errors)
                setMessage("")
                dispatch(fetchChangePassword(field))
            }
        }
    }

    const login = e => {
        e.preventDefault();
        if (validate()) {
            setErr(errors)
            setMessage("")
            dispatch(fetchChangePassword(field))
        }
    }
    return (
        <>
            <div className="login_container">
                <div className="login_image">
                    <video src={video} autoPlay muted loop> </video>
                    {/* <div className="overlay"></div> */}
                    <div className="login_title">
                        <h1>timezeta</h1>
                        <p> Timezeta, projelerin düşük maliyetle, zamanında ve  istenen kalitede tamamlanmasını sağlar.</p>
                    </div>
                    <div className="login_form">
                        <div className="login_form_inside"><h2>{trans.newPassword}</h2>
                            <p>Timezeta, ekiplerin birlikte daha verimli çalışmalarını sağlar.</p>
                            <Input
                                placeholder={trans.password}
                                type={"password"}
                                name={trans.password}
                                value={field.password}
                                onChange={e => setField({ ...field, password: e.target.value })}
                                error={err.passwordError}
                                readOnly={loader.loading}
                                onKeyPress={onEnter} />

                            <Input
                                placeholder={trans.confirmPassword}
                                type={"password"}
                                name={trans.confirmPassword}
                                value={field.newPassword}
                                onChange={e => setField({ ...field, newPassword: e.target.value })}
                                error={err.newPasswordError}
                                readOnly={loader.loading}
                                onKeyPress={onEnter} />

                            {loader.loading === false ?
                                <button onClick={login}>
                                    {trans.change}
                                </button> :
                                <button>
                                    {trans.changing}
                                </button>}
                            <div className="login_message">{message}</div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}
