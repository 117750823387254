import {
    budgetType,
    costTypeType,
    budgetDetailType,
    budgetItemType,
    addBudgetType,
    addBudgetDetailType, requestBudgetType, updateBudgetType, totalCostType
} from '../action_types'
const reducer = (state = { type: false, budgets: [], details: [], item: {}, request: { rowCount: 0, pageCount: 0, search: "", sort: "id", "desc": false, data: {} } }, action) => {
    switch (action.type) {
        case costTypeType:
            return { ...state, type: !state.type }
        case requestBudgetType:
            return { ...state, request: action.payload }
        case budgetType:
            return { ...state, budgets: action.payload, details: [] }
        case totalCostType:
            return { ...state, costDetail: action.payload, }
        case addBudgetType:
            state.budgets.push(action.payload)
            return state
        case updateBudgetType:
            var i = state.budgets.findIndex(a => a.id === action.payload.id);
            state.budgets[i] = action.payload;
            return state
        case budgetDetailType:
            return { ...state, details: action.payload }
        case addBudgetDetailType:
            state.details.push(action.payload);
            return state
        case budgetItemType:
            return { ...state, item: action.payload }
        default:
            return state
    }
}
export default reducer