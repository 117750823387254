import axios from 'axios'
import { axiosConfig, baseUrl, openNotification } from '../../config'
import { pageCountType, addWorkOrderGroupType, workOrderGroupType, updateWorkOrderGroupType, workorderType, 
    employeeListType, projectType, customerListType, departmentType, labelType, lineListType,isLoginType } from '../action_types'
import { success, calculatePageNumber, loading } from './loader_ac'
import { crud } from '../../config/constants'
export const fetchWorkOrderGroup = (request = { rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}, trans = undefined) => {
    let headers = axiosConfig.headers

    let url = `${baseUrl}/api/WorkOrderGroups`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            }).then(response => {
                dispacth(loading())
                if (response.data.success) {
                    switch (requestType) {
                        case crud.create:
                            dispacth({
                                type: addWorkOrderGroupType,
                                payload: response.data.data,
                            })
                            dispacth(success(true))
                            openNotification('success', trans.successTitle, trans.successBody, 3)
                            break;
                        case crud.read:
                            break;
                        case crud.update:
                            dispacth({
                                type: updateWorkOrderGroupType,
                                payload: response.data.data,
                            })
                            dispacth(success(true))
                            openNotification('success', trans.successTitle, trans.successBody, 3)
                            break;
                        case crud.delete:
                            openNotification('success', trans.successTitle, trans.successBody, 3)
                            break;
                        case crud.list:
                            dispacth({
                                type: workOrderGroupType,
                                payload: response.data.data,
                            })
                            dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) })
                            break;
                        case crud.with:
                            dispacth({
                                type: workorderType,
                                payload: response.data.data2.firstWorkOrders,
                            })
                            dispacth({
                                type: workOrderGroupType,
                                payload: response.data.data,
                            })
                            var options = [
                                {
                                    label: request.lang === "tr" ? "Projeler" : "Projects",
                                    options: response.data.data2.details.projects
                                },
                                {
                                    label: request.lang === "tr" ? "Müşteriler" : "Customers",
                                    options: response.data.data2.details.customers
                                },
                                {
                                    label: request.lang === "tr" ? "Departmanlar" : "Departments",
                                    options: response.data.data2.details.departments
                                },
                            ];

                            dispacth({
                                type: labelType,
                                payload: options
                            })

                            dispacth({
                                type: lineListType,
                                payload: [...response.data.data2.details.projects, ...response.data.data2.details.customers, ...response.data.data2.details.departments]
                            })

                            dispacth({
                                type: employeeListType,
                                payload: response.data.data2.details.users
                            })

                            dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) })

                            break;
                        default:
                            break;
                    }
                }
            })
                .catch(reason => {
                    
                    dispacth(loading())
                    openNotification('error', trans.errorTitle, trans.errorBody, 3)
                    
                })
        } catch (error) {
            console.log(error)
            dispacth(loading())
            openNotification('error', trans.errorTitle, trans.errorBody, 3)
        }
    }
}

