import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { crud, dateFormatter } from '../../config/constants';
import Button from '../anBrains/Button';
import Input from '../anBrains/Input';
import { PlusIcon, TrushIcon, EditIcon } from '../icons';
// import { success } from '../../redux/actions/loader_ac';
import Modal from '../anBrains/Modal';
// import Switch from '../anBrains/Switch';
// import ContentLoader from 'react-content-loader';
import Pagination from '../anBrains/Pagination';
import { fetchEvent } from '../../redux/actions/event_ac';
import { eventType } from '../../redux/action_types';
import { baseUrl } from '../../config';
// import DatePicker from 'react-datepicker'
import manprofile from '../../images/profiles/man.png';
import womanprofile from '../../images/profiles/woman.png';
export default function Event(props) {
    const { projectId, departmentId, departments } = props;
    const dispatch = useDispatch();
    const trans = useSelector(state => state.trans)
    // const theme = useSelector(state => state.theme)
    const current = useSelector(state => state.current)
    // const result = useSelector(state => state.result)
    const event = useSelector(state => state.event)
    const theme = useSelector(state => state.theme)
    const department = useSelector(state => state.department)
    const [isAddOpen, setIsAddOpen] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [item, setItem] = useState({})
    const [request, setRequest] = useState({ projectId, departmentId, rowCount: 4, pageCount: 0, search: "", sort: "id", "desc": true, data: {} })
    // const loader = useSelector(state => state.loader)
    const [selectedDepartmentId, setSelectedDepartmentId] = useState(0)


    const [selectedPage, setSelectedPage] = useState(1)

    const deleteItem = () => {
        setIsDeleteOpen(false)
        dispatch(fetchEvent(request, crud.delete, item, trans))
        let events = event.filter((e) => {
            return e.id !== item.id
        });
        dispatch({ type: eventType, payload: events })
    }

    const setSelectedPageFilter = (n) => {
        setSelectedPage(n)
        setRequest({ ...request, pageCount: n - 1 })
        dispatch(fetchEvent({ ...request, pageCount: n - 1 }, crud.list, {}, trans));
    }


    const fields = {
        name: "",
        description: "",
        projectId,
        departmentId: selectedDepartmentId,
        date: dateFormatter(new Date())
    }
    const [field, setField] = useState(fields)

    const errors = {
        nameErr: "",
        dateError: "",
        departmentIdError: ""
    }


    const [err, setErr] = useState(errors)

    let validate = () => {
        //
        if (field.name.length < 3 || field.name.length > 30) {
            setErr({ ...errors, nameErr: trans.toShort })
            return false
        }
        if (field.date === "" || field.date === undefined) {
            setErr({ ...errors, dateError: trans.pleaseEnterValidDate })
            return false
        }

        if (!field.departmentId || field.departmentId === "0" || field.departmentId === 0) {
            field.departmentId = departments[0].id
        }
        return true
    }
    const register = e => {
        e.preventDefault();
        if (validate()) {
            setErr(errors)
            if (!isEdit) {
                dispatch(fetchEvent({}, crud.create, field, trans))
                setIsAddOpen(false)
            }
            else {
                dispatch(fetchEvent({}, crud.update, field, trans))
                setIsEdit(false)
                setIsAddOpen(false)
            }
        }
    }

    const editItem = (e) => {
        setField(e);
        setIsAddOpen(true);
        setIsEdit(true)
    }
    const [isEdit, setIsEdit] = useState(false)

    const openForm = () => {
        fields.name = "";
        fields.description = "";
        setField(fields)
        setIsAddOpen(true);
    }

    const openDelete = (e) => {
        setIsDeleteOpen(true);
        setItem(e);
    }

    const changeDepartment = (e, fromModal) => {
        var departmentId = e.target.value;
        if (departmentId === "0")
            dispatch(fetchEvent({ ...request, projectId, departmentId }, crud.list, {}, trans));
        else
            dispatch(fetchEvent({ ...request, departmentId }, crud.list, {}, trans));

        setSelectedDepartmentId(departmentId)
    }
    const changeDepartmentModal = (e) => {
        var departmentId = e.target.value;
        setRequest({ ...request, departmentId })
        setField({ ...field, departmentId })
    }
    return (
        <div className="event_container">
            <Modal
                isOpen={isAddOpen}
                withClose
                setIsOpen={setIsAddOpen}
                classes={"modal_add_leave"}>
                <div className="leaveForm">
                    <Input placeholder={trans.suggestionTitle}
                        name={trans.suggestionTitle}
                        header={trans.suggestionTitle}
                        value={field.name}
                        onChange={e => setField({ ...field, name: e.target.value })}
                        error={err.nameErr} />
                    <Input
                        type={"datetime-local"}
                        header={trans.date}
                        value={field.date}
                        onChange={e => setField({ ...field, date: e.target.value })}
                        error={err.dateError} />

                    <div className="input-container">
                        <select
                            className={`input select ${err.projectGroupIdError ? 'input-error' : ''}`}
                            onChange={changeDepartmentModal}
                            value={field.departmentId} >
                            {departments
                                && departments.length > 0
                                && departments.map((d, i) => {
                                    return (<option
                                        key={"departmentSelect" + i}
                                        value={d.id}>{d.name}</option>)
                                })}
                        </select>
                    </div>

                    <Input placeholder={trans.description}
                        name={trans.description}
                        header={trans.description}
                        value={field.description}
                        textarea={true}
                        onChange={e => setField({ ...field, description: e.target.value })}
                        // error={err.descriptionError}
                        maxLength={200}
                        minLength={3} />
                    <Button classes={"btn-primary"}
                        onClick={register}>{trans.save}</Button>
                </div>
            </Modal>
            <Modal
                isOpen={isDeleteOpen}
                withClose
                setIsOpen={setIsDeleteOpen}
                classes={"modal_delete"}>
                {trans.areyousureyouwanttodelete}
                <Button classes="btn-red" onClick={() => deleteItem()}>{trans.confirm}</Button>
            </Modal>
            <div className="table_properties">
                <div className="input-container">
                    <select
                        className="input select selectfilter"
                        onChange={changeDepartment}
                        value={selectedDepartmentId} >
                        <option value="0">{trans.all}</option>
                        {departments
                            && departments.length > 0
                            && departments.map((d, i) => {
                                return (<option
                                    key={"departmentSelect" + i}
                                    value={d.id}>{d.name}</option>)
                            })}
                    </select>
                </div>
                <Button
                    icon={<PlusIcon color="rgba(96, 168, 221, 1)" />}
                    classes="btn-basic"
                    onClick={() => openForm(true)}>{trans.add}</Button>

            </div>
            {
                <div className="event_list">
                    {event && event.map((e, i) => {
                        return (
                            <div className="event_row" key={"event" + i}>
                                <div className="event_profile">
                                    <img src={e.creatorPhoto ? baseUrl + e.creatorPhoto : (e && e.creatorGender) ? manprofile : womanprofile}
                                        title={e.creatorName} />
                                    {/* <span>{e.creatorName}</span> */}
                                </div>
                                <div className="event_desc">
                                    <span>{e.dateString}</span>
                                    <span dangerouslySetInnerHTML={{ __html: e.name }}></span>
                                    <span>{e.description}</span>
                                </div>
                                <button onClick={() => editItem(e)}>
                                    <EditIcon color={(theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")} />
                                </button>
                                <button onClick={() => openDelete(e)}>
                                    <TrushIcon color={(theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)")} />
                                </button>
                            </div>
                        )
                    })}
                </div>

            }

            {current && current.pageCount > 0 &&
                <Pagination
                    setSelectedPage={setSelectedPageFilter}
                    selectedPage={selectedPage} />}
        </div>
    )
}
