import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../anBrains/Button';
import { PlusIcon, TrushIcon, EditIcon } from '../icons';
import Modal from '../anBrains/Modal';
import Input from '../anBrains/Input';
import Pagination from '../anBrains/Pagination'
import { crud, dateFormatter } from '../../config/constants';
import { fetchInOuts } from '../../redux/actions/inOut_ac';
import { inOutType } from '../../redux/action_types'
import DatePicker from 'react-datepicker'
import moment from 'moment-timezone'
import 'moment-timezone'
import "react-datepicker/dist/react-datepicker.css";

export default function InOutTab({ employeeid, isProfile }) {
    const trans = useSelector(state => state.trans)
    const inOut = useSelector(state => state.inOut)
    const theme = useSelector(state => state.theme)
    const result = useSelector(state => state.result)
    const current = useSelector(state => state.current)
    const dispatch = useDispatch()
    const [item, setItem] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [isAddOpen, setIsAddOpen] = useState(false)
    const [listParameters, setListParameters] = useState({ rowCount: 14, pageCount: 0, sort: "id", desc: true, userId: employeeid }, crud.list, {})

    useEffect(() => {
        if (result.success) {
            setIsAddOpen(false);
            setField(fields)
        }
        var curr = new Date();
        curr.setDate(curr.getDate());
        var date = dateFormatter(curr);
        setField({ ...field, endDate: date, startDate: date })
    }, [result.change])

    var fields = {
        id: 0,
        userId: employeeid,
        startDate: "",
        endDate: ""
    }
    const [field, setField] = useState(fields)

    const errors = {
        endDateError: "",
        startDateError: "",
    }
    const [err, setErr] = useState(errors)

    const [asc, setAsc] = useState(false)
    const sortbyField = (f) => {
        setListParameters({ ...listParameters, sort: f, desc: asc ? false : true })
        setAsc(!asc)
        dispatch(fetchInOuts({ ...listParameters, sort: f, desc: asc ? false : true }));
    }

    let validate = () => {
        if (field.startDate === "" || field.startDate === undefined) {
            setErr({ ...errors, startDateError: trans.pleaseEnterValidDate })
            return false
        }
        if (isEdit) {
            if (field.endDate === "" || field.endDate === undefined) {
                setErr({ ...errors, endDateError: trans.pleaseEnterValidDate })
                return false
            }
        }
        return true
    }

    const openAddForm = () => {
        setIsAddOpen(true)
        setIsEdit(false)
    }

    const register = e => {
        e.preventDefault();
        if (validate()) {
            setErr(errors)
            if (!isEdit) {
                dispatch(fetchInOuts({}, crud.create, { ...field, startDate: moment(field.startDate).utcOffset('GMT+03:00')._d, endDate: moment(field.startDate).utcOffset('GMT+03:00')._d }, trans))
            }
            else {
                dispatch(fetchInOuts({}, crud.update, { ...field, startDate: moment(field.startDate).utcOffset('GMT+03:00')._d, endDate: moment(field.endDate).utcOffset('GMT+03:00')._d }, trans));
                setIsEdit(false)
            }
        }
    }

    const deleteConfirm = (e) => {
        setIsDeleteOpen(true)
        setItem(e);
    }
    const deleteItem = () => {
        setIsDeleteOpen(false)
        dispatch(fetchInOuts({}, crud.delete, item, trans))
        let inOuts = inOut.list.filter((e) => {
            return e.id !== item.id
        });
        dispatch({ type: inOutType, payload: inOuts })
    }
    const editItem = (e) => {
        setField({ ...e, startDate: e.startDate.substr(0, 16), endDate: e.endDate.substr(0, 16) });
        // dispatch({ type: itemType, payload: e })
        setIsAddOpen(true);
        setIsEdit(true);
    }

    const [selectedPage, setSelectedPage] = useState(1)
    const setSelectedPageFilter = (n) => {
        setSelectedPage(n)
        setListParameters({ ...listParameters, pageNumber: n - 1 })
        dispatch(fetchInOuts({ ...listParameters, pageNumber: n - 1 }, trans));
    }

    return (
        <>
            <div className="leave">
                <div className="leave_detail_container">
                    {inOut && inOut.avg.avgStart && <div className="inout_detail_box">
                        <div className="leave_detail_box_number ">
                            {inOut.avg.avgStart}
                        </div>
                        <div className="leave_detail_box_text">
                            {trans.avgStartHour}
                        </div>
                    </div>}

                    {inOut && inOut.avg.avgEnd && <div className="inout_detail_box">
                        <div className="leave_detail_box_number ">
                            {inOut.avg.avgEnd}
                        </div>
                        <div className="leave_detail_box_text">
                            {trans.avgEndHour}
                        </div>
                    </div>}

                </div>
                <div className="table_properties">
                    {isProfile ?
                        <Button
                            icon={<PlusIcon color={theme === "dark" ? "#3e9fbe" : "#1a9bc4"} />}
                            classes="btn btn-basic"
                            onClick={() => openAddForm()}>{trans.add}</Button> : null}
                </div>
                <div className="table">
                    <table>
                        <thead>
                            <tr>
                                <th>{trans.day}</th>
                                <th>{trans.entry}</th>
                                <th>{trans.exit}</th>
                                {isProfile ?
                                    <th>{trans.settings}</th> : null}
                            </tr>
                        </thead>
                        <tbody>
                            {inOut && inOut.list.length > 0 && inOut.list.map((e, i) => {
                                return (
                                    <tr className="row" key={e.id}>
                                        <td>{e.day}</td>
                                        <td >{e.startHour}</td>
                                        <td>{e.endHour}</td>
                                        {isProfile ?
                                            <td>
                                                <button onClick={() => editItem(e)}>
                                                    <EditIcon color={(theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")} />
                                                </button>
                                                <button onClick={() => deleteConfirm(e)}>
                                                    <TrushIcon color={(theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)")} />
                                                </button>
                                            </td> : null}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>

                </div>{current && current.pageCount > 0 &&
                    <Pagination
                        setSelectedPage={setSelectedPageFilter}
                        selectedPage={selectedPage} />}
            </div>
            <Modal
                isOpen={isAddOpen}
                withClose
                setIsOpen={setIsAddOpen}
                classes={"modal_add_leave"}>
                <div className="leaveForm">
                    <div className="input-container">
                        <span className="input_header">{trans.startdate}</span>
                        <div className="input">
                            {/* <DatePicker
                                selected={field.startDate}
                                onChange={date => setField({ ...field, startDate: date })}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat="dd-MM-yyyy HH:mm" /> */}
                            <Input
                                type={"datetime-local"}
                                autoComplate={"off"}
                                value={field.startDate}
                                onChange={e => setField({ ...field, startDate: e.target.value })} />
                        </div>
                    </div>
                    {isEdit ?
                        <div className='input-container'>
                            <span className='input_header'>{trans.enddate}</span>
                            <div className='input'>
                                {/* <DatePicker
                                    selected={field.endDate}
                                    onChange={date => setField({ ...field, endDate: date })}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    dateFormat="dd-MM-yyyy HH:mm" /> */}
                                <Input
                                    type={"datetime-local"}
                                    autoComplate={"off"}
                                    value={field.endDate}
                                    onChange={e => setField({ ...field, endDate: e.target.value })} />
                            </div>
                        </div>
                        : null}
                    <Button classes={"btn-primary"}
                        onClick={register}>{trans.save}</Button>
                </div>
            </Modal>
            <Modal
                isOpen={isDeleteOpen}
                withClose
                setIsOpen={setIsDeleteOpen}
                classes={"modal_delete"}>
                {trans.areyousureyouwanttodelete}
                <Button classes="btn-red" onClick={() => deleteItem()}>{trans.confirm}</Button>
            </Modal>
        </>
    )
}
