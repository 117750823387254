import React from 'react'
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import video from '../../images/login.mp4';
export default function ThankYou(props) {
    const trans = useSelector(state => state.trans)
    const history = useHistory();
    const dispatch = useDispatch()
    return (
        <>
            <div className="login_container">
                <div className="login_image">
                    <video src={video} autoPlay muted loop> </video>
                    <img src="https://timezeta.com/wp-content/uploads/2020/12/6-seffaf-3.gif" />
                    {/* <div className="overlay"></div> */}
                    <div className="anonymous_container">
                        <h1>Teşekkürler ..</h1>
                        <p>Timezeta ekibi olarak teşekkür ederiz.<br />
                        Kaydınızın tamamlanması için <b style={{ color: "rgba(232, 63, 64, 1)" }}>lütfen mail adresinizi kontrol ediniz!</b></p>
                    </div>
                </div>
            </div></>
    )
}
