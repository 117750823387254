import axios from 'axios'
import { axiosConfig, baseUrl,openNotification } from '../../config'
import { pageCountType, addProjectGroupType, projectGroupType, updateProjectGroupType, projectType, departmentType, 
    customerListType, boardListType, projectBoardType, employeeListType,isLoginType } from '../action_types'
import { success, calculatePageNumber, loading } from './loader_ac'
import { crud } from '../../config/constants'
export const fetchProjectGroup = (request = { rowCount: 14, pageCount: 0, sort: "id", desc: true }, requestType = 5, data = {}, trans=undefined) => {
    let headers = axiosConfig.headers

    let url = `${baseUrl}/api/projectgroups`
    if (localStorage.getItem("token")) {
        headers = { ...headers, "authorization": `Bearer ${localStorage.getItem("token")}` }
    }
    return dispacth => {
        try {
            dispacth(loading())
            axios({
                method: 'POST',
                url,
                headers,
                data: { request, requestType, data }
            })
                .then(response => {
                    console.log(response);
                    dispacth(loading())
                    if (response.data.success) {
                        switch (requestType) {
                            case crud.create:
                                dispacth({
                                    type: addProjectGroupType,
                                    payload: response.data.data,
                                })
                                dispacth(success(true))
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.read:
                                break;
                            case crud.update:
                                dispacth({
                                    type: updateProjectGroupType,
                                    payload: response.data.data,
                                })
                                dispacth(success(true))
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.delete:
                                openNotification('success', trans.successTitle, trans.successBody, 3)
                                break;
                            case crud.list:
                                dispacth({
                                    type: projectGroupType,
                                    payload: response.data.data,
                                })
                                dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, request.rowCount) })
                                break;
                            case crud.with:
                                dispacth({
                                    type: projectGroupType,
                                    payload: response.data.data,
                                })
                                dispacth({
                                    type: projectType,
                                    payload: response.data.data2.firstProjects,
                                })

                                dispacth({ type: projectBoardType, payload: response.data.data2.firstProjects })
                                dispacth({ type: pageCountType, payload: calculatePageNumber(response.data.count, 9) })

                                dispacth({
                                    type: departmentType,
                                    payload: response.data.data2.departments,
                                })
                                dispacth({
                                    type: customerListType,
                                    payload: response.data.data2.customers,
                                })
                                dispacth({
                                    type: employeeListType,
                                    payload: response.data.data2.users
                                })

                                break;
                            default:
                                break;
                        }
                    }
                })
                .catch(reason => {
                    console.log();
                    dispacth(loading())
                    openNotification('error', trans.errorTitle, trans.errorBody,3)

                  

                })
        } catch (error) {
            console.log(error)
            dispacth(loading())
            openNotification('error', trans.errorTitle, trans.errorBody,3)
        }
    }
}

