import React, { useEffect, useState } from "react";
import Layout from "../../components/Container/Layout";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectGroup } from "../../redux/actions/project_group_ac";
import { crud } from "../../config/constants";
import { projectGroupType } from "../../redux/action_types";
import Button from "../../components/anBrains/Button";
import Input from "../../components/anBrains/Input";
import { Search2Icon, PlusIcon, SortIcon, EditIcon, TrushIcon } from "../../components/icons";
import { success } from "../../redux/actions/loader_ac";
import Modal from "../../components/anBrains/Modal";
import Switch from "../../components/anBrains/Switch";
import ContentLoader from "react-content-loader";
import { Pagination } from "antd";

export default function ProjectGroup(props) {
  const dispatch = useDispatch();
  const trans = useSelector((state) => state.trans);
  const theme = useSelector((state) => state.theme);
  const current = useSelector((state) => state.current);
  const result = useSelector((state) => state.result);
  const projectgroup = useSelector((state) => state.projectgroup);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [item, setItem] = useState({});
  const [request, setRequest] = useState({ type: -1, rowCount: 14, pageCount: 0, search: "", sort: "id", desc: false, data: {} });
  const loader = useSelector((state) => state.loader);

  useEffect(() => {
    dispatch(fetchProjectGroup(request, crud.list, {}, trans));
  }, []);

  const changeStatus = (a, e) => {
    console.log(a.target.value, e);
    let updateItem = {};
    for (let index = 0; index < projectgroup.list.length; index++) {
      const selectedItem = projectgroup.list[index];
      if (selectedItem.id === e.id) {
        projectgroup.list[index].active = a.target.checked;
        updateItem = projectgroup.list[index];
      }
    }
    dispatch({ type: projectGroupType, payload: projectgroup.list });
    dispatch(fetchProjectGroup(request, crud.update, updateItem, trans));
  };

  const changeGeneratedCode = (a, e) => {
    console.log(a.target.checked, e);
    let updateItem = {};
    for (let index = 0; index < projectgroup.list.length; index++) {
      const selectedItem = projectgroup.list[index];
      if (selectedItem.id === e.id) {
        projectgroup.list[index].generateCode = a.target.checked;
        updateItem = projectgroup.list[index];
      }
    }
    dispatch({ type: projectGroupType, payload: projectgroup.list });
    dispatch(fetchProjectGroup(request, crud.update, updateItem, trans));
  };
  // const showBar = () => {
  //     dispatch({ type: barListType })
  // }
  // const showGrid = () => {
  //     dispatch({ type: gridListType })
  // }
  const [selectedPage, setSelectedPage] = useState(1);
  const deleteConfirm = (e) => {
    setIsDeleteOpen(true);
    setItem(e);
  };

  const deleteItem = () => {
    setIsDeleteOpen(false);
    dispatch(fetchProjectGroup(request, crud.delete, item, trans));
    let projectgroups = projectgroup.list.filter((e) => {
      return e.id !== item.id;
    });
    dispatch({ type: projectGroupType, payload: projectgroups });
  };

  const setSelectedPageFilter = (n) => {
    setSelectedPage(n);
    setRequest({ ...request, pageCount: n - 1 });
    dispatch(fetchProjectGroup({ ...request, pageCount: n - 1 }, crud.list, {}, trans));
  };
  const [desc, setDesc] = useState(false);
  const sortbyField = (f) => {
    setRequest({ ...request, sort: f, desc });
    dispatch(fetchProjectGroup({ ...request, sort: f, desc }, crud.list, {}, trans));
    setDesc(!desc);
  };

  const searchChange = (text) => {
    setSearchText(text);
    if (text.length > 2) {
      dispatch(fetchProjectGroup({ ...request, search: text }, crud.list, {}, trans));
    }
  };

  const searchChangeKeyPress = (text, kcode) => {
    if (kcode === 8) {
      dispatch(fetchProjectGroup({ ...request, search: text }, crud.list, {}, trans));
    }
  };

  // const editItem = (e) => {
  //     if (breadcrumbs && breadcrumbs.some(b => b.name === e.name)) { }
  //     if (breadcrumbs.length > 1) {
  //         var newBreadCrumbs = [];
  //         newBreadCrumbs.push(breadcrumbs[0]);
  //         newBreadCrumbs.push({ name: e.name, path: "/definitions/project-group" });
  //         dispatch({
  //             type: breadcrumbType,
  //             payload: newBreadCrumbs
  //         })
  //     }
  //     else {
  //         dispatch({ type: addBreadcrumbType, payload: { name: e.name, path: "/definitions/project-group" } })
  //     }
  // }
  useEffect(() => {
    if (result.success === true) {
      setIsAddOpen(false);
      dispatch(success(false));
    }
  }, [result.change]);

  const [searchText, setSearchText] = useState("");

  var fields = {
    generateCode: false,
    name: "",
  };
  const [field, setField] = useState(fields);

  const errors = {
    nameErr: "",
  };
  const [err, setErr] = useState(errors);

  let validate = () => {
    if (field.name.length < 3 || field.name.length > 30) {
      setErr({ ...errors, nameErr: trans.toShort });
      return false;
    }

    return true;
  };
  const register = (e) => {
    e.preventDefault();
    if (validate()) {
      setErr(errors);
      if (!isEdit) dispatch(fetchProjectGroup({}, crud.create, field, trans));
      else {
        dispatch(fetchProjectGroup({}, crud.update, field, trans));
        setIsEdit(false);
      }
    }
  };

  const editItem = (e) => {
    console.log(e);
    setField(e);
    setIsAddOpen(true);
    setIsEdit(true);
  };
  const [isEdit, setIsEdit] = useState(false);

  const changeGenerateCode = (e) => {
    setField({ ...field, generateCode: !field.generateCode });
  };
  const openForm = () => {
    setField(fields);
    setIsAddOpen(true);
  };
  return (
    <Layout>
      <Modal isOpen={isAddOpen} withClose setIsOpen={setIsAddOpen} classes={"modal_add_leave"}>
        <div className="leaveForm">
          <Input
            placeholder={trans.definition}
            name={trans.definition}
            header={trans.definition}
            value={field.name}
            onChange={(e) => setField({ ...field, name: e.target.value })}
            error={err.nameErr}
          />
          <div className="input-container">
            <span className="input_header">{trans.generateCode}</span>
            <Switch classes="mediumSwitch" id="generatedCodeField" value={field.generateCode} onChange={(a) => changeGenerateCode(a)} />
          </div>

          <Button classes={"btn-primary"} onClick={register}>
            {trans.save}
          </Button>
        </div>
      </Modal>
      <Modal isOpen={isDeleteOpen} withClose setIsOpen={setIsDeleteOpen} classes={"modal_delete"}>
        {trans.areyousureyouwanttodelete}
        <Button classes="btn-red" onClick={() => deleteItem()}>
          {trans.confirm}
        </Button>
      </Modal>
      <div className="table_properties">
        <Input
          icon={<Search2Icon color="rgba(96, 168, 221, 1)" />}
          placeholder={trans.search}
          value={searchText}
          onChange={(e) => searchChange(e.target.value)}
          onKeyUp={(e) => searchChangeKeyPress(e.target.value, e.keyCode)}
        />
        <Button icon={<PlusIcon color="rgba(96, 168, 221, 1)" />} classes="btn-basic" onClick={() => openForm(true)}>
          {trans.add}
        </Button>
        {/* <div className="bar_grid">
                    <button onClick={showBar}>
                        <BarIcon color={current.bar ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "#1a9bc4") : (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")}
                        />
                    </button>
                    <button onClick={showGrid}>
                        <GridIcon color={current.grid ? (theme === "dark" ? "rgba(96, 168, 221, 1)" : "#1a9bc4") : (theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)")}
                        />
                    </button>
                </div> */}
      </div>
      {!loader.loading ? (
        <div className="table">
          <table>
            <thead>
              <tr>
                <th onClick={() => sortbyField("name")}>
                  {trans.definition}
                  <SortIcon color="rgba(96, 168, 221, 1)" />
                </th>
                <th>{trans.generateCode}</th>
                <th>{trans.status}</th>
                <th>{trans.settings}</th>
              </tr>
            </thead>
            <tbody>
              {projectgroup.list &&
                projectgroup.list.length > 0 &&
                projectgroup.list.map((e, i) => {
                  return (
                    <tr className="row" key={i}>
                      <td>{e.name}</td>
                      <td>
                        <Switch id={"customerStatusSwitchGeneratedCode" + e.id} value={e.generateCode} onChange={(a) => changeGeneratedCode(a, e)} />
                      </td>
                      <td>
                        <Switch id={"customerStatusSwitch" + e.id} value={e.active} onChange={(a) => changeStatus(a, e)} />
                      </td>
                      <td>
                        <button onClick={() => editItem(e)}>
                          <EditIcon color={theme === "dark" ? "rgba(96, 168, 221, 1)" : "rgba(96, 168, 221, 1)"} />
                        </button>
                        <button onClick={() => deleteConfirm(e)}>
                          <TrushIcon color={theme === "dark" ? "rgba(232, 63, 64, 1)" : "rgba(232, 63, 64, 1)"} />
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      ) : (
        <table>
          <thead>
            <tr>
              <th>{trans.definition}</th>
              <th>{trans.generateCode}</th>
              <th>{trans.status}</th>
              <th>{trans.settings}</th>
            </tr>
          </thead>
          <tbody>
            <tr className="row">
              <td colSpan={4}>
                <ContentLoader
                  preserveAspectRatio="none"
                  backgroundColor={theme === "dark" ? "#666666" : "#efefef"}
                  foregroundColor={theme === "dark" ? "#222222" : "#cccccc"}
                  viewBox="0 0 1000 60"
                  height={60}
                  width="100%"
                  style={{ width: "100%" }}
                  speed={2}
                >
                  <rect x="110" y="21" rx="4" ry="4" width="754" height="6" />
                  <rect x="111" y="41" rx="3" ry="3" width="185" height="7" />
                  <rect x="304" y="-46" rx="3" ry="3" width="350" height="6" />
                  <rect x="371" y="-45" rx="3" ry="3" width="380" height="6" />
                  <rect x="484" y="-45" rx="3" ry="3" width="201" height="6" />
                </ContentLoader>
              </td>
            </tr>
          </tbody>
        </table>
      )}

      {current && current.pageCount > 0 && (
        <Pagination
          defaultCurrent={selectedPage}
          onChange={setSelectedPageFilter}
          onShowSizeChange={(pageCount, rowCount) => {
            dispatch(fetchProjectGroup({ ...request, pageCount, rowCount }, crud.list, {}, trans));
            setRequest({ ...request, pageCount, rowCount });
          }}
          total={current.pageCount * request.rowCount}
        />
      )}
    </Layout>
  );
}
