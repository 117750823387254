import { combineReducers } from 'redux';
import user_rd from './user_rd'
import lang_rd from './lang_rd'
import loader_rd from './loader_rd'
import theme_rd from './theme_rd'
import size_rd from './size_rd'
import trans_rd from './trans_rd'
import employee_rd from './employee_rd'
import current_rd from './current_rd'
import position_rd from './position_rd'
import result_rd from './result_rd'
import leave_rd from './leave_rd'
import customer_rd from './customer_rd'
import overtime_rd from './overtime_rd'
import debit_rd from './debit_rd'
import payroll_rd from './payroll_rd'
import employee_document_rd from './employee_document_rd'
import inOut_rd from './inOut_rd'
import projectGroup_rd from './projectGroup_rd'
import user_file_type_rd from './user_file_type_rd'
import holiday_rd from './holiday_rd'
import department_rd from './department_rd'
import authorization_rd from './authorization_rd'
import project_rd from './project_rd'
import event_rd from './event_rd'
import meetingGroup_rd from "./meetingGroup_rd"
import meeting_rd from "./meeting_rd"
import work_order_rd from './work_order_rd'
import label_rd from './label_rd'
import calendar_rd from './calendar_rd'
import suggestion_box_rd from './suggestion_box_rd'
import company_culture_rd from './company_culture_rd'
import employee_performance_rd from "./employee_performance_rd"
import file_rd from "./file_rd"
import gantt_rd from "./gantt_rd"
import company_setting_rd from "./company_setting_rd"
import notification_rd from "./notification_rd"
import report_rd from "./report_rd"
import project_cost_rd from "./project_cost_rd"
import response_rd from "./response_rd"
import customer_event_rd from './customer_event_rd'
import workOrderGroup_rd from './workOrderGroup_rd'
const rootReducer = combineReducers({
    user: user_rd,
    lang: lang_rd,
    loader: loader_rd,
    theme: theme_rd,
    size: size_rd,
    trans: trans_rd,
    employee: employee_rd,
    current: current_rd,
    position: position_rd,
    result: result_rd,
    leave: leave_rd,
    customer: customer_rd,
    debit: debit_rd,
    overtime: overtime_rd,
    payroll: payroll_rd,
    employeedocument: employee_document_rd,
    inOut: inOut_rd,
    projectgroup: projectGroup_rd,
    userfiletype: user_file_type_rd,
    holiday: holiday_rd,
    department: department_rd,
    authorization: authorization_rd,
    project: project_rd,
    event: event_rd,
    meetingGroup: meetingGroup_rd,
    meetings: meeting_rd,
    workorder: work_order_rd,
    label: label_rd,
    calendar: calendar_rd,
    suggestionBox: suggestion_box_rd,
    companyCulture: company_culture_rd,
    performancemanagement: employee_performance_rd,
    file: file_rd,
    gantt: gantt_rd,
    companySetting: company_setting_rd,
    notification: notification_rd,
    report: report_rd,
    project_cost: project_cost_rd,
    response: response_rd,
    customerevent : customer_event_rd,
    workordergroup:workOrderGroup_rd
});

export default rootReducer;